export enum FirstLedStatus {
    Fixed,
    Flashing,
    WipedOut
}

export enum SecondLedStatus {
    BlinkingTooFast,
    Shivering,
    BlinkingEvery
}

export enum ServiceType {
    Replacement,
    Installation
}


export class InstallationDTO {
    public serial?: string;
    public latitude?: number;
    public longitude?: number;
    public note?: string;
    public antennaIsInTheCorrectPosition?: boolean;
    public secods?: number;
    public user?: string;
    public firstLedStatus?: FirstLedStatus;
    public secodLedStatus?: SecondLedStatus;
    public createdAt?: Date;
    public isComissioned?: boolean;
    public nicSerial?: string;
    public installation?: string;
    public teamIdentification?: string;
    public serviceType?: ServiceType;
}