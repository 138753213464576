import { RegistersDTO } from "./RegistersDTO";

export enum Measure {
  TotalEnergy_Wh = 0, // Totalizador Geral (kWh)
  BorderTotalizer_Wh = 1, // Totalizador Ponta (kWh)
  ReservedTotalizer_Wh = 2, // Totalizador Reservado  (kWh)
  OffBorderTotalizer_Wh = 3, // Totalizador Fora Ponta (kWh)
  TotalIntermediary_Wh = 4, // Total Intermediário (kWh)
  GeneralTotalizer = 5, // Totalizador Geral (kVarh)
  ReservedTotalizer = 6, // Totalizador Reservado (kVarh)
  GeneratedEnergyTotal_Wh = 7, // 0 Wh Energia Gerada - Total
  GeneratedOffBorderEnergyTotal_Wh = 8, // 0 Wh Energia Gerada Ponta
  GeneratedIntermediaryEnergyTotal_Wh = 9, // Energia Gerada Reservada
  GeneratedBorderEnergyTotal_Wh = 10, // Energia Gerada Fora Ponta
  GeneratedReservedEnergyTotal_Wh = 11, // Energia Gerada Intermediária
}

export class Measures {
  measure: Measure;
  value: number;
}

const Billing = {
  "03": {
    label: "Totalizador Geral (kWh)",
    code: 0,
  },
  "04": {
    lable: "Totalizador Ponta (kWh)",
    code: 1,
  },
  "06": {
    label: "Totalizador Reservado  (kWh)",
    code: 2,
  },
  "08": {
    lable: "Totalizador Fora Ponta (kWh)",
    code: 3,
  },
  "09": {
    label: "Total Intermediário (kWh)",
    code: 4,
  },
  "24": {
    lable: "Totalizador Geral (kVarh)",
    code: 5,
  },
  "27": {
    label: "Totalizador Reservado (kVarh)",
    code: 6,
  },
  G3: {
    lable: "Energia Gerada - Total",
    code: 7,
  },
  G4: {
    label: "Energia Gerada Ponta",
    code: 8,
  },
  G6: {
    label: "Energia Gerada Reservada",
    code: 9,
  },
  G8: {
    label: "Energia Gerada Fora Ponta",
    code: 10,
  },
  G9: {
    label: "Energia Gerada Intermediária",
    code: 11,
  },
};

export interface IReading {
  registers: RegistersDTO[];
  serial: string;
  origin: string;
  installation: string;
  datetime: Date;
  measures: Measures[];
}

export class ReadingDTO implements IReading {
  public registers: RegistersDTO[];
  public serial: string;
  public origin: string;
  public installation: string;
  public measures: Measures[];
  public datetime: Date;

  static getMeasures(measures: Measures[], register: RegistersDTO) {
    let registerCode = register.code || register["Code"];
    const currentCode = Billing[registerCode]
      ? Billing[registerCode].code
      : null;

    let currentMeasure: Measures = null;
    measures.forEach((_measure) => {
      if (
        currentCode === _measure.measure ||
        currentCode === _measure["Measure"]
      ) {
        currentMeasure = _measure;
      }
    });
    let value = null;
    if (currentMeasure !== null)
      value = currentMeasure.value || currentMeasure["Value"];
    return value / 1000 || 0;
  }
}
