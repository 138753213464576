import { Component, Input, Output, EventEmitter } from '@angular/core';

import { RegistersDTO } from 'src/app/core/models/RegistersDTO';
import { Utils } from 'src/app/shared/utils';
@Component({
  selector: 'app-registers-table',
  templateUrl: './registers-table.component.html',
  styleUrls: ['./registers-table.component.scss']
})
export class RegistersTableComponent {

  @Input() registers: RegistersDTO[] = [];
  @Input() isLoading = true;


  @Output() filter = new EventEmitter();
  @Output() reload = new EventEmitter();

  Utils = Utils;



  updateSearchString(stringValue: string) {
    this.filter.emit(stringValue);
  }

  reloadRegisters() {
    this.reload.emit();
  }

}
