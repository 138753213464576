import { Injectable } from "@angular/core";
import * as FileSaver from "file-saver";
import * as moment from "moment";
import * as XLS from "xlsx";
import { DatatableComponent, TableColumn } from "@swimlane/ngx-datatable";

import { Utils } from "src/app/shared/utils";
import { ActionType, ReceiptDTO } from "src/app/core/models/ReceiptDTO";
import { IBalanceResult, IMetersBalance } from 'src/app/core/models/BalanceDTO';
moment.locale("pt-br");

const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";

@Injectable({ providedIn: 'root' })
export class FileService {
  public buildAndSaveFile(
    dataTable: DatatableComponent,
    fileName: string,
    sheetName: string
  ) {
    const table = dataTable;
    const columns: TableColumn[] = table.columns || table._internalColumns;
    const rows: [] = table.rows.map((row) => {
      let jsonFile = {};
      columns.forEach((column) => {
        const prop = column.prop;
        if (
          prop === "dateTime" ||
          prop === "updatedAt" ||
          prop === "readDateTime" ||
          prop === "datetimeFinal"
        ) {
          jsonFile[column.name] = Utils.pipeDateTime(row[prop]);
        } else {
          if (!isNaN(row[prop]) && prop !== "serial") {
            if (column.name.toLowerCase().includes('kwh') || column.name.toLowerCase().includes('kvarh')) {
              jsonFile[column.name] = Number(row[prop] / 1000);
            }
            else
              jsonFile[column.name] = Number(row[prop]).toFixed(2);
          } else if (prop === "status['message']") {
            jsonFile["Status"] = row.status.message;
          } else {
            jsonFile[column.name] = row[prop];
          }
        }
      });
      return jsonFile;
    });
    this.exportAsExcelFile([...rows], fileName, sheetName);
  }

  buildAndSaveServiceNotes(dataTable: any, fileName: string) {
    const table = dataTable;
    try {
      const rows = this.buildServicesNotes(table);
      const workSheet: XLS.WorkSheet = XLS.utils.json_to_sheet([...rows]);
      workSheet["!cols"] = [{ width: 45 }];
  
      const workbook: XLS.WorkBook = {
        Sheets: {
          "Notas de Serviços": workSheet,
        },
        SheetNames: ["Notas de Serviços"],
      };
      const excelBuffer: any = XLS.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      this.saveFileAsExcel(excelBuffer, fileName);
    } catch(error) {
      console.log(error)
    }


  }

  buildAndSaveBalance(dataTable: DatatableComponent, fileName: string) {
    const table = dataTable;
    const rows = this.buildBalance(table.rows);

    const workSheet: XLS.WorkSheet = XLS.utils.json_to_sheet([...rows]);
    workSheet["!cols"] = [{ width: 45 }];

    const workbook: XLS.WorkBook = {
      Sheets: {
        "Balanços": workSheet,
      },
      SheetNames: ["Balanços"],
    };
    const excelBuffer: any = XLS.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    this.saveFileAsExcel(excelBuffer, fileName);
  }

  buildAndSaveDevicesBalance(dataTable: DatatableComponent, fileName: string) {
    const table = dataTable;
    const rows = this.buildBalancePerDevices(table.rows);

    const workSheet: XLS.WorkSheet = XLS.utils.json_to_sheet([...rows]);
    workSheet["!cols"] = [{ width: 20 }];

    const workbook: XLS.WorkBook = {
      Sheets: {
        "Balanços": workSheet,
      },
      SheetNames: ["Balanços"],
    };
    const excelBuffer: any = XLS.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    this.saveFileAsExcel(excelBuffer, fileName);
  }

  private exportAsExcelFile(jsonData: any, fileName: string, sheetName) {
    const workSheet: XLS.WorkSheet = XLS.utils.json_to_sheet(jsonData);
    workSheet["!cols"] = [{ width: 45 }];
    if (sheetName === "Memória de Massa") {
      const workbook: XLS.WorkBook = {
        Sheets: {
          "Memória de Massa": workSheet,
        },
        SheetNames: [sheetName],
      };
      const excelBuffer: any = XLS.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      this.saveFileAsExcel(excelBuffer, fileName);
      return;
    } else if (sheetName === "Alarmes") {
      const workbook: XLS.WorkBook = {
        Sheets: {
          Alarmes: workSheet,
        },
        SheetNames: [sheetName],
      };
      const excelBuffer: any = XLS.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      this.saveFileAsExcel(excelBuffer, fileName);
      return;
    } else if (sheetName === "Parametro de Qualidade") {
      const workbook: XLS.WorkBook = {
        Sheets: {
          "Parametro de Qualidade": workSheet,
        },
        SheetNames: [sheetName],
      };
      const excelBuffer: any = XLS.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      this.saveFileAsExcel(excelBuffer, fileName);
      return;
    } else if (sheetName === "Balanco") {
      const workbook: XLS.WorkBook = {
        Sheets: {
          "Balanço": workSheet,
        },
        SheetNames: [sheetName],
      };
      const excelBuffer: any = XLS.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      this.saveFileAsExcel(excelBuffer, fileName);
      return;
    }

    const workbook: XLS.WorkBook = {
      Sheets: {
        "Energia": workSheet,
      },
      SheetNames: [sheetName],
    };
    const excelBuffer: any = XLS.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    this.saveFileAsExcel(excelBuffer, fileName);
  }

  public saveFileAsExcel(buffer: any, fileName: string) {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }

  public saveGroup(json: any, name) {
    const workSheet: XLS.WorkSheet = XLS.utils.json_to_sheet(json);
    workSheet["!cols"] = [{ width: 45 }];

    const WorkBook: XLS.WorkBook = {
      Sheets: {
        Grupo: workSheet,
      },
      SheetNames: ["Grupo"],
    };
    const excelBuffer: any = XLS.write(WorkBook, {
      bookType: "xlsx",
      type: "array",
    });
    this.saveFileAsExcel(excelBuffer, name);
  }

  public readExcelFile(event: any) {
    const file: File = event.target.files[0];
    let fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);

    fileReader.onload = () => {
      const arrayBuffer: any = fileReader.result;
      const data = new Uint8Array(arrayBuffer);
      let array = new Array();
      for (let index = 0; index !== data.length; ++index) {
        array[index] = String.fromCharCode(data[index]);
      }
      const listOfKeys = array.join("");
      const workbook = XLS.read(listOfKeys, { type: "binary" });
      const fileName = workbook.SheetNames[1];
      const workSheet = workbook.Sheets[fileName];
    };
  }

  private buildServicesNotes(jsonFile: any[]) {
    let arrayOfFiles = [];

    jsonFile.forEach((row) => {
      arrayOfFiles.push(this.generateJson(row));
    });

    return arrayOfFiles;
  }


  private buildBalance(jsonFile: IBalanceResult[]) {
    let arrayOfBalances = [];
    jsonFile.forEach(_balance => {
      arrayOfBalances.push(this.generateBalance(_balance));
    });
    return arrayOfBalances;
  }

  private buildBalancePerDevices(jsonFile: IMetersBalance[]) {
    let arrayOfBalances = [];
    jsonFile.forEach(_balance => {
      arrayOfBalances.push(this.generateBalanceDevices(_balance));
    });
    return arrayOfBalances;
  }

  private generateBalanceDevices(balance: IMetersBalance) {

    return {
      "Trafo": balance.trafoNumber,
      "Dispositivo": balance.serial,
      "Data Energ. Consumi. Inicial": Utils.formateDateTime(balance.initialDate),
      'Inicial Ener. Consumida (kWh) ': balance.initialReading,
      "Data Energ. Consumi. Final": Utils.pipeDateTime(balance.finalDate),
      "Final Ener. Consumida (kWh)": balance.finalReading,
      "Consumo (kWh)": balance.energyConsumed,
    }
  }

  private generateBalance(balance: IBalanceResult) {
    let file = {
      "Data Incial": Utils.pipeDateTime(balance.initialDateTime),
      "Nome do Trafo": balance.transformer.name,
      "Medidor Trafo": balance.transformer.transformerMeter.serial,
      'Ener. Requerida (kWh)': balance.energyRequired,
      "Ener. Consumida (kWh)": balance.energyConsumed,
      // "Energ. IP (kWh)": balance.streetLighting,
      "Perda (kWh)": balance.loss,
      "Perda (%)": balance.lossPercentage,
    }
    return file;
  }




  private generateJson(json: any): any {
    let file = {
      "Data de Execução": null,
      DeadLine: null,
      "Última Atualização": null,
      "Código de Rastreo": null,
      Ação: null,
      Empresa: null,
      Status: null,
      "Número de Nota": null,
      "Tipo de Nota": null,
      "Serial do Equipamento": null,
      "Código de Grupo": null,
      "Id do Ticket": null,
      Comando: null,
      "Inicio de Execução": null,
      "Fim de Execução": null,
      "Status do Ticket": null,
      "DRC ( % )": null,
      "DRP ( % )": null,
    };

    file["Data de Execução"] = Utils.pipeDateTime(json.startDate);
    file.DeadLine = Utils.pipeDateTime(json.deadline);
    file["Última Atualização"] = Utils.pipeDateTime(json.updateAt);
    file["Código de Rastreo"] = json.trackingCode;
    file.Ação = ReceiptDTO.getActionType(json.actionType);
    file.Empresa = ReceiptDTO.getCompany(json.company);
    file.Status = ReceiptDTO.getStatus(json.status);
    file["Número de Nota"] = json.dataRequest.NumeroNota;
    file["Tipo de Nota"] = json.dataRequest.TipoNota;
    file["Serial do Equipamento"] = json.meterSerial;
    file["Código de Grupo"] = json.dataRequest.GrupoCode;
    if (json.dataResponse !== undefined && json.dataResponse !== null) {
      if (parseInt(json.actionType) === ActionType.RequestNoteLq) {
        file["DRC ( % )"] = Number(JSON.parse(json.dataResponse).drc).toFixed(2);
        file["DRP ( % )"] = Number(JSON.parse(json.dataResponse).drp).toFixed(2);
      }

    }
    if (json.dataResponse !== undefined && json.dataResponse !== null && json.dataResponse.Ticket !== undefined) {

      file["Id do Ticket"] = json.dataResponse.Ticket.ticketId;
      file["Inicio de Execução"] = Utils.pipeDateTime(
        json.dataResponse.Ticket.initialDate
      );
      file["Fim de Execução"] = Utils.pipeDateTime(
        json.dataResponse.Ticket.finishDateTime
      );
      file["Status do Ticket"] = ReceiptDTO.getTicketStatus(
        json.dataResponse.Ticket.status
      );
      file.Comando = ReceiptDTO.getCommandType(
        json.dataResponse.Ticket.commandType
      );
    }

    return file;
  }
}
