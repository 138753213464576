import { Component, OnInit } from "@angular/core";
import { ScheduleCommandService } from "../../services/schedules-commands.service";
import { ScheduleCommand, ScheduleCommandDTO, ScheduleCommandType } from "src/app/core/models/ScheduleCommandDTO";
import { HandleErrorService } from "../../services/handle-error.service";
import { PaginationResponseDTO } from "src/app/core/models/PaginationResponseDTO";
import { AuthService } from "src/app/security/auth.service";
import { UserType } from "src/app/core/models/UserDTO";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Utils } from "../../utils";
import cronstrue from 'cronstrue/i18n';
import { Router } from "@angular/router";
import { MessageService } from "../../services/message.service";
import { TranslateInCodeService } from "../../services/translate-in-code.service";
import { TranslateService } from "@ngx-translate/core";
import { EnumMapperDTO } from "src/app/core/models/EnumMapperDTO";

@Component({
    selector: 'app-scheduled-commands-table',
    styleUrls: ['./scheduled-commands.component.scss'],
    templateUrl: './scheduled-commands.component.html'
})
export class ScheduledCommandsTableComponent implements OnInit {

    public page: PaginationResponseDTO = new PaginationResponseDTO();
    public canCreatTicket = [UserType.ADMIN, UserType.OPERATOR];

    public isLoading: boolean = true;
    public isDeleting: boolean = false;
    public isLoadingStatus: boolean = false;
    isReloadingSchedules: boolean = false;

    public commands: ScheduleCommand[] = [];
    public Utils = Utils;

    public seacrhString: string = '';
    public sortBy: string = '';
    public idSchedule: string;
    ScheduleCommandDTO = ScheduleCommandDTO;

    enumMapperDTO: EnumMapperDTO;
    constructor(
        private scheduleCommandService: ScheduleCommandService,
        private messageService: MessageService,
        private modal: NgbModal,
        private errorHandle: HandleErrorService,
        public authService: AuthService,
        private _routerService: Router,
        private translateInCode: TranslateInCodeService,
        private translate: TranslateService
    ) { }

    async ngOnInit() {
        await this.getTranslate();
        this.translate.onLangChange.subscribe(e => {
            this.getTranslate();
            this.getSchedules();
        });
        this.getSchedules();
    }

    async getTranslate() {
        this.enumMapperDTO = await this.translateInCode.getTranslate('Schedule.ScheduleCommandType', ScheduleCommandType);
    }

    reload() {
        this.getSchedules();
    }

    formatCron(cron) {
        let lang = Utils.getLang() 
        lang = lang == 'pt-BR' ? lang.replace('-', '_') : lang;

        return cronstrue.toString(cron, {locale: lang });
    }

    searchByGroup(search) {
        this.seacrhString = search;
        this.reloadSchedules();
    }

    sortByProp(event) {
        const sort = event.sorts[0];
        this.sortBy = `${sort.prop}-${sort.dir}`;
        this.reloadSchedules();
    }

    changePage(event) {
        const { page } = event;
        this.page.page = page;
        this.reloadSchedules();
    }

    changeSizeValue(event) {
        this.page.pageSize = event;
        this.reloadSchedules();
    }

    deleteScheduleCommand() {
        this.isDeleting = true;
        this
            .scheduleCommandService
            .delete(this.idSchedule)
            .toPromise()
            .then(res => {
                this.commands = this.commands.filter(command => command.id != this.idSchedule);
                this.messageService.showSuccess('Agendamento de Comando', 'Agendamento deletado com sucesso!');
                this.isDeleting = false;
                this.closeModal();
            }).catch(error => {
                this.errorHandle.handle(error);
                this.isDeleting = false;
            });
    }

    getSchedules() {
        this.isLoading = true;
        this
            .scheduleCommandService
            .getWithPagination({
                searchString: this.seacrhString,
                sortBy: this.sortBy,
                page: this.page.page ? this.page.page : 1,
                pageSize: this.page.pageSize
            })
            .toPromise()
            .then(res => {
                this.commands = <ScheduleCommand[]>res.listOfItems;
                Object.assign(this.page, res);
                this.isLoading = false;
            }).catch(error => {
                this.errorHandle.handle(error);
                this.isLoading = false;
            });
    }

    reloadSchedules() {
        this.isReloadingSchedules = true;
        this
            .scheduleCommandService
            .getWithPagination({
                searchString: this.seacrhString,
                sortBy: this.sortBy,
                page: this.page.page ? this.page.page : 1,
                pageSize: this.page.pageSize
            })
            .toPromise()
            .then(res => {
                this.commands = <ScheduleCommand[]>res.listOfItems;
                Object.assign(this.page, res);
                this.isReloadingSchedules = false;
            }).catch(error => {
                this.errorHandle.handle(error);
                this.isReloadingSchedules = false;
            });
    }

    changeScheduleStatus(schedule: ScheduleCommand) {
        this.isLoadingStatus = true;
        this.scheduleCommandService.setScheduleStatus(schedule.id, !schedule.status).toPromise()
            .then(response => {
                this.messageService.showSuccess('Alterado', 'Status do agendamento alterado!');
                const scheduleIndex = this.commands.findIndex(_schedule => _schedule.id === schedule.id);
                this.commands[scheduleIndex] = response;
                this.commands = [...this.commands];
                this.isLoadingStatus = false;
            })
            .catch(error => {
                this.isLoadingStatus = false;
                this.errorHandle.handle(error);
            })
    }

    openModal(content, id: string) {
        this.modal.open(content, { centered: true });
        this.idSchedule = id;
    }

    closeModal() {
        this.modal.dismissAll();
    }

    navigateToRoutine(routine: ScheduleCommand) {
        this._routerService.navigate([`/comando-agendados/rotinas/${routine.id}`]);
    }

    navigateToGroup(id: string) {
        window.open('/#/grupos/registrados/' + id);
    }

}