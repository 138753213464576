import { Injectable } from "@angular/core";

import { environment } from "src/environments/environment";
import { IogHttpService } from "src/app/security/iog-http.service";
import { KeysDTO } from "src/app/core/models/KeysDTO";

@Injectable({ providedIn: "root" })
export class KeysService {
  static url = environment.apiUrlBack + "/keys";
  private readonly options = { responseType: "blob" as "json" };
  constructor(private http: IogHttpService) {}

  getAllKeys() {
    return this.http.get(KeysService.url).map((response) => response.valueOf());
  }

  getKey(key: KeysDTO) {
    return this.http
      .get(`${KeysService.url}/${key.serial}`)
      .map((response) => response.valueOf());
  }

  createKey(newKey: KeysDTO) {
    let key: KeysDTO = newKey;
    key.serial = String(key.serial).trim();
    return this.http.post(KeysService.url, key);
  }

  updateKey(currentKey: KeysDTO) {
    let key: KeysDTO = currentKey;
    key.serial = String(key.serial).trim();
    return this.http
      .put(KeysService.url, key)
      .map((response) => response.valueOf());
  }

  uploadFile(json: any) {
    json.forEach((key) => {
      key.serial = String(key.serial).trim();
    });
    return this.http
      .post(`${KeysService.url}/keys-mass-registry`, json)
      .map((response) => response.valueOf());
  }
  downloadFile(ticket: string) {
    let route = `/download?ticketId=${ticket}`;
    return this.http.get(`${KeysService.url}${route}`, this.options);
  }
}
