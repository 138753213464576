import { MESSAGE_TYPE } from './../enums';
import { IMessages } from "./IMessages";
import { ReadResponse } from "./ReadResponse";



export class Message {

    public static factory(bytes: number[]): IMessages {
        switch (bytes[0]) {
            case MESSAGE_TYPE.READ_RESPONSE:
                return new ReadResponse(bytes);
            case MESSAGE_TYPE.READ_REQUEST:
                return;
            case MESSAGE_TYPE.WRITE_RESPONSE:
                return;
        }
    }
}
