import { Component, Input, Output, EventEmitter } from "@angular/core";
import {
  Status,
  TicketDTO,
  TicketsStatus,
} from "src/app/core/models/TicketsDTO";
import { Utils } from "src/app/shared/utils";
@Component({
  selector: "app-progress-indicator-modal",
  templateUrl: "./progress-indicator-modal.component.html",
  styleUrls: ["./progress-indicator-modal.component.scss"],
})
export class ProgressIndicatorModalComponent {
  @Input() ticket: TicketDTO = null;
  @Output() close = new EventEmitter();

  Utils = Utils;
  TikcetStatus = TicketsStatus;
  Status = Status;

  closeModal() {
    this.close.emit();
  }
}
