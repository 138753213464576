import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AlertStatistic, ReadStatistic } from 'src/app/core/models/AlertDTO';
import { AlarmDashboardFilterService, IAlarmDashboardFilter } from '../../services/alarm-dashboard-filter.service';
import { AlertsService } from '../../services/alerts.service';

@Component({
  selector: 'app-alert-statistics',
  templateUrl: './alert-statistics.component.html',
  styleUrls: ['./alert-statistics.component.scss']
})
export class AlertStatisticsComponent implements OnInit, OnChanges {
  @Input() isLoadingSmiStatistics: boolean = true;
  @Input() isLoadingSmcStatistics: boolean = true;
  @Input() tagFilterHasBeenApplied: boolean = false;
  @Input() reload: boolean = false;

  smiReadingChartOptions;
  smiReadingStatistics: ReadStatistic;

  smcReadingChartOptions;
  smcReadingStatistics: ReadStatistic;

  public filters: IAlarmDashboardFilter;
  constructor(
    private _smiAlertServices: AlertsService,
    private _dashboardFilter: AlarmDashboardFilterService
  ) { }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.reload) {
      this._getSMIAlertStatistics();
      this._getSMCAlertReadingStatistics();
    }
  }

  ngOnInit() {
    this._getSMIAlertStatistics();
    this._getSMCAlertReadingStatistics();
    this._dashboardFilter.filters.subscribe(filters => {
      this.filters = filters;
      this._getSMCAlertReadingStatistics();
      this._getSMIAlertStatistics();
    });
  }

  sendReload() {
    this._getSMCAlertReadingStatistics();
    this._getSMIAlertStatistics();
  }

  private _setFilters() {
    return this.filters ? {
      tags: this.filters.tag.map(tag => tag.visibleId),
      endDate: this.filters.dates.endDate,
      startDate: this.filters.dates.startDate
    } : {};
  }

  private _getSMIAlertStatistics() {
    this.isLoadingSmiStatistics = true;
    this._smiAlertServices.getSMIAlertReadingStatistics(this._setFilters()).toPromise().then(response => {
      this.smiReadingStatistics = response;
      this.smiReadingChartOptions = {
        ...{
          tooltip: {
            trigger: 'item'
          },
          series: [
            {
              name: 'Alarmes SMI',
              type: 'pie',
              radius: ['40%', '70%'],
              avoidLabelOverlap: false,
              itemStyle: {
                borderRadius: 10,
                borderColor: '#fff',
                borderWidth: 2
              },
              label: {
                show: false,
                position: 'center'
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: '10',
                  fontWeight: 'bold'
                }
              },
              labelLine: {
                show: false
              },
              data: [
                {
                  name: `Lidos - ${response.readPercentage} / ${response.read}`,
                  value: response.read,
                  itemStyle: {
                    color: '#4caf50'
                  }
                },
                {
                  name: `Não Lidos -${response.unreadPercentage} / ${response.unread}`,
                  value: response.unread,

                  itemStyle: {
                    color: '#df0029'
                  }
                },
              ]
            }
          ],
        }
      };

      this.isLoadingSmiStatistics = false;
    });
  }

  private _getSMCAlertReadingStatistics() {
    this.isLoadingSmcStatistics = true;
    this._smiAlertServices.getSMCAlertReadingStatistics(this._setFilters()).toPromise().then(response => {
      this.smcReadingStatistics = response;

      this.smcReadingChartOptions = {
        ...{
          tooltip: {
            trigger: 'item'
          },
          series: [
            {
              name: 'Alarmes SMC',
              type: 'pie',
              radius: ['40%', '70%'],
              avoidLabelOverlap: false,
              itemStyle: {
                borderRadius: 10,
                borderColor: '#fff',
                borderWidth: 2
              },
              label: {
                show: false,
                position: 'center'
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: '10',
                  fontWeight: 'bold'
                }
              },
              labelLine: {
                show: false
              },
              data: [
                {
                  name: `Não Lidos -${response.unread}% / ${response.unread}`,
                  value: response.unread,

                  itemStyle: {
                    color: '#df0029'
                  }
                },
                {
                  name: `Lidos - ${response.readPercentage}% / ${response.read}`,
                  value: response.read,
                  itemStyle: {
                    color: '#4caf50'
                  }
                }
              ]
            }
          ],
        }
      };
      this.isLoadingSmcStatistics = false;
      this.reload = false;
    }).catch(error => {
      this.reload = false;
    })
  }

}
