import { Injectable } from '@angular/core';
import { CommandType, ITicketsDTO } from 'src/app/core/models/TicketsDTO';
import { BuildMeters } from '../../commands/BuildMeters';
import { ReadResponse } from '../../messages/ReadResponse';
import { DlmsProtocol } from '../../protocol/dlmsprotocol';
import { IDlmsService } from '../IDlmsService';

@Injectable(
    { providedIn: 'root' }
)
export class DlmsService implements IDlmsService {
    processReadResponse(protocol: DlmsProtocol, ticket: ITicketsDTO): any {
        let readResponse: ReadResponse = <ReadResponse>protocol.value;
        if (ticket.commandType === CommandType.ListMetersSmcSerial) {
            let buildMeters: BuildMeters = new BuildMeters(readResponse.dlmsData);
            return buildMeters.getMeters();
        }


    }

}
