import { Component, Output, Input, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserType } from 'src/app/core/models/UserDTO';
import { AuthService } from 'src/app/security/auth.service';
@Component({
    selector: 'app-rollback',
    templateUrl: './roll-back.component.html',
    styleUrls: ['./roll-back.component.scss']
})

export class RollBackComponent {

    @Input() smcSerial: string;
    @Input() isSending: boolean;

    modalRef: any = null;

    @Output() sendRollBack = new EventEmitter();

    public canOpenTheDoors = [UserType.ADMIN];

    constructor(private modalService: NgbModal, public authService: AuthService) { }


    openConfirmModal(modalContent) {
        this.modalRef = this.modalService.open(modalContent, { centered: true });

    }

    closeConfirm() {
        this.modalRef.close();
    }
    sendCommand() {
        this.sendRollBack.emit(this.smcSerial);
        this.closeConfirm();
    }

}
