import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DatatableComponent } from "@swimlane/ngx-datatable";
import * as moment from "moment";

import { FrozenDataDTO } from "src/app/core/models/FrozenDataDTO";
import { TicketDTO } from "src/app/core/models/TicketsDTO";
import { FileService } from "../../services/file.service";

@Component({
  selector: "app-frozen-data-upload-table",
  templateUrl: "./frozen-data-upload-table.component.html",
  styleUrls: ["./frozen-data-upload-table.component.scss"],
})
export class FrozenDataUploadTableComponent {
  @ViewChild("frozenDataTable", { static: false })
  frozenDatasTable: DatatableComponent;

  @Input() frozenDatas: any[] = null;
  @Input() isUploadingFile = false;
  @Input() isFrozenDatasSave = false;
  @Input() onlyView = false;
  @Input() ticket: TicketDTO = null;
  @Input() serial: string = null;

  @Output() upload = new EventEmitter();
  @Output() descart = new EventEmitter();

  FrozenDatasDTO = FrozenDataDTO;
  frozenDataToRemove = null;

  constructor(
    private _modalService: NgbModal,
    private _fileService: FileService
  ) {}

  uploadFrozenDatas() {
    this.upload.emit();
  }

  descartFrozenData() {
    this.descart.emit();
  }

  openModal(content: any, row?: any) {
    if (row !== undefined) this.frozenDataToRemove = row;
    this._modalService.open(content, {
      centered: true,
      keyboard: false,
      backdrop: "static",
    });
  }

  removeLine() {
    this.frozenDatas.forEach((qee, index) => {
      if (qee.dateTime == this.frozenDataToRemove.dateTime) {
        this.frozenDatas.splice(index, 1);
        this.frozenDatas = [...this.frozenDatas];
        this.isFrozenDatasSave = false;
        this.closeModal();
      }
    });
  }

  pipeDateTime(dateTime: string) {
    return moment(dateTime)
      .utc(true)
      .format("DD [de] MMMM [de] YYYY [às] HH:mm:ss");
  }

  saveDatas() {
    const table: DatatableComponent = this.frozenDatasTable;
    this._fileService.buildAndSaveFile(
      table,
      `Energia Exportado do medidor ${this.serial} em ${moment(
        new Date()
      ).format("DD [de] MMMM [de] YYYY [às] HH:mm:ss")}`,
      "Energia"
    );
  }

  closeModal() {
    this.frozenDataToRemove = false;
    this._modalService.dismissAll();
  }
}
