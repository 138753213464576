import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PaginationResponseDTO } from "src/app/core/models/PaginationResponseDTO";
import { IVoltageOutAlarm, VoltageOutDTO } from "src/app/core/models/VoltageOutDTO";
import { CommandsService } from "../../services/commands.service";
import { HandleErrorService } from "../../services/handle-error.service";
import { MessageService } from "../../services/message.service";
import { Utils } from "../../utils";

@Component({
    selector: 'app-voltage-alarms-table',
    styleUrls: ['./voltage-alarms.component.scss'],
    templateUrl: './voltage-alarms.component.html'
})
export class VoltageAlarmsTableComponent {
    @Input() alarms: IVoltageOutAlarm[] = [];
    @Input() isLoading: boolean = false;
    @Input() isReloading: boolean = false;
    @Input() isLongVoltageAlarm: boolean = true;
    @Input() page: PaginationResponseDTO = new PaginationResponseDTO();
    @Input() serial: string = null;
    @Input() model: string = '';

    @Output() pageSize = new EventEmitter();
    @Output() refresh = new EventEmitter();
    @Output() changePage = new EventEmitter();
    @Output() sort = new EventEmitter();
    @Output() searchString = new EventEmitter();
    @Output() filterByDate = new EventEmitter();
    @Output() alarmShort = new EventEmitter();
    @Output() alarmLong = new EventEmitter();

    Utils = Utils;
    VoltageOutDTO = VoltageOutDTO;

    quantity = 0;

    placeHolderLabel: string = 'Pesquise por...';

    startDate = null;
    endDate = null;
    public cansRequestReset: boolean = false;
    public isRequestingAlarms: boolean = false;

    constructor(
        private _modal: NgbModal,
        private _commandServices: CommandsService,
        private _handleErrorService: HandleErrorService,
        private _messageService: MessageService,
    ) { }

    openModal(ctt) {
        this._modal.open(ctt, { centered: true, backdrop: 'static', keyboard: false });
    }

    closeModal() {
        this._modal.dismissAll();
        this.cansRequestReset = false;
    }

    filterDate() {
        this.filterByDate.emit({
            endDate: this.endDate,
            startDate: this.startDate
        });
        this.closeModal();
    }

    cleanDateFilter() {
        this.endDate = null;
        this.startDate = null;
        this.filterDate();
    }

    updateSearchString(value) {
        this.searchString.emit(value);
    }

    changeSizeValue(value) {
        this.pageSize.emit(value);
    }

    changePageEvent(value) {
        this.changePage.emit(value);
    }

    refreshTable() {
        this.refresh.emit();
    }

    sortByProp(prop) {
        const sort = prop.sorts[0];
        const order = `${sort.prop}-${sort.dir}`;
        this.sort.emit(order);
    }

    sendQuantity() {
        this.isLongVoltageAlarm 
        ? this.alarmLong.emit(this.quantity)
        : this.alarmShort.emit(this.quantity)
    }


    requestAlarms() {
        this.isRequestingAlarms = true;
        if (this.isLongVoltageAlarm) {
            this._commandServices.recoverVoltageOutLong(this.serial).toPromise()
                .then(respone => {
                    this._messageService.showSuccess('Comando Enviado', 'Recuperação de Alarme Voltage Out Long enviado');
                    this.isRequestingAlarms = false;
                    this.closeModal();
                })
                .catch(error => {
                    this._handleErrorService.handle(error);
                    this.isRequestingAlarms = false;
                })
        } else {

            this._commandServices.recoverVoltageOutShort(this.serial).toPromise()
                .then(response => {
                    this._messageService.showSuccess('Comando Enviado', 'Recuperação de Alarme Voltage Out Short enviado');
                    this.isRequestingAlarms = false;
                    this.closeModal();
                })
                .catch(error => {
                    this._handleErrorService.handle(error);
                    this.isRequestingAlarms = false;
                })
        }
    }
}