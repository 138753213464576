import { BrowserModule, Title } from "@angular/platform-browser";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { CoreModule } from "./core/core.module";
import { SecurityModule } from "./security/security.module";
import { NgxSpinnerModule } from "ngx-spinner";
import { ReactiveFormsModule } from "@angular/forms";
import { MarkdownModule } from 'ngx-markdown';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from "@angular/common/http";
import { Utils } from "./shared/utils";
import { QuicklinkModule } from "ngx-quicklink";
import { environment } from "src/environments/environment.prod";
import { ServiceWorkerModule } from '@angular/service-worker';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    QuicklinkModule,
    CoreModule,
    SecurityModule,
    NgxSpinnerModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: true,
    }),
    MarkdownModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },

    }),
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [TranslateModule],
  providers: [
    Title
  ]
})
export class AppModule {
  constructor(private _translate: TranslateService, private titleService: Title) {
    _translate.setDefaultLang(Utils.getLang());
    _translate.use(Utils.getLang())
    this.setTitle()
  }


  setTitle() {
    this.titleService.setTitle(environment.clientTitle)
  }
}
