import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { QualityIndicatorDTO } from "src/app/core/models/QualityIndicatorDTO";
import { Utils } from "src/app/shared/utils";
import { FileService } from "../../services/file.service";
import { DatatableComponent } from "@swimlane/ngx-datatable";
import * as XLS from "xlsx";

@Component({
  selector: "app-quality-indicators-monthly",
  templateUrl: "./quality-indicators-monthly.component.html",
  styleUrls: ["./quality-indicators-monthly.component.scss"],
})
export class QualityIndicatorsMonthlyComponent implements OnInit {
  @ViewChild("dataTable", { static: false }) qeeTable: DatatableComponent;
  @Input() qualityIndicators: QualityIndicatorDTO[];
  @Input() nomeGrupo: string = null;
  @Input() month: string = null;

  Utils = Utils;
  QualityIndicatorDTO = QualityIndicatorDTO;
  monthRefValue = null;

  public maxMonth = null;

  drpAverage = null;
  drcAverage = null;
  dtt95Average = null;
  fd95Average = null;

  constructor(
    private modalService: NgbModal,
    private _fileService: FileService
  ) { }

  ngOnInit() {
    this.maxMonth = `${new Date().getFullYear().toString()}-${(
      "0" + new Date().getMonth()
    ).slice(-2)}`;
  }

  openModal(content) {
    this.modalService.open(content, {
      centered: true,
      backdrop: "static",
      keyboard: false,
    });
  }

  finishOperation() {
    this.close();
  }

  saveQEE() {
    const rows = this.processQEE(this.qualityIndicators);
    const workSheet: XLS.WorkSheet = XLS.utils.json_to_sheet([...rows]);
    workSheet["!cols"] = [{ width: 45 }];

    const workbook: XLS.WorkBook = {
      Sheets: {
        "Medidores": workSheet,
      },
      SheetNames: ["Medidores"],
    };
    const excelBuffer: any = XLS.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    this._fileService.saveFileAsExcel(excelBuffer, `Média de Parametro de qualidade do ${this.nomeGrupo} referente a ${this.month}`);
  }


  private processQEE(qee: any[]) {
    var qeeJsonList: any[] = []
    qee.forEach(_qee => {

      const qee = {
        'Data/Hora final do Conjunto': Utils.pipeDateTime(_qee.dateTimeFinal),
        'Data/Hora inicio do Conjunto': Utils.pipeDateTime(_qee.dateTimeInitial),
        'Medidor': _qee.serial,
        'Comissionamento': _qee.comissioned ? 'Comissionado' : 'Não Comissionado',
        'Comunicação': _qee.online ? 'Online' : 'Offline',
        'Status': _qee.status['message'],
        "DRP ( % )": _qee.drp,
        'DRC ( % )': _qee.drc,
        'Qtd. Leituras Utilizadas': _qee.count,

      };
      qeeJsonList.push(qee);
    });

    return qeeJsonList;
  }

  private close() {
    this.modalService.dismissAll();
  }
}
