import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { ModemDTO, SignalQuality } from "src/app/core/models/ModemDTO";
import { Utils } from "src/app/shared/utils";
import * as signalR from '@microsoft/signalr';

import { CommandsService } from "src/app/shared/services/commands.service";
import { HandleErrorService } from "src/app/shared/services/handle-error.service";
import { MessageService } from "../../services/message.service";
import { UserType } from "src/app/core/models/UserDTO";
import { AuthService } from "src/app/security/auth.service";
import { EnumMapperDTO } from "src/app/core/models/EnumMapperDTO";
import { TranslateInCodeService } from "../../services/translate-in-code.service";
import { TranslateService } from "@ngx-translate/core";
import { HubService } from "../../services/hub.service";
import { CommandHubDTO, CommandHubType } from "src/app/core/models/command-event/CommandHubDTO";

@Component({
  selector: "app-modem-info",
  templateUrl: "./modem-info.component.html",
  styleUrls: ["./modem-info.component.scss"],
})
export class ModemInfoComponent implements OnInit, OnDestroy {
  @Input() isLoading: boolean;
  @Input() modem: ModemDTO;
  @Input() serial: string;
  @Input() showReset = true;
  @Input() type;
  @Input() tech;
  Utils = Utils;

  canReset = false;
  isResetting = false;
  resetSeconds = 10;

  Modem = ModemDTO;
  EnumMapper: EnumMapperDTO;
  CommandHUbDTO = CommandHubDTO;

  public canResetNic = [UserType.ADMIN];
  public connection: signalR.HubConnection;

  constructor(
    private modalService: NgbModal,
    private commandServices: CommandsService,
    private handleService: HandleErrorService,
    private messageService: MessageService,
    public authServices: AuthService,
    private _translateInCode: TranslateInCodeService,
    private _translateService: TranslateService,
    private hubService: HubService,
  ) { }
  async ngOnInit() {
    await this.openConnection();
    await this.getTranslate()
    this._translateService.onLangChange.subscribe(async () => {
      await this.getTranslate();
    });
  }

  isNbIot() {
    if(!this.modem) return false;
    return this.modem.imei != null || this.modem.iccid != null || this.modem.imsi != null || this.modem.plmn != null || this.modem.bootCounter != null
  }

  async openConnection() {
    this.connection = await this.hubService.connect(
      "generalHub",
      "UpdateForAll"
    );
    this.updateModemInfo();
    this.connection.onclose(async () => {
      this.openConnection();
    });
  }

  updateModemInfo() {
    this.connection.on('UpdateForAll', (object) => {
      object = JSON.parse(object);
      object["payload"].updatedAt = this.CommandHUbDTO.formatDate(
        object["payload"].updatedAt
      );
      const commandType: CommandHubType = object["type"];

      if (commandType === CommandHubType.AModemWasUpdatedEvent) {
        let modem: ModemDTO = object['payload'].modem;
        if (modem.deviceEui === this.modem.deviceEui) {
          modem.firmwareVersion = modem.firmware;
          this.modem = modem;
        }
      }
    });
  }

  openModal(content) {
    this.modalService.open(content, { centered: true });
  }

  close() {
    this.modalService.dismissAll();
  }

  async getTranslate() {
    await this._translateInCode.getTranslate('ModemInfo.SignalQuality', SignalQuality).then(resp => {
      this.EnumMapper = resp;
    })
  }

  resetNic() {
    this.isResetting = true;
    this.close();

    this.commandServices
      .resetNic(this.serial, this.resetSeconds)
      .toPromise()
      .then((response) => {
        this.messageService.showSuccess(
          "Comando Enviado",
          "Reset do NIC enviado com sucesso!"
        );
        this.isResetting = false;
      })
      .catch((error) => {
        this.isResetting = false;
        this.handleService.handle(error);
      });
  }

  ngOnDestroy() {
    this.hubService.disconnect(this.connection);
    this.connection = null;
  }

}
