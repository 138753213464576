import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { NgForm } from "@angular/forms";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";

import { SmcDTO, SmcManufacturer, SmcModel } from "../../../core/models/SmcDTO";
import { HandleErrorService } from "../../services/handle-error.service";
import { MeterDTO, MeterPhase } from "../../../core/models/MeterDTO";
import { Utils } from "src/app/shared/utils";

import { MessageService } from "src/app/shared/services/message.service";
import { SmcsService } from "src/app/shared/services/smcs.service";
import { MetersService } from "src/app/shared/services/meters.service";

@Component({
  selector: "app-smc-models-table",
  templateUrl: "./smc-model-table.component.html",
  styleUrls: ["./smc-model-table.component.scss"],
})
export class SmcModelsTableComponent implements OnInit {
  @Input() models: SmcDTO[];
  @Input() isLoading = true;

  @Output() filter = new EventEmitter();
  @Output() reload = new EventEmitter();

  currentMeters: MeterDTO[] = [];

  SmcDTO = SmcDTO;
  Utils = Utils;
  SmcManufacturer: SmcManufacturer[] = [];
  MeterPhase = MeterPhase;

  SmcModel: SmcModel = new SmcModel();
  deletedSmc: SmcModel;
  deletedMeterSmc: MeterDTO;

  isloading = false;
  isEditing = false;

  currentModal: NgbModalRef;

  constructor(
    private smcService: SmcsService,
    private meterService: MetersService,
    private messageService: MessageService,
    private modalService: NgbModal,
    private handleService: HandleErrorService
  ) {}

  ngOnInit() {
    this.getManufacturers();
    this.SmcModel = new SmcModel();
    this.deletedSmc = new SmcModel();
  }

  keys(Enumerator): Array<string> {
    const keys = Object.keys(Enumerator);
    return keys.slice(keys.length / 2);
  }

  modalSmc(content, row?: SmcModel) {
    if (this.isEditing && row) {
      this.SmcModel = row;
      this.currentModal = this.modalService.open(content, {
        ariaLabelledBy: "modal-basic-title",
        centered: true,
      });
      return;
    }
    this.currentModal = this.modalService.open(content, {
      ariaLabelledBy: "modal-basic-title",
      centered: true,
    });
  }

  commitModalSmc(form: NgForm) {
    this.isloading = true;
    if (!this.isEditing) {
      this.smcService
        .saveSmcModels(this.SmcModel)
        .toPromise()
        .then(() => {
          this.finishOperation(form);
          this.messageService.showSuccess(
            "Operação Concluída",
            "Modelo cadastrado com sucesso"
          );
        })
        .catch((err) => {
          this.isloading = false;
          this.handleService.handle(err);
        });
      return;
    }

    this.smcService
      .updateSmcModel(this.SmcModel)
      .toPromise()
      .then(() => {
        this.finishOperation(form);
        this.messageService.showSuccess(
          "Operação Concluída",
          `Modelo ${this.SmcModel.name} modificado com sucesso`
        );
      })
      .catch((err) => {
        this.isloading = false;
        this.handleService.handle(err);
      });
  }

  confirmSmcExclusion(content, row: SmcModel) {
    this.deletedSmc = row;
    this.currentModal = this.modalService.open(content, {
      ariaLabelledBy: "modal-basic-title",
      centered: true,
    });
  }

  deleteSmc() {
    this.isloading = true;
    this.smcService
      .deleteSmcModels(this.deletedSmc)
      .toPromise()
      .then(() => {
        this.finishOperation();
        this.messageService.showSuccess(
          "Operação Concluída",
          "Modelo excluído com sucesso!"
        );
      })
      .catch((err) => {
        this.finishOperation();
        this.handleService.handle(err);
      });
  }

  getManufacturers() {
    this.meterService
      .getManufactures()
      .toPromise()
      .then((manufacturer: SmcManufacturer[]) => {
        this.SmcManufacturer = manufacturer;
      })
      .catch((err) => {
        this.handleService.handle(err);
      });
  }

  finishOperation(form?: NgForm) {
    this.SmcModel = new SmcModel();
    this.modalService.dismissAll();
    this.isloading = false;
    this.isEditing = false;
    if (form) {
      form.reset();
    }
  }
  filterModels(value: string) {
    this.filter.emit(value);
  }

  isDisabled(meter: MeterDTO) {
    const meterFound = this.currentMeters.find(
      (meterIterator) =>
        meterIterator.serial === meter.serial &&
        meterIterator.installation === meter.installation &&
        meterIterator.phase === meter.phase
    );
    if (meterFound) {
      return true;
    }
    return false;
  }

  reloadEvent() {
    this.reload.emit();
  }
}
