// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


export const environment = {
  production: true,
  apiUrlBack: "http://modofabrica.nepen.org.br:81",
  tokenWhitelistedDomains: "http://modofabrica.nepen.org.br:81/",
  tokenBlacklistedDomains: "http://modofabrica.nepen.org.br:81/users/login",
  websocketExpiration: 1572986429,
  auditService: "http://modofabrica.nepen.org.br:100",
  servicesNotes: "http://modofabrica.nepen.org.br:84",
  returnUrl: "returnUrlIog",
  currentUser: "iogCurrentUser",
  token: "iogToken",
//  alarmesCriticos: 'alarmesCriticosIog',
  smiAlarmesCriticos: 'smiAlarmesCriticosIog',
  smcAlarmesCriticos: 'smcAlarmesCriticosIog',
  appDownloadUrl: 'http://iog.nepen.org.br:1011/app-release-homolog.apk',
  currentLanguage: 'iogCurrentLanguage',
  suportedLanguage: [
    {
      name: 'Portugês (Brasil)',
      value: 'pt-BR'
    },
    {
      name: 'Inglês (US)',
      value: 'en-US'
    },
    {
      name: "Espanhol",
      value: 'es'
    }
  ],
  appVersion: '1.4.0',
  client: false,
  clientTitle: "MDC Equatorial",
  nansenTransparent: "TRANSPARENT",
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
