import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PaginationResponseDTO } from "src/app/core/models/PaginationResponseDTO";
import { IogHttpService } from "src/app/security/iog-http.service";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: 'root' })
export class HistoricReadingService {
    static url = environment.apiUrlBack + '/historic-quantity-readings-by-day';

    constructor(private http: IogHttpService) { }

    quantityReadingsByDay({
        serial = '',
        sortbyDateTime = '',
        sortOrder = '',
        page = 1,
        pageSize = 15,
        startDateTime = '',
        endDateTime = '',
        comissionedFilter = null,
        online = null,
        isSmi = null,
        qtyMeasures = null,
        tags = []
    } = {}): Observable<PaginationResponseDTO> {
        const params = `/?
        ${serial ? `searchString=${serial}&` : ''}
        ${startDateTime ? `startDateTime=${startDateTime}&` : ''}
        ${endDateTime ? `endDateTime=${endDateTime}&` : ''}
        ${sortbyDateTime ? `sortbyDateTime=${sortbyDateTime}&` : ''}
        ${sortOrder ? `sortOrder=${sortOrder}&` : ''}
        ${(comissionedFilter == true || comissionedFilter == false) ? `comissionedFilter=${comissionedFilter}&` : ''}
        ${online != null ? `online=${online}&` : ''}
        ${isSmi != null ? `isSmi=${isSmi}&` : ''}
        ${qtyMeasures != null ? `qtyMeasures=${qtyMeasures}&` : ''}
        ${tags.length ? tags.map(tag => `visibleId=${tag}&`).join().replace(/\,/g, '') : ''}
        page=${page}&pageSize=${pageSize}
      `.replace(/\s/g, '');

        return this
            .http
            .get(HistoricReadingService.url + params);
    }
}