import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-primary-button",
  templateUrl: "./primary-button.component.html",
  styleUrls: ["./primary-button.component.scss"],
})
export class PrimaryButtonComponent {
  @Input() buttonLabel: string = null;
  @Input() isLoading: boolean = false;
  @Input() icon: string = null;

  @Output() callBack = new EventEmitter();

  executeFunction() {
    this.callBack.emit();
  }
}
