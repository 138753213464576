import { Component, Input } from '@angular/core';
import * as XLS from "xlsx";
import { FileService } from '../../services/file.service';
import { Utils } from '../../utils';

export interface DICFICGROUP {
  dateTimeInitial: Date,
  dateTimeFinal: Date,
  dicHours: number,
  dicMinutes: number,
  dicHundredthHour: number,
  fic: number,
  status: {
    code: number,
    message: string
  }
  serial: number,
  count: number,
  comissioned: true,
  online: true
}

export interface DicFicGroupResponse {
  items: DICFICGROUP[];
  added: number,
  failed: number,
  total: number,
  addedPercentual: number,
  failedPercentual: number,
  updatedAt: Date;
}

@Component({
  selector: 'app-dic-fic-monthly',
  templateUrl: './dic-fic-monthly.component.html',
  styleUrls: ['./dic-fic-monthly.component.scss']
})
export class DicFicMonthlyComponent {

  @Input() items: DicFicGroupResponse = null;
  @Input() monthRef: string = null;
  @Input() nomeGrupo: string = null;

  Utils = Utils;

  constructor(
    private _fileService: FileService,
  ) { }



  saveDICFIC() {
    const rows = this.processQEE(this.items.items);
    const workSheet: XLS.WorkSheet = XLS.utils.json_to_sheet([...rows]);
    workSheet["!cols"] = [{ width: 45 }];

    const workbook: XLS.WorkBook = {
      Sheets: {
        "DICFIC": workSheet,
      },
      SheetNames: ["DICFIC"],
    };
    const excelBuffer: any = XLS.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    this._fileService.saveFileAsExcel(excelBuffer, `DIC_FIC do grupo ${this.nomeGrupo} referente a ${this.monthRef}`);
  }


  private processQEE(dicFic: DICFICGROUP[]) {
    var dicFicList: any[] = []
    dicFic.forEach(_dicfic => {

      const dicFic = {
        'Medidor': _dicfic.serial,
        'Data Hora Inicio': Utils.pipeDateTime(_dicfic.dateTimeInitial),
        'Data Hora Final': Utils.pipeDateTime(_dicfic.dateTimeFinal),
        'Mês de Refêrencia': this.monthRef,
        'Dic (Horas)': `${_dicfic.dicHours} horas ${_dicfic.dicMinutes} minutos`,
        'Dic (Centésimo de Horas)': _dicfic.dicHundredthHour,
        'FIC': _dicfic.fic,
        'Status': _dicfic.status['message'],
      };
      dicFicList.push(dicFic);
    });

    return dicFicList;
  }

}
