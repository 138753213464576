import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as XLS from "xlsx";

import { Utils } from "../../utils";

import { FirmwareDTO } from "src/app/core/models/FirmwareDTO";
import { MeterModelDTO } from "src/app/core/models/MeterModelDTO";
import { ManufacturerDTO } from "src/app/core/models/ManufacturerDTO";

import { HandleErrorService } from "src/app/shared/services/handle-error.service";
import { MessageService } from "src/app/shared/services/message.service";
import { FirmwareService } from "src/app/shared/services/firmware.service";
import { MetersService } from "src/app/shared/services/meters.service";

@Component({
  selector: "app-firmware-table",
  templateUrl: "./firmware-table.component.html",
  styleUrls: ["./firmware-table.component.scss"],
})
export class FirmwareTableComponent implements OnInit {
  @Input() isLoading = true;
  @Input() firmwares: FirmwareDTO[] = [];

  @Output() reaload = new EventEmitter();
  @Output() filter = new EventEmitter();

  public Utils = Utils;
  public selectedFirmware: FirmwareDTO = new FirmwareDTO();
  public fileURL = "./assets/models/modelo de cadastro dos firmwares.xlsx";

  public isDeleting = false;
  public isEditing = false;
  public metersManufactures: ManufacturerDTO[] = [];
  jsonFirmwareList: any = null;

  constructor(
    private firmwareService: FirmwareService,
    private modalService: NgbModal,
    private messageService: MessageService,
    private errorHandlerService: HandleErrorService,
    private metersModelsService: MetersService
  ) {}

  ngOnInit() {
    this.getMetersManufacturers();
  }

  openModal(content, firmware?: FirmwareDTO) {
    if (firmware) {
      this.selectedFirmware = firmware;
    }
    this.modalService.open(content, { centered: true });
  }

  getMetersManufacturers() {
    this.metersModelsService
      .getManufactures()
      .toPromise()
      .then((models: MeterModelDTO[]) => {
        this.metersManufactures = models;
      })
      .catch((err) => {
        this.errorHandlerService.handle(err);
      });
  }

  deleteFirmware() {
    this.isDeleting = true;
    this.firmwareService
      .deleteFirmware(this.selectedFirmware)
      .toPromise()
      .then((response) => {
        this.messageService.showSuccess(
          "Eliminado",
          `${this.selectedFirmware.name} eliminado!`
        );
        this.isDeleting = false;
        this.refreshTable();
      })

      .catch((error) => {
        this.errorHandlerService.handle(error);
        this.isDeleting = false;
      });
  }

  createOrEditFirmware() {
    this.isLoading = true;
    const modelName: any = this.selectedFirmware.manufacturer.name;
    this.selectedFirmware.manufacturer = ManufacturerDTO.getManufacurerByName(
      this.metersManufactures,
      modelName
    );
    FirmwareDTO.hexaToDecimal(this.selectedFirmware);
    if (this.isEditing) {
      this.firmwareService
        .editFirmware(this.selectedFirmware)
        .toPromise()
        .then((response) => {
          this.messageService.showSuccess(
            "Modificado",
            `${this.selectedFirmware.name} modifcado com sucesso!`
          );
          this.isEditing = false;
          this.isLoading = false;
          this.close();
        })
        .catch((error) => {
          this.isLoading = false;
          this.errorHandlerService.handle(error);
        });

      return;
    }
    this.firmwareService
      .createFirmware(this.selectedFirmware)
      .toPromise()
      .then((response) => {
        this.messageService.showSuccess(
          "Cadastrado",
          `Versão de firmware adicionado com sucesso`
        );
        this.isLoading = false;
        this.close();
      })
      .catch((error) => {
        this.isLoading = false;
        this.errorHandlerService.handle(error);
      });
  }
  filterByNameAndCRC(searchString: string): void {
    this.filter.emit(searchString);
  }

  readFile(event: any) {
    const file: File = event.target.files[0];
    let fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);
    fileReader.onload = () => {
      const arrayBuffer: any = fileReader.result;
      const data = new Uint8Array(arrayBuffer);
      let array = new Array();

      for (let index = 0; index !== data.length; ++index) {
        array[index] = String.fromCharCode(data[index]);
      }
      const listOfKeys = array.join("");
      const workbook = XLS.read(listOfKeys, { type: "binary" });
      const fileName = workbook.SheetNames[0];
      const workSheet = workbook.Sheets[fileName];

      this.jsonFirmwareList = XLS.utils.sheet_to_json(workSheet);
    };
  }

  registerMultipleFirmware() {
    this.jsonFirmwareList.forEach((firmware: FirmwareDTO, index) => {
      this.isLoading = true;
      const isSMC: any = firmware.smc;
      FirmwareDTO.hexaToDecimal(firmware);
      const modelName: any = firmware.manufacturer;
      firmware.manufacturer = ManufacturerDTO.getManufacurerByName(
        this.metersManufactures,
        modelName
      );
      firmware.smc = isSMC === "true";
      this.firmwareService
        .createFirmware(firmware)
        .toPromise()
        .then((response) => {
          this.messageService.showSuccess(
            "Cadastrado",
            `Versão de firmware adicionado com sucesso`
          );
          this.isLoading = false;
          this.refreshTable();
        })
        .catch((error) => {
          this.isLoading = false;
          this.errorHandlerService.handle(error);
        });
    });
  }

  refreshTable() {
    this.close();
    this.reaload.emit();
  }

  public close() {
    this.selectedFirmware = new FirmwareDTO();
    this.modalService.dismissAll();
  }
}
