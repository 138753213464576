import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ITransformerGroup, TransformerGroupDTO } from 'src/app/core/models/TransformerGroupDTO';
import { Utils } from 'src/app/shared/utils';


@Component({
  selector: 'app-transformer-group-table',
  templateUrl: './transformer-group-table.component.html',
  styleUrls: ['./transformer-group-table.component.scss']
})
export class TransformerGroupTableComponent {

  @Input() transformersGroup: ITransformerGroup[] = [];
  @Input() isLoading: boolean = true;
  @Input() isUpdating: boolean = false;

  @Output() reload = new EventEmitter();
  @Output() createGroup = new EventEmitter();
  @Output() editGroup = new EventEmitter();
  @Output() deleteGroup = new EventEmitter();


  Utils = Utils;
  public isEditing = false;
  public group: ITransformerGroup = new TransformerGroupDTO();

  constructor(
    private modalService: NgbModal,
  ) {
  }

  reloadEvent() {
    this.reload.emit();
  }

  createUpdate() {
    this.isEditing ? this.editGroup.emit(this.group) : this.createGroup.emit(this.group);
  }



  finishOperation() {
    this.isEditing = false;
    this.group = new TransformerGroupDTO();
    this.closeModal();
  }

  delete() {
    this.deleteGroup.emit(this.group);
  }


  openModal(content, transformerGroup?: ITransformerGroup) {
    if (transformerGroup)
      this.group = transformerGroup;
    this.modalService.open(content, { centered: true, keyboard: false, backdropClass: 'static' });
  }


  private closeModal() {
    this.modalService.dismissAll();
  }

}
