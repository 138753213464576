export enum UserType {
  ADMIN = 1,
  OPERATOR = 2,
  USER = 3,
  INSTALLER = 5,
}

export interface IUserDTO {
  description: string;
  name: string;
  userType: UserType;
  email: string;
  password: string;
  confirmPassword: string;
  updatedAt: Date;
  blocked: boolean;
}

export class UserDTO implements IUserDTO {
  public description: string;
  public name: string;
  public userType: UserType;
  public email: string;
  public password: string;
  public confirmPassword: string;
  public updatedAt: Date;
  public blocked: boolean = false;

  constructor() {
    this.userType = UserType.USER;
  }

  static getUserType(user: UserDTO) {
    switch (user.userType) {
      case UserType.ADMIN:
        return "Admistrador";
      case UserType.USER:
        return "Visualizador";
      case UserType.OPERATOR:
        return "Operador";
      case UserType.INSTALLER:
        return 'Instalador';
    }
  }
}
