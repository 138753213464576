import { ISmcDTO } from './SmcDTO';
import { IMeterDTO } from 'src/app/core/models/MeterDTO';
import { ITransformerGroup } from './TransformerGroupDTO';
export interface ITransformerDTO {
    transformerId: string;
    name: string;
    description: string;
    createdAt: string;
    transformerMeter: IMeterDTO;
    meters: IMeterDTO[];
    smcs: ISmcDTO[]
    updatedAt: Date;
    latitude: string;
    longitude: string;
    metersCount: number;
    smcsCount: number;
    publicLighting: number;
    transformerGroups: ITransformerGroup[];
    checked: boolean;
}

export class TransformerDTO implements ITransformerDTO {
    public transformerMeter: IMeterDTO;
    public meters: IMeterDTO[];
    public smcs: ISmcDTO[];
    public latitude: string;
    public longitude: string;
    public transformerId: string;
    public description: string;
    public name: string;
    public updatedAt: Date;
    public createdAt: string;
    public metersCount: number;
    public smcsCount: number;
    public publicLighting: number;
    public transformerGroups: ITransformerGroup[];
    public checked: boolean = false;

}

export interface IUpdatedClient {
    serial: string;
    updated: boolean;
    reason: string;
}

export interface IUpdateClientResponse {
    Transformer: ITransformerDTO;
    updateTransformerClients: IUpdatedClient[];
}

export class ITransformerUpdateClientResponseDTO implements IUpdateClientResponse {
    Transformer: ITransformerDTO;
    updateTransformerClients: IUpdatedClient[];

}


