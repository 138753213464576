import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HandleErrorService } from '../../services/handle-error.service';
import { VoltageOutService } from '../../services/voltage-out.service';
import { Utils } from '../../utils';
import { DicFicGroupResponse } from '../dic-fic-monthly/dic-fic-monthly.component';

export interface IDICFICResponse {
  serial: string;
  dicHours: number;
  dicMinutes: number;
  dicHundredthHour: number;
  fic: number;
}
@Component({
  selector: 'app-dic-fic-recovery',
  templateUrl: './dic-fic-recovery.component.html',
  styleUrls: ['./dic-fic-recovery.component.scss']
})
export class DicFicRecoveryComponent implements OnInit {

  @Input() isGroup: boolean = false;
  @Input() serial: string = null;

  @Output() setGroupAvarege: EventEmitter<DicFicGroupResponse> = new EventEmitter<DicFicGroupResponse>();
  @Output() setMonthRef: EventEmitter<string> = new EventEmitter<string>();

  public isLoadingVoltageOut: boolean = false;
  public hasMonthlyVoltageOut: boolean = false;
  public hasDicAndFic: boolean = false;
  public maxMonth = null;
  public monnthRefValue: string = null;
  public monthVoltageOut: IDICFICResponse = null;


  Utils = Utils;


  constructor(
    private _modalServices: NgbModal,
    private _voltageOutServices: VoltageOutService,
    private _errorHandler: HandleErrorService,
  ) { }

  ngOnInit() {
    this.maxMonth = `${new Date().getFullYear().toString()}-${(
      "0" + new Date().getMonth()
    ).slice(-2)}`;
  }


  openModal(content: any) {
    this._modalServices.open(content, { centered: true, backdrop: 'static', keyboard: false })
  }


  closeModal() {
    this._modalServices.dismissAll();
  }

  getMonthlyVoltageOut() {
    this.isLoadingVoltageOut = true;
    const year: number = parseInt(this.monnthRefValue.substring(0, 4))
    const month: number = parseInt(this.monnthRefValue.substring(5).padStart(2, '0'));
    console.log(parseInt(this.monnthRefValue.substring(5).padStart(2,'0')))
    if (this.isGroup) {
      this._voltageOutServices.getGroupVoltageOutMonthly(this.serial, year, month).toPromise()
        .then(response => {
          this.setGroupAvarege.emit(response);
          this.setMonthRef.emit(this.monnthRefValue);
          this.isLoadingVoltageOut = false;
          this.closeModal();
        })
        .catch(_error => {
          this.isLoadingVoltageOut = false;
          this._errorHandler.handle(_error);
        })
    }
    else {
      this.hasDicAndFic = false;
      this._voltageOutServices.getMeterVoltageOutMonthly(this.serial, year, month).toPromise()
        .then(response => {
          this.monthVoltageOut = response;
          this.isLoadingVoltageOut = false;
          this.hasDicAndFic = true;
        })
        .catch(error => {
          this._errorHandler.handle(error);
          this.isLoadingVoltageOut = false;
          this.hasDicAndFic = false;
        })

    }
  }

}
