import { Injectable } from '@angular/core';

import { IogHttpService } from '../../security/iog-http.service';

import { environment } from '../../../environments/environment';
import { AbsolutePeriod, SmcModel } from 'src/app/core/models/SmcDTO';

@Injectable({
  providedIn: 'root',
})
export class SmcsService {
  static url = `${environment.apiUrlBack}/smcs`;

  constructor(private http: IogHttpService) { }

  getSmcs() {
    return this.http.get(SmcsService.url).map((res) => res.valueOf());
  }

  getSmcsComissioned(
    pageSize: number = 15,
    sortOrder: string = 'updatedAt-desc',
    searchString: string = '',
    page: number = 1,
    startDateTime: string = '',
    endDateTime: string = '',
    modemSignalQuality: string = '',
    doorStatus = ''
  ) {
    return this.http
      .get(
        SmcsService.url +
        `/comissioned?pageSize=${pageSize}&sortOrder=${sortOrder}&searchString=${searchString}&page=${page}&startDateTime=${startDateTime}&endDateTime=${endDateTime}&modemSignalQuality=${modemSignalQuality}&doorStatus=${doorStatus}`
      )
      .map((res) => res.valueOf());
  }

  getSmcComissionedWithoutPagination() {
    return this.http
      .get(`${SmcsService.url}/comissioned-no-page`)
      .map((response) => response.valueOf());
  }

  getTransformersAvalaibleSMC() {
    return this.http.get(SmcsService.url + `/without-transformer-no-pagination`);
  }

  getSmcsNotComissioned(
    pageSize: number = 15,
    sortOrder: string = 'updatedAt-desc',
    searchString: string = '',
    page: number = 1,
    startDateTime: string = '',
    endDateTime: string = '',
    modemSignalQuality: string = '',
    doorStatus = ''
  ) {
    return this.http
      .get(
        SmcsService.url +
        `/not-comissioned?pageSize=${pageSize}&sortOrder=${sortOrder}&searchString=${searchString}&page=${page}&startDateTime=${startDateTime}&endDateTime=${endDateTime}&modemSignalQuality=${modemSignalQuality}&doorStatus=${doorStatus}`
      )
      .map((res) => res.valueOf());
  }

  getSmcNotComissionedWithoutPagination() {
    return this.http
      .get(`${SmcsService.url}/not-comissioned-no-page`)
      .map((response) => response.valueOf());
  }

  getSMCUnregistered() {
    return this.http
      .get(`${environment.apiUrlBack}/smc-not-registered`)
      .map((response) => response.valueOf());
  }

  getSmcsBySerial(serialFull: string) {
    return this.http
      .get(`${SmcsService.url}/${serialFull}`)
      .map((res) => res.valueOf());
  }

  getMetersOfSmcsBySerial(serialFull: string) {
    return this.http
      .get(`${SmcsService.url}/${serialFull}/meters`)
      .map((res) => res.valueOf());
  }

  getSmcModels() {
    return this.http
      .get(`${environment.apiUrlBack}/smc-models`)
      .map((response) => response.valueOf());
  }

  saveSmcModels(smcModel: SmcModel) {
    return this.http
      .post(`${environment.apiUrlBack}/smc-models`, smcModel)
      .map((response) => response.valueOf());
  }

  updateSmcModel(smcModel: SmcModel) {
    return this.http
      .put(`${environment.apiUrlBack}/smc-models`, smcModel)
      .map((response) => response.valueOf());
  }

  deleteSmcModels(smcModel: SmcModel) {
    return this.http.delete(
      `${environment.apiUrlBack}/smc-models/${smcModel.name}`
    );
  }
  saveSmc(smc: any) {
    smc.serial = String(smc.serial).trim();
    return this.http.post(SmcsService.url, smc).map((res) => res.valueOf());
  }
  updateSmc(smc) {
    return this.http.put(SmcsService.url, smc).map((res) => res.valueOf());
  }

  deleteSmc(serialFull: string) {
    return this.http.delete(`${SmcsService.url}/${serialFull}`);
  }
}
