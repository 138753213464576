import {
  Component,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from "@angular/core";
import { DatatableComponent } from "@swimlane/ngx-datatable";

import { FrozenDataDTO } from "src/app/core/models/FrozenDataDTO";

import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PaginationResponseDTO } from "src/app/core/models/PaginationResponseDTO";
import { Utils } from "../../utils";
import { ExportsDataServices } from "../../services/export-data.service";
import { HandleErrorService } from "../../services/handle-error.service";

@Component({
  selector: "app-frozen-data",
  templateUrl: "./frozen-data.component.html",
  styleUrls: ["./frozen-data.component.scss"],
})
export class FrozenDataComponent {
  @ViewChild("frozenDataTable", { static: false })
  frozenDataTable: DatatableComponent;

  @Input() frozenDatas: FrozenDataDTO[];
  @Input() isLoading: boolean;
  @Input() isReloading: boolean;
  @Input() serial: string;
  @Input() page: PaginationResponseDTO;
  @Input() canRecoveryFrozenData = true;
  @Input() serialSMC = null;
  @Output() filterEvent = new EventEmitter();
  @Output() recoveryFrozen = new EventEmitter();
  @Output() reload = new EventEmitter();

  Utils = Utils;
  FrozenDatasDTO = FrozenDataDTO;

  startDate = "";
  endDate = "";
  sortProper = "dateTime-desc";
  currentPage = 1;
  pageSize = 15;

  private filter = {
    currentPage: this.currentPage,
    start: this.startDate,
    end: this.endDate,
    sortProper: this.sortProper,
    pageSize: this.pageSize,
  };
  public isDownloadingFrozenData = false;

  constructor(
    private _exportDataServices: ExportsDataServices,
    private modalService: NgbModal,
    private handleErrorService: HandleErrorService,
  ) { }

  removeFilter() {
    this.resetValues();
    this.emiteValue();
    this.closeModal();
  }

  filterByDate() {
    this.currentPage = 1;
    this.filter["currentPage"] = this.currentPage;
    this.filter["start"] = this.startDate;
    this.filter["end"] = this.endDate;
    this.emiteValue();
    this.closeModal();
  }

  filterByPage(page) {
    this.currentPage = page.page;
    this.filter["currentPage"] = this.currentPage;
    this.emiteValue();
  }

  openModal(content) {
    this.modalService.open(content, { centered: true });
  }

  onSort(value) {
    const sort = value.sorts[0];
    this.sortProper = `${sort.prop}-${sort.dir}`;
    this.currentPage = 1;
    this.filter["sortProper"] = this.sortProper;
    this.emiteValue();
  }

  changeSizeValue(value) {
    this.pageSize = value;
    this.currentPage = 1;
    this.filter.currentPage = this.currentPage;
    this.filter.pageSize = this.pageSize;
    this.emiteValue();
  }

  finishOperation() {
    this.startDate = "";
    this.endDate = "";
    this.closeModal();
  }

  recoveryFrozenData(value) {
    this.recoveryFrozen.emit(value);
  }

  saveFrozenDatas() {
    this.isDownloadingFrozenData = true;
    this._exportDataServices.exportMeterFrozenData(this.serial).toPromise()
      .then(response => {
        const dataType = response.type;
        let binaryData = [];
        binaryData.push(response);
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(
          new Blob(binaryData, { type: dataType })
        );
        downloadLink.setAttribute('download', `Energia do medidor ${this.serial}.csv`);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        this.isDownloadingFrozenData = false;
        this.closeModal();
      })
      .catch(error => {
        this.isDownloadingFrozenData = false;
        this.handleErrorService.handle(error);
      });

  }

  private closeModal() {
    this.modalService.dismissAll();
  }

  private emiteValue() {
    this.filterEvent.emit(this.filter);
  }

  private resetValues() {
    this.startDate = "";
    this.endDate = "";
    this.sortProper = "dateTime-desc";
    this.filter["currentPage"] = 1;
    this.filter["sortProper"] = this.sortProper;
    this.filter["end"] = this.endDate;
    this.filter["start"] = this.startDate;
    this.currentPage = 1;
  }

  reloadFrozenData() {
    this.reload.emit();
  }
}
