import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { IRegionBalance } from 'src/app/core/models/BalanceDTO';

@Component({
  selector: 'app-region-balance-table',
  templateUrl: './region-balance-table.component.html',
  styleUrls: ['./region-balance-table.component.scss']
})
export class RegionBalanceTableComponent {

  @Input() regionsBalances: IRegionBalance[] = [];
  @Input() isLoading = true;


  formateDate(date: string) {
    return moment(date).utc(false).format('DD/MM/YYYY [às] HH:mm:ss')
  }

  downloadFile() { }

}
