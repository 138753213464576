export interface IQualityIndicatorDTO {
  dateTimeFinal: Date;
  dateTimeInitial: Date;
  drp: string;
  drc: string;
  dtt95: string;
  fd95: string;
  count: string;
  comissioned?: boolean;
  online?: boolean;
}

export class QualityIndicatorDTO implements IQualityIndicatorDTO {
  public dateTimeInitial: Date;
  public dateTimeFinal: Date;
  public drc: string;
  public drp: string;
  public dtt95: string;
  public fd95: string;
  public count: string;
  public comissioned?: boolean;
  online?: boolean;


  static hasPoperty(list: any[], property) {
    let hasPoperty = false;
    list.forEach((item) => {
      if (item.hasOwnProperty(property)) {
        hasPoperty = true;
        return hasPoperty;
      }
    });
    return hasPoperty;
  }
}
