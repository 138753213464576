import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { IAllAlerts } from 'src/app/core/models/AlertDTO';
import { IPaginationResponseDTO, PaginationResponseDTO } from 'src/app/core/models/PaginationResponseDTO';
import { Utils } from '../../utils';

@Component({
  selector: 'app-single-alarm-type-table',
  templateUrl: './single-alarm-type-table.component.html',
  styleUrls: ['./single-alarm-type-table.component.scss'],
  animations: [
    trigger("openFilter", [
      state(
        "open",
        style({
          "z-index": 5,
          opacity: 1,
          height: "auto",
          width: "60%",
          position: 'absolute',
          overflow: 'auto'
        })
      ),
      state(
        "closed",
        style({
          "z-index": 0,
          opacity: 0,
          padding: "0",
          height: "0",
          width: "0",
        })
      ),
      transition("open => closed", [animate("0.3s")]),
      transition("closed => open", [animate("0.4s")]),
    ]),
  ],
})
export class SingleAlarmTypeTableComponent implements OnChanges {

  @Input() isLoadingAlarms: boolean = true;
  @Input() alarms: Array<IAllAlerts> = [];
  @Input() isSMIEvents: boolean = true;
  @Input() alarmPage: IPaginationResponseDTO = null;
  @Input() isReloadingAlarms: boolean = true;
  @Input() type: string = '';
  @Input() markingAsRead: boolean = false;

  @Input() isLoadingAlerts: boolean = false;
  @Input() alerts: Array<IAllAlerts> = [];
  @Input() alertsPage = new PaginationResponseDTO();
  @Input() isReloadingAlerts: boolean = false;

  @Output() alertsPageChange = new EventEmitter();
  @Output() alertsPageSize = new EventEmitter();
  @Output() navigation = new EventEmitter();

  @Output() markAsRead = new EventEmitter();
  @Output() searchString = new EventEmitter();
  @Output() pageSize = new EventEmitter();
  @Output() page = new EventEmitter();
  @Output() reload = new EventEmitter();
  @Output() filterByDate = new EventEmitter();
  @Output() sortBy = new EventEmitter();

  @Output() navigationAlarm = new EventEmitter();

  public startDate = null;
  public endDate = null;
  public alarmsToMarkAsRead: Array<string> = [];
  public allAlarmRead: boolean = false;
  public Utils = Utils;

  public ColumnMode = ColumnMode;

  public confirmStage: IAllAlerts = null;
  public isSelectedAlarms: boolean = false;

  public isSelectClose = true;
  public lastHeigthAlarmTable = 0;
  @ViewChild('table', { static: false }) alarmTypeTable: DatatableComponent;
  @ViewChild('ngxTable', { static: false }) alarmsTable: DatatableComponent;
  public today = new Date().toISOString().substring(0, 10);
  public pageChanged: boolean = false;
  constructor(
    private _modalService: NgbModal,
  ) {}


  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.isLoadingAlarms && !changes.isLoadingAlarms.currentValue) || (changes.isReloadingAlarms && !changes.isReloadingAlarms.currentValue)) {
      this.closeModal();
      this.isSelectClose = true;
    }
    if (changes.markingAsRead && !changes.markingAsRead.currentValue) {
      this.alarmsToMarkAsRead = [];
      this.isSelectedAlarms = false;
      this.allAlarmRead = false;
    }
  }

  toggleFilter() {
    window.dispatchEvent(new Event('resize'));
    this.isSelectClose = !this.isSelectClose;
    if (this.alarmTypeTable.element.offsetHeight < this.alarmsTable.element.offsetHeight)
      return;

    if (!this.isSelectClose) {
      this.lastHeigthAlarmTable = this.alarmsTable.element.offsetHeight;
      this.alarmsTable.element.style.height = this.alarmTypeTable.element.offsetHeight + 'px';
    }
    else {
      this.alarmsTable.element.style.height = this.lastHeigthAlarmTable + 50 + 'px';
    }
  }

  onAlertPageChange({ page }) {
    if(this.pageChanged) {
      this.pageChanged = true;
      return;
    }
    page = page ? page - 1 : 0;
    this.alertsPageChange.emit(page);
  }

  onAlertPageSizeChange(event) {
    this.alertsPageSize.emit(Number(event));
  }

  onAlertNavigation(event) {
    this.navigation.emit(event);
  }

  onAlarmNavigation(event) {
    this.navigationAlarm.emit(event);
  }

  updateSearchString(event) {
    this.searchString.emit(event);
  }

  changeAlarmPage(event) {
    this.page.emit(event.page);
  }

  reloadPage() {
    this.reload.emit();
  }

  checkAlarm(event, row) {
    if (event)
      this.alarmsToMarkAsRead.push(row.serial);
    else
      this.alarmsToMarkAsRead = this.alarmsToMarkAsRead.filter(serial => row.serial != serial);

    this.isSelectedAlarms = this.alarmsToMarkAsRead.length ? true : false;
  }

  disableCheckAllAlarms() {
    return this.alarms.filter(a => a.read && a.user).length == this.alarms.length;
  }

  checkAllAlarms(event) {
    if (event) {
      this.isSelectedAlarms = true;
      this.alarmsToMarkAsRead = this.alarms.map(a => {
        if (!a.read && !a.user) {
          a.read = true;
          return a.serial;
        }
      });

    } else {
      this.isSelectedAlarms = false;
      this.alarmsToMarkAsRead = [];
      this.alarms.map(a => {
        if (!a.user) {
          a.read = false;
        }
      })
    }
  }

  sortByProp(event) {
    const sort = event.sorts[0];
    this.sortBy.emit(`${sort.prop}-${sort.dir}`);
  }

  resetFilter() {
    this.endDate = '';
    this.startDate = '';
    this.sendfilterByDate();
  }

  sendfilterByDate() {
    this.filterByDate.emit({ endDate: this.endDate, startDate: this.startDate });
  }

  changeAlarmPageSize(event) {
    this.pageSize.emit(Number(event));
  }

  sendMarkAsRead() {
    this.markAsRead.emit({
      markAllAsRead: this.allAlarmRead,
      alarms: this.alarmsToMarkAsRead
    });
  }

  openModal(content, markAllAsRead = false, row: IAllAlerts) {
    this.allAlarmRead = markAllAsRead;
    if (row)
      this.alarmsToMarkAsRead.push(row.serial);
    this._modalService.open(content, { centered: true, keyboard: false, backdrop: 'static' });
  }

  closeModal() {
    this._modalService.dismissAll();
  }

}
