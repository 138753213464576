import { NgForm } from '@angular/forms';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Utils } from 'src/app/shared/utils';
import { IRegionGroup, RegionGroupDTO } from './../../../core/models/RegionGroupDTO';

@Component({
  selector: 'app-regions-table',
  templateUrl: './regions-table.component.html',
  styleUrls: ['./regions-table.component.scss']
})
export class RegionsTableComponent {
  @Input() isLoading: boolean = true;
  @Input() regions: IRegionGroup[] = [];
  @Input() isUpdating: boolean = false;

  @Output() reload: EventEmitter<void> = new EventEmitter();
  @Output() createRegion: EventEmitter<IRegionGroup> = new EventEmitter();
  @Output() editRegion: EventEmitter<IRegionGroup> = new EventEmitter();
  @Output() deleteRegion: EventEmitter<IRegionGroup> = new EventEmitter();

  Utils = Utils;
  public isEditing: boolean = false;
  public region: IRegionGroup = new RegionGroupDTO();

  constructor(private modalService: NgbModal) { }


  openModal(content: any, region?: IRegionGroup) {
    if (region)
      this.region = region;
    this.modalService.open(content, { centered: true, backdrop: 'static', keyboard: false });
  }


  finishOperation() {
    this.isEditing = false;
    this.region = new RegionGroupDTO();
    this.modalService.dismissAll();
  }

  reloadEvent() {
    this.reload.emit();
  }

  createUpdate(form: NgForm) {
    this.isEditing ? this.editRegion.emit(this.region) : this.createRegion.emit(this.region);
  }

  delete() {
    this.deleteRegion.emit(this.region);
  }

}
