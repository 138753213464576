import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { EnumMapperDTO } from 'src/app/core/models/EnumMapperDTO';
import { MeterDTO, MeterNotComissionedDTO, MeterPhase } from 'src/app/core/models/MeterDTO';
import { ModemDTO, SignalQuality } from 'src/app/core/models/ModemDTO';
import { PaginationResponseDTO } from 'src/app/core/models/PaginationResponseDTO';
import { TranslateInCodeService } from '../../services/translate-in-code.service';
import { Utils } from '../../utils';

@Component({
  selector: 'app-meters-all-table',
  templateUrl: './meters-all-table.component.html',
  styleUrls: ['./meters-all-table.component.scss']
})
export class MetersAllTableComponent implements OnInit {
  @Input() isLoading: boolean = true;
  @Input() page = new PaginationResponseDTO();
  @Input() meters: MeterDTO[] = [];
  @Input() isReloading: boolean = false;

  @Output() navigateToMeter = new EventEmitter();
  @Output() filterMeter = new EventEmitter();
  @Output() reload = new EventEmitter();

  public filledColor: string = '#fff';

  public MeterPhase = MeterPhase;
  Utils = Utils;
  public MeterDTO = MeterDTO;
  public MeterNotComissioned = MeterNotComissionedDTO;
  Modem = ModemDTO;

  public startDate = "";
  public pageSize = 15;
  public endDate = "";
  public searchString = "";
  public sortOrder = "";
  public currentPage = 0;
  public placeHolderLabel =
    "Pesquisar pelo serial, código de instalação , EUI do modem ou Fabricante";

  private filter = {
    sortOrder: this.sortOrder,
    pageSize: this.pageSize,
    searchString: this.searchString,
    currentPage: this.currentPage,
    startDate: this.startDate,
    endDate: this.endDate,
    signal: '',
  };
  SignalQualityhMapper: EnumMapperDTO;

  constructor(
    private modalService: NgbModal,
    private _translateServices: TranslateService,
    private _translateInCodeService: TranslateInCodeService,
  ) { }
  async ngOnInit() {
    await this.getTranslate();
    this._translateServices.onLangChange.subscribe(async () => {
      await this.getTranslate();
    });
  }

  async getTranslate() {
    await this._translateInCodeService.getTranslate('ModemInfo.SignalQuality', SignalQuality).then(respo => {
      this.SignalQualityhMapper = respo;
    })
  }

  navigate(meter) {
    this.navigateToMeter.emit(meter);
  }

  updateSearchString(searchValue) {
    this.currentPage = 0;
    this.searchString = searchValue;
    this.filter["searchString"] = this.searchString;
    this.filter["currentPage"] = this.currentPage;
    this.emiteValue(this.filter);
  }

  sortByProp(prop) {
    const sort = prop.sorts[0];
    this.currentPage = 0;
    this.sortOrder = `${sort.prop}-${sort.dir}`;
    this.filter["currentPage"] = this.currentPage;
    this.filter["sortOrder"] = this.sortOrder;
    this.emiteValue(this.filter);
  }

  changePage(page) {
    this.currentPage = page.page - 1;
    this.filter["currentPage"] = this.currentPage;
    this.emiteValue(this.filter);
  }

  filterByDate() {
    this.filter["startDate"] = this.startDate;
    this.filter["endDate"] = this.endDate;
    this.currentPage = 0;
    this.filter["currentPage"] = this.currentPage;
    this.emiteValue(this.filter);
    this.closeModal();
  }

  finishOperation() {
    this.reseteDatas();
    this.closeModal();
  }

  private reseteDatas() {
    this.startDate = "";
    this.endDate = "";
  }

  openModal(content) {
    this.modalService.open(content, { centered: true });
  }

  private closeModal() {
    this.modalService.dismissAll();
  }

  private emiteValue(value) {
    this.filterMeter.emit(value);
  }

  public changeSizeValue(value) {
    this.pageSize = value;
    this.currentPage = 0;
    this.filter["pageSize"] = this.pageSize;
    this.filter["currentPage"] = this.currentPage;
    this.emiteValue(this.filter);
  }

  removeFilter() {
    this.reseteValues();
    this.emiteValue(this.filter);
    this.closeModal();
  }

  private reseteValues() {
    this.sortOrder = "";
    this.searchString = "";
    this.startDate = "";
    this.endDate = "";
    this.currentPage = 0;
    this.filter.signal = '';
    this.filledColor = '#fff';
    this.filter["currentPage"] = this.currentPage;
    this.filter["endDate"] = this.endDate;
    this.filter["startDate"] = this.startDate;
    this.filter["sortOrder"] = this.sortOrder;
    this.filter["searchString"] = this.searchString;
  }

  reloadMeters() {
    this.reload.emit();
  }

  changeSignal(signal) {
    this.filledColor = signal === null ? '#fff' : this.Modem.getSinalColor(signal);
    this.filter.signal = signal === null ? '' : signal;
    this.emiteValue(this.filter);
  }

}
