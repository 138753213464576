import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ScheduleCommand, ScheduleCommandDTO, ScheduleCommandType } from "src/app/core/models/ScheduleCommandDTO";
import cronstrue from 'cronstrue/i18n';
import { Utils } from "../../utils";
import { TranslateInCodeService } from "../../services/translate-in-code.service";
import { TranslateService } from "@ngx-translate/core";
import { EnumMapperDTO } from "src/app/core/models/EnumMapperDTO";
const ONE_HOUR_IN_MINUTES = 60;
const ONE_DAY_IN_MINUTES = 24 * ONE_HOUR_IN_MINUTES;
@Component({
    selector: 'app-routine-details-card',
    styleUrls: ['./routine-details-card.component.scss'],
    templateUrl: './routine-details-card.component.html'
})
export class RoutineDetailsCardComponent implements OnInit {
    @Input() schedule: ScheduleCommand;
    @Input() isLoading: boolean = true;
    @Input() isLoadingStatus: boolean = false;

    @Output() updateStatus: EventEmitter<any> = new EventEmitter<any>();

    ScheduleCommandType = ScheduleCommandType;
    ScheduleCommandDTO = ScheduleCommandDTO;
    Utils = Utils;


    public ScheduleCommandWithAditionalsParams = [
        ScheduleCommandType.RecoverMassMemorySmc, ScheduleCommandType.RecoverMassMemorySmi,
        ScheduleCommandType.NicResetSmi, ScheduleCommandType.NicResetSmc,
        ScheduleCommandType.RecoverEnergySmc, ScheduleCommandType.RecoverQeeIndicator,
        ScheduleCommandType.RecoverEnergySmi
    ];

    enumMapperDTO: EnumMapperDTO;
    constructor(private translateInCode: TranslateInCodeService, private translate: TranslateService) { }

    async ngOnInit() {
        await this.getTranslate();
        this.translate.onLangChange.subscribe(e => this.getTranslate());
    }

    public formatCron() {
        let lang = Utils.getLang()
        lang = lang == 'pt-BR' ? lang.replace('-', '_') : lang;
        return cronstrue.toString(this.schedule.cron, { locale: lang });
    }

    public commandsWithParams() {
        return this.ScheduleCommandWithAditionalsParams.includes(this.schedule.scheduleCommandType);
    }

    public changeScheduleStatus(status) {
        this.updateStatus.emit();
    }

    async getTranslate() {
        this.enumMapperDTO = await this.translateInCode.getTranslate('Schedule.ScheduleCommandType', ScheduleCommandType);
    }

    navigateToGroup(id: string) {
        window.open('/#/grupos/registrados/' + id);
    }

    isValidDate() {
        const [, , year] = this.Utils.getDate(this.schedule.lastExecution).split('/');
        const MAXYEAR = 2000;
        if (Number(year) < MAXYEAR) return false;
        return true;
    }


    translateDuration(durationInMinutes: number): string {
        let duration = '';
        const days = Math.floor(durationInMinutes / ONE_DAY_IN_MINUTES)
        const hoursToSplit = durationInMinutes % ONE_DAY_IN_MINUTES;
        const hours = Math.floor(hoursToSplit / ONE_HOUR_IN_MINUTES)
        const minutes = hoursToSplit % ONE_HOUR_IN_MINUTES;
        if (days !== 0)
            duration += `${days} dia(s) `
        if (hours !== 0)
            duration += `${hours} horas(s) `

        duration += `${minutes} minutos`

        return duration;
    }
}