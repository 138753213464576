import {
  Component,
  EventEmitter,
  Input,
  ViewChild,
  Output,
} from "@angular/core";
import { ReadingDTO } from "src/app/core/models/ReadingDTO";
import { Utils } from "src/app/shared/utils";

@Component({
  selector: "app-billing-table",
  templateUrl: "./billing-table.component.html",
  styleUrls: ["./billing-table.component.scss"],
})
export class BillingTableComponent {
  @Input() readings: ReadingDTO[] = [];
  @Input() isLoading = false;

  @Output() reload = new EventEmitter();

  Utils = Utils;
  ReadingDTO = ReadingDTO;

  @ViewChild("billingTable", { static: false }) table: any;

  toggleExpandRow(row) {
    this.table.rowDetail.toggleExpandRow(row);
  }

  reloadData() {
    this.reload.emit();
  }
}
