import { ITransformerGroup } from 'src/app/core/models/TransformerGroupDTO';
export interface IRegionGroup {
    regionName: string;
    regionId: string;
    description: string;
    transformerGroups: ITransformerGroup[];
    transformerGroupsCount: number;
    updatedAt: Date;
}

export class RegionGroupDTO implements IRegionGroup {
    regionName: string;
    regionId: string;
    description: string;
    transformerGroups: ITransformerGroup[];
    transformerGroupsCount: number;
    updatedAt: Date;

}

export interface IRegionAreasEdit {
    regionId: string;
    transformerGroupIds: string[];
}

export class RegionAreasEdit implements IRegionAreasEdit {
    regionId: string;
    transformerGroupIds: string[];

    constructor(regionId: string) {
        this.regionId = regionId;
        this.transformerGroupIds = [];
    }

}

export interface ITransformerGroupChange {
    add: ITransformerGroup[];
    remove: ITransformerGroup[];
}

export class ITransformGroupChange implements ITransformerGroupChange {
    add: ITransformerGroup[];
    remove: ITransformerGroup[];

    constructor() {
        this.add = [];
        this.remove = [];
    }

}