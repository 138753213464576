import { PROTOCOL_TYPE } from "../enums";
import { IMessages } from "../messages/IMessages";
import { Message } from "../messages/Message";
import { IProtocol } from "./IProtocol";

export class DlmsProtocol implements IProtocol {
    messageType: number;
    value: IMessages;
    type: PROTOCOL_TYPE = PROTOCOL_TYPE.DLMS_V2;

    constructor(data: number[]) {
        this.messageType = data[0];
        this.value = Message.factory(data);
    }
    insertDataBytes(data: number[]): void {
        this.value = Message.factory(data);
        this.value.insertData(data);
        this.messageType = data[0];

    }

    insertDataString(data: string): void {
        throw new Error("Method not implemented.");
    }
}
