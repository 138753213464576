import { Injectable } from "@angular/core";
import { HttpClient, HttpHandler, HttpHeaders } from "@angular/common/http";
import { AuthService } from "./auth.service";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

export class NotAuthenticatedError { }

@Injectable({
  providedIn: "root",
})
export class IogHttpService extends HttpClient {
  constructor(private auth: AuthService, private httpHandler: HttpHandler) {
    super(httpHandler);
  }

  public delete<T>(url: string, options?: any): Observable<T> {
    return this.makeRequest<T>(() => super.delete<T>(url, options));
  }

  public patch<T>(url: string, body: any, options?: any): Observable<T> {
    return this.makeRequest<T>(() => super.patch<T>(url, body, options));
  }

  public head<T>(url: string, options?: any): Observable<T> {
    return this.makeRequest<T>(() => super.head<T>(url, options));
  }

  public options<T>(url: string, options?: any): Observable<T> {
    return this.makeRequest<T>(() => super.options<T>(url, options));
  }

  public get<T>(url: string, options?: any): Observable<T> {
    return this.makeRequest<T>(() => super.get<T>(url, options));
  }

  public post<T>(url: string, body: any, options?: any): Observable<T> {
    return this.makeRequest<T>(() => super.post<T>(url, body, options));
  }

  public put<T>(url: string, body: any, options?: any): Observable<T> {
    return this.makeRequest<T>(() => super.put<T>(url, body, options));
  }

  private makeRequest<T>(fn: Function): Observable<T> {
    return fn();
  }
}

function getHeaders(token?) {
  return {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem(environment.token)}`,
    }),
  };
}
