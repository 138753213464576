import { Injectable } from "@angular/core";
// Services
import { IogHttpService } from "src/app/security/iog-http.service";
// Environments
import { environment } from "src/environments/environment";
import { GroupDTO } from "src/app/core/models/GroupDTO";
import { MeterDTO } from "src/app/core/models/MeterDTO";
import { Observable } from "rxjs";
import { IOnlineEstatisticDTO } from "src/app/core/models/OnlineStatisticDTO";

@Injectable({ providedIn: 'root' })
export class GroupServices {
  static url = environment.apiUrlBack + "/groups";

  constructor(private httpService: IogHttpService) { }

  getGroups(
    pageSize: number = 15,
    searchString: string = "",
    page: number = 1,
    startDate: string = "",
    endDate: string = "",
    sortOrder: string = ""
  ) {
    return this.httpService
      .get(
        `${GroupServices.url}?pageSize=${pageSize}&searchString=${searchString}&page=${page}&startDateTime=${startDate}&endDateTime=${endDate}&sortOrder=${sortOrder}`
      )
      .map((response) => response.valueOf());
  }

  addGroup(group: GroupDTO) {
    return this.httpService
      .post(`${GroupServices.url}`, group)
      .map((response) => response.valueOf());
  }

  editGroup(group: GroupDTO) {
    return this.httpService.put(`${GroupServices.url}`, group);
  }

  deleteGroup(group: GroupDTO) {
    return this.httpService.delete(`${GroupServices.url}/${group.visibleId}`);
  }

  getAll(): Observable<GroupDTO[]> {
    return this.httpService.get(GroupServices.url + '/get-all-no-page');
  }

  getSingleGroup(
    id: string,
    sortOrder: string = "updatedAt-desc",
    searchString: string = "",
    page: number = 1,
    pageSize: number = 15,
    startDate = "",
    endDate = ""
  ) {
    return this.httpService
      .get(
        `${GroupServices.url}/get-by-id/${id}?sortOrder=${sortOrder}&searchString=${searchString}&page=${page}&pageSize=${pageSize}&startDateTime=${startDate}&endDateTime=${endDate}`
      )
      .map((response) => response.valueOf());
  }

  removeMetersFromGroup(groupID: string, metersSerial: string[]) {
    return this.httpService.post(
      GroupServices.url +
      `/remove-from-group/${groupID}?${this.buildQuery(metersSerial)}`,
      {}
    );
  }

  getMetersWithoutPagination(groupId: string): Observable<MeterDTO[]> {
    return this.httpService.get<MeterDTO[]>(GroupServices.url + `/all-meters-no-pagination?id=${groupId}`);
  }

  getGroupsMetersOnlineStatic(groupId: string): Observable<IOnlineEstatisticDTO> {
    return this.httpService.get<IOnlineEstatisticDTO>(GroupServices.url + `/all-online-meters-no-pagination?id=${groupId}`);
  }

  private buildQuery(metersSerial: string[]): string {
    let query = "";
    metersSerial.forEach((_serial) => {
      query += `metersSerial=${_serial}&`;
    });
    return query;
  }
}
