import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { AuthService } from "src/app/security/auth.service";
import { environment } from "src/environments/environment";
import { Utils } from "../utils";
import { MessageService } from "./message.service";

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {
  constructor(public auth: AuthService, private message: MessageService) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${localStorage.getItem(environment.token)}`,
      },
      url: this._setLang(request.url)
    });
    return next.handle(request)
      .pipe(catchError(error => {
        if (error.status === 401 || error.status === 403) {
          this.auth.signout();
          this.message.showError('Autenticação', 'Você não tem permissão para realizar esta ação!');
          return;
        }
        return throwError(error);
      }));
  }

  private _setLang(url: string) {
    const queries = url.split('?')[1];
    const culture = queries ? `&culture=${Utils.getLang()}` : `?culture=${Utils.getLang()}`;
    return url + culture;
  }
}
