import { Router } from '@angular/router';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown/multiselect.model';
import * as moment from 'moment';

import { IBalanceCheckResponse, IReadingCheckDTO, ReadingCheckDTO } from './../../../core/models/BalanceDTO';
import { ITransformerDTO } from './../../../core/models/TransformerDTO';
import { IBalanceCheckRequestDTO, BalanceCheckRequetDTO } from 'src/app/core/models/BalanceDTO';

import { BalanceService } from '../../services/balance.service';
import { HandleErrorService } from 'src/app/shared/services/handle-error.service';
import { TranslateService } from '@ngx-translate/core';

const ONE_MONTH = 30;
@Component({
  selector: 'app-balance-header',
  templateUrl: './balance-header.component.html',
  styleUrls: ['./balance-header.component.scss']
})
export class BalanceHeaderComponent implements OnInit {
  @Input() transformers: ITransformerDTO[] = [];
  @Input() isLoading: boolean = true;

  @Output() readBalance = new EventEmitter();

  selectedTransformer: ITransformerDTO = null;
  streetLighting: number = null;
  public minuteRange: number = 20;

  initalDate: Date = null;
  endDate: Date = null;
  today: string = moment(new Date()).format('YYYY-MM-DD');
  minFinalDate: string = '';

  initialBalanceResponse: IReadingCheckDTO[] = [];
  finalBalaceResponse: IReadingCheckDTO[] = [];

  public initialBalanceCheck: IBalanceCheckRequestDTO = new BalanceCheckRequetDTO();
  public finalBalanceCheck: IBalanceCheckRequestDTO = new BalanceCheckRequetDTO();

  public initialDateRequest;
  public finalDateRequest;

  public balanceModel = {
    InitialDateTime: null,
    FinalDateTime: null,
    TransformerId: null,
    StreetLighting: 0,
    SecondRangeInitialDateTime: 20,
    SecondRangeFinalDateTime: 20,
  };


  ReadingCheckDTO = ReadingCheckDTO;

  transformerDropDownSettings: IDropdownSettings;
  initialDateDropDownSettings: IDropdownSettings;
  finalDateDropDownSettings: IDropdownSettings;
  
  translates;
  constructor(
    private balanceService: BalanceService,
    private handlerError: HandleErrorService,
    private router: Router,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.getTranslate();
    this.translate.onLangChange.subscribe(e => this.getTranslate());
  }

  getTranslate() {
    this.translates = this.translate.instant('Utils');
    this.initTransformDropDownSettings();
  }

  initTransformDropDownSettings() {
    this.transformerDropDownSettings = {
      singleSelection: true,
      idField: 'transformerId',
      textField: 'name',
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: '',
      searchPlaceholderText: this.translates.SearchByName,
    };

    this.initialDateDropDownSettings = {
      singleSelection: true,
      idField: 'operationId',
      textField: 'label',
      noDataAvailablePlaceholderText:  this.translates.NoTime,
    }

    this.finalDateDropDownSettings = {
      singleSelection: true,
      idField: 'operationId',
      textField: 'label',
      noDataAvailablePlaceholderText: this.translates.NoTime,
    }
  }

  setTranformerId(transformer: ITransformerDTO) {
    this.initialBalanceCheck.TransformerId = transformer.transformerId;
    this.finalBalanceCheck.TransformerId = transformer.transformerId;
    this.resetDatas();
  }

  sendRequest(event) {
    this.initialDateRequest = null;
    this.initialBalanceCheck.DateRequest = this.initalDate;
    this.initialBalanceCheck.secondRange = this.minuteRange;
    this.minFinalDate = moment(this.initalDate).format('YYYY-MM-DD')
    if (this.initialBalanceCheck.TransformerId !== null && this.initialBalanceCheck.DateRequest !== null && this.initialBalanceCheck.DateRequest.toString() !== '') {
      this.isLoading = true;
      this.balanceService.checkBalanceEffectivenesss(this.initialBalanceCheck).toPromise()
        .then((response: IBalanceCheckResponse) => {
          response.readingChecks.forEach(_reading => {
            _reading.label = ReadingCheckDTO.buildBalceCheckResponseLabel(_reading)
          });
          this.initialBalanceResponse = response.readingChecks;
          this.isLoading = false;
        })
        .catch(error => {
          this.handlerError.handle(error, this.router);
          this.isLoading = false;
        })
    }
  }

  sendEndRequest(event) {
    this.finalDateRequest = null;
    this.finalBalanceCheck.DateRequest = this.endDate;
    this.finalBalanceCheck.secondRange = this.minuteRange;
    this.setStreetLigth();
    if (this.finalBalanceCheck.TransformerId !== null && this.finalBalanceCheck.DateRequest !== null && this.finalBalanceCheck.DateRequest.toString() !== '') {
      this.isLoading = true;
      this.balanceService.checkBalanceEffectivenesss(this.finalBalanceCheck).toPromise()
        .then((response: IBalanceCheckResponse) => {
          response.readingChecks.forEach(_reading => {
            _reading.label = ReadingCheckDTO.buildBalceCheckResponseLabel(_reading)
          });

          this.finalBalaceResponse = response.readingChecks;
          this.isLoading = false;
        })
        .catch(error => {
          this.handlerError.handle(error, this.router);
          this.isLoading = false;
        })
    }
  }

  setInitialDateRequest(value) {
    this.balanceModel.InitialDateTime = this.initialBalanceResponse.find(_balance => _balance.operationId === value.operationId).readingHour;
  }

  setFinalDateRequest(value) {
    this.balanceModel.FinalDateTime = this.finalBalaceResponse.find(_balance => _balance.operationId === value.operationId).readingHour;
    this.balanceModel.SecondRangeFinalDateTime = this.minuteRange;
    this.balanceModel.SecondRangeFinalDateTime = this.minuteRange;
  }

  getBalance() {
    this.balanceModel.TransformerId = this.initialBalanceCheck.TransformerId;
    this.readBalance.emit(this.balanceModel);
  }

  private resetDatas() {
    this.balanceModel.StreetLighting = 0;
    this.initialDateRequest = null;
    this.finalDateRequest = null;
    this.endDate = null
    this.initalDate = null;
    this.minFinalDate = null;
    this.streetLighting = null;
    this.minuteRange = 20;
  }



  setStreetLigth(value?: number) {
    const selectedTranformer = this.transformers.find(_transformer => _transformer.transformerId === this.initialBalanceCheck.TransformerId);
    const stretLightOneDay = selectedTranformer.publicLighting / ONE_MONTH;
    const daysPassed = moment(this.endDate).diff(this.initalDate, 'days');
    this.streetLighting = stretLightOneDay * daysPassed;
    this.balanceModel.StreetLighting = this.streetLighting;
  }

  setStreetLightManual(value?: number) {
    this.streetLighting = value;
    this.balanceModel.StreetLighting = value;
  }

}
