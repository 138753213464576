import { copyRange, DLMS_DATAS_TYPES, DLMS_INSTANCES } from '../enums';
import { IDlmsData } from '../IDlmsData';
import DlmsArrayStruct from './DlmsArrayStruct';
import { IDlmsDataFactory } from './IDlmsDataFactory';

class DlmsCompactArray implements IDlmsData {
    tag: number = DLMS_DATAS_TYPES.COMPACT_ARRAY;
    totalBytes: number;
    rawValue: number[] = [];
    bodyBytes: number[];
    value: IDlmsData[] = [];

    constructor(private factory?: IDlmsDataFactory, data?: number[]) {
        if (data) {
            if (this.tag !== data[0])
                throw new Error('Dado não corresponde ao Dlms Compact Array');
            this.totalBytes = data[2] + 3;
            this.bodyBytes = new Array<number>(this.totalBytes - 3)
            this.bodyBytes =
                copyRange(this.bodyBytes, 0, data, 3, this.bodyBytes.length);

            let index: number = 0;
            let tempArray: number[] = this.bodyBytes;
            while (index !== this.totalBytes - 3) {
                let dlmsData: IDlmsData = this.factory.create(data[1]);
                let secondTempArray: number[] = new Array<number>(tempArray.length + 1);
                secondTempArray[0] = data[1];
                secondTempArray =
                    copyRange(secondTempArray, 0, tempArray, 1, tempArray.length);
                dlmsData.insertData(secondTempArray);
                this.value.push(dlmsData);
                tempArray =
                    copyRange(tempArray, 0, tempArray, dlmsData.totalBytes - 1, tempArray.length - dlmsData.rawValue.length);
                index = dlmsData.totalBytes - 1;
            }

            this.rawValue = new Array<number>(this.totalBytes);
            this.rawValue =
                copyRange(this.rawValue, 0, data, 0, this.rawValue.length);
        }
    }
    getInstance(): string {
        return DLMS_INSTANCES.compactArray
    }


    getValue(): IDlmsData[] {
        return this.value;
    }


    insertData(data: number[]): void {
        if (this.tag !== data[0])
            throw new Error('Dado não corresponde ao Dlms Compact Array');
        let descriptor: IDlmsData = this.factory.create(data[1]);
        let isAStruct: boolean = false;
        let typesDlms: number[] = [];
        let structLength: number = 0;

        if (descriptor.getInstance() === DLMS_INSTANCES.arrayStruct) {
            isAStruct = true;
            structLength = data[2];
            this.totalBytes = structLength + data[3 + structLength] + 4;
            this.bodyBytes = new Array<number>(this.totalBytes - (4 + structLength));
            for (let index = 1; index <= structLength; index++) {
                typesDlms.push(data[2 + index]);
            }
            this.bodyBytes =
                copyRange(this.bodyBytes, 0, data, 4 + structLength);

        }
        else {
            this.totalBytes = data[2] + 3;
            this.bodyBytes = new Array<number>(this.totalBytes - 3);
            typesDlms.push(data[1]);
            this.bodyBytes =
                copyRange(this.bodyBytes, 0, data, 3, this.bodyBytes.length + 3);
        }



        let bytesList: number[] = this.bodyBytes;
        while (bytesList.length > 0) {
            if (isAStruct) {
                let index: number = 0;
                typesDlms.forEach(_type => {
                    bytesList.splice(index, 0, _type);
                    index += 2;
                }
                )
                bytesList.unshift(2);
                bytesList.unshift(structLength);
                descriptor = new DlmsArrayStruct(this.factory,bytesList);
                this.value.push(descriptor);

                bytesList = bytesList.slice(descriptor.totalBytes);
            } else {
                let dlmsData: IDlmsData = this.factory.create(data[1]);
                bytesList.push(0, data[1]);
                dlmsData.insertData(bytesList);

                this.value.push(dlmsData);
                bytesList = bytesList.slice(dlmsData.totalBytes);
            }
        }
        this.rawValue = new Array<number>(this.totalBytes);
        this.rawValue =
            copyRange(this.rawValue, 0, data, 0, this.rawValue.length);

    }

}

export default DlmsCompactArray;
