import { SmcDTO } from './../../../core/models/SmcDTO';
import { SmcsService } from './../../services/smcs.service';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IMeterDTO, MeterDTO } from 'src/app/core/models/MeterDTO';

import * as XLS from "xlsx";
import { FileService } from '../../services/file.service';
import { HandleErrorService } from '../../services/handle-error.service';
import { METERCONECTIONPHASE } from '../meter-register-table/meter-register-table.component';

@Component({
  selector: 'app-smc-file-upload-table',
  templateUrl: './smc-file-upload-table.component.html',
  styleUrls: ['./smc-file-upload-table.component.scss']
})
export class SmcFileUploadTableComponent {

  @Input() meters: IMeterDTO[] = [];
  @Input() isUploadingFile: boolean = false;


  @Output() descartAllMeters: EventEmitter<void> = new EventEmitter();
  @Output() upload: EventEmitter<void> = new EventEmitter();
  @Output() edit: EventEmitter<void> = new EventEmitter();
  @Output() descartLine: EventEmitter<string> = new EventEmitter();
  isLoadingAllMeters = false;

  MeterDTO = MeterDTO;

  public serial: string = null;
  public isEdit: boolean = false;

  public fileUrlDownload = './assets/models/modelo de cadastro dos smc.xlsx';

  constructor(
    private _modalService: NgbModal,
    private _smcServices: SmcsService,
    private handleService: HandleErrorService,
    public fileService: FileService,
  ) { }


  openModal(content, serial?: string) {
    if (serial)
      this.serial = serial;
    this._modalService.open(content, { centered: true, backdrop: 'static' });
  }

  closeModal() {
    this._modalService.dismissAll();
  }

  removeLine() {
    this.descartLine.emit(this.serial);
  }

  descartMeters() {
    this.descartAllMeters.emit();
  }

  uploadMeters() {
    this.upload.emit();
  }

  editMeters() {
    this.edit.emit();
  }

  // downloadAllMeters() {
  //   this.isLoadingAllMeters = true;
  //   this._smcServices.getAllMeters().toPromise()
  //     .then((response: MeterDTO[]) => {
  //       const rows = this.processMeters(response);
  //       const workSheet: XLS.WorkSheet = XLS.utils.json_to_sheet([...rows]);
  //       workSheet["!cols"] = [{ width: 45 }];

  //       const workbook: XLS.WorkBook = {
  //         Sheets: {
  //           "Medidores": workSheet,
  //         },
  //         SheetNames: ["Medidores"],
  //       };
  //       const excelBuffer: any = XLS.write(workbook, {
  //         bookType: "xlsx",
  //         type: "array",
  //       });
  //       this.fileService.saveFileAsExcel(excelBuffer, 'Todos os Medidores');

  //       this.isLoadingAllMeters = false;
  //     }).catch(error => {
  //       this.handleService.handle(error);
  //       this.isLoadingAllMeters = false;
  //     })

  // }

  // private processMeters(smcs: SmcDTO[]) {
  //   const smcList = [];


  //   smcs.forEach(_smc => {

  //     const meter = {
  //       serial: _smc.serial,
  //       latitude: _smc.latitude,
  //       longitude: _smc.longitude,
  //       smcModel: _smc.meterModel.name,
  //       description: _smc.description,
  //     };
  //     smcList.push(meter);
  //   }
  //   );
  //   return smcList;
  // }

  private getConnectionPhase(phase: number) {
    return METERCONECTIONPHASE[phase];
  }

}
