import * as XLS from 'xlsx';

export class ExcelFileDTO {
    static excelToObject<T>(file: File): Promise<Array<T>> {
        const reader = new FileReader();
        reader.readAsBinaryString(file);
        return new Promise((resolve, reject) => {
          reader.onload = (e: any) => {
              const content = e.target.result;
              const wb = XLS.read(content, { type: 'binary' });
              wb.SheetNames.forEach(sheet => {
                  const row = XLS.utils.sheet_to_json(wb.Sheets[sheet]);
                  resolve(<Array<T>>row);
              });
          }
        });
    }

    static objectToExcel(rows, sheetName='') {
        const ws = ExcelFileDTO._getWorkSheet(rows);
        const wb = ExcelFileDTO._getWorkBook(ws);
        return ExcelFileDTO._writeXls(wb);
    }

    static _getWorkSheet(rows): XLS.WorkSheet {
        const workSheet = XLS.utils.json_to_sheet([...rows]);
        workSheet["!cols"] = [{ width: 45 }];
        return workSheet;
      }
    
    static _getWorkBook(workSheet: XLS.WorkSheet, sheetName=''): XLS.WorkBook {
        return {
            Sheets: {
                [sheetName]: workSheet,
            },
            SheetNames: [sheetName]
        }
    }

    static _writeXls(workBook) {
        return XLS.write(workBook, { bookType: 'xlsx', type: 'array' });
    }
}
