import {
  Component,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
} from "@angular/core";
import { NgbModal, NgbModalRef, NgbTimeStruct } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import { MeterModelDTO } from "src/app/core/models/MeterModelDTO";
import { UserType } from "src/app/core/models/UserDTO";
import { AuthService } from "src/app/security/auth.service";
import { Utils } from "../../utils";

@Component({
  selector: "app-request-energy",
  templateUrl: "./request-energy.component.html",
  styleUrls: ["./request-energy.component.scss"],
})
export class RequestEnergyComponent implements AfterViewInit {
  @Input() isLoading: boolean;
  @Input() isSMI: boolean = true;
  @Input() model: MeterModelDTO;

  @Output() filter = new EventEmitter();

  public startDate = null;
  public endDate = null;
  public quantity = null;
  public intervalInMinutes: NgbTimeStruct = { hour: 0, minute: 10, second: 0 };
  minuteStep = 10;
  public hasPermitionToRecoveryEnergy = [UserType.ADMIN];

  private modalOpen: NgbModalRef
  constructor(
    private modalService: NgbModal,
    public authService: AuthService
  ) {}
  ngAfterViewInit(): void {
    this.minuteStep = this.model.manufacturer.name === "ELETRA" ? 15 : 10;
  }

  openModal(content) {
    this.modalOpen = this.modalService.open(content, { centered: true, backdrop: "static" });
  }

  finishOperation() {
    this.startDate = null;
    this.endDate = null;
    this.intervalInMinutes.hour = 0;
    this.intervalInMinutes.minute = 10;
    this.intervalInMinutes.second = 0;
    this.modalOpen.dismiss();
  }

  findEnergysByDate() {
    const dates = {
      begin: moment(this.startDate).format("YYYY-MM-DDTHH:mm:ss"),
      end: moment(this.endDate).format("YYYY-MM-DDTHH:mm:ss"),
      periodInMinutes: Utils.getTimeInMinutes(this.intervalInMinutes),
    };
    this.filter.emit(this.isSMI ? dates : this.quantity);
  }
}
