import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";

import * as XLS from "xlsx";
import { AccountStatus, MeterDTO } from "src/app/core/models/MeterDTO";
import { PaginationResponseDTO } from "src/app/core/models/PaginationResponseDTO";
import { GroupServices } from "../../services/groups.service";
import { Utils } from "../../utils";
import { FileService } from "../../services/file.service";
import { HandleErrorService } from "../../services/handle-error.service";
import { TranslateInCodeService } from "../../services/translate-in-code.service";
import { TranslateService } from "@ngx-translate/core";
import { EnumMapperDTO } from "src/app/core/models/EnumMapperDTO";

@Component({
  selector: "app-group-meters-table [groupId]",
  templateUrl: "./group-meters-table.component.html",
  styleUrls: ["./group-meters-table.component.scss"],
})
export class GroupMetersTableComponent implements OnInit {
  @Input() meters: MeterDTO[] = [];
  @Input() groupId: string = null;
  @Input() isLoading: boolean = true;
  @Input() isReloading: boolean = false;
  @Input() groupName: string = null;
  @Input() page: PaginationResponseDTO = new PaginationResponseDTO();

  @Output() reload = new EventEmitter();
  @Output() exportCheckedMeters = new EventEmitter();
  @Output() navigateToMeter = new EventEmitter();
  @Output() filterMeter = new EventEmitter();


  MeterDTO = MeterDTO;
  Utils = Utils;

  public startDate = "";
  public endDate = "";
  public searchString = "";
  public sortOrder = "updatedAt-desc";
  public currentPage = 0;
  public pageSize = 15;
  public placeholder =
    "Pesquisar pelo serial, código de instalação , EUI do modem ou Fabricante";
  public isDownLoading = false;
  private filter = {
    pageSize: this.pageSize,
    sortOrder: this.sortOrder,
    searchString: this.searchString,
    currentPage: this.currentPage,
    startDate: this.startDate,
    endDate: this.endDate,
  };

  enumMapperDTO: EnumMapperDTO;
  constructor(
    private modalService: NgbModal,
    private groupsServices: GroupServices,
    public fileService: FileService,
    private handleErrorService: HandleErrorService,
    private router: Router,
    private translateInCode: TranslateInCodeService,
    private translate: TranslateService
  ) { }

  async ngOnInit() {
    await this.getTranslate();
    this.translate.onLangChange.subscribe(e => this.getTranslate());
  }

  async getTranslate() {
    this.enumMapperDTO = await this.translateInCode.getTranslate('Meter.AccountStatus', AccountStatus);
  }

  updateSearchString(searchValue) {
    this.currentPage = 0;
    this.searchString = searchValue;
    this.filter["searchString"] = this.searchString;
    this.filter["currentPage"] = this.currentPage;
    this.emiteValue(this.filter);
  }

  downloadMeters() {
    this.isDownLoading = true;
    this.groupsServices.getMetersWithoutPagination(this.groupId).toPromise().then(response => {
      const rows = this.processMeters(response);
      const workSheet: XLS.WorkSheet = XLS.utils.json_to_sheet([...rows]);
      workSheet["!cols"] = [{ width: 45 }];

      const workbook: XLS.WorkBook = {
        Sheets: {
          "Medidores": workSheet,
        },
        SheetNames: ["Medidores"],
      };
      const excelBuffer: any = XLS.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      this.fileService.saveFileAsExcel(excelBuffer, `Todos os Medidores do Grupo ${this.groupName}`);
      this.isDownLoading = false;
      this.closeModal();
    })
      .catch(error => {
        this.handleErrorService.handle(error, this.router);
        this.isDownLoading = false;
      })
  }

  sortByProp(prop) {
    const sort = prop.sorts[0];
    this.currentPage = 0;
    this.sortOrder = `${sort.prop}-${sort.dir}`;
    this.filter["currentPage"] = this.currentPage;
    this.filter["sortOrder"] = this.sortOrder;
    this.emiteValue(this.filter);
  }

  changePage(value) {
    const { page } = value;
    this.currentPage = page ? page - 1 : 0;
    this.filter["currentPage"] = this.currentPage;
    this.emiteValue(this.filter);
  }

  changeSizeValue(value) {
    this.pageSize = value;
    this.currentPage = 0;
    this.filter["pageSize"] = this.pageSize;
    this.filter["currentPage"] = this.currentPage;
    this.emiteValue(this.filter);
  }

  filterByDate() {
    this.filter["startDate"] = this.startDate;
    this.filter["endDate"] = this.endDate;
    this.currentPage = 0;
    this.filter["currentPage"] = this.currentPage;
    this.emiteValue(this.filter);
    this.closeModal();
  }

  finishOperation() {
    this.reseteDatas();
    this.closeModal();
  }

  private processMeters(meters: MeterDTO[]) {
    let metersList = [];
    meters.forEach(_meter => {
      let tags: string = '';
      let registers: string = '';
      _meter.tags.forEach(tag => {
        tags += tag.keyword + ';';
      })
      if (tags.slice(tags.length - 1) === ';') {
        tags = tags.slice(0, tags.length - 1);
      }
      _meter.meterRegisters.forEach(_register => {
        registers += _register.code + ';';
      })
      if (registers.slice(registers.length - 1) === ';')
        registers = registers.slice(0, registers.length - 1);

      const meter = {
        Fase: MeterDTO.getPhaseValue(_meter),
        Modelo: _meter.meterModel.name,
        Smc: _meter.smc !== null ? _meter.smc.serial : null,
        Serial: _meter.serial,
        Latitude: _meter.latitude,
        Longitude: _meter.longitude,
        Tags: tags,
        ['Código de Instalação']: _meter.installation,
        ['Estado de Comunicação']: _meter.online ? 'Online' : 'Offline',
        DeviceEui: _meter.modem !== null ? _meter.modem.deviceEui : null,
        Fabricante: _meter.meterModel !== null ? _meter.meterModel.manufacturer.name : null

      };
      metersList.push(meter);
    })
    return metersList;
  }

  private reseteDatas() {
    this.startDate = "";
    this.endDate = "";
  }

  openModal(content) {
    this.modalService.open(content, { centered: true });
  }

  private closeModal() {
    this.modalService.dismissAll();
  }

  private emiteValue(value) {
    this.filterMeter.emit(value);
  }

  removeFilter() {
    this.reseteValues();
    this.emiteValue(this.filter);
    this.closeModal();
  }

  private reseteValues() {
    this.sortOrder = "updatedAt-desc";
    this.searchString = "";
    this.startDate = "";
    this.endDate = "";
    this.currentPage = 0;
    this.filter["currentPage"] = this.currentPage;
    this.filter["endDate"] = this.endDate;
    this.filter["startDate"] = this.startDate;
    this.filter["sortOrder"] = this.sortOrder;
    this.filter["searchString"] = this.searchString;
  }
  public checkedMeters: string[] = [];

  checkAll(event) {
    this.meters.forEach((meter) => {
      meter.checked = event;
      this.checkMeter(event, meter);
    });
  }

  navigate(serial) {
    this.navigateToMeter.emit(serial);
  }

  checkMeter(event, row) {
    const meter = this.checkedMeters.find((serial) => row.serial === serial);
    if (meter) {
      if (!event) {
        const meterIndex = this.checkedMeters.findIndex(
          (serial) => serial === row.serial
        );
        this.checkedMeters.splice(meterIndex, 1);
      }
    } else if (event) {
      this.checkedMeters.push(row.serial);
    }
    this.exportCheckedMeters.emit(this.checkedMeters.slice());
  }

  reloadGroup() {
    this.reload.emit();
  }
}
