import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { CommandDTO, CommandStatus } from "src/app/core/models/CommandDTO";
import { EnumMapperDTO } from "src/app/core/models/EnumMapperDTO";
import { PaginationResponseDTO } from "src/app/core/models/PaginationResponseDTO";
import {
  CommandType,
  Status,
  StatusCommand,
  TicketDTO,
  TicketsStatus,
} from "src/app/core/models/TicketsDTO";
import { MessageService } from "src/app/shared/services/message.service";
import { TranslateInCodeService } from "../../services/translate-in-code.service";
import { Utils } from "../../utils";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { KeysService } from "src/app/shared/services/keys.service";
import { saveAs } from "file-saver";

@Component({
  selector: "app-auditoria-table",
  templateUrl: "./auditoria-table.component.html",
  styleUrls: ["./auditoria-table.component.scss"],
})
export class AuditoriaTableComponent implements OnInit {
  @Input() tickets: TicketDTO[];
  @Input() page: PaginationResponseDTO;
  @Input() isLoading: boolean;
  @Input() isReloading = false;
  @Input() serial: string = null;
  @Input() isSchedulingTables = false;

  @Output() filter = new EventEmitter();
  @Output() reload = new EventEmitter();

  @ViewChild("ngxTable", { static: false }) table: any;

  ticketDTO = TicketDTO;
  Utils = Utils;
  Status = Status;
  CommandType = CommandType;
  TicketStatus = TicketsStatus;
  public ticket: TicketDTO;
  public CommandDTO = CommandDTO;
  public StatusCommand = StatusCommand;

  public sortOrder = this.isSchedulingTables
    ? "ticketCreationDate-desc"
    : "updatedAt-desc";
  public currentPage = 0;
  public pageSize = 15;
  public filters = {
    pageSize: this.pageSize,
    page: this.currentPage,
    sortOrder: this.sortOrder,
  };

  EnumMapperDTO: EnumMapperDTO[];
  constructor(
    private _modalService: NgbModal,
    private _messageService: MessageService,
    private _translateInCode: TranslateInCodeService,
    private _translateService: TranslateService,
    private _keyService: KeysService
  ) {}
  async ngOnInit() {
    await this.getTranslate();
    this._translateService.onLangChange.subscribe(async () => {
      await this.getTranslate();
    });
  }

  toggleExpandRow(row) {
    this.table.rowDetail.toggleExpandRow(row);
  }

  async getTranslate() {
    this._translateInCode
      .getTranslateEnunsInArray("Tickets.Enuns", [
        TicketsStatus,
        StatusCommand,
        CommandType,
      ])
      .then((res) => {
        this.EnumMapperDTO = res;
      });
  }

  onSort(value) {
    const { sorts } = value;
    this.sortOrder = `${sorts[0].prop}-${sorts[0].dir}`;
    this.filters["sortOrder"] = this.sortOrder;
    this.currentPage = 0;
    this.filters["page"] = this.currentPage;
    this.emiteValues();
  }

  filterByPage(value) {
    const { page } = value;
    this.currentPage = page ? page - 1 : 0;
    this.filters["page"] = this.currentPage;
    this.emiteValues();
  }

  changeSizeValue(value) {
    this.currentPage = 0;
    this.filters["page"] = this.currentPage;
    this.pageSize = value;
    this.filters["pageSize"] = this.pageSize;
    this.emiteValues();
  }

  removeFilter() {
    this.reseteValues();
    this.emiteValues();
  }

  reloadTickets() {
    this.reload.emit();
  }

  private reseteValues() {
    this.sortOrder = "updatedAt-desc";
    this.pageSize = this.page.pageSize;
    this.currentPage = 0;
    this.filters["page"] = this.currentPage;
    this.filters["sortOrder"] = this.sortOrder;
    this.emiteValues();
  }

  private emiteValues() {
    this.filter.emit(this.filters);
  }

  downloadArquivo(ticketId: any): void {
    this._keyService.downloadFile(ticketId).subscribe((response: any) => {
      if (response) {
        const blob = new Blob([response], { type: "application/octet-stream" });
        const fileName = "resultado.xls";
        saveAs(blob, fileName);
        this._messageService.showSuccess("Sucesso", "Download feito.");
      } else {
        this._messageService.showError(
          "Erro",
          "Não foi possível a realização do download."
        );
      }
    });
  }
  /**
   * verifica se o comando possui alguma resposta do tipo percentagem
   * @param {TicketDTO} ticket comando ticket
   * @returns {boolean} indicando se é percentagem ou não
   */
  showProgessBar(ticket: TicketDTO): boolean {
    return (
      (ticket.commandType === CommandType.ImportFrozenData ||
        ticket.commandType === CommandType.ImportMassMemory ||
        ticket.commandType === CommandType.ImportVoltageOutAlarm ||
        ticket.commandType === CommandType.ImportQEEIndicator) &&
      ticket.status !== Status.Failed
    );
  }
}
