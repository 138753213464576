import { MeterDTO } from "src/app/core/models/MeterDTO";
import { IDlmsData } from "../IDlmsData";

export class BuildMeters {
    meters: MeterDTO[] = [];
    constructor(data: IDlmsData) {
        let struct: IDlmsData[] = data.getValue();
        struct.forEach(_struct => {
            let value = _struct.getValue();
            let serial = parseInt(value[1].getValue(), 16);
            let meter: MeterDTO = new MeterDTO();
            meter.serial = serial.toString().padStart(12, '0');
            meter.position = value[0].getValue() & 0x0F;
            this.meters.push(meter);
        });
    }

    showMeters(): void {
        this.meters.forEach(meter => {
        })
    }

    getMeters(): MeterDTO[] {
        return this.meters;
    }
}
