import { IUserDTO } from './../../core/models/UserDTO';
import { Observable } from 'rxjs';
import { Injectable } from "@angular/core";
import "rxjs/add/operator/map";
import { environment } from "../../../../src/environments/environment";
import { IogHttpService } from "../../security/iog-http.service";
import { UserDTO } from "../../core/models/UserDTO";

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  static url = environment.apiUrlBack;

  constructor(private http: IogHttpService) { }

  getUsers(): Observable<IUserDTO[]> {
    return this.http
      .get<IUserDTO[]>(UsersService.url + "/users")
  }

  getUser(email: string) {
    return this.http
      .get(UsersService.url + "/users/" + email)
      .map((res) => res.valueOf());
  }

  saveUser(DTO: UserDTO) {
    return this.http.post(UsersService.url + "/users", DTO);
  }

  updateUser(DTO: UserDTO) {
    return this.http.put(UsersService.url + "/users", DTO);
  }

  deleteUser(email) {
    return this.http.delete(UsersService.url + `/users/${email}`);
  }

  unlockUser(user: UserDTO) {
    const newData = {
      email: user.email.toLocaleLowerCase(),
      newPassword: user.password,
    };
    return this.http.post(
      UsersService.url + "/users/reset-user-blocked",
      newData
    );
  }
}
