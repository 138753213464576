import { Component, Input, Output, EventEmitter } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { GroupDTO } from "src/app/core/models/GroupDTO";
import { IOnlineEstatisticDTO } from "src/app/core/models/OnlineStatisticDTO";
import { UserType } from "src/app/core/models/UserDTO";
import { AuthService } from "src/app/security/auth.service";
import { FileService } from "../../services/file.service";

@Component({
  selector: "app-group-header",
  templateUrl: "./group-header.component.html",
  styleUrls: ["./group-header.component.scss"],
})
export class GroupHeaderComponent {
  @Input() group: GroupDTO = new GroupDTO();
  @Input() canSaveGroup = false;
  @Input() newGroup = true;
  @Input() isSaving = false;
  @Input() isEditing = false;
  @Input() groupStatusStatistics: IOnlineEstatisticDTO = null;

  @Output() saveGroup = new EventEmitter();
  @Output() editGroup = new EventEmitter();

  public canCreateCroup = [UserType.ADMIN, UserType.OPERATOR];

  constructor(
    private modalService: NgbModal,
    private fileService: FileService,
    public authService: AuthService
  ) { }

  public openModal(content) {
    this.modalService.open(content, { centered: true });
  }

  closeAll() {
    this.modalService.dismissAll();
  }

  saveEditGroup() {
    this.newGroup ? this.save() : this.edit();
  }

  salvarGrupo() {
    this.fileService.saveGroup(
      GroupDTO.buildGroupJson(this.group),
      this.group.name
    );
  }

  private save() {
    this.saveGroup.emit(this.group);
  }

  private edit() {
    this.editGroup.emit(this.group);
  }
}
