import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { icon, marker } from "leaflet";
import { FirstLedStatus, SecondLedStatus, ServiceType } from "src/app/core/models/AuditDTO";
import { EnumMapperDTO } from "src/app/core/models/EnumMapperDTO";
import { PaginationResponseDTO } from "src/app/core/models/PaginationResponseDTO";
import { TranslateInCodeService } from "../../services/translate-in-code.service";
import { Utils } from "../../utils";

@Component({
    selector: 'app-installation-table',
    styleUrls: ['./installation-table.component.scss'],
    templateUrl: './installation-table.component.html'
})
export class InstallationTableComponent implements OnInit {
    @Input() page = new PaginationResponseDTO();
    @Input() isLoading = true;

    @Output() onSearch = new EventEmitter();
    @Output() onChangePage = new EventEmitter();
    @Output() onFilterByDate = new EventEmitter();
    @Output() onChangePageSize = new EventEmitter();
    @Output() onGifToggle = new EventEmitter();
    @Output() onDownload = new EventEmitter();

    gifLoadingUrl: string = 'assets/images/loading.gif';

    @ViewChild('table', { static: false }) table;
    dates = {
        startDate: '',
        endDate: ''
    }

    FirstLedStatus: EnumMapperDTO;
    SecondLedStatus: EnumMapperDTO;
    ServiceType: EnumMapperDTO;
    Utils = Utils;
    constructor(
        private readonly _translateInCode: TranslateInCodeService,
        private readonly _translateService: TranslateService,
        private readonly _modal: NgbModal) { }

    async ngOnInit() {
        await this.getTranslate();
    }

    sendDownload() {
        this.onDownload.emit();
    }

    toggleExpandRow(row) {
        this.table.rowDetail.toggleExpandRow(row);
        if (row.gif == null) {
            this.onGifToggle.emit(row);
        }
        window.dispatchEvent(new Event('resize'));
    }


    getMarker(row) {
        return [marker([+row.latitude || 0, +row.longitude || 0], {
            icon: icon({
                iconUrl: "assets/images/medidor-online.png",
                iconSize: [30, 30],
            }),
            title: row.serial,
        })];
    }

    sortByProp(sort) { }

    onDetailToggle(event) {
        console.log('Detail Toggled', event);
    }

    openModal(ctx) {
        this._modal.open(ctx, { centered: true });
    }

    closeModal() {
        this._modal.dismissAll();
    }

    async getTranslate() {
        const [fisrtLedStatus, secondsLedStatus, serviceType] = await this
            ._translateInCode
            .getTranslateEnunsInArray('Installation.Table.Enuns', [FirstLedStatus, SecondLedStatus, ServiceType]);

        this.FirstLedStatus = fisrtLedStatus;
        this.SecondLedStatus = secondsLedStatus;
        this.ServiceType = serviceType;
    }

    search(event) {
        this.onSearch.emit(event);
    }

    changePage(event) {
        this.onChangePage.emit(event.page);
    }

    changePageSize(event) {
        this.onChangePageSize.emit(event);
    }

    cleanFilter() {
        this.dates['endDate'] = '';
        this.dates['startDate'] = '';
        this.filterByDate();
    }

    filterByDate() {
        this.onFilterByDate.emit(this.dates);
    }
}