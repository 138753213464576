import { IMeterDTO, MeterDTO } from "../models/MeterDTO";

export enum VoltageOut {
    SHORT_START = 106,
    SHORT_END = 107,
    LONG_START = 108,
    LONG_END,
    Waison_less_than_07_for_3min = 221, // alarme de curta duração
    Waison_less_than_07_over_3min = 222, // alarme de longa duração
    Waison_less_than_limit_3min = 223, // alarme de curta duração
    Waison_less_than_limit_over_3Mmin = 224 // alarme de longa duração
}

export enum FilterType {
    Long = 0,
    Short = 1,
}

export interface IVoltageOutAlarm {
    meter: IMeterDTO;
    dateTime: Date;
    voltageOut: VoltageOut;
    createdAt: Date;
}

export interface VoltageOutVaration {
    meter: MeterDTO;
    voltageOutAlarmStart: VoltageOutVaration;
    voltageOutAlarmEnd: VoltageOutVaration;
    timeVaration: number;
}

export interface VoltageOutImport {
    id?: number;
    V?: number;
    Time: string;
    code?: number;
    "Event code": string;
}

export interface VoltageOutAlarmsImport {
    datetime: Date;
    voltageOut: VoltageOut;
}

export class VoltageOutDTO {

    static getVoltageOutDesc(voltageOut: VoltageOut) {
        switch (voltageOut) {
            case VoltageOut.SHORT_START:
                return 'Voltage Out Short Start';
            case VoltageOut.SHORT_END:
                return 'Voltage Out Short End';
            case VoltageOut.LONG_START:
                return 'Voltage Out Long Start';
            case VoltageOut.LONG_END:
                return 'Voltage Out Long End';
            case VoltageOut.Waison_less_than_07_for_3min:
                return 'Tensão inferior a 0,7Un para 3S-3min';
            case VoltageOut.Waison_less_than_07_over_3min:
                return 'Tensão inferior a 0.7Un por mais de 3min ';
            case VoltageOut.Waison_less_than_limit_3min:
                return 'Tensão inferior ao limite de funcionamento para 3S-3min ';
            case VoltageOut.Waison_less_than_limit_over_3Mmin:
                return 'Tensão inferior ao limite de funcionamento por mais de 3min';
        }
    }
}