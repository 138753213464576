export interface IInstanceMeasures {
  Serial: string;
  Datetime: Date;
  Group2VoltageVaH_V: number;
  Group2VoltageVbH_V: number;
  Group2VoltageVcH_V: number;
  Group3CurrentIaH_A: number;
  Group3CurrentIbH_A: number;
  Group3CurrentIcH_A: number;
}
export class InstanteMeasureDTO implements IInstanceMeasures {
  public Serial: string;
  public Datetime: Date;
  public Group2VoltageVaH_V: number;
  public Group2VoltageVbH_V: number;
  public Group2VoltageVcH_V: number;
  public Group3CurrentIaH_A: number;
  public Group3CurrentIbH_A: number;
  public Group3CurrentIcH_A: number;

  static hasPoperty(list: any[], property) {
    let hasPoperty = false;
    list.forEach((item) => {
      if (item[property] !== null) {
        hasPoperty = true;
        return hasPoperty;
      }
    });
    return hasPoperty;
  }
}
