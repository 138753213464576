import { Injectable } from '@angular/core';

import { IogHttpService } from 'src/app/security/iog-http.service';
import { environment } from 'src/environments/environment';

import { FirmwareDTO } from 'src/app/core/models/FirmwareDTO';

@Injectable({
  providedIn: 'root'
})
export class FirmwareService {

  public static url = environment.apiUrlBack + '/firmware';

  constructor(private http: IogHttpService) { }

  getFirmwares() {
    return this.http.get(FirmwareService.url).map(response => response.valueOf());
  }

  createFirmware(firmware: FirmwareDTO) {
    return this.http.post(FirmwareService.url, firmware).map(response => response.valueOf());
  }

  editFirmware(firmware: FirmwareDTO) {
    return this.http.patch(FirmwareService.url, firmware);
  }

  deleteFirmware(firmware: FirmwareDTO) {
    return this.http.delete(FirmwareService.url + `?crc=${firmware.crc}`);
  }
}
