import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExcerptPipe } from './excerpt.pipe';
import { GetValueByKeyPipe } from './get-value-by-key.pipe';
import { RelativeTimePipe } from './relative-time.pipe';
import { PrettyJsonPipe } from './json.pipe';

const pipes = [
    ExcerptPipe,
    GetValueByKeyPipe,
    RelativeTimePipe,
    PrettyJsonPipe
];

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: pipes,
    exports: pipes
})
export class SharedPipesModule {
}
