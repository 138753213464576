import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(
        private router: Router,
        private auth: AuthService
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (route['_routerState']['url'] === '/sessao/entrar') {
            if (this.auth.isInvalidAccessToken()) {
                return true;
            }
            this.router.navigate(['/dashboard/leituras']);
            return false;
        } else if (this.auth.isInvalidAccessToken()) {
            if (route["_routerState"]['url']) {
                localStorage.setItem(environment.returnUrl, route["_routerState"]['url'])
            }
            this.router.navigate(['/sessao/entrar']);
            return false;
        }
        return true;
    }
}
