import { Component, Input, Output, EventEmitter } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
// Models
import { GroupDTO } from "src/app/core/models/GroupDTO";
// Utils
import { Utils } from "src/app/shared/utils";
import { PaginationResponseDTO } from "src/app/core/models/PaginationResponseDTO";
import { FileService } from "../../services/file.service";
import { UserType } from "src/app/core/models/UserDTO";
import { AuthService } from "src/app/security/auth.service";

@Component({
  selector: "app-groups-table",
  templateUrl: "./groups-table.component.html",
  styleUrls: ["./groups-table.component.scss"],
})
export class GroupsTableComponent {
  @Input() isLoading = true;
  @Input() isReloading = false;
  @Input() isDeleting = false;
  @Input() groups: GroupDTO[] = [];
  @Input() page: PaginationResponseDTO = new PaginationResponseDTO();

  @Output() navigateToGroup = new EventEmitter();
  @Output() deleteGroup = new EventEmitter();
  @Output() filter = new EventEmitter();
  @Output() reload = new EventEmitter();

  public hasPermitionToDeleGroup = [UserType.ADMIN, UserType.OPERATOR];

  Utils = Utils;
  deletedGroup: GroupDTO = null;

  placeholder = "Procurar grupo pelo nome";
  seacrchString = "";
  sortOrder = "";
  startDate = "";
  endDate = "";
  pageSize: number = this.page.pageSize;
  currentPage = 0;

  public filters = {
    pageSize: this.pageSize,
    searchString: this.seacrchString,
    page: this.pageSize,
    startDate: this.startDate,
    endDate: this.endDate,
    sortOrder: this.sortOrder,
  };

  constructor(
    private modalService: NgbModal,
    private fileService: FileService,
    public authService: AuthService
  ) {}

  saveGroups() {
    let groups = [];
    this.groups.forEach((group) => {
      groups.push(...GroupDTO.buildGroupJson(group));
    });
    this.fileService.saveGroup(groups, "Grupos Cadastrados");
  }

  openModal(content, group?: GroupDTO) {
    if (group) {
      this.deletedGroup = group;
    }
    this.modalService.open(content, { centered: true });
  }

  sortByProp(prop) {
    const sort = prop.sorts[0];
    this.currentPage = 0;
    this.filters.page = this.currentPage;
    this.sortOrder = `${sort.prop}-${sort.dir}`;
    this.filters.sortOrder = this.sortOrder;
    this.filters.page = this.currentPage;
    this.emiteValue();
  }

  navigate(row: GroupDTO): void {
    this.navigateToGroup.emit(row.visibleId);
  }

  updateSearchString(text: string) {
    this.seacrchString = text;
    this.filters.searchString = this.seacrchString;
    this.emiteValue();
  }

  changePage(page) {
    this.currentPage = page.page - 1;
    this.filters.page = this.currentPage;
    this.emiteValue();
  }

  changeSizeValue(size) {
    this.pageSize = size;
    this.currentPage = 0;
    this.filters.pageSize = this.pageSize;
    this.filters.page = this.currentPage;
    this.emiteValue();
  }

  filterByDate() {
    this.currentPage = 0;
    this.filters.endDate = this.endDate;
    this.filters.startDate = this.startDate;
    this.filters.page = this.currentPage;
    this.emiteValue();
    this.closeModal();
  }

  removeFilters() {
    this.reseteValues();
    this.emiteValue();
  }

  finishOperation() {
    this.closeModal();
    this.reseteDates();
  }

  private closeModal() {
    this.modalService.dismissAll();
  }

  private reseteDates() {
    this.endDate = null;
    this.startDate = null;
  }

  private reseteValues() {
    this.pageSize = 15;
    this.currentPage = 0;
    this.endDate = "";
    this.startDate = "";
    this.seacrchString = "";

    this.filters.endDate = this.endDate;
    this.filters.startDate = this.startDate;
    this.filters.searchString = this.seacrchString;
    this.filters.pageSize = this.pageSize;
    this.filters.page = this.currentPage;
  }

  private emiteValue() {
    this.filter.emit(this.filters);
    this.closeModal();
  }

  delete(group: GroupDTO): void {
    this.deleteGroup.emit(group);
  }

  reloadGroups() {
    this.reload.emit();
  }
}
