import {
  Component,
  Input,
  OnInit,
  ViewChild,
  AfterViewInit,
} from "@angular/core";
import {
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  FormControl,
} from "@angular/forms";
import { MatChip, MatChipList } from "@angular/material/chips";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { map } from "rxjs/operators";

import { MeterDTO } from "src/app/core/models/MeterDTO";
import { TagsDTO } from "src/app/core/models/TagsTDO";
import { HandleErrorService } from "../../services/handle-error.service";
import { MessageService } from "../../services/message.service";
import { MetersService } from "../../services/meters.service";
import { TagsServices } from "../../services/tags.service";

interface IChipsValue extends TagsDTO {
  selected?: boolean;
}

@UntilDestroy()
@Component({
  selector: "app-chips-multi-select",
  templateUrl: "./chips-multi-select.component.html",
  styleUrls: ["./chips-multi-select.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ChipsMultiSelectComponent,
      multi: true,
    },
  ],
})
export class ChipsMultiSelectComponent
  implements OnInit, ControlValueAccessor, AfterViewInit {
  @ViewChild(MatChipList, { static: false }) chipList!: MatChipList;
  @Input() options: IChipsValue[] = [];
  @Input() meter: MeterDTO = null;

  allTags: IChipsValue[] = [];

  isLoading: boolean = false;

  value: IChipsValue[] = [];
  chipsToSelect: MatChip[] = [];
  optionsSelected: IChipsValue[] = [];

  onChange!: (value: TagsDTO[]) => void;
  onTouch: any;

  chipsControl = new FormControl([]);

  public filterQuery = "";

  constructor(
    private modalService: NgbModal,
    private meterService: MetersService,
    private messageService: MessageService,
    private handleService: HandleErrorService,
    private tagService: TagsServices
  ) { }

  writeValue(value: IChipsValue[]): void {
    if (this.chipList && value) {
      this.selectChips();
    } else if (value) {
      this.value = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  ngOnInit() {
    this.options.forEach((opt) => {
      Object.assign(opt, { selected: false });
    });
    this.allTags = this.options;
  }

  getTags() {
    this.tagService
      .getTags()
      .toPromise()
      .then((data: TagsDTO[]) => {
        this.allTags = data;
      });
  }

  ngAfterViewInit() {
    this.selectChips();

    this.chipsControl = new FormControl(
      this.chipsToSelect.map((chip) => chip.value)
    );

    this.chipList.chipSelectionChanges
      .pipe(
        untilDestroyed(this),
        map((event) => event.source)
      )
      .subscribe((chip) => {
        if (chip.selected) {
          this.value = [...this.value, chip.value];
        } else {
          this.value = this.value.filter((o) => o !== chip.value);
        }

        this.propagateChange(this.value);
      });
  }

  propagateChange(value: TagsDTO[]) {
    if (this.onChange) {
      this.onChange(value);
    }
  }

  closeModal() {
    this.chipsControl.reset();
    this.modalService.dismissAll();
  }

  selectChips() {
    this.chipList.chips.forEach((chip) => chip.deselect());

    this.chipsToSelect = this.meter.tags.map((tag) => {
      return this.chipList.chips.find(
        (chip: MatChip) => tag.visibleId === chip.value.visibleId
      );
    });

    this.chipsToSelect.forEach((chip) => {
      chip.value.selected = true;
      chip.select();
    });

    this.optionsSelected = this.chipsToSelect.map((chip) => {
      return this.options.find(
        (option) => chip.value.visibleId === option.visibleId && !!option
      );
    });
  }

  toggleSelection(chip: MatChip) {
    const res = chip.toggleSelected();
    chip.value.selected = !!res;
  }

  filterTagsByName() {
    this.options = this.allTags.filter((tag) => {
      return (
        tag.keyword.toLowerCase().indexOf(this.filterQuery.toLowerCase()) !== -1
      );
    });
  }

  submitTagChanges() {
    if (this.filterQuery !== "") {
      this.filterQuery = "";
      this.options = this.allTags;
      this.messageService.showWarning("Aviso!", "Nenhuma Tag selecionada");
    } else {
      this.value = this.options
        .map((tag) => {
          return tag.selected === true ? tag : null;
        })
        .filter((tag) => tag !== null);
      this.meter.tags = this.value;
      this.isLoading = true;
      this.meterService
        .updateMeter(this.meter)
        .toPromise()
        .then(() => {
          this.messageService.showSuccess(
            "Operação Concluída",
            `Tags do medidor de serial ${this.meter.serial} modificadas com sucesso!`
          );
          this.isLoading = false;
          this.closeModal();
        })
        .catch((error) => {
          this.isLoading = false;
          this.handleService.handle(error);
        });
    }
  }
}
