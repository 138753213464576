import { Component, Input, Output, EventEmitter } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";


import { MeterEventLogDTO } from "../../../core/models/MeterEventLogDTO";
import { Utils } from "src/app/shared/utils";
import { PaginationResponseDTO } from "src/app/core/models/PaginationResponseDTO";

@Component({
  selector: "app-table-event-log",
  templateUrl: "./table-event-log.component.html",
  styleUrls: ["./table-event-log.component.scss"],
})
export class TableEventLogComponent {
  @Input() eventsLogs: MeterEventLogDTO[];
  @Input() isLoading = true;
  @Input() page: PaginationResponseDTO;
  @Input() isReloading = false;
  @Input() serial: string = null;

  @Output() filterLogs = new EventEmitter();
  @Output() reload = new EventEmitter();

  connection: signalR.HubConnection;
  LogEventDTO = MeterEventLogDTO;
  Utils = Utils;



  public startDate = "";
  public endDate = "";
  public sortOrder = "updatedAt-desc";
  public searchString = "";
  public currentPage = 0;
  public pageSize = 15;
  public filter = {
    pageSize: this.pageSize,
    page: this.currentPage,
    begin: this.startDate,
    end: this.endDate,
    sortOrder: this.sortOrder,
    searchString: this.searchString,
  };

  constructor(private modalService: NgbModal) { }

  openModal(content) {
    this.modalService.open(content, { centered: true });
  }

  onSort(value) {
    const { sorts } = value;
    this.sortOrder = `${sorts[0].prop}-${sorts[0].dir}`;
    this.filter["sortOrder"] = this.sortOrder;
    this.currentPage = 0;
    this.filter["page"] = this.currentPage;
    this.emiteValues();
  }

  updateSearchString(value: string): void {
    this.searchString = value;
    this.filter.searchString = this.searchString;
    this.emiteValues();
  }

  changeSizeValue(value) {
    this.currentPage = 0;
    this.pageSize = value;
    this.filter["page"] = this.currentPage;
    this.filter["pageSize"] = this.pageSize;
    this.emiteValues();
  }

  filterByPage(page) {
    this.currentPage = page.page - 1;
    this.filter["page"] = this.currentPage;
    this.emiteValues();
  }

  filterByDate() {
    this.filter["page"] = 0;
    this.filter["sortOrder"] = "updatedAt-desc";
    this.filter["begin"] = this.startDate;
    this.filter["end"] = this.endDate;
    this.emiteValues();
    this.finishOperation();
  }

  finishOperation() {
    this.modalService.dismissAll();
  }

  removeFilter() {
    this.reseteValues();
    this.emiteValues();
  }

  private reseteValues() {
    this.sortOrder = "updatedAt-desc";
    this.startDate = "";
    this.endDate = "";
    this.searchString = "";
    this.currentPage = 0;
    this.filter.searchString = this.searchString;
    this.filter["page"] = this.currentPage;
    this.filter["end"] = this.endDate;
    this.filter["begin"] = this.startDate;
    this.filter["sortOrd"] = this.sortOrder;
    this.finishOperation();
  }

  private emiteValues() {
    this.filterLogs.emit(this.filter);
    document.getElementById("top").scrollIntoView(true);
  }

  reloadLogs() {
    this.reload.emit();
  }
}
