import { HandleErrorService } from 'src/app/shared/services/handle-error.service';
import {
  Component,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from "@angular/core";
import { DatatableComponent } from "@swimlane/ngx-datatable";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

// Models
import { MeterEnergyDTO } from "src/app/core/models/MeterEnergyDTO";
import { MeterDTO } from "src/app/core/models/MeterDTO";
import { SmcDTO } from "src/app/core/models/SmcDTO";
import { PaginationResponseDTO } from "src/app/core/models/PaginationResponseDTO";
// Services
import { Utils } from "src/app/shared/utils";
import { MeterModelDTO } from "src/app/core/models/MeterModelDTO";
import { ExportsDataServices } from "../../services/export-data.service";

@Component({
  selector: "app-energy-table",
  templateUrl: "./energy-table.component.html",
  styleUrls: ["./energy-table.component.scss"],
})
export class EnergyTableComponent {
  @ViewChild("dataTable", { static: false }) energyTable: DatatableComponent;

  @Input() energys: MeterEnergyDTO[] = [];
  @Input() serial: string;
  @Input() isLoading = true;
  @Input() isReloading = false;
  @Input() smc: SmcDTO;
  @Input() canRecovery: boolean = null;
  @Input() page: PaginationResponseDTO;
  @Input() model: MeterModelDTO;

  @Output() findEnergy = new EventEmitter();
  @Output() recovery = new EventEmitter();
  @Output() filterEnergy = new EventEmitter();

  EnergyDTO = MeterEnergyDTO;
  Utils = Utils;
  MeterDTO = MeterDTO;

  public isDownloadingEnergys = false;

  public currentPage = 0;
  public pageSize = 15;
  public startDateTime = "";
  public endDateTime = "";
  public sortOrder = "dateTime-desc";

  public filters = {
    page: this.currentPage,
    pageSize: this.pageSize,
    startDate: this.startDateTime,
    endDateTime: this.endDateTime,
    sortOrder: this.sortOrder,
  };

  constructor(
    private _modalService: NgbModal,
    private handleErrorService: HandleErrorService,
    private _exportDataServices: ExportsDataServices,
  ) { }

  reloadEnergy() {
    this.findEnergy.emit();
  }

  onSort(value) {
    const { sorts } = value;
    this.sortOrder = `${sorts[0].prop}-${sorts[0].dir}`;
    this.filters.sortOrder = this.sortOrder;
    this.filter();
  }

  recoveryEnergy(value) {
    this.recovery.emit(value);
  }

  openModal(modalContent: any): void {
    this._modalService.open(modalContent, {
      centered: true,
      backdrop: "static",
      keyboard: false,
    });
  }

  saveEnergy() {
    this.isDownloadingEnergys = true;
    this._exportDataServices.exportMeterMassMemory(this.serial).toPromise()
      .then(response => {
        const dataType = response.type;
        let binaryData = [];
        binaryData.push(response);
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(
          new Blob(binaryData, { type: dataType })
        );
        downloadLink.setAttribute('download', `Memória de massa do medidor ${this.serial}.csv`);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        this.isDownloadingEnergys = false;
        this.close();
      })
      .catch(error => {
        this.isDownloadingEnergys = false;
        this.handleErrorService.handle(error);
      });
  }

  close() {
    this._modalService.dismissAll();
  }

  filterByDate() {
    this.currentPage = 0;
    this.filters.endDateTime = this.endDateTime;
    this.filters.startDate = this.startDateTime;
    this.filters.page = this.currentPage;
    this.filter();
    this.close();
  }

  filterByPage(page) {
    this.currentPage = page.page - 1;
    this.filters.page = this.currentPage;
    this.filter();
  }

  resetValues() {
    this.endDateTime = "";
    this.startDateTime = "";
  }

  filter() {
    this.filterEnergy.emit(this.filters);
  }

  changeSizeValue(value) {
    this.pageSize = value;
    this.currentPage = 0;
    this.page.pageSize = this.pageSize;
    this.filters.page = this.currentPage;
    this.filters.pageSize = this.pageSize;
    this.filter();
  }

  removeFilter() {
    this.endDateTime = "";
    this.currentPage = 0;
    this.sortOrder = "dateTime-desc";
    this.startDateTime = "";
    this.pageSize = 15;
    this.page.pageSize = this.pageSize;

    this.filters.pageSize = this.pageSize;
    this.filters.endDateTime = this.endDateTime;
    this.filters.startDate = this.startDateTime;
    this.filters.page = this.currentPage;
    this.filters.sortOrder = this.sortOrder;
    this.filter();
    this.close();
  }
}
