export interface IManufacturerDTO {
    name: string;
    updatedAt: string;
}

export class ManufacturerDTO implements IManufacturerDTO {
    public name: string;
    public updatedAt: string;

    constructor() {
        this.name = '';
    }

    static getManufacurerByName(manufacteres: ManufacturerDTO[], name: string): ManufacturerDTO {
        return manufacteres.find(manufacter => manufacter.name === name);
    }

}
