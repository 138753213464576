import { environment } from "src/environments/environment";
import { ManufacturerDTO } from "./ManufacturerDTO";

export interface IMeterModelDTO {
  name: string;
  manufacturer: ManufacturerDTO;
  updatedAt: string;
}

export class MeterModelDTO implements IMeterModelDTO {
  public name: string;
  public manufacturer: ManufacturerDTO;
  public updatedAt: string;

  static getModelsByName(
    name: string,
    modelsList: MeterModelDTO[],
  ): MeterModelDTO {
    return modelsList.find((moden) => moden.name === name);
  }

  static getModelsByNicTransparent(modelsList: MeterModelDTO[]): MeterModelDTO {
    return modelsList.find(
      (moden) =>
        moden.manufacturer.name.toLowerCase() ===
        environment.nansenTransparent.toLowerCase(),
    );
  }
}
