import { Component, Input } from "@angular/core";
import { IMetersComissionedStatisticDTO, IOnlineEstatisticDTO } from "src/app/core/models/OnlineStatisticDTO";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { Utils } from 'src/app/shared/utils';
import { FileService } from "../../services/file.service";
import * as ExcelJS from 'exceljs/dist/exceljs.bare.js';
import { Workbook, Worksheet } from 'exceljs';
import { CELLTABLECOLORS, INumberOfReadings } from "src/app/core/models/HistoricReadingQuantityDTO";
import { getSupportedInputTypes, Platform, supportsPassiveEventListeners, supportsScrollBehavior } from "@angular/cdk/platform";

(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

type ITableData = {
  dates: Date[],
  datas: INumberOfReadings[]
}
@Component({
  selector: 'app-download-pdf',
  templateUrl: './download-pdf.component.html',
  styleUrls: ['./download-pdf.component.scss']
})
export class DownloadPDFComponent {
  @Input() metersComissionedStatistics: IMetersComissionedStatisticDTO;
  @Input() metersStatistics: IOnlineEstatisticDTO;
  @Input() filtersTable: any;
  @Input() table: ITableData;

  Utils = Utils;

  private ws: Worksheet;
  static filename = 'Estatistica';

  supportedInputTypes = Array.from(getSupportedInputTypes()).join(', ');
  supportsPassiveEventListeners = supportsPassiveEventListeners();
  supportsScrollBehavior = supportsScrollBehavior();

  @Input() isLoading = false;
  constructor(private fileService: FileService, public platform: Platform) { }

  private setTitle(value, column) {
    const title = this.ws.getCell(column);
    Object.assign(title, { value, font: { size: 15, bold: true } });
  }

  private setSubTitle(value, column) {
    const subTitle = this.ws.getCell(column);
    Object.assign(subTitle, { value, font: { size: 10 } });
  }

  private createExcelTableComissioned() {
    this.setTitle('Comissionamento', 'A9');
    this.ws.addTable({
      name: 'Filters',
      ref: 'A10',
      headerRow: true,
      columns: [{ name: 'Comissionados', filterButton: true }, { name: 'Não Comissionados', filterButton: true }],
      rows: [[
        `${this.metersComissionedStatistics.comissionedPercentage} / ${this.metersComissionedStatistics.totalComissionedMeters}`,
        `${this.metersComissionedStatistics.notComissionedPercentage} / ${this.metersComissionedStatistics.totalNotComissionedMeters}`
      ]
      ]
    });
  }

  private createExcelTableComunication() {
    this.setTitle(`Comunicação`, 'A13');
    this.setSubTitle(this.subTitle(), 'A14')
    this.ws.addTable({
      name: 'Filters',
      ref: 'A15',
      headerRow: true,
      columns: [{ name: 'Online' }, { name: 'Offline' }],
      rows: [[
        `${this.metersStatistics.onlinePercentage} / ${this.metersStatistics.totalOnlineMeters}`,
        `${this.metersStatistics.offlinePercentage} / ${this.metersStatistics.totalOfflineMeters}`
      ]]
    });
  }

  private createExcelTableFilters() {
    this.setTitle('Filtros Aplicados', 'A5');
    this.ws.addTable({
      name: 'Filters',
      ref: 'A6',
      headerRow: true,
      columns: [{ name: 'Tags' }, { name: 'Comunicação' }, { name: 'Qntd. Energia' }, { name: 'Tipo' }],
      rows: [this.hasFilter()]
    })
  }

  private createExcelTableReading() {
    this.setTitle('Tabela de Leituras', 'A18');
    this.ws.addTable({
      name: 'Tabela',
      ref: 'A19',
      headerRow: true,
      totalsRow: false,
      displayName: 'DSADSA',
      columns: [
        { name: 'Serial' },
        { name: 'SMC' },
        { name: 'Última Leitura' },
        ...this.table.dates.map(date => ({ name: this.Utils.getDate(date), filterButton: false }))],
      rows: [...this.getRowTable()]
    });
    this.setTableColorExcel();
  }

  private setTableColorExcel() {
    this.ws.eachRow((row, rownumber) => {
      row.eachCell({ includeEmpty: true }, (row, rownumber) => {
        if (row.value && typeof (row.value) === 'number' || row.value == '0') {
          row.style.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: this.getColor(row.value, true) }
          }
        }
      })
    });
  }

  private setDocumentTitle() {
    const documentTitle = this.ws.getCell('B2');
    Object.assign(documentTitle, {
      value: 'Estatística',
      font: { size: 18, bold: true }
    });
  }

  private changeColor(cell, color: string) {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: color }
    }
  }

  private addColorHeader(cell) {
    if (cell.value == 'Online' || cell.value == 'Comissionados') {
      this.changeColor(cell, '4caf50');
    } else if (cell.value == 'Offline' || cell.value == 'Não Comissionados') {
      this.changeColor(cell, 'd22346');
    }
  }

  private addColorToHeaderTableExcel() {
    this.ws.eachRow((row, rowNumber) => {
      row.eachCell((cell, colNumber) => {
        this.addColorHeader(cell)
        if (rowNumber == 6 || rowNumber == 19) {
          this.changeColor(cell, 'f5b914');
        }
      })
      row.commit();
    });
  }

  generateExcel() {
    const wb: Workbook = new ExcelJS.Workbook();
    this.ws = wb.addWorksheet('Main Sheet');
    this.setDocumentTitle();

    this.createExcelTableFilters();
    this.createExcelTableReading();
    this.createExcelTableComissioned();
    this.createExcelTableComunication();
    this.addColorToHeaderTableExcel();
    this.ws.columns.forEach(column => {
      column.width = 18;
    });

    wb
      .xlsx
      .writeBuffer({ useStyles: true })
      .then(buffer => {
        this.fileService.saveFileAsExcel(buffer, DownloadPDFComponent.filename + new Date().getTime());
      }).catch();
  }

  generatePdf() {
    pdfMake.createPdf({
      content: [{
        text: 'Estatísticas',
        bold: true,
        fontSize: 20,
        alignment: 'center',
        margin: [0, 0, 0, 20]
      }, {
        text: 'Filtros Aplicados',
        style: 'header'
      },
      this.createTagsTable(), {
        text: 'Comissionamento',
        style: 'header',
      }, {
        columns: [
          this.createComissionedTable()
        ]
      }, {
        columns: [{
          text: 'Comunicação',
          style: 'header',
          width: 'auto'
        }, {
          text: this.subTitle(),
          style: 'subTitle'
        }
        ]
      }, {
        columns: [
          this.createComunicationTable()
        ]
      }, {
        text: 'Quantidade de Leituras',
        style: 'header',
      }, {
        columns: [
          this.createReadingTable()
        ]
      }],
      pageOrientation: 'landscape',
      pageSize: 'A2',
      info: {
        title: 'Estatistica',
      },
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 20, 0, 10],
        },
        tableHeader: {
          bold: true,
          fillColor: '#f5b914'
        },
        positive: {
          bold: true,
          fillColor: '#4caf50'
        },
        negative: {
          bold: true,
          fillColor: '#d22346'
        },
        subTitle: {
          fontSize: 10,
          alignment: 'left',
          margin: [0, 25, 0, 10]
        }
      }
    }).download(`${DownloadPDFComponent.filename}-${new Date().getTime()}.pdf`);
  }

  subTitle() {
    const comissioned = '(*Estatística baseada nos medidores COMISSIONADOS)';
    const notComissioned = '(*Estatística baseada nos medidores NÃO COMISSIONADOS)';
    const all = '(*Estatística baseada em TODOS os medidores)';
    if (!this.filtersTable) {
      return comissioned;
    }

    if (this.filtersTable.comissioned) return comissioned;
    else if (this.filtersTable.comissioned == false) return notComissioned;
    else return all;
  }

  hasFilter() {
    return this.filtersTable
      ? Object.values(this.filtersTable).filter(text => typeof (text) === 'string')
      : 'Filtro não aplicado!.'.repeat(4).split('.').filter(text => text);
  }

  createTagsTable() {
    return {
      table: {
        body: [
          [
            { text: 'Tags', style: 'tableHeader' },
            { text: 'Comunicação', style: 'tableHeader' },
            { text: 'Qntd. Energia', style: 'tableHeader' },
            { text: 'Tipo', style: 'tableHeader' }
          ],
          this.hasFilter()
        ],
      },
    }
  }

  createComissionedTable() {
    return {
      table: {
        widths: ['20%', '20%'],
        body: [[
          { text: 'Comissionados', style: 'positive' },
          { text: 'Não Comissionados', style: 'negative' }],
        [
          `${this.metersComissionedStatistics.comissionedPercentage} / ${this.metersComissionedStatistics.totalComissionedMeters}`,
          `${this.metersComissionedStatistics.notComissionedPercentage} / ${this.metersComissionedStatistics.totalNotComissionedMeters}`,
        ],
        ]
      }
    }
  }

  createComunicationTable() {
    return {
      table: {
        widths: ['20%', '20%'],
        body: [[
          { text: 'Online', style: 'positive' },
          { text: 'Offline', style: 'negative' }
        ], [
          `${this.metersStatistics.onlinePercentage} / ${this.metersStatistics.totalOnlineMeters}`,
          `${this.metersStatistics.offlinePercentage} / ${this.metersStatistics.totalOfflineMeters}`
        ]]
      }
    }
  }

  createReadingTable() {
    return {
      table: {
        body: [
          [
            { text: 'Serial', style: 'tableHeader' },
            { text: 'SMC', style: 'tableHeader' },
            { text: 'Última Leitura', style: 'tableHeader' },
            ...this.getHeaderTable()
          ],
          ...this.getRowTable()
        ],
      },
      layout: {
        fillColor: (rowIndex, node, columnIndex) => {
          this.searchTableCell(rowIndex, node, columnIndex);
          return '';
        }
      }
    }
  }

  searchTableCell(rowIndex, node, columnIndex) {
    for (let i = 1; i < node.table.body.length; i++) {
      for (let idx = 2; idx < node.table.body[i].length; idx++) {
        const tableRow = node.table.body[i][idx] as any & { fillColor: string; text: string; };
        tableRow.fillColor = this.getColor(tableRow.text);
        Object.assign(node.table.body[i][idx], tableRow);
      }
    }
  }

  getColor(value, argb = false) {
    if (value === "0" || value === 0) return argb ? '00cc1f36' : CELLTABLECOLORS.ZERO;
    if (value > 0 && value < 4) return argb ? '005edb43' : CELLTABLECOLORS.OneThree;
    if (value > 3) return argb ? '0047A92E' : CELLTABLECOLORS.GreaterThanThree;
  }

  getRowTable() {

    return this
      .table
      .datas
      .map(data => [
        data.meter.serial, data.meter.smcSerial || 'Sem SMC',
        data.meter.lastAttMeasure || 'Sem Leitura',
        ...data.readingStatisticDtos.map(reading => reading ? reading.quantityOfMeasures : null)
      ]);
  }

  getHeaderTable() {
    return this.table.dates.map(date => ({
      text: this.Utils.getDate(date),
      style: 'tableHeader'
    }));
  }

}