import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IDropdownSettings } from 'ng-multiselect-dropdown/multiselect.model';
import { IAllAlerts } from 'src/app/core/models/AlertDTO';
import { IPaginationResponseDTO, PaginationResponseDTO } from 'src/app/core/models/PaginationResponseDTO';
import { AlertsService } from '../../services/alerts.service';
import { HandleErrorService } from '../../services/handle-error.service';
import { MessageService } from '../../services/message.service';
import { debounce } from "lodash";

@Component({
  selector: 'app-all-alerts-table',
  templateUrl: './all-alerts-table.component.html',
  styleUrls: ['./all-alerts-table.component.scss']
})
export class AllAlertsTableComponent implements OnInit {
  @Input() isLoadingSMiAlerts: boolean = true;
  @Input() isReloadingSMI: boolean = false;
  @Input() smiAlerts: IAllAlerts[] = [];

  @Input() smiPage: IPaginationResponseDTO = new PaginationResponseDTO();
  @Input() isLoadingSMCAlerts: boolean = true;
  @Input() isReloadingSMC: boolean = false;
  @Input() smcAlerts: IAllAlerts[] = [];
  @Input() smcPage: IPaginationResponseDTO = new PaginationResponseDTO();
  @Input() tagFilterHasBeenApplied: boolean = false;

  @Output() filterSmc: EventEmitter<any> = new EventEmitter();
  @Output() filterSmi: EventEmitter<any> = new EventEmitter();
  @Output() reloadAlarms: EventEmitter<void> = new EventEmitter<void>();
  @Output() navigate: EventEmitter<IAllAlerts> = new EventEmitter<IAllAlerts>();

  @Output() reload = new EventEmitter();

  smcPageSize: number = 15;
  smcCurrentPage: number = 1;

  smiPageSize: number = 15;
  smiCurrentPage: number = 1;

  public isSMIAlerts = true;
  public allSMIAlerts: IAllAlerts[] = [];
  public allSMCAlerts: IAllAlerts[] = [];
  public smiCritisAlerts: IAllAlerts[] = [];
  public smcCriticsAlerts: IAllAlerts[] = [];

  public isSavingChanges: boolean = false;

  private smiFilter = {
    pageSize: this.smiPageSize,
    currentPage: this.smiCurrentPage,
  };
  private smcFilter = {
    pageSize: this.smcPageSize,
    currentPage: this.smcCurrentPage,
  };

  private alarmsToRemove: Set<IAllAlerts> = new Set<IAllAlerts>();
  private alarmsToAdd: Set<IAllAlerts> = new Set<IAllAlerts>();

  public dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    enableCheckAll: true,
    idField: "translate",
    textField: "translate",
    allowSearchFilter: true,
    noDataAvailablePlaceholderText: "Nenhum Alarme encontrado",
    itemsShowLimit: 5,
    searchPlaceholderText: 'Procurar alarme pela descrição',
    clearSearchFilter: true,
    showSelectedItemsAtTop: true,
    unSelectAllText: 'Desselecionar todos',
    selectAllText: 'Selecionar todos'
  };


  @Output() smiSearchString = new EventEmitter();
  @Output() smcSearchString = new EventEmitter();

  smiSearch = '';
  smcSearch = '';
  constructor(
    private _modalService: NgbModal,
    private _alertServices: AlertsService,
    private _handleErrorServices: HandleErrorService,
    private _messageServices: MessageService,
  ) {
    this.searchSmiType = debounce(this.searchSmiType, 1000);
    this.searchSmcType = debounce(this.searchSmcType, 1000);
  }

  async ngOnInit() {
    this.getAllSmiAlerts();
    this._getAllSmcAlerts();
  }

  searchSmcType(event) {
    this.smcSearchString.emit(this.smcSearch);
  }

  searchSmiType(event) {
    this.smiSearchString.emit(this.smiSearch);
  }

  sendReload() {
    this.reload.emit();
  }

  private getAllSmiAlerts() {
    this.smiCritisAlerts = [];
    this._alertServices.getAllSMIAlarms().toPromise()
      .then(response => {
        this.allSMIAlerts = <IAllAlerts[]>response;
        this.allSMIAlerts.forEach((_alert) => {
          if (_alert.isCritical)
            this.smiCritisAlerts.push(_alert);
        });
      })
      .catch(error => {
        this._handleErrorServices.handle(error);
      })
  }

  navigateToAlarm(alarm: IAllAlerts) {
    this.navigate.emit(alarm);
  }

  private _getAllSmcAlerts() {
    this.smcCriticsAlerts = [];
    this._alertServices.getAllSMCAlarms().toPromise()
      .then(response => {
        this.allSMCAlerts = <IAllAlerts[]>response;
        this.allSMCAlerts.forEach(_alert => {
          if (_alert.isCritical)
            this.smcCriticsAlerts.push(_alert);
        })
      })
      .catch(error => {
        this._handleErrorServices.handle(error);
      })
  }



  changeSMCPage(value) {
    const { page } = value;
    this.smcCurrentPage = page ? page - 1 : 0;
    this.smcFilter["currentPage"] = this.smcCurrentPage;
    this.filterSmc.emit(this.smcFilter)
  }

  changeSMIPage(value) {
    const { page } = value;
    this.smiCurrentPage = page ? page - 1 : 0;
    this.smiFilter["currentPage"] = this.smiCurrentPage;
    this.filterSmi.emit(this.smiFilter);
  }


  changeSmiPageSize(value) {
    this.smiPageSize = value;
    this.smiCurrentPage = 0;
    this.smiFilter["pageSize"] = this.smiPageSize;
    this.smiFilter["currentPage"] = this.smiCurrentPage;
    this.filterSmi.emit(this.smiFilter);
  }


  changeSmcPageSize(value) {
    this.smcPageSize = value;
    this.smcCurrentPage = 0;
    this.smcFilter["pageSize"] = this.smcPageSize;
    this.smcFilter["currentPage"] = this.smcCurrentPage;
    this.filterSmc.emit(this.smcFilter);
  }


  saveAlarms() {
    this.isSavingChanges = true;
    return Promise.all([this.clearCriticalAlarm(), this.addCriticalAlarm()]).then(response => {
      this.isSavingChanges = false;
      this.closeModal()
      this.alarmsToRemove.clear();
      this.alarmsToAdd.clear();
      this.reloadAllAlarms();
      this.isSMIAlerts ? this.getAllSmiAlerts() : this._getAllSmcAlerts();
      this.reloadAlarms.emit();
      this._messageServices.showSuccess('Alarmes Alterado', 'Os Alarmes foram salvos e entraram nas estatisticas');
    }).catch(error => {
      this._handleErrorServices.handle(error);
      this.isSavingChanges = false;
    })

  }

  onSelectAll(descriptions: string[]) {
    this.alarmsToRemove.clear();
    this.alarmsToAdd.clear();
    const alerts = this.isSMIAlerts
      ? this.allSMIAlerts.filter(_alert => descriptions.includes(_alert.translate) && !_alert.isCritical)
      : this.allSMCAlerts.filter(_alert => descriptions.includes(_alert.translate) && !_alert.isCritical);
    alerts.forEach(_alert => this.alarmsToAdd.add(_alert));
  }

  unSelectAll(alarm) {
    this.alarmsToAdd.clear();
    const alerts = this.isSMIAlerts
      ? this.allSMIAlerts.filter(_alert => _alert.isCritical)
      : this.allSMCAlerts.filter(_alert => _alert.isCritical);

    alerts.forEach(_alert => this.alarmsToRemove.add(_alert));
  }

  setCriticalAlarms(alarmDescription: string) {
    if (this.isSMIAlerts) {
      this.alarmsToAdd.add(this.allSMIAlerts.find(_alert => _alert.translate.trim().toLowerCase() === alarmDescription.trim().toLowerCase() && !_alert.isCritical));
    } else {
      this.alarmsToAdd.add(this.allSMCAlerts.find(_alert => _alert.translate.trim().toLowerCase() === alarmDescription.trim().toLowerCase() && !_alert.isCritical));
    }
    this.alarmsToRemove.forEach(_alarm => {
      if (_alarm.translate.trim().toLowerCase() === alarmDescription.trim().toLowerCase())
        this.alarmsToRemove.delete(_alarm);
    })
  }

  removeCriticalAlarms(alarmDescription: string) {
    if (this.isSMIAlerts) {
      this.alarmsToRemove.add(
        (this.allSMIAlerts.find(_alert => _alert.translate.trim().toLowerCase() === alarmDescription.trim().toLowerCase() && _alert.isCritical)));
    } else {
      this.alarmsToRemove.add(this.allSMCAlerts.find(_alert => _alert.translate.trim().toLowerCase() === alarmDescription.trim().toLowerCase() && _alert.isCritical));
    }
  }

  private clearCriticalAlarm(): Promise<any> {
    let criticalAlarmsId: Array<string> = new Array<string>();
    if (this.alarmsToRemove && this.alarmsToRemove.size > 0) {
      this.alarmsToRemove.forEach(_alarm => {
        if (_alarm && _alarm.isCritical)
          criticalAlarmsId.push(_alarm.criticalAlarmId);
      })
      return new Promise((resolve, reject) => {
        return this._alertServices.removeCriticalAlarms(criticalAlarmsId).toPromise()
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        resolve('');
      })
    }
  }


  private addCriticalAlarm(): Promise<any> {
    let criticalAlerts: Array<IAllAlerts> = Array.from<IAllAlerts>(this.alarmsToAdd.values());
    criticalAlerts = criticalAlerts.filter(_alert => _alert);
    if (this.alarmsToAdd && this.alarmsToAdd.size > 0) {
      return new Promise((resolve, reject) => {
        return this._alertServices.setCriticalAlarms(criticalAlerts).toPromise()
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          })
      })
    }
    else {
      return new Promise((resolve, reject) => resolve(''));
    }
  }


  openModal(content) {
    this._modalService.open(content, { centered: true, keyboard: false, backdrop: 'static' });
  }

  closeModal() {
    this._modalService.dismissAll();
  }


  reloadAllAlarms() {
    this.getAllSmiAlerts();
    this._getAllSmcAlerts();
  }

}
