import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TagsDTO } from 'src/app/core/models/TagsTDO';
import { AlarmDashboardFilterService } from '../../services/alarm-dashboard-filter.service';
import { HandleErrorService } from '../../services/handle-error.service';
import { TagsServices } from '../../services/tags.service';

@Component({
  selector: 'app-alerts-dashboard-filters',
  templateUrl: './alerts-dashboard-filters.component.html',
  styleUrls: ['./alerts-dashboard-filters.component.scss']
})
export class AlertsDashboardFiltersComponent implements OnInit {

  public tags: TagsDTO[];
  public filteredTags: TagsDTO[] = [];
  public filteredTagsSelect: TagsDTO[] = [];

  public criticalFilters = {
    isCritical: false,
    isSelected: false,
  }

  public readFilter = {
    read: false,
    isSelected: true
  }

  public dateFilter = {
    startDate: null,
    endDate: null,
    isSelected: false
  }

  public isLoadingTags: boolean = true;
  public filterHasBeenApplied: boolean = false;

  public today = new Date().toISOString().substring(0, 10);
  constructor(
    private tagService: TagsServices, 
    private errorHandle: HandleErrorService,
    private modal: NgbModal,
    private dashboardFilter: AlarmDashboardFilterService
  ) {}

  ngOnInit() {
    this.getTag();
  }

  addReadFilter(read: boolean) {
    this.readFilter.isSelected = this.readFilter.isSelected && this.readFilter.read == read ? false : true;
    this.readFilter.read = read;
  }

  addDateFilter() {
    this.dateFilter.isSelected = true;
    this.closeModal();
  }

  getTag() {
    this.isLoadingTags = false;
    this.tagService
      .getTags()
      .toPromise()
      .then(res => {
        this.tags = res as TagsDTO[];
        this.filteredTagsSelect = this.tags;
        this.isLoadingTags = false;
      }).catch(error => {
        this.errorHandle.handle(error);
        this.isLoadingTags = false;
      });
  }


  changeTag(event) {
    const tag = this.tags.filter(tag => tag.visibleId === event.id);
    event.checked
      ? this.filteredTags.push(...tag)
      : this.filteredTags = this.filteredTags.filter(tag => tag.visibleId !== event.id);
  }

  searchTag(event) {
    if (event.value) {
      this.filteredTagsSelect = []
      this.tags.forEach(tag => {
        if (tag.keyword.toLowerCase().match(event.value)) {
          this.filteredTagsSelect.push(tag);
        }
      });
    } else {
      this.filteredTagsSelect = this.tags;
    }
  }


  closeModal(){
    this.modal.dismissAll();
  }

  openModal(ctt) {
    this.modal.open(ctt, { centered: true });
  }

  cleanFilter(){
    this.dateFilter.isSelected = false;
    this.dateFilter.endDate = null;
    this.dateFilter.startDate = null;
    this.readFilter.isSelected = true;
    this.readFilter.read = false;
    this.filteredTags = [];
    this.closeModal();
    this.addFilter(true);
  }

  addFilter(reset = false) {
    this.dashboardFilter.next({
      tag: this.filteredTags,
      dates: { endDate: this.dateFilter.isSelected ? this.dateFilter.endDate : null, startDate: this.dateFilter.isSelected ? this.dateFilter.startDate : null},
      read: this.readFilter.isSelected ? this.readFilter.read : null
    });
    this.filterHasBeenApplied = reset ? false : true;
  }
}
