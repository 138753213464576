export enum ScheduleCommandType {
    RelayOn = 'RelayOn',
    RelayStatus = 'RelayStatus',
    UpdateDatetimeSmc = 'UpdateDatetimeSmc',
    UpdateDatetimeSmi = 'UpdateDatetimeSmi',
    InstantMeasureSmc = 'InstantMeasureSmc',
    InstantMeasureSmi = 'InstantMeasureSmi',
    NicResetSmi = 'NicResetSmi',
    NicResetSmc = 'NicResetSmc',
    RecoverMassMemorySmc = 'RecoverMassMemorySmc',
    RecoverMassMemorySmi = 'RecoverMassMemorySmi',
    RecoverEnergySmc = 'RecoverEnergySmc',
    RecoverEnergySmi = 'RecoverEnergySmi',
    RecoverQeeIndicator = 'RecoverQeeIndicator',
    RequestRelayStatusAllSmc = 'RequestRelayStatusAllSmc',
    RequestDoorStatus = 'RequestDoorStatus',
    GetVoltageOutShort = 'GetVoltageOutShort',
    GetVoltageOutLong = 'GetVoltageOutLong',
    SetStandardFrozenData = 'SetStandardFrozenData',
    GetQeeTransparent = 'GetQeeTransparent'
}

export type ScheduleCommand = {
    id: string;
    nextExecution: Date;
    lastExecution: Date;
    cron: string;
    groupVisibleId: string;
    groupName: string;
    description: string;
    duration: number;
    scheduleCommandType: ScheduleCommandType;
    input: string;
    status: boolean;
}

export type RecoverQeeIndicatorInput = {
    QuantityOfLastMeasures: number;
}

export type RecoverEnergySmcInput = {
    QuantityOfLastMeasures: number;
}

export type RecoverEnergySmiInput = {
    InitialDatetime: Date;
    FinishDatetime: Date;
}

export type RecoverMassMemorySmcInput = {
    QuantityOfLastMeasures: number
}

export type RecoverMassMemorySmiInput = {
    InitialDatetime: Date,
    FinishDatetime: Date,
    IntervalInMinutes: number;
}

export type NicResetInput = {
    Seconds: 0
}

export type ScheduleCommandPost = {
    GroupVisibleId: string;
    Description: string;
    ScheduleCommandType: number;
    Cron: string;
    RecoverQeeIndicatorInput?: RecoverQeeIndicatorInput;
    RecoverEnerySmcInput?: RecoverEnergySmcInput;
    RecoverEnergySmiInput?: RecoverEnergySmiInput;
    RecoverMassMemorySmcInput?: RecoverMassMemorySmcInput;
    RecoverMassMemorySmiInput?: RecoverMassMemorySmiInput;
    NicResetInput?: NicResetInput
}

export class ScheduleCommandDTO {
    public static getScheduleCommand(scheduleCommand: ScheduleCommandType) {
        switch (scheduleCommand) {
            case ScheduleCommandType.RelayOn:
                return 'Ligar cliente';
            case ScheduleCommandType.InstantMeasureSmc:
                return 'Solicitar medida instantânea SMC';
            case ScheduleCommandType.InstantMeasureSmi:
                return 'Solicitar medida instantânea SMI';
            case ScheduleCommandType.NicResetSmc:
                return 'Resetar NIC SMC';
            case ScheduleCommandType.NicResetSmi:
                return 'Resetar NIC SMI';
            case ScheduleCommandType.RecoverEnergySmc:
                return 'Recuperar energia do SMC';
            case ScheduleCommandType.RecoverEnergySmi:
                return 'Recuperar energia do SMI';
            case ScheduleCommandType.RecoverMassMemorySmc:
                return 'Recuperar memória de massa SMC';
            case ScheduleCommandType.RecoverMassMemorySmi:
                return 'Recuperar memória de massa SMI';
            case ScheduleCommandType.RelayStatus:
                return 'Obter Estado do Relé';
            case ScheduleCommandType.RequestDoorStatus:
                return 'Obter estado da porta';
            case ScheduleCommandType.RequestRelayStatusAllSmc:
                return "Obter estado de relé de todos os medidores do SMC";
            case ScheduleCommandType.UpdateDatetimeSmc:
                return 'Alterar Data/Hora do SMC';
            case ScheduleCommandType.UpdateDatetimeSmi:
                return 'Alterar Data/Hora do SMI';
            case ScheduleCommandType.RecoverQeeIndicator:
                return 'Solicitar paramêtros de qualidade';
            case ScheduleCommandType.GetVoltageOutLong:
                return 'Recuperar alarmes de longa duração'
            case ScheduleCommandType.GetVoltageOutShort:
                return 'Recuperar alarmes de longa duração'
            case ScheduleCommandType.SetStandardFrozenData:
                return 'Resetar Frozen Data para valores padrão';
            case ScheduleCommandType.GetQeeTransparent:
                return 'Recuperar Parâmetros de Qualidade(NIC TRANSPARENTE)';
        }
    }
}