import { Injectable } from "@angular/core";
import { IogHttpService } from "src/app/security/iog-http.service";
import { environment } from "src/environments/environment";
import { CommandsService } from "./commands.service";

@Injectable({ providedIn: 'root' })
export class FrozenDataServices {
  static frozenDataUrl = environment.apiUrlBack + "/frozen-data/";

  constructor(private http: IogHttpService) { }

  getFrozenData(
    meterSerial: string,
    sortOrder: string = "dateTime-desc",
    currentPage: number = 1,
    pageSize: number = 15,
    startDate: string = "",
    endDate: string = ""
  ) {
    return this.http
      .get(
        `${FrozenDataServices.frozenDataUrl}${meterSerial}?pageSize=${pageSize}&sortOrder=${sortOrder}&page=${currentPage}&startDateTime=${startDate}&endDateTime=${endDate}`
      )
      .map((response) => response.valueOf());
  }

  recoverFrozenData(serial: string, startDate: string, endDate: string) {
    return this.http.post(
      CommandsService.url +
      `recover-frozen-data/${serial}?startDate=${startDate}&endDate=${endDate}`,
      {}
    );
  }

  uploadFrozenDataJson(serial: string, frozenData: any) {
    return this.http.post(
      FrozenDataServices.frozenDataUrl + serial,
      frozenData
    );
  }
}
