import { DatatableComponent } from '@swimlane/ngx-datatable';
import { FileService } from 'src/app/shared/services/file.service';
import { IBalanceResult } from './../../../core/models/BalanceDTO';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-single-device-balance',
  templateUrl: './single-device-balance.component.html',
  styleUrls: ['./single-device-balance.component.scss']
})
export class SingleDeviceBalanceComponent {
  @Input() isLoading: boolean = false;
  @Input() devicesBalance: IBalanceResult[] = [];

  @ViewChild('deviceTable', { static: false }) deviceBalanceTable: DatatableComponent;

  constructor(private fileServices: FileService) { }


  formateDate(date: string) {
    return moment(date).utc(false).format('DD/MM/YYYY [às] HH:mm:ss')
  }


  downloadBalances() {
    this.fileServices.buildAndSaveDevicesBalance(this.deviceBalanceTable, 'Balanço dos dispositivos');
  }

}
