import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { NgForm } from "@angular/forms";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";

import { SmcDTO } from "src/app/core/models/SmcDTO";
import { UserType } from "src/app/core/models/UserDTO";

import { Utils } from "src/app/shared/utils";
import { PaginationResponseDTO } from "src/app/core/models/PaginationResponseDTO";
import { ModemDTO, SignalQuality } from 'src/app/core/models/ModemDTO';
import { EnumMapperDTO } from 'src/app/core/models/EnumMapperDTO';
import { TranslateInCodeService } from '../../services/translate-in-code.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: "app-smc-table",
  templateUrl: "./smc-table.component.html",
  styleUrls: ["./smc-table.component.scss"],
})
export class SmcTableComponent implements OnInit {
  @Input() data: SmcDTO[];
  @Input() isLoading = true;
  @Input() isReloading = false;
  @Input() title: string;
  @Input() page: PaginationResponseDTO;

  @Output() navigateToSMC = new EventEmitter();
  @Output() filterSMC = new EventEmitter();
  @Output() reload = new EventEmitter();
  @Output() download = new EventEmitter();
  public filledColor: string = '#fff';

  Utils = Utils;
  SmcDTO = SmcDTO;

  loading = false;
  isEditing = false;
  placeholderLabel = "Pesquisar pelo serial, Euid do moden ou marca";

  hardOperations = [UserType.ADMIN, UserType.OPERATOR];

  currentModal: NgbModalRef;
  Modem = ModemDTO;
  SiganlStrengthMapper: EnumMapperDTO;

  public startDate = "";
  public endDate = "";
  public searchString = "";
  public sortOrder = "";
  public currentPage = 0;
  public pageSize = 15;
  public doorStatus = "";

  private filter = {
    pageSize: this.pageSize,
    sortOrder: this.sortOrder,
    searchString: this.searchString,
    currentPage: this.currentPage,
    startDate: this.startDate,
    endDate: this.endDate,
    signal: '',
    doorStatus: this.doorStatus
  };

  constructor(
    private modalService: NgbModal,
    private _translateServices: TranslateService,
    private _translateInCodeService: TranslateInCodeService,
  ) {}

  async ngOnInit() {
    await this.getTranslate();
    this._translateServices.onLangChange.subscribe(async () => await this.getTranslate());
  }

  async getTranslate() {
    this.SiganlStrengthMapper = await this._translateInCodeService.getTranslate('ModemInfo.SignalQuality', SignalQuality);
  }

  navigate(serial) {
    this.navigateToSMC.emit(serial);
  }

  sendDownload(): void {
    this.download.emit();
  }

  updateSearchString(searchValue) {
    this.currentPage = 0;
    this.searchString = searchValue;
    this.filter["searchString"] = this.searchString;
    this.filter["currentPage"] = this.currentPage;
    this.emiteValue();
  }

  finishOperation(form?: NgForm) {
    this.modalService.dismissAll();
    if (form) {
      form.reset();
    }
  }

  changePage(page) {
    this.currentPage = page.page - 1;
    this.filter["currentPage"] = this.currentPage;
    this.emiteValue();
  }

  changeSizeValue(value) {
    this.currentPage = 0;
    this.pageSize = value;
    this.filter["pageSize"] = this.pageSize;
    this.filter["currentPage"] = this.currentPage;
    this.emiteValue();
  }

  sortByProp(prop) {
    const sort = prop.sorts[0];
    this.currentPage = 0;
    this.sortOrder = `${sort.prop}-${sort.dir}`;
    this.filter["currentPage"] = this.currentPage;
    this.filter["sortOrder"] = this.sortOrder;
    this.emiteValue();
  }

  filterByDate() {
    this.filter["startDate"] = this.startDate;
    this.filter["endDate"] = this.endDate;
    this.currentPage = 0;
    this.filter["currentPage"] = this.currentPage;
    this.emiteValue();
    this.closeModal();
  }

  filterByDoorStatus() {
    this.filter['doorStatus'] = this.doorStatus;
    this.currentPage = 0;
    this.filter["currentPage"] = this.currentPage;
    this.emiteValue();
    this.closeModal();
  }

  private emiteValue(): void {
    this.filterSMC.emit(this.filter);
    document.getElementById("smcCardTop").scrollIntoView(true);
  }

  removeFilter() {
    this.reseteValues();
    this.emiteValue();
    this.closeModal();
  }

  private closeModal() {
    this.modalService.dismissAll();
  }

  openModal(content) {
    this.modalService.open(content, { centered: true });
  }

  private reseteValues() {
    this.sortOrder = "";
    this.searchString = "";
    this.startDate = "";
    this.endDate = "";
    this.currentPage = 0;
    this.filter.signal = '';
    this.filledColor = '#fff';
    this.doorStatus = "";
    this.filter["currentPage"] = this.currentPage;
    this.filter["endDate"] = this.endDate;
    this.filter["startDate"] = this.startDate;
    this.filter["sortOrder"] = this.sortOrder;
    this.filter["searchString"] = this.searchString;
    this.filter["doorStatus"] = this.doorStatus;
  }

  reloadSmc() {
    this.reload.emit();
  }

  changeSignal(signal) {
    this.filledColor = signal === null ? '#fff' : this.Modem.getSinalColor(signal);
    this.filter.signal = signal === null ? '' : signal;
    this.emiteValue();
  }
}
