import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UsersService } from "../../../views/users/users.service";
import "rxjs-compat/add/observable/timer";
import "rxjs-compat/add/operator/first";
import { AuthService } from "../../../security/auth.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { HandleErrorService } from "../../services/handle-error.service";
import { HttpErrorResponse } from "@angular/common/http";
import { UserDTO, UserType } from "../../../core/models/UserDTO";
import { MessageService } from "../../services/message.service";
import {
  FormControl,
  FormGroup,
  NgForm,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";

const atLeastOneDigit = /(?=.*\d)/;
const ONELOWERCASE = /(?=.*[a-z])/;
const ESPECIALCARACTER = /([-+_!@#$%^&*.,?])/;
const UPPERCASE = /(?=.*[A-Z])/;
@Component({
  selector: "app-user-table",
  templateUrl: "./user-table.component.html",
  styleUrls: ["./user-table.component.scss"],
})
export class UserTableComponent implements OnInit {
  @Input() users: UserDTO[];
  @Input() isLoading = true;

  @Output() reload = new EventEmitter();
  @Output() filter = new EventEmitter();

  public data: UserDTO;
  public isEditing: boolean;
  public isBlocked: boolean = false;
  public deletedUser: UserDTO;
  UserType = UserType;
  UserDTO = UserDTO;
  public hardOperations = [UserType.ADMIN];
  public isValidPassWord = false;
  private loading: boolean;

  userForm = new FormGroup({
    name: new FormControl(null, [Validators.required]),
    email: new FormControl(null, [Validators.required, Validators.email]),
    password: new FormControl(null),
    confirmPassword: new FormControl(null),
    description: new FormControl(null, [Validators.required]),
    userType: new FormControl(null, [Validators.required]),
  });

  constructor(
    private dataService: UsersService,
    private authService: AuthService,
    private modalService: NgbModal,
    private handleService: HandleErrorService,
    private message: MessageService
  ) {
    this.userForm.setValidators(this.comparisonValidator());
  }

  ngOnInit() {
    this.data = new UserDTO();
  }

  keys(Enumerator): Array<string> {
    const keys = Object.keys(Enumerator);
    return keys.slice(keys.length / 2);
  }

  async modalUser(content, row?) {
    if (this.authService.hasPermition(this.hardOperations)) {
      if ((this.isEditing || this.isBlocked) && row) {
        this.userForm.get("password").clearValidators();
        this.userForm.get("confirmPassword").clearValidators();
        await this.dataService
          .getUser((<UserDTO>row).email)
          .toPromise()
          .then((data) => {
            this.data = <UserDTO>data;
            this.userForm.patchValue({
              name: this.data.name,
              email: this.data.email,
              password: this.data.password,
              confirmPassword: this.data.confirmPassword,
              userType: this.data.userType,
              description: this.data.description,
            });

            this.modalService.open(content, {
              ariaLabelledBy: "modal-basic-title",
              centered: true
            });
          })
          .catch((err) => {
            this.handleService.handle(err);
          });
      } else {
        this.userForm.reset();
        this.modalService.open(content, {
          ariaLabelledBy: "modal-basic-title",
          centered: true
        });
      }
    } else {
      this.modalService.dismissAll();
      this.handleService.handle(new HttpErrorResponse({ status: 401 }));
    }
  }

  async commitModalUser(form: NgForm) {
    this.loading = true;
    this.getFormValue();
    if (this.isBlocked) {
      await this.dataService
        .unlockUser(this.data)
        .toPromise()
        .then((response) => {
          this.finishOperation(form);
          this.message.showSuccess(
            "Acesso liberado!",
            "Usuario liberado com nova senha!"
          );
          this.isLoading = false;
        })
        .catch((error) => {
          this.handleService.handle(error);
          this.isLoading = false;
        });
    } else if (!this.isEditing) {
      await this.dataService
        .saveUser(this.data)
        .toPromise()
        .then((data) => {
          this.finishOperation(form);
          this.message.showSuccess(
            "Operação Concluída",
            "Usuário cadastrado com sucesso"
          );
        })
        .catch((err) => {
          this.loading = false;
          this.handleService.handle(err);
        });
    } else {
      await this.dataService
        .updateUser(this.data)
        .toPromise()
        .then((data) => {
          this.finishOperation(form);
          this.message.showSuccess(
            "Operação Concluída",
            "Usuário modificado com sucesso"
          );
        })
        .catch((err) => {
          this.loading = false;
          this.handleService.handle(err);
        });
    }
  }

  confirmUserExclusion(content, row) {
    if (this.authService.hasPermition(this.hardOperations)) {
      this.deletedUser = <UserDTO>row;
      this.modalService.open(content, {
        ariaLabelledBy: "modal-basic-title",
        centered: true,
      });
    } else {
      this.modalService.dismissAll();
      this.handleService.handle(new HttpErrorResponse({ status: 401 }));
    }
  }

  async deleteUser() {
    this.loading = true;
    await this.dataService
      .deleteUser(this.deletedUser.email)
      .toPromise()
      .then((data) => {
        this.finishOperation();
        this.message.showSuccess(
          "Operação Concluída",
          "Usuário excluído com sucesso"
        );
      })
      .catch((err) => {
        this.finishOperation();
        this.handleService.handle(err);
      });
  }

  finishOperation(form?: NgForm) {
    this.data = new UserDTO();
    this.modalService.dismissAll();
    this.loading = false;
    this.isEditing = false;
    this.isBlocked = false;
    if (form) {
      form.reset();
    }
  }

  containsPasswordValue() {
    if (this.data) {
      if (this.data.password) {
        if (
          this.data.password !== null ||
          this.data.password !== undefined ||
          this.data.password.trim().length > 0
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else return false;
  }

  canDeleteUser(userType) {
    if (userType === UserType.ADMIN) {
      let check = false;
      let checkCount = 0;
      this.users.forEach((user) => {
        if (user.userType === UserType.ADMIN) {
          checkCount++;
        }
        if (checkCount > 1) {
          check = true;
        }
      });
      return check;
    }
    return true;
  }

  searchByNameOrEmail(nameOrEmail: string) {
    this.filter.emit(nameOrEmail);
  }

  canChangeUser(userType) {
    return this.canDeleteUser(userType) || !this.isEditing;
  }

  reloadUsers() {
    this.reload.emit();
  }

  comparisonValidator(): ValidatorFn {
    return (group: FormGroup): ValidationErrors => {
      const passwordControl = group.controls["password"];
      const confirmPasswordControl = group.controls["confirmPassword"];

      if (passwordControl.value) {
        if (
          (this.isEditing && passwordControl.value.length < 8) ||
          !ONELOWERCASE.test(passwordControl.value) ||
          !atLeastOneDigit.test(passwordControl.value) ||
          !ESPECIALCARACTER.test(passwordControl.value) ||
          !UPPERCASE.test(passwordControl.value)
        ) {
          passwordControl.setErrors({
            minlength: !(
              passwordControl.value && passwordControl.value.length >= 8
            ),
            oneDigit: !(
              passwordControl.value &&
              atLeastOneDigit.test(passwordControl.value)
            ),
            lowerCase: !(
              passwordControl.value && ONELOWERCASE.test(passwordControl.value)
            ),
            especialCaracter: !(
              passwordControl.value &&
              ESPECIALCARACTER.test(passwordControl.value)
            ),
            upperCase: !(
              passwordControl.value && UPPERCASE.test(passwordControl.value)
            ),
            required: !passwordControl.value,
          });
        } else {
          passwordControl.setErrors(null);
        }
        if (confirmPasswordControl.value) {
          if (confirmPasswordControl.value !== passwordControl.value) {
            confirmPasswordControl.setErrors({ isDiferent: true });
          } else {
            confirmPasswordControl.setErrors(null);
          }
        } else {
          confirmPasswordControl.setErrors({ required: true });
        }
      } else if (this.isEditing) {
        passwordControl.setErrors(null);
        confirmPasswordControl.setErrors(null);
      } else {
        passwordControl.setErrors({ required: true });
      }

      return;
    };
  }

  private getFormValue() {
    this.data.name = this.userForm.controls["name"].value;
    this.data.email = this.userForm.controls["email"].value;
    this.data.password = this.userForm.controls["password"].value;
    this.data.confirmPassword = this.userForm.controls["confirmPassword"].value;
    this.data.description = this.userForm.controls["description"].value;
    this.data.userType = this.userForm.controls["userType"].value;
  }
}
