import { SmcDTO } from './../../../core/models/SmcDTO';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
} from "@angular/core";
import { NgbModal, NgbModalRef, NgbTimeStruct } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import { UserType } from "src/app/core/models/UserDTO";
import { AuthService } from "src/app/security/auth.service";
import { Utils } from "../../utils";

@Component({
  selector: 'app-recovey-energy',
  templateUrl: './recovey-energy.component.html',
  styleUrls: ['./recovey-energy.component.scss']
})
export class RecoveyEnergyComponent implements AfterViewInit {
  @Output() filter = new EventEmitter();

  @Input() smc: SmcDTO;
  @Input() isSMI: boolean = false;
  @Input() isLoading: boolean  = false;

  public quantity = null;
  public startDate = null;
  public endDate = null;
  public intervalInMinutes: NgbTimeStruct = { hour: 0, minute: 10, second: 0 };
  public minuteStep = 10;
  public hasPermitionToRecoveryEnergy = [UserType.ADMIN];
  private modalRef: NgbModalRef;
  constructor(
    private modalService: NgbModal,
    public authService: AuthService
  ) { }

  ngAfterViewInit(): void {
    this.minuteStep = this.smc.smcModel.manufacturer.name === "ELETRA" ? 15 : 10;
  }

  openModal(ctx) {
    this.modalRef = this.modalService.open(ctx, {
      centered: true
    });
  }

  finishOperation() {
    this.startDate = null;
    this.endDate = null;
    this.intervalInMinutes.hour = 0;
    this.intervalInMinutes.minute = 10;
    this.intervalInMinutes.second = 0;
    this.modalRef.close();
  }

  setMinValue() {
    if (this.quantity !== null && this.quantity < 1) this.quantity = 1;
  }

  findEnergysByDate() {
    const dates = {
      begin: moment(this.startDate).format("YYYY-MM-DDTHH:mm:ss"),
      end: moment(this.endDate).format("YYYY-MM-DDTHH:mm:ss"),
      periodInMinutes: Utils.getTimeInMinutes(this.intervalInMinutes),
    };
    this.filter.emit(this.isSMI ? dates : this.quantity);
  }

}
