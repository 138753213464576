import { ITransformerDTO } from "./TransformerDTO";

export interface ITransformerGroup {
    groupName: string;
    groupId: string;
    description: string;
    transformers: ITransformerDTO[];
    transformersCount: number;
    updatedAt: Date;
}

export class TransformerGroupDTO implements ITransformerGroup {
    updatedAt: Date;
    groupName: string;
    groupId: string;
    description: string;
    transformersCount: number;
    transformers: ITransformerDTO[];

}

export interface ITransformerGroupEditDevice {
    groupId: string;
    transformerIds: string[];
}

export class TransformerGroupDeviceEditDTO implements ITransformerGroupEditDevice {
    groupId: string;
    transformerIds: string[];


    constructor(groupId: string) {
        this.groupId = groupId;
        this.transformerIds = [];
    }
}