import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PaginationResponseDTO } from 'src/app/core/models/PaginationResponseDTO';
import { VoltageOutAlarmsImport } from 'src/app/core/models/VoltageOutDTO';
import { IogHttpService } from 'src/app/security/iog-http.service';
import { environment } from 'src/environments/environment';
import { DicFicGroupResponse } from '../components/dic-fic-monthly/dic-fic-monthly.component';
import { IDICFICResponse } from '../components/dic-fic-recovery/dic-fic-recovery.component';

@Injectable({ providedIn: 'root' })
export class VoltageOutService {
    static url = environment.apiUrlBack + '/voltage-out/';
    constructor(private _http: IogHttpService) { }

    getAlarms({
        serial, sortOrder = null,
        searchString = null, page = 1,
        pageSize = 15, startDateTime = null,
        endDateTime = null,
        filterType = null
    }
    ): Observable<PaginationResponseDTO> {
        const url = `${VoltageOutService.url}alarm/${serial}/?
            ${sortOrder ? `sortOrder=${sortOrder}&` : ''}
            ${searchString ? `searchString=${searchString}&` : ''}
            ${startDateTime ? `startDateTime=${startDateTime}&` : ''}
            ${endDateTime == true ? `endDateTime=${endDateTime}&` : ''}
            page=${page}&pageSize=${pageSize}&filterType=${filterType}
            `.replace(/\s/g, '');
        return this._http.get(url);
    }

    getMeterVoltageOutMonthly(meterSerial: string, year: number, month: number): Observable<IDICFICResponse> {
        return this._http.get<IDICFICResponse>(VoltageOutService.url + `dic-fic-meter/${meterSerial}/${month}/${year}`);
    }

    getGroupVoltageOutMonthly(groupId: string, year: number, month: number): Observable<DicFicGroupResponse> {
        return this._http.get<DicFicGroupResponse>(VoltageOutService.url + `dic-fic-group/${groupId}/${month}/${year}`);
    }

    importAlarms(serial, data: VoltageOutAlarmsImport[]) {
        return this._http.post(VoltageOutService.url + serial, data);
    }
}