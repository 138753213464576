import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IDropdownSettings } from 'ng-multiselect-dropdown/multiselect.model';
import { AlarmDTO, AlarmTypes } from 'src/app/core/models/AlarmsDTO';
import { IAllAlerts } from 'src/app/core/models/AlertDTO';
import { environment } from 'src/environments/environment';
import { MessageService } from '../../services/message.service';
import { Utils } from '../../utils';

@Component({
  selector: 'app-critic-alerts',
  templateUrl: './critic-alerts.component.html',
  styleUrls: ['./critic-alerts.component.scss']
})
export class CriticAlertsComponent implements OnInit, OnChanges {

  @Input() alarm: AlarmDTO = null;
  @Input() alarmsList: Array<IAllAlerts> = null;
  @Input() isLoading: boolean = true;
  @Input() isSmiAlarms: boolean = true;
  @Input() _smcAlarmsOnCache: Array<any> = null;
  @Input() _smiAlarmsOnCache: Array<any> = null;

  @Output() navigateToAlarm = new EventEmitter();
  @Output() reloadAlarms = new EventEmitter();

  public alarmsToEdit = new Array();


  public dropDownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: "enumValue",
    textField: "translate",
    allowSearchFilter: true,
    noDataAvailablePlaceholderText: "Nenhum Alarme  encontrado",
    limitSelection: 5,
    itemsShowLimit: 5,
    searchPlaceholderText: 'Procurar alarme pela descrição',
    clearSearchFilter: true,
    showSelectedItemsAtTop: true,
  };

  Utils = Utils;
  // alarms = AlarmTypes;

  constructor(
    private _modalServices: NgbModal,
    private _messageServices: MessageService,
  ) {
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.isSmiAlarms) {
      this._smiAlarmsOnCache = JSON.parse(localStorage.getItem(environment.smiAlarmesCriticos));
      this.alarmsToEdit = this._smiAlarmsOnCache;
    }
    else {
      this._smcAlarmsOnCache = JSON.parse(localStorage.getItem(environment.smcAlarmesCriticos));
      this.alarmsToEdit = this._smcAlarmsOnCache;
    }
  }

  ngOnInit(): void {
    if (this.isSmiAlarms) {
      this.alarmsToEdit = this._smiAlarmsOnCache;
    } else {
      this.alarmsToEdit = this._smcAlarmsOnCache;
    }
  }

  navigate() {
    this.navigateToAlarm.emit();
  }

  selectCriticAlarms(content) {
    this._modalServices.open(content, { centered: true, size: 'lx', backdrop: 'static', keyboard: false })
  }

  close() {
    this._modalServices.dismissAll();
  }




  resetDefault() {
    if (this.isSmiAlarms) {
      localStorage.removeItem(environment.smiAlarmesCriticos);
      this._smiAlarmsOnCache.push({
        translate:
          "Falha do Relé", enumValue: 6
      }, { translate: "SMI Timeout", enumValue: 14 },
        { translate: "Last Gasp", enumValue: 10 },
        { translate: "Religamento local ", enumValue: 50 },

        { translate: "Erro no sistema de medição", enumValue: 28 },
      );
      localStorage.setItem(environment.smiAlarmesCriticos, JSON.stringify(this._smiAlarmsOnCache));
    } else {
      localStorage.removeItem(environment.smcAlarmesCriticos);
      this._smcAlarmsOnCache.push({
        translate:
          "Porta Aberta", enumValue: 183
      },
        { translate: "Last Gasp", enumValue: 10 },
        { translate: "Chaves HLS Inválidas", enumValue: 14 },
        { translate: "Medidor Inválido", enumValue: 182 },

        { translate: "SMC Timeout ", enumValue: 191 },
      );
      localStorage.setItem(environment.smcAlarmesCriticos, JSON.stringify(this._smcAlarmsOnCache));
    }
    this._messageServices.showSuccess('Salvo!', 'Configuração de alarmes críticos resetadas ao default!');
    this.close();
    this.reloadAlarms.emit();
  }


  saveCriticAlarms() {
    if (this.isSmiAlarms) {
      localStorage.setItem(environment.smiAlarmesCriticos, JSON.stringify(this.alarmsToEdit));
    } else {
      localStorage.setItem(environment.smcAlarmesCriticos, JSON.stringify(this.alarmsToEdit));
    }
    this._messageServices.showSuccess('Salvo!', 'Configuração de Alarmes Críticos salvos!');
    this.reloadAlarms.emit();
    this.close();
  }

}
