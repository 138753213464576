import { MeterDTO } from "./MeterDTO";
import { TagsDTO } from "./TagsTDO";
import { Utils } from "src/app/shared/utils";

export interface IGroupDTO {
  visibleId: string;
  createdAt: Date;
  name: string;
  numberOfClients: number;
  description: string;
  updatedAt: Date;
  meters: MeterDTO[];
  tags: TagsDTO[];
}

export class GroupDTO implements IGroupDTO {
  public visibleId: string;
  public name: string;
  public description: string;
  public createdAt: Date;
  public numberOfClients: number;
  public meters: MeterDTO[];
  public tags: TagsDTO[];
  public updatedAt: Date;

  public static buildGroupJson(group: GroupDTO): any {
    return [
      {
        Nome: group.name,
        "Descrição do Grupo": group.description,
        "Quantidade de Cliente": group.numberOfClients,
        "Data de Criação": Utils.pipeDateTime(group.createdAt),
        "Data da Última Atualização": Utils.pipeDateTime(group.updatedAt),
        "Tags do Grupo": TagsDTO.getTagsNames(group.tags).join(","),
        "Serial dos medidores": MeterDTO.getMetersSerial(group.meters).join(
          ","
        ),
      },
    ];
  }
}
