import { Injectable, Injector } from '@angular/core';
import { ITicketsDTO } from 'src/app/core/models/TicketsDTO';
import { MESSAGE_TYPE, PROTOCOL_TYPE } from '../../enums';
import { DlmsProtocol } from '../../protocol/dlmsprotocol';
import { Protocols } from '../../protocol/Protocol';
import { IDriverServices } from '../IDriverService';
import { DlmsService } from './DlmsService';

@Injectable({
    providedIn: 'root'
})
export class DlmsDriverService implements IDriverServices {

    Protocol = Protocols;
    constructor(private readonly injector: Injector) {
    }
    processMessage(destiny: number[], ticket: ITicketsDTO): any {
        let protocol: DlmsProtocol = this.Protocol.factory(PROTOCOL_TYPE.DLMS_V2, destiny);
        return (this.processProtocolDlmsV2(protocol, ticket));
    }


    private processProtocolDlmsV2(dlmsProtocol: DlmsProtocol, ticket): any {
        if (dlmsProtocol.messageType === MESSAGE_TYPE.READ_RESPONSE)
            var dlmsService = this.injector.get<DlmsService>(DlmsService)
        return dlmsService.processReadResponse(dlmsProtocol, ticket);
    }
}
