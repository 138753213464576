import { MeterDTO } from "./MeterDTO";

export interface IRegisters {
  code: string;
  description: string;
  meters: MeterDTO[];
  updatedAt: Date;
}

export class RegistersDTO implements IRegisters {
  public code: string;
  public description: string;
  public meters: MeterDTO[];
  public updatedAt: Date;

  static getRegistersByName(
    allRegisters: RegistersDTO[],
    registersNames: string[]
  ): RegistersDTO[] {
    let registersToSave: RegistersDTO[] = [];
    registersNames.forEach((code) =>
      registersToSave.push(
        allRegisters.find((register) => register.code === code)
      )
    );
    return registersToSave;
  }
}
