import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown/multiselect.model';


import { Utils } from 'src/app/shared/utils';
import { HandleErrorService } from 'src/app/shared/services/handle-error.service';
import { MetersService } from 'src/app/shared/services/meters.service';

import { ITransformerDTO, TransformerDTO } from './../../../core/models/TransformerDTO';
import { PaginationResponseDTO } from 'src/app/core/models/PaginationResponseDTO';
import { IMeterDTO } from 'src/app/core/models/MeterDTO';

@Component({
  selector: 'app-transformers-table',
  templateUrl: './transformers-table.component.html',
  styleUrls: ['./transformers-table.component.scss']
})
export class TransformersTableComponent {

  @Input() isLoading: boolean = true;
  @Input() isReloading: boolean = false;
  @Input() transformers: ITransformerDTO[] = [];
  @Input() page: PaginationResponseDTO;
  @Input() label: string = 'Transformadores';
  @Input() showCrud: boolean = true;


  @Output() createTransformer = new EventEmitter();
  @Output() deleteTransformer = new EventEmitter();
  @Output() editTransformer = new EventEmitter();
  @Output() reloadTransformer = new EventEmitter();


  public isEditing: boolean = false;
  public massEditing: boolean = false;
  public placeHolderLabel: string = 'Procurar transformador pelo nome';
  public transformer: ITransformerDTO = new TransformerDTO();

  public meters: IMeterDTO[] = []


  Utils = Utils;

  public meterTransformerDropDownSettings: IDropdownSettings = {
    singleSelection: true,
    idField: "serial",
    textField: "serial",
    allowSearchFilter: true,
    noDataAvailablePlaceholderText: "Nenhum medidor encontrado",
    searchPlaceholderText: 'Filtrar pelo serial'
  };


  constructor(
    private modalService: NgbModal,
    private metersService: MetersService,
    private handleError: HandleErrorService,
  ) {
    this.getAllMeters();
  }



  getAllMeters() {
    this.isLoading = true;
    this.metersService.getTransformersAvalaibleMeter().toPromise().then((meters: IMeterDTO[]) => {
      this.meters = meters;
      this.isLoading = false;
    })
      .catch(error => {
        this.handleError.handle(error);
        this.isLoading = false;
      })
  }

  setTransformerMeter(serial: string) {
    this.transformer.transformerMeter = this.meters.find(meter => meter.serial === serial);
  }



  openModal(content) {
    this.modalService.open(content, { centered: true, keyboard: false, backdrop: 'static' });
  }


  modalTransformer(content, row?: ITransformerDTO) {
    if (row)
      this.transformer = row;
    this.openModal(content);
  }


  createUpdate() {
    this.isEditing ? this.editTransformer.emit(this.transformer) : this.createTransformer.emit(this.transformer);
  }

  delete() {
    this.deleteTransformer.emit(this.transformer);
  }

  realoadEvent() {
    this.reloadTransformer.emit();
  }

  finishOperation(form?: NgForm) {
    if (form) {
      form.reset();
    }
    this.modalService.dismissAll();
    this.transformer = new TransformerDTO();
    this.isLoading = false;
    this.isEditing = false;
    this.massEditing = false;
  }




}
