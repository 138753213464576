import { Component, Input } from "@angular/core";
import { EChartOption } from "echarts";

@Component({
  selector: "app-balance-line-chart",
  templateUrl: "./balance-line-chart.component.html",
  styleUrls: ["./balance-line-chart.component.scss"],
})
export class BalanceLineChartComponent {
  @Input() mergeInfo: EChartOption = {};
  @Input() isLoading: boolean = false;

  chartOption: EChartOption = {
    tooltip: {
      trigger: "axis",
    },
    legend: {
      data: ["Energia Requerida", "Energia Consumida", "Iluminação Pública"],
    },
    xAxis: [
      {
        type: "category",
        data: [],
      },
    ],
    yAxis: [
      {
        type: "value",
      },
    ],
    series: [
      {
        name: "Energia Requerida",
        type: "bar",
        color: '#d22346',
        barWidth: 25,
        data: [],
        stack: 'one',
      },
      {
        name: "Energia Consumida",
        type: "bar",
        color: '#263db5',
        barWidth: 25,
        data: [],
        stack: 'two'
      },
      {
        name: "Iluminação Pública",
        type: "bar",
        color: '#E7EB0E',
        barWidth: 25,
        data: [],
        stack: 'two'
      },
    ],
  };

}
