import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { NavigationService } from "../../../../services/navigation.service";
import { SearchService } from "../../../../services/search.service";
import { AuthService } from "../../../../../security/auth.service";
import { AlarmService } from "../../../../services/alarm.service";
import * as moment from "moment";
import { AlarmDTO } from "../../../../../core/models/AlarmsDTO";
import { HubService } from "../../../../services/hub.service";
import { environment } from "src/environments/environment";
import { Utils } from "src/app/shared/utils";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-header-sidebar-large",
  templateUrl: "./header-sidebar-large.component.html",
  styleUrls: ["./header-sidebar-large.component.scss"],
})
export class HeaderSidebarLargeComponent implements OnInit, OnDestroy {
  notifications: any[];
  configuredNotifications: any[] = [];
  connection: signalR.HubConnection;

  user: any;

  check = [{ check: true }];

  @Output() closeNavBarChild = new EventEmitter();

  public suportedLanguage = environment.suportedLanguage;
  public currentLangue = Utils.getLang();

  iWantToCloseWs: boolean = false;
  constructor(
    private navService: NavigationService,
    public searchService: SearchService,
    public auth: AuthService,
    public alarmService: AlarmService,
    private translateServices: TranslateService,
    private hubService: HubService
  ) { }

  async ngOnInit() {
    this.navService.publishNavigationChange(this.auth.getuser().userType);
  }

  async openConnection() {
    this.connection = await this.hubService.connect(
      "alarmsHub",
      "updateForAll"
    );
    this.refreshAlarms();
    this.connection.onclose(async () => {
      !this.iWantToCloseWs && await this.openConnection();
    });
  }

  configAlarms() {
    this.notifications.forEach((notification) => {
      const alarm = this.modifyConfigAlarms(notification as AlarmDTO, false);
      if (alarm) {
        this.configuredNotifications.push(alarm);
      }
    });
  }

  changeUserLanguage() {
    this.translateServices.use(this.currentLangue)
    localStorage.setItem(environment.currentLanguage, this.currentLangue);
  }

  modifyConfigAlarms(notification: AlarmDTO, deleteAction) {
    let configuredNotification;
    if (notification.smc) {
      if (deleteAction) {
        const alarm = this.configuredNotifications.find(
          (objectAlarm) => objectAlarm["identifier"] === notification.identifier
        );
        if (alarm) {
          const alarmIndex = this.configuredNotifications.indexOf(alarm);
          this.configuredNotifications.splice(alarmIndex, 1);
        }
      } else {
        configuredNotification = {
          icon: "i-Posterous",
          serial: notification.smc.serial,
          identifier: notification.identifier,
          title: notification.alarmsTypes.description,
          time: moment(notification.updatedAt).format("DD/MM/YYYY HH:mm:ss"),
          status: "primary",
          text: `Serial do SMC: ${notification.smc.serial}`,
          link: `/smcs/${notification.smc.smcManufacturer}/smc-info/${notification.smc.serial}/alarms`,
        };
        return configuredNotification;
      }
    } else if (notification.meter) {
      let link = "";
      let text = "";
      if (notification.meter.smc) {
        text = `Serial do medidor: ${notification.meter.serial}`;
        link =
          `/smcs/${notification.meter.smc.smcManufacturer}` +
          `/smc-info/${notification.meter.smc.serial}/meter-info/${notification.meter.serial}/alarms`;
      } else {
        text = `Serial do SMI: ${notification.meter.serial}`;
        link = `/meters/all/${notification.meter.serial}/alarms`;
      }
      if (deleteAction) {
        const alarm = this.configuredNotifications.find(
          (objectAlarm) => objectAlarm["identifier"] === notification.identifier
        );
        if (alarm) {
          const alarmIndex = this.configuredNotifications.indexOf(alarm);
          this.configuredNotifications.splice(alarmIndex, 1);
        }
      } else {
        configuredNotification = {
          icon: "i-Dashboard",
          serial: notification.meter.serial,
          identifier: notification.identifier,
          title: notification.alarmsTypes.description,
          time: moment(notification.updatedAt).format("DD/MM/YYYY HH:mm:ss"),
          status: "primary",
          text: text,
          link: link,
        };
        return configuredNotification;
      }
    }
  }

  refreshAlarms() {
    this.connection.on("updateForAll", (data, deleteAction) => {
      data = JSON.parse(data);
      deleteAction = JSON.parse(deleteAction);
      if (data) {
        if (deleteAction) {
          this.modifyConfigAlarms(data as AlarmDTO, true);
          this.configuredNotifications = this.configuredNotifications.slice();
        } else {
          const alarm = this.modifyConfigAlarms(data as AlarmDTO, false);
          const wantedAlarm = this.configuredNotifications.find(
            (configuredNotification) =>
              configuredNotification["identifier"] === alarm["identifier"]
          );
          if (wantedAlarm) {
            const wantedAlarmIndex = this.configuredNotifications.indexOf(
              wantedAlarm
            );
            if (wantedAlarmIndex > -1) {
              this.configuredNotifications[wantedAlarmIndex] = alarm;
            }
          } else {
            this.configuredNotifications.unshift(alarm);
          }
        }
      }
    });
  }

  toggelSidebar() {
    const state = this.navService.sidebarState;
    if (state.childnavOpen && state.sidenavOpen) {
      return (state.childnavOpen = false);
    }
    if (!state.childnavOpen && state.sidenavOpen) {
      return (state.sidenavOpen = false);
    }
    if (!state.sidenavOpen && !state.childnavOpen) {
      state.sidenavOpen = true;
      /*setTimeout(() => {
        state.childnavOpen = true;
      }, 50);*/
    }
  }

  signout() {
    this.auth.signout();
  }

  closeNavChild() {
    this.closeNavBarChild.emit(this.check.slice().concat(this.check.slice()));
  }

  ngOnDestroy(): void {
    this.iWantToCloseWs = true;
    this.hubService.disconnect(this.connection);
    this.connection = null;
  }

  getLogo(): string {
    if (environment.client)
      return './assets/images/logo2.png'

    return './assets/images/logo-nepen.png'
  }
}
