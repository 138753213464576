import { copyRange, DATA_ACESS_RESULT } from '../enums';
import { IDlmsData } from '../IDlmsData';
import { DlmsData } from '../types-dmls/factory';
import { IMessages } from './IMessages';

export class ReadResponse implements IMessages {
    public qty: number;
    public parameterType: number;
    dlmsData: IDlmsData;
    dataAcessResult: DATA_ACESS_RESULT;
    value: number[];


    constructor(bytes: number[]) {
        this.value = bytes;
        this.qty = bytes[1];
        this.parameterType = bytes[2];
        switch (this.parameterType) {
            case DATA_ACESS_RESULT.SUCESS:
                this.dlmsData = DlmsData.create(bytes[3]);
                let tempArray = new Array<number>(bytes.length - 3);
                tempArray = copyRange(tempArray, 0, bytes, 3, bytes.length);
                this.dlmsData.insertData(tempArray);
                this.dataAcessResult = DATA_ACESS_RESULT.SUCESS;
                break;
            case DATA_ACESS_RESULT.HARDWARE_FAULT:
                this.dataAcessResult = DATA_ACESS_RESULT.HARDWARE_FAULT;
                break;
            default:
                this.dataAcessResult = bytes[3];
                break;
        }


    }

    insertData(bytes: number[]): void {
        this.value = bytes;
        this.qty = bytes[1];
        this.parameterType = bytes[2];

        switch (this.parameterType) {
            case DATA_ACESS_RESULT.SUCESS:
                this.dlmsData = DlmsData.create(bytes[3]);
                let tempArray = new Array<number>(bytes.length - 3);
                tempArray = copyRange(tempArray, 0, bytes, 3, bytes.length);
                this.dlmsData.insertData(tempArray);
                this.dataAcessResult = DATA_ACESS_RESULT.SUCESS;
                break;
            case DATA_ACESS_RESULT.HARDWARE_FAULT:
                this.dataAcessResult = DATA_ACESS_RESULT.HARDWARE_FAULT;
                break;
            default:
                this.dataAcessResult = bytes[3];
                break;
        }
    }
    getBytes(): number[] {
        throw new Error('Method não  implementado.');
    }
    getHexString(): string {
        let hexStringresult = '';
        this.value.forEach((value: number) => {
            hexStringresult += value.toFixed(16).toUpperCase().padStart(2, '0') + ' ';
        });
        return hexStringresult;
    }
}
