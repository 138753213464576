import { IBalanceRegionCheckRequestDTO, IBalanceResult, IRegionBalance } from 'src/app/core/models/BalanceDTO';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { IBalanceCheckRequestDTO, IBalanceGroupCheckRequestDTO, IGroupBalanceResult } from 'src/app/core/models/BalanceDTO';
import { IogHttpService } from './../../security/iog-http.service';

@Injectable({
  providedIn: 'root'
})
export class BalanceService {
  static url: string = environment.apiUrlBack + '/balance';

  constructor(private http: IogHttpService) { }

  checkBalanceEffectivenesss(balanceRequest: IBalanceCheckRequestDTO) {
    return this.http.post(BalanceService.url + '/check-date-effectiveness', balanceRequest);
  }


  checkBalanceEffectivenessForGroup(balanceRequest: IBalanceGroupCheckRequestDTO) {
    return this.http.post(BalanceService.url + '/check-date-effectiveness-for-group', balanceRequest);
  }

  checkBalanceEffectivenessRegion(balanceRequest: IBalanceRegionCheckRequestDTO) {
    return this.http.post(BalanceService.url + '/check-date-effectiveness-for-region', balanceRequest);
  }

  getBalance(balance: any): Observable<IBalanceResult[]> {
    return this.http.post<IBalanceResult[]>(BalanceService.url + '/get-by-date-time-intervals', balance);
  }

  getGroupBalance(groupBalance: any): Observable<IGroupBalanceResult[]> {
    return this.http.post<IGroupBalanceResult[]>(BalanceService.url + '/get-by-date-for-group-time-intervals', groupBalance);
  }

  getRegionBalance(region: any): Observable<IRegionBalance[]> {
    return this.http.post<IRegionBalance[]>(BalanceService.url + '/get-by-date-for-region-time-intervals', region);
  }

}
