import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";

const TWENTMINUTESINMILESECONDS = 1200000;
@Injectable({
  providedIn: "root",
})
export class MessageService {
  private options = {
    timeOut: 6000,
    closeButton: true,
    progressBar: true,
    resetTimeoutOnDuplicate: true,
  };
  private infiniteOptions = {
    timeOut: TWENTMINUTESINMILESECONDS,
    closeButton: true,
    progressBar: true,
    resetTimeoutOnDuplicate: true,
  };

  constructor(private toastr: ToastrService) {}

  showSuccess(title, body) {
    this.toastr.success(body, title, this.options);
  }

  showWarning(title, body) {
    this.toastr.warning(body, title, this.options);
  }

  showError(title, body) {
    this.toastr.error(body, title, this.options);
  }

  showInfinitSuccess(title, body) {
    this.toastr.success(body, title, this.infiniteOptions);
  }

  showInfinitWarning(title, body) {
    this.toastr.warning(body, title, this.infiniteOptions);
  }

  showInfinitError(title, body) {
    this.toastr.error(body, title, this.infiniteOptions);
  }
}
