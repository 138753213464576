import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { EnumMapperDTO } from "src/app/core/models/EnumMapperDTO";
import { CommandType, StatusCommand, TicketDTO } from "src/app/core/models/TicketsDTO";
import { TranslateInCodeService } from "../../services/translate-in-code.service";
import { Utils } from "../../utils";

@Component({
    selector: "app-last-command-card",
    templateUrl: "./last-command-card.component.html",
    styleUrls: ["./last-command-card.component.scss"],
})
export class AppLastCommandCard implements OnChanges {
    @Input() ticket: TicketDTO = null;
    @Input() isLoading: boolean = true;
    public TicketDTO = TicketDTO;
    public Utils = Utils;

    EnumMappersDTO: EnumMapperDTO[];
    constructor(private _translateInCode: TranslateInCodeService,
        private _translateService: TranslateService,
    ) { }

    async ngOnChanges(changes: SimpleChanges) {
        await this.getTranslate();
        this._translateService.onLangChange.subscribe(async () => {
            await this.getTranslate();
        })
    }

    async getTranslate() {
        this.EnumMappersDTO = await this._translateInCode
            .getTranslateEnunsInArray('Tickets.Enuns', [CommandType, StatusCommand]);
    }
}