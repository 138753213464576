export interface IKeysDTO {
  ak: string;
  ek: string;
  mk: string;
  serial: string;
  updatedAt: Date;
  saved: boolean;
}

export class KeysDTO implements IKeysDTO {
  public ak: string;
  public ek: string;
  public mk: string;
  public serial: string;
  public saved: boolean;
  public updatedAt: Date;

  constructor() {
    this.ak = null;
    this.ek = null;
    this.mk = null;
    this.serial = null;
  }

  static resetKeys(key: KeysDTO): KeysDTO {
    key.ak = null;
    key.ek = null;
    key.mk = null;
    return key;
  }

  static convertKeysToHexCode(key: KeysDTO): KeysDTO {
    if (KeysDTO.isAsciiCode(key.ak)) {
      key.ak = KeysDTO.asciCodeToHexaCode(key.ak);
    }
    if (KeysDTO.isAsciiCode(key.ek)) {
      key.ek = KeysDTO.asciCodeToHexaCode(key.ek);
    }

    if (KeysDTO.isAsciiCode(key.mk)) {
      key.mk = KeysDTO.asciCodeToHexaCode(key.mk);
    }
    return key;
  }

  private static isAsciiCode(code: string): boolean {
    return code.length === 16;
  }

  private static asciCodeToHexaCode(asciiCode: string): string {
    let hexValue: string[] = [];
    for (const code of asciiCode) {
      hexValue.push(Number(code.charCodeAt(0)).toString(16).toUpperCase());
    }
    return hexValue.join("");
  }
}
