import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from "@angular/core";
import * as L from 'leaflet';
import 'leaflet.markercluster';
import 'leaflet/dist/images/marker-shadow.png';
import 'leaflet/dist/images/marker-icon.png';
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: "app-maps",
  templateUrl: "./maps.component.html",
  styleUrls: ["./maps.component.scss"],
})
export class MapsComponent implements OnInit, OnChanges {
  @Input() markerClusterData: L.Marker[];
  @Input() searchLabel: string;
  @Input() center: { latitude: number; longitude: number };
  @Input() isLoadingMap = true;
  @Input() isLoadingMarkers = true;
  @Input() searchable = true;
  @Input() zoom = 3;
  medidorOnline = "assets/images/medidor-online.png";


  options;
  layersControl;
  layers;
  map;
  markerClusterGroup: L.MarkerClusterGroup;
  markerClusterOptions: L.MarkerClusterGroupOptions;

  @Output() filter = new EventEmitter();

  constructor(private spinnerService: NgxSpinnerService) {
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.isLoadingMarkers) {
      this.spinnerService.show();
    } else {
      this.spinnerService.hide();
    }
  }


  ngOnInit(): void {
    if (this.isLoadingMarkers) {
      this.spinnerService.show();
    } else {
      this.spinnerService.hide();
    }
    this.showMap();
    this.markerClusterOptions = {
      disableClusteringAtZoom: 14,
    }
  }

  showMap() {
    this.options = {
      layers: [
        L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
          attribution: "&copy; OpenStreetMap contributors",
        }),
      ],

      zoom: this.zoom,
      minZoom: 3,
      center: L.latLng([
        this.center.latitude,
        this.center.longitude,
      ]),

    };
  }


  onMapReady(map) {
    this.map = map;
  }

  sendFilter(value) {
    this.emiteValue(value);
  }

  private emiteValue(value) {
    this.filter.emit(value);
  }

  markerClusterReady(group: L.MarkerClusterGroup) {
    this.markerClusterGroup = group;
  }

}
