import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormGroup, FormBuilder } from "@angular/forms";

import { AccountStatus, MeterDTO } from "src/app/core/models/MeterDTO";
import { SmcDTO } from "src/app/core/models/SmcDTO";
import { TagsDTO } from "src/app/core/models/TagsTDO";

import { MessageService } from "src/app/shared/services/message.service";
import { TagsServices } from "src/app/shared/services/tags.service";
import { MetersService } from "src/app/shared/services/meters.service";
import { Utils } from "src/app/shared/utils";
import { HandleErrorService } from "src/app/shared/services/handle-error.service";
import { UserType } from "src/app/core/models/UserDTO";
import { AuthService } from "src/app/security/auth.service";
import { IDropdownSettings } from "ng-multiselect-dropdown/multiselect.model";
import { TicketDTO } from "src/app/core/models/TicketsDTO";
import { EnumMapperDTO } from "src/app/core/models/EnumMapperDTO";
import { TranslateInCodeService } from "../../services/translate-in-code.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-meter-info",
  templateUrl: "./meter-info.component.html",
  styleUrls: ["./meter-info.component.scss"],
})
export class MeterInfoComponent implements OnInit {
  @Input() isLoading = false;
  @Input() relay = false;
  @Input() meter: MeterDTO;
  @Input() smc: SmcDTO;
  @Input() requestingQualityIndicators = false;
  @Input() lastTicket: TicketDTO;
  @Input() isLoadingTicket = true;
  @Output() requestQEE = new EventEmitter();
  @Output() commandQueUpdateEvent = new EventEmitter();

  public hasPermitionToCrudTags = [UserType.OPERATOR, UserType.ADMIN];
  public TicketDTO = TicketDTO;
  editTagModal: FormGroup;
  editModalRef = null;
  tagcolor = "#ffffff";
  tagToDelete: TagsDTO = null;

  MeterDTO = MeterDTO;
  isLoadingData = false;
  isEditing = false;
  isDeleting = false;
  meterTags = [];
  Utils = Utils;

  selectedTag: TagsDTO = null;
  selectedTags: TagsDTO[] = [];
  tags: TagsDTO[] = [];
  allTags: TagsDTO[] = [];
  public filterQuery = "";


  RelayEnumMapper: EnumMapperDTO;


  public tagsDropDownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: "visibleId",
    textField: "keyword",
    enableCheckAll: false,
    selectAllText: "Selecionar todas as tags",
    unSelectAllText: "Remover todas as tags.",
    allowSearchFilter: true,
    noDataAvailablePlaceholderText: "Nenhuma tag encontrado",
    searchPlaceholderText: "Procurar tag pelo nome",
  };

  constructor(
    private modalService: NgbModal,
    private metersService: MetersService,
    private tagService: TagsServices,
    private messageService: MessageService,
    private handleService: HandleErrorService,
    public authService: AuthService,
    private formBuilder: FormBuilder,
    private _translateInCodeServices: TranslateInCodeService,
    private _translateServices: TranslateService,
  ) { }


  async ngOnInit() {
    await this.getTranslates();
    this.editTagModal = this.formBuilder.group({
      keyword: [""],
      colorHexCode: [""],
      visibleId: [""],
    });
    this.getTags();

    this._translateServices.onLangChange.subscribe(async () => {
      await this.getTranslates();
    });

  }


  async getTranslates() {
    this._translateInCodeServices.getTranslate('Meter.AccountStatus', AccountStatus)
      .then(response => {
        this.RelayEnumMapper = response;
      })
  }




  getTags() {
    this.tagService
      .getTags()
      .toPromise()
      .then((data: TagsDTO[]) => {
        this.allTags = data;
        this.tags = this.allTags;
      });
  }

  openTagModal(content, tag?: TagsDTO) {
    if (tag) {
      this.tagToDelete = tag;
    }
    this.editModalRef = this.modalService.open(content, {
      centered: true,
      backdrop: "static",
      keyboard: false,
    });
  }

  filterTagsByName() {
    const searchString = this.filterQuery;
    this.tags = this.allTags.filter(function (tag) {
      return (
        tag.keyword.toLowerCase().indexOf(searchString.toLowerCase()) !== -1
      );
    });
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  openCreateUpdateTag(content: any, tag: TagsDTO = null) {
    let data: TagsDTO;
    this.tagcolor = "#ffffff";
    if (tag) {
      this.isEditing = true;
      data = tag;
      this.tagcolor = tag.colorHexCode;
    } else {
      data = new TagsDTO();
      data.keyword = this.filterQuery;
    }
    this.editTagModal.patchValue({
      visibleId: data.visibleId,
      keyword: data.keyword,
      colorHexCode: this.tagcolor,
    });
    this.editModalRef = this.modalService.open(content, { centered: true });
  }

  close() {
    this.editModalRef.close();
    this.isEditing = false;
  }

  createTag() {
    this.isLoadingData = true;
    let tag: TagsDTO = this.editTagModal.getRawValue();
    delete tag.visibleId;
    let meterToSave: MeterDTO = Object.assign(this.meter);
    meterToSave.tags.push(tag);
    this.metersService
      .updateMeter(meterToSave)
      .toPromise()
      .then((response: MeterDTO) => {
        this.meter = response;
        this.isLoadingData = false;
        this.messageService.showSuccess(
          "Tag adicionado!",
          "Tag Criado com sucesso!"
        );
        this.getTags();
        this.close();
      })
      .catch((error) => {
        this.isLoadingData = false;
        this.handleService.handle(error);
      });
  }

  deleteTag(tag: TagsDTO) {
    this.isDeleting = true;
    this.tagService
      .deleteTag(tag)
      .toPromise()
      .then(() => {
        this.metersService
          .getMeterBySerial(this.meter.serial)
          .toPromise()
          .then((data) => {
            this.meter = <MeterDTO>data;
            this.messageService.showSuccess(
              "Tag Removido",
              "Tag Removido com sucesso!"
            );
            this.isDeleting = false;
            this.close();
          });
        this.getTags();
      })
      .catch((error) => {
        this.handleService.handle(error);
        this.isDeleting = false;
      });
  }

  updateTags() {
    this.isLoadingData = true;
    const tag: TagsDTO = this.editTagModal.getRawValue();
    this.tagService
      .updateTag(tag)
      .toPromise()
      .then(() => {
        this.getTags();
        this.metersService
          .getMeterBySerial(this.meter.serial)
          .toPromise()
          .then((data: MeterDTO) => {
            this.messageService.showSuccess(
              "Tag Modificado!",
              `A tag ${tag.keyword} foi alterado com sucesso!`
            );
            this.meter = data;
          });
        this.isLoadingData = false;
        this.close();
      })
      .catch((error) => {
        this.handleService.handle(error);
        this.isLoadingData = false;
      });
  }

  addRemoveTag(tag: TagsDTO) {
    this.selectedTag = this.tags.find(
      (meterTag) => meterTag.visibleId === tag.visibleId
    );

    this.selectedTags = this.meter.tags.map((meterTag) => {
      return this.tags.find(
        (tag) => tag.visibleId === meterTag.visibleId && !!meterTag
      );
    });

    this.meter.tags = this.selectedTags;


    if (!MeterDTO.meterIncludesTag(this.selectedTag, this.meter)) {
      this.tagService
        .updateMeter(this.meter)
        .toPromise()
        .then(() => {
          this.messageService.showSuccess(
            "",
            `Tag ${this.selectedTag.keyword} removido do Medidor ${this.meter.serial}!`
          );
          this.metersService.getMeterBySerial(this.meter.serial).toPromise();
        })
        .catch((error) => {
          this.handleService.handle(error);
        });
    } else {
      delete this.selectedTag.meters;
      this.tagService
        .updateMeter(this.meter)
        .toPromise()
        .then(() => {
          this.metersService
            .getMeterBySerial(this.meter.serial)
            .toPromise()
            .then((data) => {
              this.meter = <MeterDTO>data;
              this.messageService.showSuccess(
                "",
                `Tag ${this.selectedTag.keyword} associado ao medidor ${this.meter.serial}`
              );
            });
        })
        .catch((error) => {
          this.handleService.handle(error);
          this.meter.tags.pop();
        });
    }
  }

  requestQualityIndicators(event) {
    this.requestQEE.emit(event);
  }


}
