export interface IMeterEnergyDTO {
  dateTime: Date;
  totalEnergy_Wh: string;
  totalActiveEnergy_Wh: string;
  totalDirectActiveEnergy_Wh: string;
  borderTotalizer_Wh: string;
  reservedTotalizer_Wh: string;
  offBorderTotalizer_Wh: string;
  totalIntermediary_Wh: string;
  generatedEnergyTotal_Wh: string;
  totalReverseActiveEnergy_Wh: string;
  generatedOffBorderEnergyTotal_Wh: string;
  generatedIntermediaryEnergyTotal_Wh: string;
  generatedBorderEnergyTotal_Wh: string;
  generatedReservedEnergyTotal_Wh: string;
  q1ReactiveEnergy_VarH: string;
  q2ReactiveEnergy_VarH: string;
  q3ReactiveEnergy_VarH: string;
  q4ReactiveEnergy_VarH: string;
  reactiveEnergyImportQiRi_VarH: string;
  reactiveEnergyImportQivRc_VarH: string;
  phaseAVoltageAverage_V: string;
  phaseBVoltageAverage_V: string;
  phaseCVoltageAverage_V: string;
  lastAverageVoltageL1_V: string;
  lastAverageVoltageL2_V: string;
  lastAverageVoltageL3_V: string;
  phaseACurrentAverage_A: string;
  phaseBCurrentAverage_A: string;
  phaseCCurrentAverage_A: string;
  lastAverageCurrentL1_A: string;
  lastAverageCurrentL2_A: string;
  lastAverageCurrentL3_A: string;
  totalPowerFactor_NoUnit: string;
  phaseAVoltageThd_NoUnit: string;
  phaseBVoltageThd_NoUnit: string;
  phaseCVoltageThd_NoUnit: string;
  phaseACurrentThd_NoUnit: string;
  phaseBCurrentThd_NoUnit: string;
  phaseCCurrentThd_NoUnit: string;
  group0DirectEnergy_Wh: string;
  group0InductiveReactiveDirectEnergy_VArhPlus: string;
  group0CapacitiveReactiveDirectEnergy_VArhMinus: string;
  group1ReverseEnergy_Wh: string;
  group1InductiveReactiveReverseEnergy_VArhPlus: string;
  group1CapacitiveReactiveReverseEnergy_VArhMinus: string;
  group2VoltageVaH_V: string;
  group2VoltageVbH_V: string;
  group2VoltageVcH_V: string;
  group3CurrentIaH_A: string;
  group3CurrentIbH_A: string;
  group3CurrentIcH_A: string;
  group4ThdVoltageVaH_NoUnit: string;
  group4ThdVoltageVbH_NoUnit: string;
  group4ThdVoltageVcH_NoUnit: string;
  group5ThdCurrentIaH_NoUnit: string;
  group5ThdCurrentIbH_NoUnit: string;
  group5ThdCurrentIcH_NoUnit: string;
  voltageA: string;
  voltageB: string;
  voltageC: string;
  maximumVoltageA_V: string;
  maximumVoltageB_V: string;
  maximumVoltageC_V: string;
  minimumVoltageA_V: string;
  minimumVoltageB_V: string;
  minimumVoltageC_V: string;
  currentA: string;
  currentB: string;
  currentC: string;
  maximumCurrentA_A: string;
  activePowerA: string;
  activePowerB: string;
  activePowerC: string;
  updatedAt: Date;
  totalEnergyIncremental_Wh: string; // 0 Wh Energia Ativa Direta
  q1ReactiveEnergyIncremental_VarH: string; // 0 VarH Energia Reativa Q1
  q4ReactiveEnergyIncremental_VarH: string; // 0 VarH Energia Reativa Q2
  totalActiveEnergy_KWh: string; // 1 KWh //  Energia total ativa
  totalInductiveReactiveEnergy_KVarh: string; // 1 KWh  Energia Reativa Indutiva Total 
  totalCapacitiveReactiveEnergy_KVarh: string; // 1 KWh  Energia Reativa Capacitiva Total 
  totalReverseActiveEnergy_KWh: string; // 1 KWh //  Energia reversa total ativa
  reactiveEnergyImportQiiiRi_KVarH: string; // 1 KVarH  Import Energia Ativa QIII (-Ri)
  reactiveEnergyImportQiiRc_KVarH: string; // 1 KVarH  Import Energia Ativa QI (+Rc)
  maximumVoltageL1_V: string; // -2 V Tensão Máxima L1
  maximumVoltageL2_V: string; // -2 V Tensão Máxima L2
  maximumVoltageL3_V: string; // -2 V Tensão Máxima L3
  minimumVoltageL1_V: string; // -2 V Tensão Minima L1
  minimumVoltageL2_V: string; // -2 V Tensão Minima L2
  minimumVoltageL3_V: string; // -2 V Tensão Minima L3
  maximumCurrentL1_A: string; // -3 A Maximum Current L1 Corrente Máxima L1
  maximumCurrentL2_A: string; // -3 A Maximum Current L1 Corrente Máxima L2
  maximumCurrentL3_A: string; // -3 A Maximum Current L3 Corrente Máxima L3
}

export class MeterEnergyDTO implements IMeterEnergyDTO {
  public totalActiveEnergy_KWh: string; // 1 KWh //  Energia total ativa 
  public totalInductiveReactiveEnergy_KVarh: string; // 1 KWh  Energia Reativa Indutiva Total 
  public totalCapacitiveReactiveEnergy_KVarh: string; // 1 KWh  Energia Reativa Capacitiva Total 
  public totalReverseActiveEnergy_KWh: string; // 1 KWh //  Energia reversa total ativa 
  public reactiveEnergyImportQiiiRi_KVarH: string; // 1 KVarH  Import Energia Ativa QIII (-Ri) 
  public reactiveEnergyImportQiiRc_KVarH: string; // 1 KVarH  Import Energia Ativa QI (+Rc) 
  public maximumVoltageL1_V: string; // -2 V Tensão Máxima L1 X
  public maximumVoltageL2_V: string; // -2 V Tensão Máxima L2 X
  public maximumVoltageL3_V: string; // -2 V Tensão Máxima L3 X
  public minimumVoltageL1_V: string; // -2 V Tensão Minima L1 X
  public minimumVoltageL2_V: string; // -2 V Tensão Minima L2 X
  public minimumVoltageL3_V: string; // -2 V Tensão Minima L3 X
  public maximumCurrentL1_A: string; // -3 A Corrente Máxima L1
  public maximumCurrentL2_A: string; // -3 A Corrente Máxima L2
  public maximumCurrentL3_A: string; // -3 A Corrente Máxima L3
  public totalEnergyIncremental_Wh: string;
  public q1ReactiveEnergyIncremental_VarH: string;
  public q4ReactiveEnergyIncremental_VarH: string;
  public dateTime: Date; // Data e Hora
  public totalEnergy_Wh: string; // 0 Wh Totalizador Geral
  public totalActiveEnergy_Wh: string; // 1 Wh // Total Active Energy Energia total ativa
  public totalDirectActiveEnergy_Wh: string; // 0, 1 Wh Energia Ativa Direta Total
  public borderTotalizer_Wh: string; // 0, 1 Wh Totalizador Ponta
  public reservedTotalizer_Wh: string; // 0, 1 Wh Totalizador Fora Ponta
  public offBorderTotalizer_Wh: string; // 0, 1 Wh Totalizador Reservado
  public totalIntermediary_Wh: string; // 0, 1 Wh Total Intermediário
  public generatedEnergyTotal_Wh: string; // 0, 1 Wh Energia Gerada - Total
  public totalReverseActiveEnergy_Wh: string; // 0 Wh Energia Ativa Reversa Total
  public generatedOffBorderEnergyTotal_Wh: string; // 0, 1 Wh Energia Gerada Fora Ponta
  public generatedIntermediaryEnergyTotal_Wh: string; // 0, 1 Wh Energia Gerada Intermediária
  public generatedBorderEnergyTotal_Wh: string; // 0, 1 Wh Energia Gerada Ponta
  public generatedReservedEnergyTotal_Wh: string; // 0, 1 Wh Energia Gerada Reservada
  public q1ReactiveEnergy_VarH: string; // 0 VarH Energia Reativa Q1
  public q2ReactiveEnergy_VarH: string; // 0 VarH Energia Reativa Q2
  public q3ReactiveEnergy_VarH: string; // 0 VarH Energia Reativa Q3
  public q4ReactiveEnergy_VarH: string; // 0 VarH Energia Reativa Q4
  public reactiveEnergyImportQiRi_VarH: string; // 1 VarH Reactive Energy Import QI (+Ri) Import Energia Ativa QI (+Ri)
  public reactiveEnergyImportQivRc_VarH: string; // 1 VarH Reactive Energy Import QI (+Ri) Import Energia Ativa QIV (-Rc)
  public phaseAVoltageAverage_V: string; // -1 V Tensão Média Fase A
  public phaseBVoltageAverage_V: string; // -1 V Tensão Média Fase B
  public phaseCVoltageAverage_V: string; // -1 V Tensão Média Fase C
  public lastAverageVoltageL1_V: string; // -2 V Last Average Voltage L1 Última média de tensão L1
  public lastAverageVoltageL2_V: string; // -2 V Last Average Voltage L2 Última média de tensão L2
  public lastAverageVoltageL3_V: string; // -2 V Last Average Voltage L3 Última média de tensão L3
  public phaseACurrentAverage_A: string; // -2 A Corrente Média Fase A
  public phaseBCurrentAverage_A: string; // -2 A Corrente Média Fase B
  public phaseCCurrentAverage_A: string; // -2 A Corrente Média Fase C
  public lastAverageCurrentL1_A: string; // -3 A Last Average Voltage L2 Última média de Corrente L1
  public lastAverageCurrentL2_A: string; // -3 A Last Average Voltage L2 Última média de Corrente L2
  public lastAverageCurrentL3_A: string; // -3 A Last Average Voltage L3 Última média de Corrente L3
  public totalPowerFactor_NoUnit: string; // -2, -3 no unit Fator de Potência Total
  public phaseAVoltageThd_NoUnit: string; // -2, -3 no unit THD Tensão Fase A
  public phaseBVoltageThd_NoUnit: string; // -2, -3 no unit THD Tensão Fase B
  public phaseCVoltageThd_NoUnit: string; // -2, -3 no unit THD Tensão Fase C
  public phaseACurrentThd_NoUnit: string; // -2, -3 no unit THD Corrente Fase A
  public phaseBCurrentThd_NoUnit: string; // -2, -3 no unit THD Corrente Fase B
  public phaseCCurrentThd_NoUnit: string; // -2, -3 no unit THD Corrente Fase C
  public group0DirectEnergy_Wh: string; // Wh Grupo 0 Energia Direta
  public group0InductiveReactiveDirectEnergy_VArhPlus: string; // WArh+ Grupo 0 Energia Direta
  public group0CapacitiveReactiveDirectEnergy_VArhMinus: string; // WArh- Grupo 0 Energia Direta
  public group1ReverseEnergy_Wh: string; // Wh Grupo 1 Energia Reversa
  public group1InductiveReactiveReverseEnergy_VArhPlus: string; // WArh+ Grupo 1 Energia Reversa
  public group1CapacitiveReactiveReverseEnergy_VArhMinus: string; // WArh- Grupo 1 Energia Reversa
  public group2VoltageVaH_V: string; // V Grupo 2 Tensão Va_h
  public group2VoltageVbH_V: string; // V Grupo 2 Tensão Vb_h
  public group2VoltageVcH_V: string; // V Grupo 2 Tensão Vc_h
  public group3CurrentIaH_A: string; // A Grupo 3 Corrente Ia_h
  public group3CurrentIbH_A: string; // A Grupo 3 Corrente Ib_h
  public group3CurrentIcH_A: string; // A Grupo 3 Corrente Ic_h
  public group4ThdVoltageVaH_NoUnit: string; // Grupo 4 Tensão THD Va_h
  public group4ThdVoltageVbH_NoUnit: string; // Grupo 4 Tensão THD Vb_h
  public group4ThdVoltageVcH_NoUnit: string; // Grupo 4 Tensão THD Vc_h
  public group5ThdCurrentIaH_NoUnit: string; // V Grupo 3 Corrente THD Ia_h
  public group5ThdCurrentIbH_NoUnit: string; // V Grupo 3 Corrente THD Ib_h
  public group5ThdCurrentIcH_NoUnit: string; // V Grupo 3 Corrente THD Ic_h
  public updatedAt: Date; // Ultima Atualização
  public voltageA: string; // Tensão A
  public voltageB: string; // Tensão B
  public voltageC: string; // Tensão C
  public maximumVoltageA_V: string; //  Tensão Máxima A
  public maximumVoltageB_V: string; // Tensão Máxima  B
  public maximumVoltageC_V: string; //  Tensão Máxima C
  public minimumVoltageA_V: string; // Tensão Minima A
  public minimumVoltageB_V: string; // Tensão Minima B
  public minimumVoltageC_V: string; // Tensão Minima C
  public currentA: string; // Corrente A
  public currentB: string; // Corrente B
  public currentC: string; // Corrente C
  public maximumCurrentA_A: string; //Corrente Máxima A
  public activePowerA: string; // Potência Ativa A
  public activePowerB: string; // Potencia Ativa B
  public activePowerC: string; // Potência Ativa C
  public instantaneousVoltageL1_V: number;
  public instantaneousCurrentL1_V: number;
  public instantaneousActiveImportPowerAPlusL1: number;
  public instantaneousActiveExportPowerAMinusL1: number;
  public instantaneousReactiveImportPowerRPlusL1: number;
  public instantaneousReactiveExportPowerRMinusL1: number;


  static hasPoperty(list: any[], property) {
    let hasPoperty = false;
    list.forEach((item) => {
      if (item.hasOwnProperty(property)) {
        hasPoperty = true;
        return hasPoperty;
      }
    });
    return hasPoperty;
  }
}
