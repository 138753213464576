import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { ToastrService } from "ngx-toastr";
import { JwtHelperService } from "@auth0/angular-jwt";
import { environment } from "../../environments/environment";
import { UserDTO, UserType } from "../core/models/UserDTO";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  jwtPayload: any;

  constructor(
    private http: HttpClient,
    private router: Router,
    private toastr: ToastrService,
    private jwtHelperService: JwtHelperService,
    private modalService: NgbModal
  ) {
    this.loadToken();
  }

  getuser() {
    if (
      localStorage.getItem(environment.currentUser) &&
      JSON.parse(localStorage.getItem(environment.currentUser))["user"]
    ) {
      return <UserDTO>(
        JSON.parse(localStorage.getItem(environment.currentUser))["user"]
      );
    }
  }

  hasPermition(roles: number[]) {
    if (this.getuser()) {
      return roles.some((role) => role === this.getuser().userType);
    }
  }

  async signin(credentials) {
    const user = new UserDTO();
    user.email = credentials.email.toLowerCase();
    user.password = credentials.password;
    let msg: string;
    await this.http
      .post(`${environment.apiUrlBack}/users/login`, user)
      .toPromise()
      .then((response) => {
        if (response && response["authenticated"]) {
          localStorage.setItem(
            environment.currentUser,
            JSON.stringify(response)
          );
          this.storeToken(response["accessToken"]);
        } else {
          msg = response["message"];
          this.toastr.error(msg, "Erro de autenticação", {
            timeOut: 10000,
            closeButton: true,
            progressBar: true,
          });
        }
      });
  }

  resetPassWord(newAcess) {
    const data = {
      email: newAcess["recoveryEmail"],
      newPassWord: newAcess["newpassword"],
      oldPassword: newAcess["oldpassword"],
    };
    return this.http.post(
      `${environment.apiUrlBack}/users/change-password`,
      data
    );
  }

  storeToken(token: string) {
    this.jwtPayload = this.jwtHelperService.decodeToken(token);
    localStorage.setItem(environment.token, token);
  }

  loadToken() {
    const token = localStorage.getItem(environment.token);
    if (token) {
      this.storeToken(token);
    }
  }

  clearSession() {
    localStorage.removeItem(environment.token);
    localStorage.removeItem(environment.currentUser);
    localStorage.removeItem(environment.returnUrl);
    this.jwtPayload = null;
    this.modalService.dismissAll();
  }

  isInvalidAccessToken() {
    const token = localStorage.getItem(environment.token);
    return !token || this.jwtHelperService.isTokenExpired(token);
  }

  isAInsatallerUser(): boolean {
    const user: UserDTO = JSON.parse(localStorage.getItem(environment.currentUser)).user;
    return user.userType === UserType.INSTALLER;
  }

  signout(returnUrl?) {
    this.clearSession();
    if (returnUrl) {
      localStorage.setItem(environment.returnUrl, returnUrl);
    }
    localStorage.removeItem(environment.token);
    this.router.navigate(["/sessao/entrar"]);
  }
}
