import * as moment from "moment";
import { environment } from "src/environments/environment";
import { MeterDTO } from "./MeterDTO";
import { UserDTO } from "./UserDTO";
export interface ITagsModelDTO {
  keyword: string;
  colorHexCode: string;
  visibleId: string;
  updatedAt: Date;
  meters: MeterDTO[];
}

export class TagsDTO implements ITagsModelDTO {
  public keyword: string;
  public colorHexCode: string;
  public visibleId: string;
  public updatedAt: Date;
  public meters: MeterDTO[];


  constructor() {
    this.colorHexCode = '#' + Math.floor(Math.random() * 16777215).toString(16);
  }

  static orderTags(meters: MeterDTO[]) {
    return meters.map((meter, i) => {
      meter.tags.sort((firstMeter, secondMeter) => firstMeter.keyword.length - secondMeter.keyword.length);
      const posMeter = meters[i+1];

      if(!posMeter || !posMeter.tags.length || !meter.tags.length) return meter;
      const index = posMeter.tags.findIndex(tag => tag.keyword == meter.tags[0].keyword);
      
      if(index != -1) {
        const _tag = posMeter.tags[index];
        posMeter.tags.splice(index, 1);
        posMeter.tags.unshift(_tag);
      } 
      return meter;
    });
  }

  static getTagsNames(tags: TagsDTO[]): string[] {
    let nameList: string[] = [];
    tags.forEach((tag) => {
      nameList.push(tag.keyword);
    });
    return nameList;
  }

  public static getTagsByVisibleId(
    tag: TagsDTO,
    selectedTags: TagsDTO[]
  ): TagsDTO {
    selectedTags.forEach((currentTag) => {
      delete currentTag.meters;
    });
    return selectedTags.find((tags) => tags.visibleId === tag.visibleId);
  }

  static getTagsByVisibleIdString(
    visibleId: string,
    tagsList: TagsDTO[]
  ): TagsDTO {
    tagsList.forEach((_tag) => {
      delete _tag.meters;
    });
    const tag = tagsList.find((_tag) => _tag.visibleId === visibleId);
    return tag !== undefined ? tag : this.createTagWithID(visibleId);
  }

  static createTagWithID(visibleId: string): TagsDTO {
    let newTag: TagsDTO = new TagsDTO();
    newTag.visibleId = visibleId;
    newTag.keyword = `Tag criado por ${<UserDTO>(
      JSON.parse(localStorage.getItem(environment.currentUser))["user"]["name"]
    )} em ${moment().utc(true).format("DD [de] MMMM [de] YYYY [às] HH:mm:ss")}`;
    newTag.colorHexCode =
      "#" + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, "0");
    return newTag;
  }
}
