import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as moment from 'moment';

import { IMeterDTO } from 'src/app/core/models/MeterDTO';
import { ITransformerSerialType, TransformerSerialTypeDTO } from './../../../core/models/BalanceDTO';
import { ISmcDTO } from './../../../core/models/SmcDTO';
import { Utils } from './../../utils';

@Component({
  selector: 'app-transformer-meter-table',
  templateUrl: './transformer-meter-table.component.html',
  styleUrls: ['./transformer-meter-table.component.scss']
})
export class TransmorferMeterTableComponent {

  @Input() label: string = 'Medidores disponiveis';
  @Input() meters: any[] = [];
  @Input() isLoading: boolean = true;
  @Input() isReloading: boolean = false;
  @Input() isUpdateDevices: boolean = false;
  @Input() isUpdating: boolean = false;

  @Output() exportCheckedMeters = new EventEmitter();
  @Output() save = new EventEmitter();
  @Output() remove = new EventEmitter();
  @Output() filterMeter = new EventEmitter();


  allChecked: boolean = false;
  Utils = Utils;
  checkedMeters: ITransformerSerialType[] = [];

  TransformerDeviceType = TransformerSerialTypeDTO;

  public placeHolderLabel = 'Pesquisar pelo serial';

  public searchString = "";


  sendFilter(searchSerial: string) {
    this.emiteValue(searchSerial);
  }


  formateDate(date: string) {
    return moment(date).format('DD/MMM/YYYY [às] HH:mm:ss')
  }

  checkAll(event: boolean) {
    this.meters.forEach(_meter => {
      _meter.checked = event;
      this.checkMeter(event, _meter);
    });
  }

  checkMeter(event: boolean, row: IMeterDTO | ISmcDTO) {
    const device: ITransformerSerialType = this.TransformerDeviceType.buildDeviceSerialType(row)
    const transformer: ITransformerSerialType = this.checkedMeters.find(_meter => row.serial === _meter.Serial);
    if (transformer) {
      if (!event) {
        const transformerIndex = this.checkedMeters.findIndex(_meter => _meter.Serial === row.serial);
        this.checkedMeters.splice(transformerIndex, 1);
      }
    } else if (event) {
      this.checkedMeters.push(device);
    }
    this.exportCheckedMeters.emit(this.checkedMeters.slice());
  }

  saveMeters() {
    this.save.emit();
  }

  removeMeters() {
    this.remove.emit();
  }


  private emiteValue(value) {
    this.filterMeter.emit(value)
  }

}
