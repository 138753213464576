import { SmcDTO } from './SmcDTO';
import { MeterDTO } from './MeterDTO';
import { AlarmTypeDTO } from './AlarmTypeDTO';

export interface IAlarmDTO {
    alarmsTypes: AlarmTypeDTO;
    description: string;
    smc: SmcDTO;
    meter: MeterDTO;
    updatedAt: Date;
    terminal: AlarmTerminal;
    serial: string;
    readDateTime: Date;
}

export enum AlarmTerminal {
    A = 0,
    B = 1,
    C = 2,
    METER = 3

}

export const AlarmTypes = [
    {
        name:
            "Falha no Programa", value: 0
    },
    {
        name:
            "Falha na Energia", value: 1
    },
    {
        name:
            "Falha nos Parâmetros do Medidor", value: 2
    },
    { name: "Corrente Reversa", value: 3 },
    {
        name:
            "Timeout", value: 4
    },
    {
        name:
            "Estado Relé", value: 5
    },

    { name: "Falha do Relé", value: 6 },
    {
        name:
            "Sobre-tensão", value: 7
    },
    { name: "Subtensão", value: 8 },
    { name: "Sobre-Corrente", value: 9 },
    { name: "Last Gasp ", value: 10 },
    { name: "Energia Restaurada", value: 11 },
    { name: "Heart Beat", value: 12 },
    {
        name:
            "Senha LLS Inválida", value: 13
    },

    { name: "Chaves HLS Inválidas", value: 14 },
    { name: "Tampa do medidor aberta ", value: 15 },
    { name: "SMI Timeout", value: 16 },
    { name: "Falta de energia ", value: 17 },
    { name: "Energia Restaurada", value: 18 },
    { name: "Medidor entrou/saiu do Horário de Verão", value: 19 },
    { name: "Ajuste do relógio (horário antigo)", value: 20 },
    { name: "Ajuste de relógio (novo horário)", value: 21 },
    { name: "Relógio Inválido", value: 22 },
    { name: "Troca de bateria", value: 23 },
    { name: "Bateria baixa", value: 24 },
    { name: "Múltipla tarifa ativa", value: 25 },
    { name: "Erro de RAM", value: 26 },
    { name: "Reset por Watchdog ou hardware", value: 27 },
    { name: "Erro no sistema de medição", value: 28 },
    { name: "Firmware válido para ativação ", value: 29 },
    { name: "Firmware ativado", value: 30 },
    { name: "Configuração passiva de múltipla tarifa configurada ", value: 31 },
    { name: "MD Reset", value: 32 },
    { name: "EXT Reset", value: 33 },
    { name: "Power On Reset", value: 34 },
    { name: "Erro Firmware Update", value: 35 },
    { name: "Tampa do bloco de terminais removida ", value: 36 },
    { name: "Tampa do bloco de terminais fechada ", value: 37 },

    { name: "Tampa do medidor fechada ", value: 38 },
    { name: "Falha de autenticação  ", value: 39 },
    { name: "Alteração de parâmetros  ", value: 40 },
    { name: "Falha de criptografia ou autenticação ", value: 41 },
    { name: "Energy Reset", value: 42 },
    { name: "Abertura de caixa de medição ", value: 43 },
    { name: "Caixa de medição fechada ", value: 44 },
    { name: "Religamento manual ", value: 45 },
    { name: "Corte remoto ", value: 46 },
    { name: "Religamento remoto ", value: 47 },
    { name: "Corte local ", value: 48 },
    { name: "Limite de threshold excedido ", value: 800 },
    { name: "Limite de threshold OK ", value: 49 },
    { name: "Religamento local ", value: 50 },
    { name: "Sub tensão L1 ", value: 51 },
    { name: "Sub tensão L2 ", value: 52 },
    { name: "Sub tensão L3 ", value: 53 },
    { name: "Sobre tensão L1 ", value: 54 },
    { name: "Sobre tensão L2 ", value: 55 },
    { name: "Sobre tensão L3 ", value: 56 },
    { name: "Sem tensão L1 ", value: 57 },
    { name: "Sem tensão L2 ", value: 58 },
    { name: "Sem tensão L3 ", value: 59 },
    { name: "Tensão normal L1 ", value: 60 },
    { name: "Tensão normal L2 ", value: 61 },
    { name: "Tensão normal L3 ", value: 62 },
    { name: "Sequência reversa", value: 63 },
    { name: "MISSING CURRENT START  ", value: 64 },
    { name: "MISSING CURRENT END", value: 65 },
    { name: "OUTPUT CONSTANT CHANGED", value: 66 },
    { name: "Erro na Memória Flash", value: 67 },
    { name: "NEUTRAL DISTURBANCE START", value: 68 },
    { name: "NEUTRAL DISTURBANCE END", value: 69 },
    { name: "SWELLS POWEACTIVE START", value: 70 },
    { name: "SWELLS_POWEREACTIVE_START", value: 71 },
    { name: "SWELLS_POWEACTIVE_END", value: 72 },
    { name: "SWELLS_POWEREACTIVE_END", value: 73 },
    { name: "Subtensão L1 (usuário)", value: 74 },
    { name: "Subtensão L2 (usuário)", value: 75 },
    { name: "Subtensão L3 (usuário)", value: 76 },
    { name: "Sobretensão L1 (usuário)", value: 77 },
    { name: "Sobretensão L2 (usuário)", value: 78 },
    { name: "Sobretensão L3 (usuário)", value: 79 },
    { name: "Sem tensão L1 (usuário)", value: 80 },
    { name: "Sem tensão L2 (usuário)", value: 81 },
    { name: "Sem tensão L3 (usuário)", value: 82 },
    { name: "Início de detecção de carga desbalanceada (fabricante) ", value: 83 },
    { name: "Término de detecção de carga desbalanceada (fabricante) ", value: 84 },
    { name: "Início de detecção de sobre corrente (fabricante) ", value: 85 },
    { name: "Término de detecção de sobre corrente (fabricante) ", value: 86 },
    { name: "Início de detecção de corrente reversa (fabricante) ", value: 87 },
    { name: "Início de detecção de corrente reversa L1 (fabricante) ", value: 88 },
    { name: "Início de detecção de corrente reversa L2 (fabricante) ", value: 89 },
    { name: "Início de detecção de corrente reversa L3 (fabricante) ", value: 90 },
    { name: "Término de detecção de corrente reversa (fabricante) ", value: 91 },
    { name: "Término de detecção de corrente reversa L1 (fabricante) ", value: 92 },
    { name: "Término de detecção de corrente reversa L2 (fabricante) ", value: 93 },
    { name: "Término de detecção de corrente reversa L3 (fabricante) ", value: 94 },
    { name: "Início de detecção de baixo fator de potência (fabricante) ", value: 95 },
    { name: "Término de detecção de baixo fator de potência (fabricante) ", value: 96 },
    { name: "Início de detecção de tampa de superior aberta (fabricante) ", value: 97 },
    { name: "Término de detecção de tampa de superior aberta (fabricante) ", value: 98 },
    { name: "PASS START (fabricante) ", value: 99 },
    { name: "PASS END (fabricante) ", value: 100 },
    { name: "Término de sequência de fase invertida ", value: 101 },
    { name: "Alteração de Tarifa", value: 102 },
    { name: "Alteração de conjunto de múltipla tarifa ", value: 103 },
    { name: "Medidor zerado (fabricante)", value: 104 },
    { name: "Perfil de carga zerado ", value: 105 },
    { name: "Log de eventos zerado ", value: 106 },
    { name: "Desligamento completo do dispositivo. Relacionado ao dispositivo. ", value: 108 },
    { name: "Dispositivo ligado novamente após um desligamento completo ", value: 109 },
    { name: "Bateria Interna Fraca ", value: 110 },
    { name: "Bateria Externa Fraca ", value: 111 },
    { name: "Tariff Shift Times Ativada", value: 112 },
    { name: "Registro de Erro apagado", value: 113 },
    { name: "Registro de Alarme apagado", value: 114 },
    { name: "Erro de Memória do Programa", value: 115 },
    { name: "Erro físico ou lógico na memória não volátil", value: 116 },
    { name: "Ocorreu mudança do Tariff Shift Times (TOU)", value: 117 },
    { name: "Auto-verificação bem-sucedida após atualização do firmware", value: 118 },
    { name: "Falha na verificação da assinatura digital", value: 119 },
    { name: "Sucesso na verificação da assinatura digital", value: 120 },
    { name: "Forte campo magnético DC foi detectado", value: 121 },
    { name: "Forte campo magnético DC desapareceu", value: 122 },
    { name: "Configuração está ativada ou desativada após a instalação do medidor", value: 123 },
    { name: "Desconexão Manual", value: 124 },
    { name: "Desconexão Remota", value: 125 },
    { name: "Desconexão Local", value: 126 },
    { name: "Limite do Threshold foi excedido", value: 127 },
    { name: "Fechamento remoto do relé, falhou", value: 128 },
    { name: "Fechamento manual do relé falhou", value: 129 },
    { name: "Fechamento manudo relé falhou", value: 130 },
    { name: "Iniciou Falta de Tensão L1 ", value: 131 },
    { name: "Terminou Falta de Tensão L1 ", value: 132 },
    { name: "Iniciou Falta de Tensão L3 ", value: 133 },
    { name: "Terminou Falta de Tensão L2 ", value: 134 },
    { name: "Terminou Falta de Tensão L3 ", value: 135 },
    { name: "Forte campo magnético DC foi detectado", value: 136 },
    { name: "Forte campo magnético DC desapareceu", value: 137 },
    { name: "Iniciou Sequência de Fase Reversa ", value: 138 },
    { name: "Terminou Sequência de Fase Reversa ", value: 139 },
    { name: "Iniciou Corrente Reversa L1 ", value: 140 },
    { name: "Terminou Corrente Reversa L1 ", value: 141 },
    { name: "Iniciou Corrente Reversa L2 ", value: 142 },
    { name: "Terminou Corrente Reversa L2 ", value: 143 },
    { name: "Iniciou Corrente Reversa L3 ", value: 144 },
    { name: "Terminou Corrente Reversa L3 ", value: 145 },
    { name: "Iniciou Tensão Baixa L1 ", value: 146 },
    { name: "Terminou Tensão Baixa L1 ", value: 148 },
    { name: "Iniciou Tensão Baixa L2 ", value: 149 },
    { name: "Terminou Tensão Baixa L2 ", value: 150 },
    { name: "Iniciou Tensão Baixa L3 ", value: 151 },
    { name: "Terminou Tensão Baixa L3 ", value: 152 },
    { name: "Iniciou Tensão Alta L1 ", value: 153 },
    { name: "Terminou Tensão Alta L1 ", value: 154 },
    { name: "Iniciou Tensão Alta L2 ", value: 155 },
    { name: "Terminou Tensão Alta L2 ", value: 156 },
    { name: "Iniciou Tensão Alta L3 ", value: 157 },
    { name: "Terminou Tensão Alta L3 ", value: 158 },
    { name: "Iniciou Corrente Alta L1 ", value: 159 },
    { name: "Terminou Corrente Alta L1 ", value: 160 },
    { name: "Iniciou Corrente Alta L2 ", value: 161 },
    { name: "Terminou Corrente Alta L2 ", value: 162 },
    { name: "Iniciou Corrente Alta L3 ", value: 163 },
    { name: "Terminou Corrente Alta L3 ", value: 164 },
    { name: "Sobrecarga Iniciou ", value: 165 },
    { name: "Sobrecarga Terminou ", value: 166 },
    { name: "Iniciou Falta da Fase L1 ", value: 167 },
    { name: "Terminou Falta da Fase L1 ", value: 168 },
    { name: "Iniciou Falta da Fase L2 ", value: 169 },
    { name: "Terminou Falta da Fase L2 ", value: 170 },
    { name: "Iniciou Falta da Fase L3 ", value: 171 },
    { name: "Terminou Falta da Fase L3 ", value: 172 },
    { name: "Modulo de Comunicação com Comportamento Anormal ", value: 173 },
    { name: "Modulo de Comunicação Reestabeleceu Conexão ", value: 174 },
    { name: "TOU modificado ", value: 175 },
    { name: "Relé desconectado, tensão no lado da carga detectada início ", value: 176 },
    { name: "Relé desconectado, tensão no lado da carga detectada fim ", value: 177 },
    { name: "Falha de Firmware da CPU", value: 178 },
    { name: "Falha no Módulo RF", value: 179 },
    { name: "Falha de Barramento", value: 180 },
    { name: "Falha DFlash", value: 181 },
    { name: "Medidor Inválido", value: 182 },
    { name: "Porta Aberta", value: 183 },
    { name: "Variação do Status da Porta", value: 184 },
    { name: "Porta Fechada", value: 185 },
    { name: "Last Gasp", value: 186 },
    { name: "Energia Restaurada", value: 187 },
    { name: "Senha LLS Inválida", value: 189 },
    { name: "Chaves HLS Inválidas", value: 190 },
    { name: "SMC Timeout", value: 191 },
    { name: 'Medidor Removido de Smc', value: 256 }
];

export class AlarmDTO implements IAlarmDTO {
    public description: string;
    public alarmsTypes: AlarmTypeDTO;
    public smc: SmcDTO;
    public meter: MeterDTO;
    public identifier: string;
    public updatedAt: Date;
    public terminal: AlarmTerminal;
    public serial: string;
    public readDateTime: Date;



    static getTerminal(terminal: AlarmTerminal) {
        switch (terminal) {
            case AlarmTerminal.A:
                return 'A';
            case AlarmTerminal.B:
                return 'B';
            case AlarmTerminal.C:
                return 'C';
            case AlarmTerminal.METER:
                return 'Medidor';
        }
    }
}
