export interface IEnumMapper {
    value: any;
    label: any;
}

export class EnumMapperDTO {
    protected translates: IEnumMapper[] = [];
    constructor(obj, translates) {
        Object
            .values(obj)
            .forEach(((c: string) =>{ 
                if(translates[c]) {
                    this.translates.push({ label: translates[c], value: obj[c]} )
                }
            }));
    }

    getAll() {
        return this.translates;
    }
    
    getTranslatedValue(key) {
        const translate = this.translates.find(status => key === status.value);
        return translate ? translate.label : null;
    }

    static transformTranslate(enums, translate) {
        const enumMappers: EnumMapperDTO[] = [];
        const identifies = EnumMapperDTO._identifyTranslateByEnum(enums, translate);
        const listObjToCreateInstance = EnumMapperDTO._searchTranslateByQuantity(identifies);
        listObjToCreateInstance.forEach(l => {
            enumMappers.push(new EnumMapperDTO(l.type, l.translate));
        });
        return enumMappers;
    }

    static _identifyTranslateByEnum(enums = [], translates) {
        const identify = [];
        enums.forEach(async (_enum, indexType) => {
            identify[indexType] ? indexType[indexType].push({ translates: [], type: _enum }) : identify[indexType] = { translates: [], type: _enum };
            Object.values(translates).forEach((translate, translateIndex) => {
                identify[indexType]['translates'][translateIndex] = { translate, values: [] };
                Object.keys(translate).forEach(v => {
                    const include = Object.values(_enum).includes(v);
                    include && identify[indexType]['translates'][translateIndex].values.push(include);
                });
            });
        });
        return identify;
    }

    static _searchTranslateByQuantity(identifies) {
        const organizedObjectList = [];
        identifies.forEach((identity, index) => {
            organizedObjectList[index] = { count: 0, translate: null, type: identity.type };
            identity.translates.forEach((translate) => {
                if(translate.values.length > organizedObjectList[index].count) {
                    organizedObjectList[index].count = translate.values.length;
                    organizedObjectList[index].translate = translate.translate;
                }
            });
        });
        return organizedObjectList;
    }
}