import { DatatableComponent } from '@swimlane/ngx-datatable';
import { FileService } from 'src/app/shared/services/file.service';
import { Component, Input, ViewChild } from '@angular/core';
import * as moment from 'moment';

import { IBalanceResult } from 'src/app/core/models/BalanceDTO';

@Component({
  selector: 'app-balance-table',
  templateUrl: './balance-table.component.html',
  styleUrls: ['./balance-table.component.scss']
})
export class BalanceTableComponent {

  @Input() balance: IBalanceResult[] = [];
  @Input() isLoading: boolean = false;


  @ViewChild('balanceTable', { static: false }) balanceTable: DatatableComponent;


  constructor(private fileServices: FileService) { }


  formateDate(date: string) {
    return moment(date).utc(false).format('DD/MM/YYYY [às] HH:mm:ss')
  }

  downloadFile() {
    this.fileServices.buildAndSaveBalance(this.balanceTable, `Balanço do Transformador ${this.balance[0].transformer.name}`);
  }

}
