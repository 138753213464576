import { DLMS_DATAS_TYPES } from '../enums';
import { IDlmsData } from '../IDlmsData';

export class DlmsData {
    static create(dlmsType: DLMS_DATAS_TYPES): IDlmsData {
        switch (dlmsType) {
            case DLMS_DATAS_TYPES.DLMS_ARRAY_STRUCT:
                return new (require('./DlmsArrayStruct').default)(this);
            case DLMS_DATAS_TYPES.COMPACT_ARRAY:
                return new (require('./CompactArray').default)(this);
            case DLMS_DATAS_TYPES.OCTECT_STRING:
                return new (require('./OctectString').default)();
            case DLMS_DATAS_TYPES.UINT_8:
                return new (require('./Uint8').default)();
        }
    }
}