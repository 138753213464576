import { Component, Input, Output, EventEmitter } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
// SERVICES
import { CommandsService } from "src/app/shared/services/commands.service";
import { MessageService } from "src/app/shared/services/message.service";
import { Utils } from "src/app/shared/utils";
// DTO
import { InstanteMeasureDTO } from "src/app/core/models/InstanteMeasuresDTO";
import { UserType } from "src/app/core/models/UserDTO";
import { AuthService } from "src/app/security/auth.service";

@Component({
  selector: "app-instant-measure",
  templateUrl: "./instant-measure.component.html",
  styleUrls: ["./instant-measure.component.scss"],
})
export class InstantMeasureComponent {
  @Input() isLoading = true;
  @Input() measure: InstanteMeasureDTO[] = [];
  @Input() meterSerial: string;

  @Output() refresh = new EventEmitter();

  Utils = Utils;

  InstanteMeasure = InstanteMeasureDTO;
  public isReloadingMeasure = false;

  public canReadyInstanMeasure: number[] = [UserType.ADMIN];

  constructor(
    private _commandServices: CommandsService,
    private _messageService: MessageService,
    private modalService: NgbModal,
    public authService: AuthService,
  ) { }

  refreshMeasure() {
    this.isReloadingMeasure = true;
    this._commandServices
      .getInstantMeasure(this.meterSerial)
      .toPromise()
      .then(() => {
        this._messageService.showSuccess(
          "Sucesso!",
          "Requisição de valores instantâneos enviada com sucesso!"
        );
        this.isReloadingMeasure = false;
        this.close();
      })
      .catch((error) => {
        this._messageService.showError(
          "Algo deu Errado!",
          "Não foi possível requisitar valores instantâneos!"
        );
        this.isReloadingMeasure = false;
      });
  }

  openModal(content) {
    this.modalService.open(content, { centered: true });
  }

  close() {
    this.modalService.dismissAll();
  }
}
