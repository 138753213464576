import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { IGroupBalanceResult } from 'src/app/core/models/BalanceDTO';

@Component({
  selector: 'app-area-balance-table',
  templateUrl: './area-balance-table.component.html',
  styleUrls: ['./area-balance-table.component.scss']
})
export class AreaBalanceTableComponent {
  @Input() areaBalances: IGroupBalanceResult[] = [];
  @Input() isLoading = true;


  formateDate(date: string) {
    return moment(date).utc(false).format('DD/MM/YYYY [às] HH:mm:ss')
  }

  downloadFile(){}

}
