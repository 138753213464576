import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IRateTypeDTO, RateTypeDTO } from 'src/app/core/models/RateTypeDTO';
import { Utils } from '../../utils';

@Component({
  selector: 'app-rate-types-table',
  templateUrl: './rate-types-table.component.html',
  styleUrls: ['./rate-types-table.component.scss']
})
export class RateTypesTableComponent {

  @Input() ratesTypes: IRateTypeDTO[] = [];
  @Input() isLoading: boolean = true;

  @Output() deleteRateType = new EventEmitter();
  @Output() createRateType = new EventEmitter();
  @Output() reload = new EventEmitter();
  @Output() search = new EventEmitter();

  public isEditing = false;
  public rateType: IRateTypeDTO = new RateTypeDTO();
  Utils = Utils;

  constructor(private modalService: NgbModal) { }


  openModal(modalContent, rateType?: IRateTypeDTO) {
    if (rateType) {
      this.rateType = rateType;
    }
    this.modalService.open(modalContent, { centered: true });
  }

  finishOperation(form?: NgForm) {
    if (form) {
      form.reset();
    }
    this.rateType = new RateTypeDTO();
    this.modalService.dismissAll();
  }


  filterByName(value: string) {
    this.search.emit(value);
  }


  delete() {
    this.deleteRateType.emit(this.rateType);
  }


  realoadEvent() {
    this.reload.emit()
  }

  create() {
    this.createRateType.emit(this.rateType);
  }
}
