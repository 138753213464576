import { Component, Input, Output, EventEmitter } from "@angular/core";
import {
  MeterDTO,
  MeterPhase,
  MeterUnregisteredDTO,
} from "../../../core/models/MeterDTO";
import * as XLS from "xlsx";
import { Utils } from "src/app/shared/utils";
import { PaginationResponseDTO } from "src/app/core/models/PaginationResponseDTO";
import { MetersService } from "../../services/meters.service";
import { HandleErrorService } from "../../services/handle-error.service";
import { FileService } from "../../services/file.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-meter-table-unregistered",
  templateUrl: "./meter-table-unregistered.component.html",
  styleUrls: ["./meter-table-unregistered.component.scss"],
})
export class MeterTableUnregisteredComponent {
  @Input() items: MeterUnregisteredDTO[] = [];
  @Input() meter: MeterUnregisteredDTO;
  @Input() isLoading = true;
  @Input() isReloading: boolean;
  @Input() page: PaginationResponseDTO;

  @Output() filter = new EventEmitter();
  @Output() reload = new EventEmitter();

  public MeterPhase = MeterPhase;
  public MeterDTO = MeterDTO;
  public MeterUnregisteredDTO = MeterUnregisteredDTO;
  public Utils = Utils;

  isLoadingAllMeters = false;

  placeHolderLabel = "Pesquisar pelo serial ou pelo DeviceEuid";

  constructor(
    public fileService: FileService,
    private handleService: HandleErrorService,
    private meterUnregisteredService: MetersService,
    private modalService: NgbModal
  ) {}

  sendFilter(filter) {
    this.filter.emit(filter);
  }

  reloadMeters() {
    this.reload.emit();
  }

  finishOperation() {
    this.closeModal();
  }

  openModal(content) {
    this.modalService.open(content, { centered: true });
  }

  private closeModal() {
    this.modalService.dismissAll();
  }
  downloadAllNotComissionedMeters() {
    this.isLoadingAllMeters = true;
    this.meterUnregisteredService
      .getMetersUnregistred()
      .toPromise()
      .then((meters: MeterUnregisteredDTO[]) => {
        const rows = this.processMeters(meters);
        const workSheet: XLS.WorkSheet = XLS.utils.json_to_sheet([...rows]);
        workSheet["!cols"] = [{ width: 45 }];

        const workbook: XLS.WorkBook = {
          Sheets: {
            Medidores: workSheet,
          },
          SheetNames: ["Medidores"],
        };
        const excelBuffer: any = XLS.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });
        this.fileService.saveFileAsExcel(
          excelBuffer,
          "Medidores - Não Registrados"
        );
        this.isLoadingAllMeters = false;
      })
      .catch((error) => {
        this.handleService.handle(error);
        this.isLoadingAllMeters = false;
      });
  }

  private processMeters(meters: MeterUnregisteredDTO[]) {
    let metersList = [];
    meters.forEach((_meter) => {
      const meter = {
        Serial: _meter.serial,
        Fase: MeterUnregisteredDTO.getPhaseValue(_meter.meterPhase),
        DeviceEui: _meter.deviceEui,
        ["Última Atualização"]: this.Utils.pipeDateTime(_meter.updatedAt),
      };
      metersList.push(meter);
    });
    return metersList;
  }
}
