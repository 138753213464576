import { Utils } from './../../utils';
import { IRegionGroup } from 'src/app/core/models/RegionGroupDTO';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-region-card-info',
  templateUrl: './region-card-info.component.html',
  styleUrls: ['./region-card-info.component.scss']
})
export class RegionCardInfoComponent implements OnInit {
  @Input() isLoading: boolean = true;
  @Input() region: IRegionGroup;

  Utils = Utils;

  constructor() { }

  ngOnInit() {
  }

}
