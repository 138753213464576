import { ITransformerGroup } from './TransformerGroupDTO';
import { ITransformerDTO } from './TransformerDTO';
import * as moment from "moment";
import { IRegionGroup } from './RegionGroupDTO';

export interface IBalanceCheckRequestDTO {
    DateRequest: Date;
    TransformerId: string;
    secondRange: number;
}

export class BalanceCheckRequetDTO implements IBalanceCheckRequestDTO {
    DateRequest: Date;
    TransformerId: string;
    label: string;
    secondRange: number = 20;
}
export interface IBalanceGroupCheckRequestDTO {
    DateRequest: Date;
    GroupId: string;
    secondRange: number;
}
export interface IBalanceRegionCheckRequestDTO {
    DateRequest: Date;
    RegionId: string;
    secondRange: number;
}

export class BalanceRegionCheckRequetDTO implements IBalanceRegionCheckRequestDTO {
    RegionId: string;
    DateRequest: Date;
    label: string;
    secondRange: number = 20;
}

export class BalanceGroupCheckRequetDTO implements IBalanceGroupCheckRequestDTO {
    GroupId: string;
    DateRequest: Date;
    label: string;
    secondRange: number = 20;
}

export enum TransformerDeviceType {
    SMC,
    SMI
}

export interface ITransformerSerialType {
    Serial: string;
    Type: TransformerDeviceType;
    UpdatedAt: Date;
}

export class TransformerSerialTypeDTO implements ITransformerSerialType {
    Serial: string;
    Type: TransformerDeviceType;
    UpdatedAt: Date;


    public static buildDeviceSerialType(device: any): ITransformerSerialType {
        let transformerSerialType: ITransformerSerialType = new TransformerSerialTypeDTO();
        transformerSerialType.Serial = device.serial;
        transformerSerialType.UpdatedAt = device.updatedAt;
        transformerSerialType.Type = device.smcModel === undefined ? TransformerDeviceType.SMI : TransformerDeviceType.SMC;
        return transformerSerialType;
    }

}

export interface IAddMetersModelsDTO {
    TransformerId: string;
    TransformerSerialType: ITransformerSerialType[];
}

export class AddTranformerDeviceDTO implements IAddMetersModelsDTO {
    TransformerId: string;
    TransformerSerialType: ITransformerSerialType[];

    constructor(id: string) {
        this.TransformerId = id;
    }

}

export interface IReadingCheckDTO {
    readingHour: Date;
    hasReadingPercentage: number;
    label: string;
    operationId: string;
}

export class ReadingCheckDTO implements IReadingCheckDTO {
    readingHour: Date;
    hasReadingPercentage: number;
    label: string;
    operationId: string;

    static buildBalceCheckResponseLabel(balancandeResponse: ReadingCheckDTO): string {
        return moment(balancandeResponse.readingHour).utc(false).format('DD/MM/YYYY HH:mm:ss') + ' -E: ' + balancandeResponse.hasReadingPercentage + '%';
    }

}

export interface ICheckEffictiveness {
    dateRequested: Date;
    transformerId: string;
    secondRange: number;
}

export class CheckEffictivenessDTO implements ICheckEffictiveness {
    public dateRequested: Date;
    public transformerId: string;
    public secondRange: number;

    constructor() {
        this.secondRange = 20;
    }

}

export interface IBalanceCheckResponse {
    dateTimeRequest: Date;
    readingChecks: IReadingCheckDTO[];
}

export class BalanceCheckResponseDTO implements IBalanceCheckResponse {
    dateTimeRequest: Date;
    readingChecks: IReadingCheckDTO[];

}


export interface IMetersBalance {
    serial: string;
    finalReading: number;
    initialReading: number;
    energyConsumed: number;
    trafoNumber: string;
    initialDate: Date;
    finalDate: Date;
}

export interface ISmcBalance {
    energyConsumed: number;
    finalReading: number;
    initialReading: number;
    trafoNumber: string;
    initialDate: Date;
    finalDate: Date;
    metersBalance: IMetersBalance[];
}


export interface IBalanceResult {
    initialDateTime: Date;
    finalDateTime: Date;
    transformer: ITransformerDTO;
    transformerMeterInitialReading: number;
    transformerMeterFinalReading: number;
    energyRequired: number;
    energyConsumed: number;
    streetLighting: number;
    loss: number;
    lossPercentage: number;
    smcBalance: ISmcBalance[];
    smiBalance: IMetersBalance[];
}


export interface IGroupBalanceResult {
    initialDateTime: Date;
    finalDateTime: Date;
    group: ITransformerGroup;
    groupInitialReading: number;
    groupFinalReading: number;
    energyRequired: number;
    energyConsumed: number;
    streetLighting: number;
    loss: number;
    lossPercentage: number;
    balanceResults: IBalanceResult[];
    smiBalance: IMetersBalance[];
    groupPublicLighting: number;

}

export interface IRegionBalance {
    initialDateTime: Date;
    finalDateTime: Date;
    region: IRegionGroup;
    regionInitialReading: number;
    regionFinalReading: number;
    energyRequired: number;
    energyConsumed: number;
    streetLighting: number;
    loss: number;
    lossPercentage: number;
    balanceResultGroups: IGroupBalanceResult[];
    regionPublicLighting: number;

}