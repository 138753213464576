import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { TicketDTO } from 'src/app/core/models/TicketsDTO';
import { ISerialCommand, RelayActionMass, SerialComamndDTO } from 'src/app/core/models/ClusterRelayActionDTO';

@Component({
  selector: 'app-cluster-relay-action',
  templateUrl: './cluster-relay-action.component.html',
  styleUrls: ['./cluster-relay-action.component.scss']
})
export class ClusterRelayActionComponent {
  @Input() relayActions: ISerialCommand[] = [];
  @Input() isLoading: boolean = false;
  @Output() emiteDatas = new EventEmitter();

  TicketDTO = TicketDTO;

  constructor(private modalService: NgbModal) { }

  ClusterRelayAction = SerialComamndDTO;


  openModal(content) {
    this.modalService.open(content, { centered: true });
  }


  sendDatas() {
    this.emiteDatas.emit(this.relayActions);
  }


  getActionType(relayAction: ISerialCommand): string {
    if (relayAction.commandType === RelayActionMass.RelayOn) return 'Ativar Relé';
    else if (relayAction.commandType === RelayActionMass.RelayOff) return 'Desativar Relé'

    return 'Obter estado do relé'

  }
}
