import { copyRange, DLMS_DATAS_TYPES, DLMS_INSTANCES } from '../enums';
import { IDlmsData } from '../IDlmsData';
import { IDlmsDataFactory } from './IDlmsDataFactory';

class DlmsArrayStruct implements IDlmsData {
    tag: number = DLMS_DATAS_TYPES.DLMS_ARRAY_STRUCT;
    totalBytes: number;
    rawValue: number[];
    bodyBytes: number[];

    size: number;
    value: IDlmsData[] = [];

    constructor(private factory:IDlmsDataFactory,data?: number[], dlmsDatas?: IDlmsData[]) {
        if (data && data !== null) {
            if (this.tag !== data[0])
                throw new Error('Dado não corresponde ao Array Struct');
            this.size = data[1];
            this.bodyBytes = Array<number>(data.length - 1 - 1);
            this.bodyBytes =
                copyRange(this.bodyBytes, 0, data, 1 + 1);
            let temp: number[] = this.bodyBytes;
            for (let index = 0; index < this.size; index++) {
                if (temp !== null) {
                    let tempValue: IDlmsData = this.factory.create(temp[0]);
                    tempValue.insertData(temp);
                    this.value.push(tempValue);
                    temp =
                        copyRange(temp, 0, temp, tempValue.totalBytes);
                }
            }

            let sumValue: number = 0;
            this.value.forEach(_value => {
                sumValue += _value.totalBytes;
            });
            this.rawValue = Array<number>(sumValue + 1 + 1);
            this.rawValue =
                copyRange(this.rawValue, 0, data, 0, this.rawValue.length);
            this.totalBytes = this.rawValue.length;

        }

        if (dlmsDatas) {
            this.size = dlmsDatas.length;
            let dataCount: number = 0;
            dlmsDatas.forEach(_data => {
                dataCount += _data.totalBytes;
            });

            this.totalBytes = dataCount + 2;

            this.bodyBytes = new Array<number>(this.totalBytes - 2);
            let externalIndex: number = 0;
            for (let index = 0; index < this.size; index++) {
                this.value.push(dlmsDatas[index]);
                this.bodyBytes =
                    copyRange(this.bodyBytes, externalIndex, dlmsDatas[index].rawValue, 0, dlmsDatas[index].totalBytes);
                externalIndex = externalIndex + dlmsDatas[index].totalBytes;
            }
            this.rawValue = new Array<number>(this.totalBytes);
            this.rawValue[0] = DLMS_DATAS_TYPES.DLMS_ARRAY_STRUCT;
            this.rawValue[1] = this.size;
            this.rawValue =
                copyRange(this.rawValue, 2, this.bodyBytes, 0, this.totalBytes - 2);
        }
    }
    getInstance(): string {
        return DLMS_INSTANCES.arrayStruct
    }

    getValue() {
        return this.value;
    }
    insertData(data: number[]): void {
        if (this.tag !== data[0])
            throw new Error('Dado não corresponde ao Array Struct');
        this.size = data[1];
        this.bodyBytes = Array<number>(data.length - 1 - 1);
        this.bodyBytes =
            copyRange(this.bodyBytes, 0, data, 1 + 1);
        let temp: number[] = this.bodyBytes;

        for (let index = 0; index < this.size; index++) {
            if (temp !== null) {
                let tempValue: IDlmsData = this.factory.create(temp[0]);
                tempValue.insertData(temp);
                this.value.push(tempValue);
                temp =
                    copyRange(temp, 0, temp, tempValue.totalBytes);
            }
        }

        let sumValue: number = 0;
        this.value.forEach(_value => {
            sumValue += _value.totalBytes;
        });

        this.rawValue = Array<number>(sumValue + 1 + 1);
        this.rawValue =
            copyRange(this.rawValue, 0, data, 0, this.rawValue.length);
        this.totalBytes = this.rawValue.length;
    }

    insertDlmsList(dlmsDatas: IDlmsData[]) {
        this.size = dlmsDatas.length;
        let dataCount: number = 0;
        dlmsDatas.forEach(_data => {
            dataCount += _data.totalBytes;
        });

        this.totalBytes = dataCount + 2;

        this.bodyBytes = new Array<number>(this.totalBytes - 2);
        let externalIndex: number = 0;
        for (let index = 0; index < this.size; index++) {
            this.value.push(dlmsDatas[index]);
            this.bodyBytes =
                copyRange(this.bodyBytes, externalIndex, dlmsDatas[index].rawValue, 0, dlmsDatas[index].totalBytes);
            externalIndex = externalIndex + dlmsDatas[index].totalBytes;
        }
        this.rawValue = new Array<number>(this.totalBytes);
        this.rawValue[0] = DLMS_DATAS_TYPES.DLMS_ARRAY_STRUCT;
        this.rawValue[1] = this.size;
        this.rawValue =
            copyRange(this.rawValue, 2, this.bodyBytes, 0, this.totalBytes - 2);
    }

}

export default DlmsArrayStruct;