import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { EnumMapperDTO } from "src/app/core/models/EnumMapperDTO";
import { HandleErrorService } from "./handle-error.service";

@Injectable({
    providedIn: 'root'
})
export class TranslateInCodeService {
    constructor(
        private readonly _translateService: TranslateService,
        private readonly _handleError: HandleErrorService
    ) { }

    async getTranslate<T>(key, obj: T): Promise<EnumMapperDTO> {
        try {
            const translate = await this._translateService.get(key).toPromise();
            return new EnumMapperDTO(obj, translate);
        } catch (error) {
            this._handleError.handle(new Error('Falha ao carregar tradução!'));
        }
    }

    async getTranslateEnunsInArray(transalateKey: string, enums: any[] = []) {
        try {
            const translate = await this._translateService.get(transalateKey).toPromise();
            return EnumMapperDTO.transformTranslate(enums, translate);
        } catch (error) {
            this._handleError.handle(new Error('Falha ao carregar traduções!'));
        }
    }
}