import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { TagsDTO } from "src/app/core/models/TagsTDO";

export interface IAlarmDashboardFilter {
    tag: TagsDTO[],
    dates: { startDate: Date, endDate: Date },
    read: boolean
}

@Injectable({ providedIn: 'root' })
export class AlarmDashboardFilterService {

    public filters: Subject<IAlarmDashboardFilter> = new Subject();

    next(filter: IAlarmDashboardFilter) {
        this.filters.next(filter);
    }
}