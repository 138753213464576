import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { DatatableComponent } from "@swimlane/ngx-datatable";

import {
  AccountStatus,
  MeterDTO,
  MeterManufacturer,
  MeterPhase,
  MeterType,
} from "src/app/core/models/MeterDTO";
import { UserType } from "src/app/core/models/UserDTO";
import { TicketDTO } from "src/app/core/models/TicketsDTO";
import { Utils } from "src/app/shared/utils";
import { TranslateInCodeService } from "../../services/translate-in-code.service";
import { TranslateService } from "@ngx-translate/core";
import { EnumMapperDTO } from "src/app/core/models/EnumMapperDTO";

@Component({
  selector: "app-meter-table",
  templateUrl: "./meter-table.component.html",
  styleUrls: ["./meter-table.component.scss"],
})
export class MeterTableComponent implements OnInit {
  @Input() showCheck: boolean;
  @Input() showView = true;
  @Input() relayOperations: boolean;
  @Input() meters: MeterDTO[] = [];
  @Input() isLoading = true;
  @Input() useMeterSmcModal = false;
  @Input() phaseCount: number;
  @Input() TicketDTO: TicketDTO[] = [];
  @Input() smcSerial: string = null;

  @Output() navigateToMeter = new EventEmitter();
  @Output() exportCheckedMeters = new EventEmitter();

  @ViewChild("ngxTable", { static: true }) table: DatatableComponent;

  allChecked = false;

  MeterDTO = MeterDTO;
  ticketDTO = TicketDTO;
  Utils = Utils;

  MeterPhase = MeterPhase;
  MeterManufacturer = MeterManufacturer;
  MeterType = MeterType;
  MeterModel;

  meter: MeterDTO;

  hardOperations = [UserType.ADMIN, UserType.OPERATOR];
  checkedMeters: string[] = [];

  EnumMapperDTO: EnumMapperDTO;

  constructor(
    private _translateInCode: TranslateInCodeService,
    private _translate: TranslateService
  ) { }

  async ngOnInit() {
    this.meter = new MeterDTO();
    this.getTranslate();

    this._translate
      .onLangChange.subscribe(e => this.getTranslate());
  }

  async getTranslate() {
    this.EnumMapperDTO = await this.
      _translateInCode
      .getTranslate('Meter.AccountStatus', AccountStatus);
  }

  keys(Enumerator): Array<string> {
    if (Enumerator) {
      const keys = Object.keys(Enumerator);
      return keys.slice(keys.length / 2);
    }
  }

  navigate(serial) {
    this.navigateToMeter.emit(serial);
  }

  getIdCardTable() {
    if (!this.relayOperations) {
      return "card-table-meter";
    } else {
      return "card-table-smc-meter";
    }
  }

  checkAll(event) {
    this.meters.forEach((meter) => {
      meter.checked = event;
      this.checkMeter(event, meter);
    });
  }

  checkMeter(event, row) {
    const meter = this.checkedMeters.find((serial) => row.serial === serial);
    if (meter) {
      if (!event) {
        const meterIndex = this.checkedMeters.findIndex(
          (serial) => serial === row.serial
        );
        this.checkedMeters.splice(meterIndex, 1);
      }
    } else if (event) {
      this.checkedMeters.push(row.serial);
    }
    this.exportCheckedMeters.emit(this.checkedMeters.slice());
  }
}
