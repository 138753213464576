export type IMeterReading = {
    serial: string;
    smcSerial: string;
    lastAttMeasure: Date;
}

export type IReadingStatisticDtos = {
    dateTimeOfLastMeasure: Date,
    dateTime: Date,
    quantityOfMeasures: number;
}

export type INumberOfReadings = {
    meter: IMeterReading;
    readingStatisticDtos: IReadingStatisticDtos[];
}

export enum ORDER {
    ASC = 'qtyMeasures-asc',
    DESC = 'qtyMeasures-desc'
}

export enum CELLTABLECOLORS {
    ZERO = '#d22346',
    OneThree = '#97F5A1',
    GreaterThanThree = '#119305'
}

export enum QtyMeasures {
    ZERO = 0,
    ONETHREE = 1,
    GREATERTHANTHREE = 2 
}

export class HistoricReadingQuantityDTO {
    static getQtyMeasures(qtyMeasures: string) {
        switch(qtyMeasures) {
            case '0': 
                return QtyMeasures.ZERO;
            case '1-3':
                return QtyMeasures.ONETHREE;
            case '+3':
                return QtyMeasures.GREATERTHANTHREE;
        }
    }
}