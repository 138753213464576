import { Component, Input } from '@angular/core';

import { LogsRadioDTO } from 'src/app/core/models/LogRadioDTO';

@Component({
    selector: 'app-radio-log-table',
    templateUrl: 'radio-log-table.component.html',
    styleUrls: ['./radio-log-table.component.scss']
})

export class RadioLogTableComponent {
    @Input() radioLogs: LogsRadioDTO[];

}
