import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DatatableComponent } from "@swimlane/ngx-datatable";
import * as moment from "moment";

import { QualityIndicatorDTO } from "src/app/core/models/QualityIndicatorDTO";
import { TicketDTO } from "src/app/core/models/TicketsDTO";
import { FileService } from "../../services/file.service";

@Component({
  selector: "app-quality-indicators-upload",
  templateUrl: "./quality-indicators-upload.component.html",
  styleUrls: ["./quality-indicators-upload.component.scss"],
})
export class QualityIndicatorsUploadComponent {
  @ViewChild("dataTable", { static: false }) qeeTable: DatatableComponent;

  @Input() qualityIndicators: any[] = null;
  @Input() isUploadingFile = false;
  @Input() isQualityIndicatorsSave = false;
  @Input() onlyView = false;
  @Input() ticket: TicketDTO = null;
  @Input() serial: string;

  @Output() upload = new EventEmitter();
  @Output() descart = new EventEmitter();

  QualityIndicatorDTO = QualityIndicatorDTO;
  public qeeToRemove = null;

  constructor(
    private _modalService: NgbModal,
    private _fileService: FileService
  ) {}

  openModal(content: any, row?: any) {
    if (row !== undefined) this.qeeToRemove = row;
    this._modalService.open(content, {
      centered: true,
      backdrop: "static",
      keyboard: false,
    });
  }

  uploadQEE() {
    this.upload.emit();
  }

  descartQEE() {
    this.descart.emit();
  }

  closeModal() {
    this.qeeToRemove = null;
    this._modalService.dismissAll();
  }

  removeLine() {
    this.qualityIndicators.forEach((qee, index) => {
      if (qee.datetimeFinal == this.qeeToRemove.datetimeFinal) {
        this.qualityIndicators.splice(index, 1);
        this.qualityIndicators = [...this.qualityIndicators];
        this.closeModal();
        this.isQualityIndicatorsSave = false;
      }
    });
  }

  pipeDateTime(dateTime: string) {
    return moment(dateTime)
      .utc(true)
      .format("DD [de] MMMM [de] YYYY [às] HH:mm:ss");
  }

  downloadQEE() {
    this._fileService.buildAndSaveFile(
      this.qeeTable,
      `Parametro de qualidade exportado do medidor ${this.serial} em ${moment(
        new Date()
      ).format("DD [de] MMMM [de] YYYY [às] HH:mm:ss")}`,
      "Parametro de Qualidade"
    );
  }
}
