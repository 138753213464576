import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { IDropdownSettings } from 'ng-multiselect-dropdown/multiselect.model';

import { BalanceGroupCheckRequetDTO, IBalanceCheckResponse, IBalanceGroupCheckRequestDTO, IReadingCheckDTO, ReadingCheckDTO } from 'src/app/core/models/BalanceDTO';
import { BalanceService } from '../../services/balance.service';
import { HandleErrorService } from '../../services/handle-error.service';
import { ITransformerGroup } from 'src/app/core/models/TransformerGroupDTO';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-area-balance-header',
  templateUrl: './area-balance-header.component.html',
  styleUrls: ['./area-balance-header.component.scss']
})
export class AreaBalanceHeaderComponent implements OnInit {



  @Input() transformersGroup: ITransformerGroup[] = [];
  @Input() isLoading: boolean = true;

  @Output() readBalance = new EventEmitter();

  selectedTransformerGroup: ITransformerGroup = null;
  streetLighting: number = null;
  public minuteRange: number = 20;

  initalDate: Date = null;
  endDate: Date = null;
  today: string = moment(new Date()).format('YYYY-MM-DD');
  minFinalDate: string = '';

  initialBalanceResponse: IReadingCheckDTO[] = [];
  finalBalaceResponse: IReadingCheckDTO[] = [];

  public initialBalanceCheck: IBalanceGroupCheckRequestDTO = new BalanceGroupCheckRequetDTO();
  public finalBalanceCheck: IBalanceGroupCheckRequestDTO = new BalanceGroupCheckRequetDTO();

  public initialDateRequest;
  public finalDateRequest;

  public balanceModel = {
    InitialDateTime: null,
    FinalDateTime: null,
    GroupId: null,
    SecondRangeInitialDateTime: 20,
    SecondRangeFinalDateTime: 20,
  };


  ReadingCheckDTO = ReadingCheckDTO;

  transformerDropDownSettings: IDropdownSettings;

  initialDateDropDownSettings: IDropdownSettings;

  finalDateDropDownSettings: IDropdownSettings;

  translates;
  constructor(
    private balanceService: BalanceService,
    private handlerError: HandleErrorService,
    private router: Router,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.getTranslate();
    this.translate.onLangChange.subscribe(e => this.getTranslate());
  }

  
  getTranslate() {
    this.translates = this.translate.instant('Utils');
    this.initTransformDropDownSettings();
  }

  initTransformDropDownSettings() {
    this.transformerDropDownSettings = {
      singleSelection: true,
      idField: 'groupId',
      textField: 'groupName',
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'Nenhuma área disponivel',
      searchPlaceholderText: this.translates.SearchByName,
    };

    this.initialDateDropDownSettings = {
      singleSelection: true,
      idField: 'operationId',
      textField: 'label',
      noDataAvailablePlaceholderText: this.translates.NoTime,
    }

    this.finalDateDropDownSettings = {
      singleSelection: true,
      idField: 'operationId',
      textField: 'label',
      noDataAvailablePlaceholderText: this.translates.NoTime,
    }
  }

  setTranformerId(transformer: ITransformerGroup) {
    this.initialBalanceCheck.GroupId = transformer.groupId;
    this.finalBalanceCheck.GroupId = transformer.groupId;
    this.resetDatas();
  }

  sendRequest(event) {
    this.initialDateRequest = null;
    this.initialBalanceCheck.DateRequest = this.initalDate;
    this.initialBalanceCheck.secondRange = this.minuteRange;
    this.minFinalDate = moment(this.initalDate).format('YYYY-MM-DD')
    if (this.initialBalanceCheck.GroupId !== null && this.initialBalanceCheck.DateRequest !== null && this.initialBalanceCheck.DateRequest.toString() !== '') {
      this.isLoading = true;
      this.balanceService.checkBalanceEffectivenessForGroup(this.initialBalanceCheck).toPromise()
        .then((response: IBalanceCheckResponse) => {
          response.readingChecks.forEach(_reading => {
            _reading.label = ReadingCheckDTO.buildBalceCheckResponseLabel(_reading)
          });
          this.initialBalanceResponse = response.readingChecks;
          this.isLoading = false;
        })
        .catch(error => {
          this.handlerError.handle(error, this.router);
          this.isLoading = false;
        })
    }
  }

  sendEndRequest(event) {
    this.finalDateRequest = null;
    this.finalBalanceCheck.DateRequest = this.endDate;
    this.finalBalanceCheck.secondRange = this.minuteRange;
    if (this.finalBalanceCheck.GroupId !== null && this.finalBalanceCheck.DateRequest !== null && this.finalBalanceCheck.DateRequest.toString() !== '') {
      this.isLoading = true;
      this.balanceService.checkBalanceEffectivenessForGroup(this.finalBalanceCheck).toPromise()
        .then((response: IBalanceCheckResponse) => {
          response.readingChecks.forEach(_reading => {
            _reading.label = ReadingCheckDTO.buildBalceCheckResponseLabel(_reading)
          });

          this.finalBalaceResponse = response.readingChecks;
          this.isLoading = false;
        })
        .catch(error => {
          this.handlerError.handle(error, this.router);
          this.isLoading = false;
        })
    }
  }

  setInitialDateRequest(value) {
    this.balanceModel.InitialDateTime = this.initialBalanceResponse.find(_balance => _balance.operationId === value.operationId).readingHour;
  }

  setFinalDateRequest(value) {
    this.balanceModel.FinalDateTime = this.finalBalaceResponse.find(_balance => _balance.operationId === value.operationId).readingHour;
    this.balanceModel.SecondRangeFinalDateTime = this.minuteRange;
    this.balanceModel.SecondRangeFinalDateTime = this.minuteRange;
  }

  getBalance() {
    this.balanceModel.GroupId = this.initialBalanceCheck.GroupId;
    this.readBalance.emit(this.balanceModel);
  }

  private resetDatas() {
    this.initialDateRequest = null;
    this.finalDateRequest = null;
    this.endDate = null
    this.initalDate = null;
    this.minFinalDate = null;
    this.streetLighting = null;
    this.minuteRange = 20;
  }


}
