import { Component, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { CommunicationStatus } from "src/app/core/models/MeterDTO";
import { HandleErrorService } from "../../services/handle-error.service";

@Component({
  selector: "app-card-status",
  templateUrl: "./card-status.component.html",
  styleUrls: ["./card-status.component.scss"],
})
export class CardStatusComponent {
  @Input() isLoading: boolean;
  @Input() icon: string;
  @Input() spinner: boolean;
  @Input() spinnerClass: string;


  public static comunicationStatusTranslate = []


  constructor(
    private _translateServices: TranslateService,
    private handleService: HandleErrorService,

  ) {
    this.getAllComunicationStatus();
  }


  getAllComunicationStatus() {
    const a = this._translateServices.instant('ComunicationStatus');
    this._translateServices.get('ComunicationStatus').toPromise()
      .then(response => {
        if (CardStatusComponent.comunicationStatusTranslate.length) return;

        Object
          .values(CommunicationStatus)
          .filter(c => !Number.isInteger(c))
          .forEach(c => CardStatusComponent.comunicationStatusTranslate.push({ label: response[c], value: CommunicationStatus[c] }));

        /* const noInfoIndex = CardStatusComponent.comunicationStatusTranslate.findIndex(status => status.value === CommunicationStatus.NO_INFORMATION);
         CardStatusComponent.comunicationStatusTranslate[noInfoIndex].label = response['NoInformation'];
 
         const sucessIndex = CardStatusComponent.comunicationStatusTranslate.findIndex(status => status.value === CommunicationStatus.SUCCESS);
         CardStatusComponent.comunicationStatusTranslate[sucessIndex].label = response['Success'];
 
         const busyIndex = CardStatusComponent.comunicationStatusTranslate.findIndex(status => status.value === CommunicationStatus.BUSY);
         CardStatusComponent.comunicationStatusTranslate[busyIndex].label = response['Busy'];
 
         const comunicationFailedIndex = CardStatusComponent.comunicationStatusTranslate.findIndex(status => status.value === CommunicationStatus.NO_INFORMATION);
         CardStatusComponent.comunicationStatusTranslate[comunicationFailedIndex].label = response['CommunicationFailed'];*/

      })
      .catch(error => {
        this.handleService.handle(error);
      })
  }
}

