import { Component, Input } from "@angular/core";
import { MeterDTO } from "src/app/core/models/MeterDTO";
import { SmcDTO } from "src/app/core/models/SmcDTO";
import { Utils } from "../../utils";

@Component({
  selector: "app-meter-date-info",
  templateUrl: "./meter-date-info.component.html",
  styleUrls: ["./meter-date-info.component.scss"],
})
export class MeterDateInfoComponent {
  @Input() isLoading = false;
  @Input() relay = false;
  @Input() meter: MeterDTO;
  @Input() smc: SmcDTO;
  @Input() requestingQualityIndicators = false;

  Utils = Utils;

}