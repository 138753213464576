import { Component, Input } from '@angular/core';
import { IGroupDTO } from 'src/app/core/models/GroupDTO';
import { MeterDTO } from 'src/app/core/models/MeterDTO';
import { Utils } from '../../utils';

@Component({
  selector: 'app-group-info-card',
  templateUrl: './group-info-card.component.html',
  styleUrls: ['./group-info-card.component.scss']
})
export class GroupInfoCardComponent {

  @Input() isLoading: boolean = true;
  @Input() group: IGroupDTO = null;


  Utils = Utils;
  MeterDTO = MeterDTO;



}
