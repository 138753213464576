import { Component, Input, Output, EventEmitter } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Options } from "ng5-slider";

// Models
import { SmcDTO } from "src/app/core/models/SmcDTO";
import { UserType } from "src/app/core/models/UserDTO";
import { AuthService } from "src/app/security/auth.service";

@Component({
  selector: "app-open-the-doors",
  templateUrl: "./btn-open-the-doors.component.html",
  styleUrls: ["./btn-open-the-doors.component.scss"],
})
export class OpenTheDoorsComponent {
  @Input() SMC: SmcDTO;
  @Input() isOpenning = false;
  @Input() isRequistingStatus = false;

  @Output() open = new EventEmitter();
  @Output() close = new EventEmitter();
  @Output() getStatus = new EventEmitter();

  public canOpenTheDoors = [UserType.ADMIN];


  selectedTimeIndex: number = null;
  selectedTime: {};
  confirmModalRef: any;

  sliderOptions: Options = {
    floor: 0,
    ceil: 120,
    maxLimit: 120,
    showSelectionBar: true,
    translate: (value: number): string => {
      return value + " Min.";
    },
  };
  currentMin = 0;

  doorOpenningTimes = [
    {
      label: "2 Horas",
      value: 2,
    },
    {
      label: "4 Horas",
      value: 4,
    },
    {
      label: "6 Horas",
      value: 6,
    },
    {
      label: "8 Horas",
      value: 8,
    },
    {
      label: "Tempo Indeterminado",
      value: 0,
    },
  ];

  constructor(
    private modalService: NgbModal,
    public authService: AuthService
  ) { }

  OpenTheDoorsModal(content) {
    this.modalService.open(content, {
      ariaLabelledBy: "modal-basic-title",
      centered: true,
    });
  }

  finishOperation() {
    this.modalService.dismissAll();
  }

  selectTime(number) {
    this.selectedTime = this.doorOpenningTimes[number];
    this.selectedTimeIndex = number;
  }

  confirmModal(content) {
    this.confirmModalRef = this.modalService.open(content, {
      ariaLabelledBy: "modal-basic-title",
      centered: true,
    });
  }

  closeConfirm() {
    this.confirmModalRef.close();
  }

  openTheDoors() {
    this.open.emit(this.currentMin);
    this.closeConfirm();
  }

  getDoorStatus() {
    this.getStatus.emit();
  }

  closeTheDoors() {
    this.close.emit();
  }
}
