import { Component, Input, Output, EventEmitter } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import { UserType } from "src/app/core/models/UserDTO";
import { AuthService } from "src/app/security/auth.service";

@Component({
  selector: "app-request-frozen-data",
  templateUrl: "./request-frozen-data.component.html",
  styleUrls: ["./request-frozen-data.component.scss"],
})
export class RequestFrozenDayaComponent {
  @Input() isLoading: boolean;
  @Output() filter = new EventEmitter();

  @Input() startDate = null;
  @Input() endDate = null;
  public hasPermitionToRecoveryFrozenData = [UserType.ADMIN];
  constructor(
    private modalService: NgbModal,
    public authService: AuthService
  ) { }

  openModal(content) {
    this.modalService.open(content, { centered: true, backdrop: "static" });
  }

  finishOperation() {
    this.startDate = null;
    this.endDate = null;
    this.modalService.dismissAll();
  }

  findEnergysByDate() {
    const dates = {
      begin: moment(this.startDate).format("YYYY-MM-DDTHH:mm:ss"),
      end: moment(this.endDate).format("YYYY-MM-DDTHH:mm:ss"),
    };
    this.filter.emit(dates);
  }
}
