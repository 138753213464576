import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-card-table",
  templateUrl: "./card-table.component.html",
  styleUrls: ["./card-table.component.scss"],
})
export class CardTableComponent {
  @Input() title: string;
  @Input() isLoading = true;
  @Input() showReload = false;
  @Input() showDownload: boolean = false;
  @Input() showSearch = false;
  @Input() placeholder: string;

  @Output() reload = new EventEmitter();
  @Output() search = new EventEmitter();
  @Output() download = new EventEmitter();

  realoadEvent() {
    this.reload.emit("");
  }

  updateSearchString(value: string) {
    this.search.emit(value);
  }

  downloadEvent() {
    this.download.emit();
  }
}
