import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DatatableComponent } from "@swimlane/ngx-datatable";
import * as moment from "moment";
import { MeterEnergyDTO } from "src/app/core/models/MeterEnergyDTO";
import { TicketDTO } from "src/app/core/models/TicketsDTO";
import { FileService } from "../../services/file.service";

@Component({
  selector: "app-energy-table-upload",
  templateUrl: "./energy-table-upload.component.html",
  styleUrls: ["./energy-table-upload.component.scss"],
})
export class EnergyTableUploadComponent {
  @ViewChild("dataTable", { static: false }) energyTable: DatatableComponent;

  @Input() energys: any[] = null;
  @Input() isUploadingFile = false;
  @Input() isEnergysSave = false;
  @Input() onlyView = false;
  @Input() ticket: TicketDTO = null;
  @Input() serial: string = null;

  @Output() upload = new EventEmitter();
  @Output() descart = new EventEmitter();

  EnergyDTO = MeterEnergyDTO;
  public energyToRemove = null;

  constructor(
    private _modalService: NgbModal,
    private _fileService: FileService
  ) {}

  openModal(content: any, row?: any) {
    if (row !== undefined) this.energyToRemove = row;
    this._modalService.open(content, {
      centered: true,
      keyboard: false,
      backdrop: "static",
    });
  }

  closeModal() {
    this.energyToRemove = null;
    this._modalService.dismissAll();
  }

  removeLine() {
    this.energys.forEach((energy, index) => {
      if (energy.dateTime == this.energyToRemove.dateTime) {
        this.energys.splice(index, 1);
        this.energys = [...this.energys];
        this.closeModal();
        this.isEnergysSave = false;
      }
    });
  }

  uploadEnergy() {
    this.upload.emit();
  }

  saveEnergy() {
    this._fileService.buildAndSaveFile(
      this.energyTable,
      `Memória de Massa importado do medidor ${this.serial} em ${moment(
        new Date()
      ).format("DD [de] MMMM [de] YYYY [às] HH:mm:ss")}`,
      "Memória de Massa"
    );
  }

  descartEnergy() {
    this.descart.emit();
  }

  pipeDateTime(dateTime: string) {
    return moment(dateTime)
      .utc(true)
      .format("DD [de] MMMM [de] YYYY [às] HH:mm:ss");
  }
}
