import { Injectable } from "@angular/core";
import "rxjs/add/operator/map";

import { environment } from "src/environments/environment";
import { IogHttpService } from "src/app/security/iog-http.service";

@Injectable({
  providedIn: 'root'
})
export class ServicesNoteServices {
  static url = environment.servicesNotes + "/Schedule";

  constructor(private http: IogHttpService) { }

  getAllSchedules() {
    return this.http
      .get(ServicesNoteServices.url + "?pageSize=15&sortBy=Deadline&orderBy=desc")
      .map((response) => response.valueOf());
  }

  getSchedulesByDate(
    pageSize = 15,
    page = 1,
    startDate: string,
    endDate: string,
    tracingCode: string,
    company: string,
    status: string,
    orderBy: string = "Deadline",
    sorterBy: string = "desc",
  ) {
    return this.http
      .get(
        ServicesNoteServices.url +
        `?pageSize=${pageSize}&page=${page}` +
        this.buildQuery(
          startDate,
          endDate,
          tracingCode,
          company,
          orderBy,
          sorterBy,
          status
        )
      )
      .map((response) => response.valueOf());
  }

  private buildQuery(
    startDate: string,
    endDate: string,
    tracingCode: string,
    company: string,
    orderBy: string,
    sorterBy: string,
    status: string,
  ): string {
    let query = null;
    if (startDate !== null && startDate !== "") {
      if (query !== null) {
        query += `&startDate=${startDate}&deadline=${endDate}`;
      } else {
        query = `&startDate=${startDate}&deadline=${endDate}`;
      }
    }
    if (tracingCode !== null && tracingCode !== "") {
      if (query !== null) {
        query += `&search=${tracingCode}`;
      } else {
        query = `&search=${tracingCode}`;
      }
    }

    if (company !== null) {
      if (query !== null) {
        query += `&company=${company}`;
      } else {
        query = `&company=${company}`;
      }
    }

    if (status !== null) {
      if (query !== null) {
        query += `&status=${status}`
      } else {
        query = `&status=${status}`
      }
    }

    if (sorterBy !== null) {
      if (query !== null) {
        query += `&sortBy=${sorterBy}&orderBy=${orderBy}`;
      } else {
        query = `&sortBy=${sorterBy}&orderBy=${orderBy}`;
      }
    }

    return query !== null ? query : "";
  }
}
