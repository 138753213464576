export interface IClusterBilling {
    Serial: string;
    DateTime: Date;
}
export enum BILLINPROBLEMS {
    NO_PROBLEM, // Nenhum Problema
    METER_NOT_FOUND, //Medidor não encontrado
    NO_REGISTER, //Sem registrador
    FROZENDATA_NOT_FOUND, //Frozen Data não encontrado no intervalo de 15 dias
    FROZENDATA_NOT_FOUND_NEARBY, //Frozen Data não encontrado no intervalo de 1 dia
    READING_NOT_FOUND, //Leitura não encontrada no intervalo de 15 dias
    READING_NOT_FOUND_NEARBY //Leitura não encontrada no intervalo de 1 dia
}

export enum BILLINGREGISTER {
    REGISTER_FOUND, //Registrador encontrado
    REGISTER_NOT_FOUND_AND_ADDED, //Registrador não encontrado e adicionado
    REGISTER_NOT_FOUND_AND_NOT_ADDED //Registrador não encontrado e não foi adicionado
}




export interface IClusterBillingCheck {
    hasBilling: boolean;
    checkComment: string;
    serial: string;
    closestDate: Date;
    dateRequested: Date;
    billingProblem: BILLINPROBLEMS;
    billingRegister: BILLINGREGISTER;
    sendMeasure: boolean;
}


export class ClusterBillingDTO implements IClusterBilling {
    Serial: string;
    DateTime: Date;

}



export class ClusterBillingCheckingDTO implements IClusterBillingCheck {
    checkComment: string;
    dateRequested: Date;
    billingProblem: BILLINPROBLEMS;
    billingRegister: BILLINGREGISTER;
    serial: string;
    hasBilling: boolean;
    closestDate: Date;
    sendMeasure: boolean;


    static processResponse(preProcessedBillings: ClusterBillingCheckingDTO[]): IClusterBilling[] {
        const processedList: IClusterBilling[] = [];
        preProcessedBillings.forEach((_precossedBilling) => {
            _precossedBilling.sendMeasure = _precossedBilling.hasBilling;
            let billing = new ClusterBillingDTO();
            if (_precossedBilling.hasBilling) {
                billing.DateTime = _precossedBilling.closestDate;
                billing.Serial = _precossedBilling.serial;
                processedList.push(billing);
            }

        });
        return processedList;
    }


    static getBillingProblemnsLabel(billing: IClusterBillingCheck): string {
        if (billing.billingRegister === BILLINGREGISTER.REGISTER_NOT_FOUND_AND_ADDED)
            return "Registrador 'Totalizador Geral (kWh)' adicionado e será usado para faturamento ";
        switch (+billing.billingProblem) {
            case BILLINPROBLEMS.NO_PROBLEM:
                return 'Leitura encontrado no dia solicitado'
            case BILLINPROBLEMS.NO_REGISTER:
                return 'Sem Registrador e não foi possível adicionar ';
            case BILLINPROBLEMS.METER_NOT_FOUND:
                return 'Medidor não encontrado';
            case BILLINPROBLEMS.FROZENDATA_NOT_FOUND:
                return 'Energia não encontrada no intervalo de 30 dias';
            case BILLINPROBLEMS.FROZENDATA_NOT_FOUND_NEARBY:
                return 'Energia encontrada no intervalo de 30 dias';
            case BILLINPROBLEMS.READING_NOT_FOUND:
                return 'Leitura não encontrada no intervalo de 30 dias';
            case BILLINPROBLEMS.READING_NOT_FOUND_NEARBY:
                return 'Leitura encontrada no intervalo de 30 dias';
        }
    }

    static getBillingProblemnsLabelClass(billing: IClusterBillingCheck) {
        if (billing.billingRegister === BILLINGREGISTER.REGISTER_NOT_FOUND_AND_ADDED)
            return 'badge badge-info';
        switch (+billing.billingProblem) {
            case BILLINPROBLEMS.NO_PROBLEM:
                return 'badge badge-success';
            case BILLINPROBLEMS.METER_NOT_FOUND:
                return 'badge badge-danger'
            case BILLINPROBLEMS.NO_REGISTER:
                return 'badge badge-danger';
            default:
                return 'badge badge-warning';
        }
    }



}