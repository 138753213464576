import { Injectable } from '@angular/core';


import { IogHttpService } from '../../security/iog-http.service';
import { environment } from '../../../environments/environment';
import { Utils } from '../utils';

@Injectable({
    providedIn: 'root'
})
export class AlarmService {

    static url = environment.apiUrlBack + '/alarms';
    static smcAlarmeUrl = environment.apiUrlBack + '/smc-alarms';
    static meterAlarm = environment.apiUrlBack + '/meter-alarms';


    constructor(private http: IogHttpService) {
    }



    getAllMetersAlarm() {
        return this.http.get(AlarmService.meterAlarm).map(response => response.valueOf());
    }

    getAllSmcAlarms() {
        return this.http.get(AlarmService.smcAlarmeUrl).map(response => response.valueOf());
    }

    getAlarmsBySmc(smcSerial: string, searchOrder: string = 'updatedAt-desc', currentPage: number = 1,
        pageSize: number = 15, startDate: string = '', endDate: string = '', searchString: string = '') {
        return this.http.get(
            `${AlarmService.smcAlarmeUrl}/${smcSerial}?sortOrder=${searchOrder}&page=${currentPage}&pageSize=${pageSize}&startDateTime=${startDate}&endDateTime=${endDate}&searchString=${searchString}`
        ).map(res => res.valueOf());
    }

    getAlarmsMetersBySmc(smcSerial: string) {
        return this.http.get(`${AlarmService.smcAlarmeUrl} / ${smcSerial}`).map(res => res.valueOf());
    }

    getMeterSMCAlarms(smcSerial: string, sortOrder: string = 'updatedAt-desc', currentPage: number = 1,
        pageSize: number = 15, startDate: any = '', endDate: any = '', searchString: string = '') {
        return this.http.get(
            `${AlarmService.meterAlarm}/smc/${smcSerial}?sortOrder=${sortOrder}&page=${currentPage}&pageSize=${pageSize}&startDateTime=${startDate}&endDateTime=${endDate}&searchString=${searchString}`)
            .map(response => response.valueOf());
    }


    getMeterAlarms(meterSerial: string, sortOrder: string = 'updatedAt-desc', currentPage: number = 1,
        pageSize: number = 15, startDate: any = '', endDate: any = '', searchString: string = '') {
        return this.http.get(
            `${AlarmService.meterAlarm}/${meterSerial}?sortOrder=${sortOrder}&page=${currentPage}&pageSize=${pageSize}&startDateTime=${startDate}&endDateTime=${endDate}&searchString=${searchString}`)
            .map(response => response.valueOf());
    }
}
