import { ManufacturerDTO } from "./ManufacturerDTO";

export interface IFirmware {
  crc: string;
  name: string;
  manufacturer: ManufacturerDTO;
  smc: boolean;
  updatedAt: Date;
}

export class FirmwareDTO implements IFirmware {
  public crc: string;
  public name: string;
  public manufacturer: ManufacturerDTO;
  public smc: boolean;
  public updatedAt: Date;

  constructor() {
    this.manufacturer = new ManufacturerDTO();
    this.smc = false;
  }

  static isCRCHexadecimal(crc: string): boolean {
    return !/^\d+$/.test(crc) && /^[A-F0-9]+$/i.test(crc);
  }

  static hexaToDecimal(firmware: FirmwareDTO) {
    firmware.crc = this.isCRCHexadecimal(firmware.crc)
      ? parseInt(firmware.crc, 16).toString()
      : firmware.crc;
  }
}
