import { Component, EventEmitter, Input, Output } from "@angular/core";
import { debounce } from "lodash";

@Component({
  selector: "app-search-table",
  templateUrl: "./search-table.component.html",
  styleUrls: ["./search-table.component.scss"],
})
export class SearchTableComponent {
  @Input() placeholder: string;
  @Input() isLoading = true;

  @Output() searchString = new EventEmitter();

  public filter: string;
  public filled = false;

  constructor() {
    this.changeString = debounce(this.changeString, 1000);
  }

  changeString(value) {
    this.emitValue(this.filter);
  }

  private emitValue(value) {
    this.searchString.emit(value);
  }

  removeFilter() {
    this.filter = "";
    this.emitValue(this.filter);
  }
}
