import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgForm } from "@angular/forms";

import { MeterModelDTO } from "src/app/core/models/MeterModelDTO";
import { Utils } from "src/app/shared/utils";
import { ManufacturerDTO } from "src/app/core/models/ManufacturerDTO";

import { HandleErrorService } from "src/app/shared/services/handle-error.service";
import { MetersService } from "src/app/shared/services/meters.service";

@Component({
  selector: "app-meter-models-table",
  templateUrl: "./meter-models-table.component.html",
  styleUrls: ["./meter-models-table.component.scss"],
})
export class MetersModelsTableComponent implements OnInit {
  @Input() models: MeterModelDTO[] = [];
  @Input() isLoading: false;

  @Output() modelEdit = new EventEmitter();
  @Output() modelCreate = new EventEmitter();
  @Output() modelExclusion = new EventEmitter();
  @Output() reloadModels = new EventEmitter();
  @Output() filterByName = new EventEmitter();

  public Utils = Utils;
  public name = "";

  isEditing = false;
  manufacturers: ManufacturerDTO[] = [];

  meterModel: MeterModelDTO;
  deleteModel: MeterModelDTO;

  constructor(
    private modalService: NgbModal,
    private handleService: HandleErrorService,
    private metersService: MetersService
  ) {}

  ngOnInit() {
    this.getManufactures();
    this.meterModel = new MeterModelDTO();
    this.deleteModel = new MeterModelDTO();
  }

  modalModel(content, row?): void {
    if (this.isEditing && row) {
      this.meterModel = row;
      this.modalService.open(content, {
        ariaLabelledBy: "modal-basic-title",
        centered: true,
      });
    } else {
      this.modalService.open(content, {
        ariaLabelledBy: "modal-basic-title",
        centered: true,
      });
    }
  }

  private getManufactures(): void {
    this.metersService
      .getManufactures()
      .toPromise()
      .then((manufactures: ManufacturerDTO[]) => {
        this.manufacturers = manufactures;
      })
      .catch((error) => {
        this.handleService.handle(error);
      });
  }

  createUpdate(form: NgForm) {
    this.isEditing
      ? this.modelEdit.emit(this.meterModel)
      : this.modelCreate.emit(this.meterModel);
  }

  realoadEvent() {
    this.reloadModels.emit();
  }

  searchByName(name: string) {
    this.name = name;
    this.filterByName.emit(this.name);
  }

  confirmDeleteModel(content, row: MeterModelDTO) {
    this.finishOperation();
    this.deleteModel = row;
    this.modalService.open(content, {
      ariaLabelledBy: "modal-basic-title",
      centered: true,
    });
  }

  delete() {
    this.modelExclusion.emit(this.deleteModel);
  }
  finishOperation(form?: NgForm): void {
    if (form) {
      form.reset();
    }
    this.modalService.dismissAll();
    this.isEditing = false;
  }
}
