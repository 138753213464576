import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { concat } from 'rxjs';
import { ExcelFileDTO } from 'src/app/core/models/ExcelFileDTO';
import { VoltageOut, VoltageOutAlarmsImport, VoltageOutDTO, VoltageOutImport } from 'src/app/core/models/VoltageOutDTO';
import { FileService } from '../../services/file.service';
import { Utils } from '../../utils';

@Component({
    selector: 'app-dic-fic-import-table',
    styleUrls: ['./dic-fic-import-table.component.scss'],
    templateUrl: './dic-fic-import-table.component.html'
})
export class DicFicImportTableComponent {
    @Input() isLoading: boolean = false;

    filename: string = '';
    voltages: VoltageOutImport[] = [];
    allVoltages: VoltageOutImport[] = [];
    excludeVoltages: VoltageOutImport[] = [];

    _voltageModel: VoltageOutImport[] = [];
    eventCodes = Object.values(VoltageOut).filter(v => Number.isInteger(v));

    @ViewChild('error', { static: false }) errorModalTemplate;
    validationError = {
        causeBy: '',
        valid: true,
        validItems: [],
        invalidItems: []
    };

    @Output() sendAlarms = new EventEmitter();
    constructor(private _file: FileService, private _modal: NgbModal) { }



    async file(event) {
        const file: File = event.target.files[0];
        this.filename = file.name;
        const voltages = await ExcelFileDTO.excelToObject<VoltageOutImport>(file);
        this.formatVoltages(voltages);
        this.validateVoltage();
    }

    send() {
        this.validateVoltage()
        if (!this.validationError.valid) return;

        const alarms = this.voltages.map(v => ({ datetime: v.Time, voltageOut: v.code }));
        this.sendAlarms.emit(alarms);
    }

    download() {
        this.generateModel();
        const buffer = ExcelFileDTO.objectToExcel(this._voltageModel);
        this._file.saveFileAsExcel(buffer, 'Modelo');
    }

    generateModel() {
        this.eventCodes.forEach(e => {
            this._voltageModel.push({
                Time: Utils.formateDateTime(new Date()),
                "Event code": VoltageOutDTO.getVoltageOutDesc(Number(e)) + `(${e})`
            });
        });
    }

    openModal(ctt) {
        this._modal.open(ctt, { centered: true, windowClass: 'alarm-error', size: 'lg' });
    }

    closeModal(cleanTable = false) {
        this._modal.dismissAll();
        cleanTable && this.cleanFile();
    }

    clearFileUpload(event) {
        event.value = null;
    }

    cleanFile() {
        this.voltages = [];
        this.allVoltages = [];
        this.filename = '';
        this.validationError.valid = true;
    }

    extractNumber(str: string) {
        return Number(str.match(/(\d+)/)[0])
    }

    formatVoltages(voltages: VoltageOutImport[]) {
        this.allVoltages = voltages.map((_voltage, index) => ({
            id: index,
            code: this.extractNumber(_voltage['Event code']),
            ..._voltage
        }));
    }

    validateVoltage() {
        const shorts = [
            this.allVoltages.filter(v => v.code === VoltageOut.SHORT_START),
            this.allVoltages.filter(v => v.code === VoltageOut.SHORT_END)
        ];
        const SHORT_START_POS = 0;
        const SHORT_END_POS = 1;

        const longs = [
            this.allVoltages.filter(v => v.code === VoltageOut.LONG_START),
            this.allVoltages.filter(v => v.code === VoltageOut.LONG_END)
        ];
        const LONG_START_POS = 0;
        const LONG_END_POS = 1;

        if (shorts[SHORT_START_POS].length !== shorts[SHORT_END_POS].length) {
            if (shorts[SHORT_START_POS].length > shorts[SHORT_END_POS].length) {
                this.validationError.invalidItems = shorts[SHORT_END_POS];
                this.validationError.validItems = shorts[SHORT_START_POS];
            } else {
                this.validationError.invalidItems = shorts[SHORT_START_POS];
                this.validationError.validItems = shorts[SHORT_END_POS];
            }

            Object.assign(this.validationError, {
                causeBy: 'AlarmsImport.ShortError',
                valid: false,
            });
        } else if (longs[LONG_START_POS].length !== longs[LONG_END_POS].length) {
            if (longs[LONG_START_POS].length > longs[LONG_END_POS].length) {
                this.validationError.invalidItems = longs[LONG_END_POS];
                this.validationError.validItems = longs[LONG_START_POS];
            } else {
                this.validationError.invalidItems = longs[LONG_START_POS]
                this.validationError.validItems = longs[LONG_END_POS];
            }

            Object.assign(this.validationError, {
                causeBy: 'AlarmsImport.LongError',
                valid: false
            })
        }

        this._verifyAndOpenModal();
    }

    private _verifyAndOpenModal() {
        if (this.validationError && !this.validationError.valid) {
            this.openModal(this.errorModalTemplate);
        }
        else {
            this.voltages = this.allVoltages;
        }
    }


    removeDevice(identifier) {
        this.voltages = this.voltages.filter(_voltage => _voltage.id != identifier);
        this.allVoltages = this.allVoltages.filter(_voltage => _voltage.id != identifier);
    }
}