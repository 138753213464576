import { ManufacturerDTO } from './ManufacturerDTO';

export interface ISmcModelDTO {
    name: string;
    positionsCount: number;
    manufacturer: ManufacturerDTO;
}

export class SmcModelDTO implements ISmcModelDTO {
    public name: string;
    public positionsCount: number;
    public manufacturer: ManufacturerDTO;

    constructor() {
        this.manufacturer = new ManufacturerDTO();
    }
}
