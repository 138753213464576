import { Component, Input } from '@angular/core';
import { VoltageOut, VoltageOutDTO } from 'src/app/core/models/VoltageOutDTO';
import { FileService } from '../../services/file.service';
import { Utils } from '../../utils';
import * as XLS from 'xlsx';


export interface IStatus {
  code: number;
  message: string;
}

export interface VoltageOutAlarmResponseFile {
  dateTime: Date;
  voltageOut: VoltageOut;
  status: IStatus;
}


@Component({
  selector: 'app-voltage-out-alarm-response',
  templateUrl: './voltage-out-alarm-response.component.html',
  styleUrls: ['./voltage-out-alarm-response.component.scss']
})

export class VoltageOutAlarmResponseComponent {

  @Input() voltagesAlarms: VoltageOutAlarmResponseFile[] = [];

  Utils = Utils;

  VoltageOutDTO = VoltageOutDTO;

  constructor(private _fileServices: FileService) { }



  downloadAlarmes() {
    const rows = this.processFile(this.voltagesAlarms);
    const workSheet: XLS.WorkSheet = XLS.utils.json_to_sheet([...rows]);

    const workbook: XLS.WorkBook = {
      Sheets: {
        "Alarmes": workSheet,
      },
      SheetNames: ["Alarmes"],
    };
    const excelBuffer: any = XLS.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    this._fileServices.saveFileAsExcel(excelBuffer, `Resposta dos Alarmes Importados`);
  }



  private processFile(voltageOutResponse: VoltageOutAlarmResponseFile[]): any[] {
    let voltagesOut: any[] = [];

    voltageOutResponse.forEach((_voltage, index) => {
      const voltage = {
        'No': index,
        'Time': _voltage.dateTime,
        'Event Code': this.setVoltageString(_voltage.voltageOut),
      }
      voltagesOut.push(voltage);
    });

    return voltagesOut;
  }

  private setVoltageString(voltageOut: VoltageOut): string {
    switch (voltageOut) {
      case VoltageOut.LONG_END:
        return `Long voltage out end (109)`;
      case VoltageOut.LONG_START:
        return `Long voltage out end (108)`;
      case VoltageOut.SHORT_END:
        return 'Short voltage out end (107)';
      default:
        return 'Short voltage out end (106)';
    }
  }

}
