import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { NgForm } from "@angular/forms";
import * as XLS from "xlsx";

import { KeysDTO } from "src/app/core/models/KeysDTO";

import { Utils } from "src/app/shared/utils";
import { HandleErrorService } from "src/app/shared/services/handle-error.service";
import { MessageService } from "src/app/shared/services/message.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { KeysService } from "src/app/shared/services/keys.service";
import { saveAs } from "file-saver";
import { timeStamp } from "console";

@Component({
  selector: "app-keys-table",
  templateUrl: "./keys-table.component.html",
  styleUrls: ["./keys-table.component.scss"],
})
export class KeysTableComponent implements OnInit {
  @ViewChild("fileDownloadModal", { static: false })
  fileDownloadModal!: ElementRef;
  @Input()
  keys: KeysDTO[] = [];
  @Input() isLoading = true;

  @Output() filter = new EventEmitter();
  @Output() reload = new EventEmitter();
  public isEditing = false;
  public removeSufixAndPrefix = false;

  public file: any = null;
  public helpFile: any[] = [];
  public key: KeysDTO;
  public deleteKey: KeysDTO;

  public invalidKeys: KeysDTO[] = [];

  public fileURL = "./assets/models/modelo de cadastro das chaves.xlsx";
  Utils = Utils;
  private COLUMNS = ["serial", "ak", "ek", "mk"];
  public ticketId: string;

  constructor(
    private modalService: NgbModal,
    private keysService: KeysService,
    private messageService: MessageService,
    private handleService: HandleErrorService
  ) {}

  ngOnInit() {
    this.key = new KeysDTO();
  }

  finishOperation(form?: NgForm) {
    if (form) {
      form.reset();
    }
    this.key = new KeysDTO();
    this.modalService.dismissAll();
    this.isEditing = false;
  }

  async modalKey(modalContent, key?: KeysDTO) {
    if (this.isEditing && key) {
      await this.keysService
        .getKey(key)
        .toPromise()
        .then((response: KeysDTO) => {
          this.key = KeysDTO.resetKeys(response);
        })
        .catch((err) => {
          this.handleService.handle(err);
        });
    }
    this._openModal(modalContent);
  }

  createUpdate(form: NgForm) {
    this.isLoading = true;
    if (this.isEditing) {
      const keyToUpdate: KeysDTO = KeysDTO.convertKeysToHexCode(this.key);
      this.keysService
        .updateKey(keyToUpdate)
        .toPromise()
        .then((response) => {
          this.messageService.showSuccess(
            "Sucesso!",
            `Chave com o serial ${this.key.serial} modificado com sucesso!`
          );
          this.isLoading = false;
          this.modalService.dismissAll();
        })
        .catch((error) => {
          this.isLoading = false;
          this.handleService.handle(error);
        });
      return;
    }
    const keyToCreat: KeysDTO = KeysDTO.convertKeysToHexCode(this.key);
    this.keysService
      .createKey(keyToCreat)
      .toPromise()
      .then((resonse) => {
        this.messageService.showSuccess(
          "Sucesso",
          "Chave adicionado com sucesso"
        );
        this.isLoading = false;
        this.modalService.dismissAll();
      })
      .catch((error) => {
        this.isLoading = false;
        this.handleService.handle(error);
      });
  }

  public readFile(event) {
    const file: File = event.target.files[0];
    let fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);
    fileReader.onload = () => {
      const arrayBuffer: any = fileReader.result;
      const data = new Uint8Array(arrayBuffer);
      let array = new Array();

      for (let index = 0; index !== data.length; ++index) {
        array[index] = String.fromCharCode(data[index]);
      }
      const listOfKeys = array.join("");
      const workbook = XLS.read(listOfKeys, { type: "binary" });
      const fileName = workbook.SheetNames[0];
      const workSheet = workbook.Sheets[fileName];

      this.helpFile = [...XLS.utils.sheet_to_json(workSheet)];
      this.helpFile.forEach((key: KeysDTO) => {
        key = KeysDTO.convertKeysToHexCode(key);
      });
    };
  }

  async uploadFile() {
    this.isLoading = false;
    this.modalService.dismissAll();
    if (this.removeSufixAndPrefix) {
      this.helpFile.forEach((key: KeysDTO) => {
        key.serial = Utils.removePrefixAndSufix(key.serial);
      });
    }
    if (
      Object.keys(this.helpFile[0]).sort().join(",") ==
      this.COLUMNS.sort().join(",")
    ) {
      this.keysService
        .uploadFile(this.helpFile)
        .toPromise()
        .then((response: any) => {
          let json = JSON.parse(response.toString());
          if (json["TicketId"]) {
            this.messageService.showSuccess(
              "Sucesso",
              "Cadastro em massa concluído." +
                " Ticket de Resposta: " +
                json["TicketId"]
            );
          } else {
            response.forEach((key) => {
              if (key.saved) {
                this.messageService.showSuccess(
                  "Sucesso",
                  `Chave com serial ${key.serial} cadastrado!`
                );
              } else {
                this.messageService.showError(
                  "Erro",
                  `Chave com serial ${key.serial} não cadastrado`
                );
              }
            });
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.handleService.handle(error);
        });
    } else {
      this.isLoading = false;
      this.handleService.handle(
        'As colunas estão incorretas, por favor, siga o modelo. ("Baixar modelo")'
      );
    }
  }

  private _openModal(modalContent) {
    this.modalService.open(modalContent, { centered: true, backdrop: true });
  }

  filterBySerial(serial: string) {
    this.filter.emit(serial);
  }

  realoadEvent() {
    this.reload.emit();
  }
}
