import { Component, Input, Output, EventEmitter } from "@angular/core";
import { debounce } from "lodash";

@Component({
  selector: "app-search-bar",
  templateUrl: "./search-bar.component.html",
  styleUrls: ["./search-bar.component.scss"],
})
export class SearchBarComponent {
  @Input() placeholder: string;
  @Input() isLoading = true;

  @Output() searchString = new EventEmitter();

  public filter: string;

  constructor() {
    this.changeString = debounce(this.changeString, 1000);
  }

  changeString(value) {
    this.emitValue(this.filter);
  }

  private emitValue(value) {
    this.searchString.emit(value);
  }
}
