import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges } from '@angular/core';

import { ITransformerGroupChange, ITransformGroupChange } from 'src/app/core/models/RegionGroupDTO';
import { ITransformerGroup } from './../../../core/models/TransformerGroupDTO';

@Component({
  selector: 'app-region-areas-table',
  templateUrl: './region-areas-table.component.html',
  styleUrls: ['./region-areas-table.component.scss']
})
export class RegionAreasTableComponent implements OnChanges {
  @Input() isLoading: boolean = true;
  @Input() currentAreas: ITransformerGroup[] = [];
  @Input() avalaibleAreas: ITransformerGroup[] = [];
  @Input() isEditing: boolean = false;

  @Output() relaod: EventEmitter<void> = new EventEmitter();
  @Output() exportAreas: EventEmitter<ITransformerGroupChange> = new EventEmitter();

  public listConfig: ITransformerGroupChange = new ITransformGroupChange();

  ngOnChanges(changes: SimpleChanges): void {
    this.currentAreas.forEach((_area) => {
      this.avalaibleAreas.forEach((avalaible, index) => {
        if (avalaible.groupId === _area.groupId) {
          this.avalaibleAreas.splice(index, 1)
        }
      })
    })
    this.avalaibleAreas = [...this.avalaibleAreas];
  }


  drop(event: CdkDragDrop<ITransformerGroup[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }

  saveChanges() {
    this.listConfig.add = this.currentAreas;
    this.listConfig.remove = this.avalaibleAreas;
    this.exportAreas.emit(this.listConfig);
  }


}
