import { SmcModelDTO } from "./SmcModelDTO";

export interface ISMCNotRegistered {
    serial: string;
    deviceEui: string;
    qtyMeters: number;
    updatedAt: Date;
    smcModel: SmcModelDTO;
    latitude: number;
    longitude: number;
}

export class SMCNotRegisteredDTO implements ISMCNotRegistered {
    public serial: string;
    public deviceEui: string;
    public qtyMeters: number;
    public updatedAt: Date;
    public smcModel: SmcModelDTO;
    public latitude: number;
    public longitude: number;


    constructor() {
        this.smcModel = new SmcModelDTO();
        this.latitude = 0;
        this.longitude = 0;
    }
}
