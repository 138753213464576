import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ITransformerSerialType, TransformerSerialTypeDTO } from 'src/app/core/models/BalanceDTO';
import { ITransformerDTO } from 'src/app/core/models/TransformerDTO';

@Component({
  selector: 'app-area-transformers-table',
  templateUrl: './area-transformers-table.component.html',
  styleUrls: ['./area-transformers-table.component.scss']
})
export class AreaTransformersTableComponent implements OnChanges {
  @Input() currentTransformers: ITransformerDTO[] = [];
  @Input() avalaibleTransformers: ITransformerDTO[] = [];
  @Input() isLoading: boolean = true;
  @Input() isEditing: boolean = false;


  @Output() reload = new EventEmitter();
  @Output() exportCheckedTransformers: EventEmitter<any> = new EventEmitter();


  public listConfig = {
    add: null,
    remove: null,
  }

  checkedTransformers: ITransformerSerialType[] = [];
  TransformerDeviceType = TransformerSerialTypeDTO;

  ngOnChanges(changes: SimpleChanges): void {
    this.currentTransformers.forEach((_transformer) => {
      this.avalaibleTransformers.forEach((avalaible, index) => {
        if (avalaible.transformerId === _transformer.transformerId) {
          this.avalaibleTransformers.splice(index, 1)
        }
      })
    })
    this.avalaibleTransformers = [...this.avalaibleTransformers];
  }

  realoadEvent() {
    this.reload.emit();
  }


  drop(event: CdkDragDrop<ITransformerDTO[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }

  saveChanges() {
    this.listConfig.add = this.currentTransformers;
    this.listConfig.remove = this.avalaibleTransformers;
    this.exportCheckedTransformers.emit(this.listConfig);
  }


}
