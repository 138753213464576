import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "src/environments/environment";
import { Utils } from "../../utils";


@Component({
    selector: 'app-change-language',
    styleUrls: ['./change-language.component.scss'],
    templateUrl: './change-language.component.html'
})
export class ChangeLanguageComponent implements OnInit {
    public suportedLanguage = environment.suportedLanguage;
    public currentLangue = Utils.getLang();
    public currentLangObj = null;
    constructor(private _translate: TranslateService) {}

    ngOnInit(): void {
        this.getCurrentLangObj();
    }

    changeLanguage(lang) {
        this.currentLangue = lang
        this._translate.use(this.currentLangue);
        localStorage.setItem(environment.currentLanguage, this.currentLangue);
        this.getCurrentLangObj();
    }

    getCurrentLangObj() {
        this.currentLangObj = this.suportedLanguage.filter(lang => this.currentLangue == lang.value)[0];
    }
}