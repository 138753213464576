import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { EnumMapperDTO } from 'src/app/core/models/EnumMapperDTO';
import { ModemDTO, SignalQuality } from 'src/app/core/models/ModemDTO';
import { TranslateInCodeService } from '../../services/translate-in-code.service';

@Component({
  selector: 'app-signal-info',
  templateUrl: './signal-info.component.html',
  styleUrls: ['./signal-info.component.scss']
})
export class SignalInfoComponent implements OnInit {

  @Input() fillColor: string = '#fff';
  @Input() isFilter: boolean = false;

  @Output() sendFilter: EventEmitter<number> = new EventEmitter<number>();
  selectedSignal: number = null;

  public siganlStrength = [0, 1, 2, 3, 4, 5];
  SignalEnumMapper: EnumMapperDTO;

  constructor(
    private _modalService: NgbModal,
    private _translateinCodeService: TranslateInCodeService,
    private _translateServices: TranslateService,
  ) {

  }
  async ngOnInit() {
    await this.getTranslate();
    this._translateServices.onLangChange.subscribe(async () => await this.getTranslate());
  }

  async getTranslate() {
    this.SignalEnumMapper = await this._translateinCodeService.getTranslate('ModemInfo.SignalQuality', SignalQuality);
  }


  openModal(content: any) {
    this._modalService.open(content, {
      centered: true,
      backdrop: 'static',
      keyboard: false,
    })
  }

  closeModal() {
    this._modalService.dismissAll();
  }

  applyFilter() {
    this.sendFilter.emit(this.selectedSignal);
    this.closeModal();
  }

  clearFilter() {
    this.selectedSignal = null;
    this.sendFilter.emit(this.selectedSignal);
    this.closeModal();
  }

}
