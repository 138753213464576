import { DLMS_INSTANCES } from './../enums';
import { utf8Encode } from '@angular/compiler/src/util';
import { copyRange, DLMS_DATAS_TYPES } from '../enums';
import { IDlmsData } from '../IDlmsData';

 class Uint8 implements IDlmsData {
    tag: number = DLMS_DATAS_TYPES.UINT_8;
    totalBytes: number;
    rawValue: number[];
    bodyBytes: number[];
    value: number;

    constructor(data?: number[], value?: number) {
        if (data && data !== null) {
            if (this.tag !== data[0])
                throw Error('Dado não corresponde ao tipo Uint8');
            this.bodyBytes = [data[1]];
            this.value = data[1];
            this.rawValue = new Array<number>(2);
            this.rawValue =
                copyRange(this.rawValue, 0, data, 0, this.rawValue.length);
            this.totalBytes = this.rawValue.length;
        }

        if (value && value !== null) {
            this.totalBytes = 2;
            this.value = value;
            this.bodyBytes = [parseInt(utf8Encode(value.toString()))];
            this.rawValue = new Array<number>(this.totalBytes);
            this.rawValue[0] = this.tag;
            this.rawValue[1] = value;
        }

    }
    getInstance(): string {
        return DLMS_INSTANCES.uint8;
    }

    getValue(): number {
        return this.value;
    }
    insertData(data: number[]): void {
        if (this.tag !== data[0])
            throw Error('Dado não corresponde ao tipo Uint8');
        this.bodyBytes = [data[1]];
        this.value = data[1];
        this.rawValue = new Array<number>(2);
        this.rawValue =
            copyRange(this.rawValue, 0, data, 0, this.rawValue.length);
        this.totalBytes = this.rawValue.length;
    }

    insertDataValue(value: number) {
        this.totalBytes = 2;
        this.value = value;
        this.bodyBytes = [parseInt(utf8Encode(value.toString()))];
        this.rawValue = new Array<number>(this.totalBytes);
        this.rawValue[0] = this.tag;
        this.rawValue[1] = value;
    }

}
export default Uint8;