import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as XLS from "xlsx";

// Models
import { TagsDTO } from "src/app/core/models/TagsTDO";
import { Utils } from "src/app/shared/utils";

// Services
import { HandleErrorService } from "src/app/shared/services/handle-error.service";
import { MessageService } from "src/app/shared/services/message.service";
import { TagsServices } from "src/app/shared/services/tags.service";
import { UserType } from "src/app/core/models/UserDTO";
import { AuthService } from "src/app/security/auth.service";
import { TranslateService } from "@ngx-translate/core";


export interface ITagsMessages {
  DeleteTitle: string;
  DeleteMessage: string;
  CreatedTitle: string;
  CreatedMessage: string;
  EditedTitle: string;
  EditedMessage: string;
}

@Component({
  selector: "app-tags-table",
  templateUrl: "./tags-table.component.html",
  styleUrls: ["./tags-table.component.scss"],
})
export class TagsTableComponent implements OnInit {
  @Input() tags: TagsDTO[] = [];
  @Input() isLoading = true;

  @Output() searchTag = new EventEmitter();
  @Output() relaodTags = new EventEmitter();

  public canCreatTicket = [UserType.ADMIN, UserType.OPERATOR];

  Utils = Utils;
  modalRef: any = null;
  selectedTag: TagsDTO = new TagsDTO();
  public isDeleting = false;
  public isEditing = false;
  public isLoadingData = false;
  public file: any[];
  excelFile: any;
  messagesTranslateds: ITagsMessages;

  tagFileModelURL = "./assets/models/Modelo de Cadastro de Tags.xlsx";

  constructor(
    private modalService: NgbModal,
    private tagServices: TagsServices,
    private errorHandlerService: HandleErrorService,
    private messageService: MessageService,
    public authService: AuthService,
    private _translateInCode: TranslateService,
  ) {

  }

  filterTagsByName(tagName: string): void {
    this.searchTag.emit(tagName.toLowerCase());
  }

  async ngOnInit() {
    await this.getTagsTranslates();
  }

  async getTagsTranslates() {
    await this._translateInCode.get('TagsTable.Messages').subscribe(response => {
      this.messagesTranslateds = response;
    });
  }

  refresh() {
    this.relaodTags.emit();
  }

  openModal(content, tag?: TagsDTO) {
    if (tag) {
      this.selectedTag = tag;
      this.isEditing = true;
    }
    this.modalService.open(content, { centered: true });
  }

  deleteTag() {
    this.isDeleting = true;
    this.tagServices
      .deleteTag(this.selectedTag)
      .toPromise()
      .then(() => {
        this.messageService.showSuccess(
          this.messagesTranslateds.DeleteTitle,
          `${this.selectedTag.keyword} ${this.messagesTranslateds.DeleteMessage}!`
        );
        this.isDeleting = false;
        this.close();
        this.refresh();
      })
      .catch((error) => {
        this.errorHandlerService.handle(error);
        this.isDeleting = false;
      });
  }

  updateTag() {
    this.isLoadingData = true;
    this.tagServices
      .updateTag(this.selectedTag)
      .toPromise()
      .then((response) => {
        this.messageService.showSuccess(
          this.messagesTranslateds.EditedTitle,
          `${this.selectedTag.keyword} ${this.messagesTranslateds.EditedMessage}!`
        );
        this.isLoadingData = false;
        this.isEditing = false;
        this.close();
        this.refresh();
      })
      .catch((err) => {
        this.errorHandlerService.handle(err);
        this.isLoadingData = false;
      });
  }

  createTag() {
    this.isLoadingData = true;
    this.tagServices
      .addTag(this.selectedTag)
      .toPromise()
      .then((response: TagsDTO) => {
        this.messageService.showSuccess(
          this.messagesTranslateds.CreatedTitle,
          `${response.keyword} ${this.messagesTranslateds.CreatedMessage}`
        );
        this.isLoadingData = false;
        this.refresh();
        this.close();
      })
      .catch((err) => {
        this.errorHandlerService.handle(err);
        this.isLoadingData = false;
      });
  }

  close() {
    this.modalService.dismissAll();
    this.selectedTag = new TagsDTO();
    this.isEditing = false;
  }

  public convertExcelToJsonArray(event: any) {
    const file: File = event.target.files[0];
    let fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);
    fileReader.onload = () => {
      const arrayBuffer: any = fileReader.result;
      const data = new Uint8Array(arrayBuffer);
      let array = new Array();

      for (let index = 0; index !== data.length; ++index) {
        array[index] = String.fromCharCode(data[index]);
      }
      const listOfKeys = array.join("");
      const workbook = XLS.read(listOfKeys, { type: "binary" });
      const fileName = workbook.SheetNames[0];
      const workSheet = workbook.Sheets[fileName];
      this.file = [...XLS.utils.sheet_to_json(workSheet)];
    };
  }

  cadastrarTags() {
    this.isLoadingData = true;
    this.file.forEach((tag: TagsDTO) => {
      this.tagServices
        .addTag(tag)
        .toPromise()
        .then((response: TagsDTO) => {
          this.messageService.showSuccess(
            this.messagesTranslateds.CreatedTitle,
            `${response.keyword} ${this.messagesTranslateds.CreatedMessage}`
          );
          this.isLoadingData = false;
          this.close();
          this.refresh();
        })
        .catch((err) => {
          this.isLoadingData = false;
          this.errorHandlerService.handle(err);
        });
    });
  }

  updateTags() {
    this.isLoadingData = true;
    delete this.selectedTag.meters;
    this.tagServices
      .updateTag(this.selectedTag)
      .toPromise()
      .then((response: TagsDTO) => {
        this.messageService.showSuccess(
          this.messagesTranslateds.EditedTitle,
          `${response.keyword} ${this.messagesTranslateds.EditedMessage}`
        );
        this.isLoadingData = false;
        this.close();
        this.refresh();
      })
      .catch((error) => {
        this.isLoadingData = false;
        this.errorHandlerService.handle(error);
      });
  }
}
