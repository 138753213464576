import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CELLTABLECOLORS, HistoricReadingQuantityDTO, INumberOfReadings, ORDER } from "src/app/core/models/HistoricReadingQuantityDTO";
import { PaginationResponseDTO } from "src/app/core/models/PaginationResponseDTO";
import { HandleErrorService } from "../../services/handle-error.service";
import { HistoricReadingService } from "../../services/historic-reading.service";
import { Utils } from "../../utils";
@Component({
    selector: 'app-reading-table',
    templateUrl: './reading-table.component.html',
    styleUrls: ['./reading-table.component.scss']
})
export class ReadingTableComponent implements OnInit, OnChanges {
    public isLoadingTable = true;
    public today = new Date().toISOString().substring(0, 10);

    public initialDate: Date;
    public finalDate: Date;
    public lastInitialDate: string = null;
    public lastFinalDate: string = null;
    public dateFilterHasBeenApp: boolean = false;

    public isLoadingReading: boolean = false;
    public placeholder = 'Pesquise pelo serial do Medidor ou SMC...'

    public datesCount: number;
    public dates: Array<Date> = [];

    public Utils = Utils;

    public searchString = '';
    public sortbyDateTime = '';
    public sortOrder: ORDER = ORDER.ASC;
    public ORDER = ORDER;

    public CELLTABLECOLORS = CELLTABLECOLORS;

    public page = new PaginationResponseDTO();
    public datas: INumberOfReadings[] = [];
    public quantityPerPage = 15;
    public quantityPerPageAuth = [10, 15, 20, 50];
    @Output() table = new EventEmitter<any>();
    @Input() filters: any = {
        comissioned: true
    };
    constructor(
        private modal: NgbModal,
        private historicReadingService: HistoricReadingService,
        private errorHandle: HandleErrorService,
    ) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.filters && !changes.filters.firstChange) {
            this.quantityPerPage = 15;
            this.getHistoricData();
        }
    }

    ngOnInit(): void {
        this.createInitialDate();
        this.configureDate();
        this.getHistoricData();
    }

    openMeterInfo(serial) {
        window.open('/#/medidores/info/' + serial);
    }

    getHistoricData() {
        this.isLoadingTable = true;
        this.datas = [];
        this.emitData();
        this
            .historicReadingService
            .quantityReadingsByDay({
                startDateTime: this.lastInitialDate,
                endDateTime: this.lastFinalDate,
                serial: this.searchString,
                sortbyDateTime: this.sortbyDateTime,
                sortOrder: this.sortOrder,
                page: this.page.page === 0 ? 1 : this.page.page,
                comissionedFilter: this.filters.comissioned,
                online: this.filters.status,
                isSmi: this.filters.typeFilter,
                qtyMeasures: HistoricReadingQuantityDTO.getQtyMeasures(this.filters.readingFilter),
                tags: this.filters.tags && this.filters.tags.map(tag => tag.visibleId),
                pageSize: this.quantityPerPage
            })
            .toPromise()
            .then(res => {
                this.datas = res.listOfItems;
                Object.assign(this.page, res);
                this.setReadingInColumn();
                this.emitData();
                this.isLoadingTable = false;
            }).catch(error => {
                this.errorHandle.handle(error);
                this.isLoadingTable = false;
            });
    }

    openModal(content) {
        this.modal.open(content, {
            centered: true
        });
    }

    closeModal() {
        this.modal.dismissAll();
    }

    emitData() {
        this.table.emit({ dates: this.dates, datas: this.datas });
    }

    refreshTable() {
        this.getHistoricData();
    }

    perPage(value) {
        this.quantityPerPage = value;
        this.getHistoricData();
    }

    searchBySerial(event) {
        this.searchString = event;
        this.getHistoricData();
    }

    byDate() {
        const startDate = new Date(this.lastInitialDate);
        const endDate = new Date(this.lastFinalDate);
        this.initialDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
        this.finalDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() + 1);
        this.configureDate();
        this.getHistoricData();
        this.closeModal();
    }

    sortBy(date: Date) {
        this.sortbyDateTime = this.Utils.getDateByFilter(date);
        this.sortOrder == ORDER.ASC ? this.sortOrder = ORDER.DESC : this.sortOrder = ORDER.ASC;
        this.getHistoricData();
    }

    pagination(event) {
        this.page.page = event;
        this.getHistoricData();
    }

    createInitialDate() {
        this.finalDate = new Date();
        this.initialDate = new Date(this.finalDate.getFullYear(), this.finalDate.getMonth(), this.finalDate.getDate() - 10);
    }

    configureDate() {
        this.dates = [];
        this.datesCount = Math.floor((this.finalDate.getTime() - this.initialDate.getTime()) / (1000 * 3600 * 24));
        for (let count = 0; count < this.datesCount; count++) {
            this.dates.push(new Date(this.initialDate.getFullYear(), this.initialDate.getMonth(), this.initialDate.getDate() + count + 1))
        }
        this.dates.reverse();
    }

    cleanFilters() {
        this.searchString = '';
        this.sortbyDateTime = '';
        this.lastFinalDate = '';
        this.lastInitialDate = '';
        this.sortOrder = this.ORDER.ASC;
        this.createInitialDate();
        this.configureDate();
        this.getHistoricData();
        this.closeModal();
    }

    setReadingInColumn() {
        const datesCount = this.dates.length;
        this.datas.forEach((data) => {
            const readings = new Array(datesCount).fill(null);
            data.readingStatisticDtos.forEach((reading) => {
                const index = this.dates.findIndex((date, index) => this.Utils.getDate(this.Utils.getDateByFilter(reading.dateTime)) == this.Utils.getDate(date));
                readings.splice(index, 1, reading);
            });
            data.readingStatisticDtos = readings;
        });
    }

    setColor(value: number) {
        if (value == null || value == undefined) return;
        if (value < 1) return CELLTABLECOLORS.ZERO;
        if (value > 0 && value < 4) return CELLTABLECOLORS.OneThree;
        if (value > 3) return CELLTABLECOLORS.GreaterThanThree;
    }
}