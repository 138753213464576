export interface IRateTypeDTO {
  name: string;
  updatedAt: string;
}

export class RateTypeDTO implements IRateTypeDTO {
  public name: string;
  public updatedAt: string;

  static getRateTypeByName(
    name: string,
    ratesType: RateTypeDTO[]
  ): RateTypeDTO {
    const _rate: RateTypeDTO = ratesType.find(
      (rateType) => rateType.name === name
    );
    return _rate === undefined ? this.createNewRate(name) : _rate;
  }

  static createNewRate(rateName: string): RateTypeDTO {
    let rate: RateTypeDTO = new RateTypeDTO();
    rate.name = rateName;
    return rate;
  }
}
