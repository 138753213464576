import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';


import { TagsDTO } from '../../core/models/TagsTDO';
import { MeterDTO } from 'src/app/core/models/MeterDTO';

import { IogHttpService } from 'src/app/security/iog-http.service';
import { environment } from 'src/environments/environment';
@Injectable({
    providedIn: 'root'
})
export class TagsServices {
    headers = new Headers();

    static url = environment.apiUrlBack + '/tags';
    static meterUrl = environment.apiUrlBack + '/meters';


    constructor(private http: IogHttpService) {
    }

    getTags() {
        return this.http.get(TagsServices.url).map(tags => tags.valueOf());
    }

    deleteTag(tag: TagsDTO) {
        return this.http.delete(TagsServices.url + `/${tag.visibleId}`);
    }

    addTag(Tag: TagsDTO) {
        return this.http.post(TagsServices.url, Tag).map(response => response.valueOf());
    }

    updateTag(Tag: TagsDTO) {
        return this.http.put(TagsServices.url, Tag);
    }

    updateMeter(meter: MeterDTO) {
        return this.http.put(TagsServices.meterUrl, meter);
    }

    getMetersByTags(tags: TagsDTO[]) {
        return this.http.get(`${TagsServices.url}/meters?${this.buildQuery(tags)}`).map(response => response.valueOf());
    }

    private buildQuery(tags: TagsDTO[]): string {
        let queryString = 'id=';
        tags.forEach((tag, index) => {
            queryString += `${tag.visibleId}&id=`;
        });
        return queryString;
    }

}
