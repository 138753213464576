import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { HandleErrorService } from 'src/app/shared/services/handle-error.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { CommandsService } from 'src/app/shared/services/commands.service';
import { UserType } from 'src/app/core/models/UserDTO';
import { AuthService } from 'src/app/security/auth.service';

@Component({
    selector: 'app-requestdata',
    templateUrl: './request-data.component.html',
    styleUrls: ['./request-data.component.scss']
})

export class RequestDataComponent {

    @Input() meterSerial: string;
    public isSending = false;
    public canUpdateDateAndTime = [UserType.ADMIN];

    modalRef: any = null;

    constructor(
        private modalService: NgbModal,
        private messageService: MessageService,
        private commandService: CommandsService,
        private handleService: HandleErrorService,
        public authService: AuthService,
    ) { }


    openConfirmModal(modalContent) {
        this.modalRef = this.modalService.open(modalContent, { centered: true });

    }

    closeConfirm() {
        this.modalRef.close();
    }

    sendCommand() {
        this.isSending = true;
        this.commandService.updateDateTime(this.meterSerial).toPromise()
            .then(response => {
                this.isSending = false;
                this.messageService.showSuccess('Sucesso!', 'Requisição de data e hora enviada!');
            })
            .catch(error => {
                this.handleService.handle(error);
                this.isSending = false;
            });
        this.closeConfirm();
    }

}
