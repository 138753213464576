import { copyRange, DLMS_DATAS_TYPES, DLMS_INSTANCES } from '../enums';
import { IDlmsData } from '../IDlmsData';

class OctectString implements IDlmsData {
    tag: number = DLMS_DATAS_TYPES.OCTECT_STRING;
    totalBytes: number;
    rawValue: number[];
    bodyBytes: number[];
    value: string = '';
    length: number;

    constructor(data?: number[]) {
        if (data) {
            if (this.tag !== data[0])
                throw Error('O tipo do dado não corresponde ao Octeto String');

            let size: number = data[1];
            if (size < 0x80) {
                this.length = data[1];
                size = 0;
            } else {
                size = data[1] - 0x80;
                let arrayOfSizes = new Array<number>(size);
                arrayOfSizes =
                    copyRange(arrayOfSizes, 0, data, 2, size);
                this.length = parseInt(arrayOfSizes.toString(), 16);
            }
            this.bodyBytes = new Array<number>(this.length);
            this.bodyBytes =
                copyRange(this.bodyBytes, 0, data, 2 + size, this.bodyBytes.length + (2 + size));
            this.bodyBytes.forEach(_byte => this.value += parseInt(_byte.toString(), 16).toString().padStart(2, '0').toUpperCase())
            this.rawValue = new Array<number>(this.length + 1 + 1);
            this.rawValue =
                copyRange(this.rawValue, 0, data, 0, this.rawValue.length);

            this.totalBytes = this.rawValue.length;
        }
    }
    getInstance(): string {
        return DLMS_INSTANCES.octectString;
    }

    stringData(data: string): void {
        this.value = data;
        this.length = data.length % 2;
        this.bodyBytes = new Array<number>(this.length);
        for (let index = 0; index < data.length; index++)
            if (data.charCodeAt(index) % 2 === 0)
                this.bodyBytes.push(data.charCodeAt(index));


    }

    getValue() {
        return this.value;
    }
    insertData(data: number[]): void {

        if (this.tag !== data[0])
            throw Error('O tipo do dado não corresponde ao Octeto String');

        let size: number = data[1];
        if (size < 0x80) {
            this.length = data[1];
            size = 0;
        } else {
            size = data[1] - 0x80;
            let arrayOfSizes = new Array<number>(size);
            arrayOfSizes =
                copyRange(arrayOfSizes, 0, data, 2, size);
            this.length = parseInt(arrayOfSizes.toString(), 16);
        }
        this.bodyBytes = new Array<number>(this.length);
        this.bodyBytes =
            copyRange(this.bodyBytes, 0, data, 2 + size, this.bodyBytes.length + (2 + size));
        this.bodyBytes.forEach(_byte => this.value += _byte.toString(16).padStart(2, '0').toUpperCase());
        this.rawValue = new Array<number>(this.length + 2 + size);
        this.rawValue =
            copyRange(this.rawValue, 0, data, 0, this.rawValue.length);
        this.totalBytes = this.rawValue.length;
    }

}

export default OctectString;