import { Component, Input } from '@angular/core';

import { Utils } from 'src/app/shared/utils';
import { ITransformerDTO } from './../../../core/models/TransformerDTO';

@Component({
  selector: 'app-transformer-info-card',
  templateUrl: './transformer-info.component.html',
  styleUrls: ['./transformer-info.component.scss']
})
export class TransformerInfoComponent {

  @Input() transformer: ITransformerDTO = null;
  @Input() isLoading: boolean = true;


  Utils = Utils;


}
