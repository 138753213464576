import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import cronstrue from 'cronstrue/i18n';
import { Utils } from '../../utils';
export enum TabID {
    MINUTES = 'ngb-tab-0',
    HOURS = 'ngb-tab-1',
    DAYS = 'ngb-tab-2',
    WEEK = 'ngb-tab-3',
    MONTH = 'ngb-tab-4',
    Year = 'ngb-tab-5'
}

@Component({
    selector: 'app-cron-editor',
    styleUrls: ['./cron-editor.component.scss'],
    templateUrl: './cron-editor.component.html'
})
export class CronEditorComponent implements OnChanges, AfterViewInit {

    @ViewChild('editor', { static: false }) tabEditor;
    TabID = TabID;

    minutesTab = new Array(60).fill('').map((p, i) => i);
    secondsTab = new Array(60).fill('').map((p, i) => i);
    hoursTab = new Array(24).fill('').map((p, i) => i);
    daysTab = new Array(31).fill('').map((p, i) => i + 1);
    weeksTab = [
        { key: 'MON', label: 'CronEditor.Week.Monday' },
        { key: 'TUE', label: 'CronEditor.Week.Tuesday' },
        { key: 'WED', label: 'CronEditor.Week.Wednesday' },
        { key: 'THU', label: 'CronEditor.Week.Thursday' },
        { key: 'FRI', label: 'CronEditor.Week.Friday' },
        { key: 'SAT', label: 'CronEditor.Week.Saturday' },
        { key: 'SUN', label: 'CronEditor.Week.Sunday' }
    ];
    weeksNumericTab = [
        { key: 1, label: 'CronEditor.Month.First' },
        { key: 2, label: 'CronEditor.Month.Second' },
        { key: 3, label: 'CronEditor.Month.Third' },
        { key: 4, label: 'CronEditor.Month.Fourth' },
        { key: 5, label: 'CronEditor.Month.Fifth' },
        { key: 'L', label: 'CronEditor.Month.Last' },
    ];
    monthsTab = new Array(12).fill('').map((p, i) => i + 1);
    monthsLabel = [
        { key: 1, label: 'CronEditor.Year.January' },
        { key: 2, label: 'CronEditor.Year.February' },
        { key: 3, label: 'CronEditor.Year.March' },
        { key: 4, label: 'CronEditor.Year.April' },
        { key: 5, label: 'CronEditor.Year.May' },
        { key: 6, label: 'CronEditor.Year.June' },
        { key: 7, label: 'CronEditor.Year.July' },
        { key: 8, label: 'CronEditor.Year.August' },
        { key: 9, label: 'CronEditor.Year.September' },
        { key: 10, label: 'CronEditor.Year.October' },
        { key: 11, label: 'CronEditor.Year.November' },
        { key: 12, label: 'CronEditor.Year.December' },
    ]

    activeTab = TabID.MINUTES;

    isWorkingDay = false;
    isEveryMonth = true;
    isEveryYear = true;

    minutes = 0;
    seconds = 0;
    hour = 0;
    day = 1;
    weeks = [];
    week = 'MON';
    weekNumeric = 1;
    month = 1;
    monthLabel = 1;
    @Input() cron: string = '';
    lastCron: string = '';
    @Output() getCronString = new EventEmitter();

    changes = 0;
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.cron && this.changes < 2) {
            this.lastCron = this.cron;
            this.selectTabByCron();
            this.changes++;
        }
    }

    ngAfterViewInit(): void {
        this.selectTabByCron();
    }

    changeMinutes(event) {
        this.minutes = event;
    }

    changeSeconds(event) {
        this.seconds = event;
    }

    changeHours(event) {
        this.hour = event;
    }

    changeDays(event) {
        this.day = event;
    }

    send() {
        this.getCronString.emit(this.cron);
    }

    addWeek(week) {
        const exists = this.weeks.filter(w => w === week.key).length;
        if (exists) {
            this.weeks = this.weeks.filter(w => w !== week.key)
        } else {
            this.weeks.push(week.key);
        }
        this.formatCronString();
    }

    changeTab(event) {
        this.activeTab = event.nextId;
        this.formatCronString();
    }

    isWorkingDays(event) {
        this.isWorkingDay = event;
        this.formatCronString();
    }

    isEveryMonths(event) {
        this.isEveryMonth = event;
        this.formatCronString()
    }

    isEveryYears(event) {
        this.isEveryYear = event;
        this.formatCronString();
    }

    getCron(value) {
        if (value) {
            let lang = Utils.getLang()
            lang = lang == 'pt-BR' ? lang.replace('-', '_') : lang;
            return cronstrue.toString(value, { locale: lang });
        }
    }

    selectTabByCron() {
        try {
            const [seconds, minutes, hours, day, month, year] = this.lastCron.split(' ');
            if (minutes.match(/^0\//) && hours.match(/^(0\/)|\*/)) {
                this.tabEditor.select(TabID.MINUTES);
            } else if (hours.match(/^0\//) && day.match('1/1')) {
                this.tabEditor.select(TabID.HOURS);
            } else if ((day.match(/^(1\/[1-31])/) || year.match('MON-FRI')) && minutes.match(/[0-59]/)) {
                this.tabEditor.select(TabID.DAYS);
            } else if (year.match(/^[A-Z]+/)) {
                this.tabEditor.select(TabID.WEEK);
            } else if (month.match(/^([1-12]\/[1-12])/)) {
                this.tabEditor.select(TabID.MONTH);
            } else {
                this.tabEditor.select(TabID.Year);
            }
        } catch (error) {
        }
    }

    formatCronString() {
        switch (this.activeTab) {
            case TabID.MINUTES:
                this.cron = `${this.seconds} 0/${this.minutes} * 1/1 * ?`;
                this.send();
                break;
            case TabID.HOURS:
                this.cron = `${this.seconds} ${this.minutes} 0/${this.hour} 1/1 * ?`;
                this.send();
                break;
            case TabID.DAYS:
                this.cron = !this.isWorkingDay
                    ? `${this.seconds} ${this.minutes} ${this.hour} 1/${this.day} * ?`
                    : `${this.seconds} ${this.minutes} ${this.hour} ? * MON-FRI`;
                this.send();
                break;
            case TabID.WEEK:
                this.cron = `${this.seconds} ${this.minutes} ${this.hour} ? * ${this.weeks.length ? this.weeks.join(',') : '*'}`;
                this.send();
                break;
            case TabID.MONTH:
                this.cron = this.isEveryMonth
                    ? `${this.seconds} ${this.minutes} ${this.hour} ${this.day} 1/${this.month} ?`
                    : `${this.seconds} ${this.minutes} ${this.hour} ? ${this.monthLabel}/${this.month} ${this.week}${Number.isInteger(Number(this.weekNumeric)) ? '#' + `${this.weekNumeric}` : this.weekNumeric}`;
                this.send();
                break;
            case TabID.Year:
                this.cron = this.isEveryYear
                    ? `${this.seconds} ${this.minutes} ${this.hour} ${this.day} ${this.monthLabel} ?`
                    : `${this.seconds} ${this.minutes} ${this.hour} ? ${this.monthLabel} ${this.week}${Number.isInteger(Number(this.weekNumeric)) ? '#' + `${this.weekNumeric}` : this.weekNumeric}`;
                this.send();
                break;
        }
    }
}