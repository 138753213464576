import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CELLTABLECOLORS } from "src/app/core/models/HistoricReadingQuantityDTO";
import { TagsDTO } from "src/app/core/models/TagsTDO";
import { HandleErrorService } from "../../services/handle-error.service";
import { TagsServices } from "../../services/tags.service";

@Component({
    selector: 'app-dashboard-filter',
    templateUrl: "./dashboard-filter.component.html",
    styleUrls: ["./dashboard-filter.component.scss"],
})
export class DashBOardFilterComponent implements OnInit {
    public tags: TagsDTO[];
    public filteredTags: TagsDTO[] = [];
    public filteredTagsSelect: TagsDTO[] = [];


    public tagFilter: string = '';

    public typeFilter = {
        isSelected: false,
        isSMI: false
    }

    public statusFilter = {
        isSelected: false,
        isOnline: false,
    }

    public readingFilter = {
        isSelected: false,
        reading: '0'
    }

    public comissionedFilter = {
        isSelected: true,
        comissioned: true
    }

    public isLoadingTags: boolean = true;
    public filterHasBeenApplied: boolean = false;

    @Output() filters = new EventEmitter();
    @Output() cleanFilterSend = new EventEmitter();

    @Input() isLoading = false;
    constructor(
        private tagsSerivce: TagsServices, 
        private erroHandler: HandleErrorService,
        private modal: NgbModal
    ) {}

    ngOnInit(): void {
        this.getTags();
    }

    openModal(content) {
        this.modal.open(content, {
            centered: true
        });
    } 

    closeModal() {
        this.modal.dismissAll();
    }

    getFilters() {
        return {
            tags: this.filteredTags,
            status: this.statusFilter.isSelected ? this.statusFilter.isOnline : null,
            readingFilter: this.readingFilter.isSelected ? this.readingFilter.reading : null,
            typeFilter: this.typeFilter.isSelected ? this.typeFilter.isSMI : null,
            comissioned: this.comissionedFilter.comissioned
        }
    }

    addFilter() {
        this.filterHasBeenApplied = true;
        this.filters.emit({
            files: this.mountFilter(), 
            all: this.getFilters()
        });
    }

    mountFilter() {
        return {
            filterHasBeenApplied: this.filterHasBeenApplied,
            tags: this.moutTagsFilter(),
            status: this.moutStatusFilter(),
            readingFilter: this.mountReadingFilter(),
            typeFilter: this.moutTypeFilter(),
            comissioned: this.comissionedFilter.comissioned
        }
    }

    moutTagsFilter() {
        if(this.filteredTags.length) {
            return [].concat(this.filteredTags.map(tag => tag.keyword)).join().replace(/\,/g, '; ')
        }else {
            return 'Nenhum filtro aplicado!'
        }
    }

    moutTypeFilter() {
        if(this.typeFilter.isSelected) {
            return this.typeFilter.isSMI ? 'SMI' : 'SMC'
        } else {
            return 'Nenhum filtro aplicado!'
        }
    }

    mountReadingFilter() {
        if(this.readingFilter.isSelected) {
            return this.readingFilter.reading;
        } else {
            return 'Nenhum filtro aplicado!'
        }
    }

    moutStatusFilter() {
        if(this.statusFilter.isSelected) {
            return this.statusFilter.isOnline ? 'Online' : 'Offline'
        } else {
            return 'Nenhum filtro aplicado!'
        }
    }

    addComissionedFilter(value) {
        if(this.comissionedFilter.comissioned == value) {
            return this.comissionedFilter.comissioned = false;
        }
        this.comissionedFilter.comissioned = value;
    }

    addReadingFilter(value: string) {
        if(this.readingFilter.isSelected && this.readingFilter.reading === value) {
            return this.readingFilter.isSelected = false;
        }

        this.readingFilter = {
            isSelected: true,
            reading: value
        }
    }

    addTypeFilter(value: boolean) {
        if(this.typeFilter.isSelected && this.typeFilter.isSMI === value) {
            return this.typeFilter.isSelected = false;
        }

        this.typeFilter = {
            isSelected: true,
            isSMI: value
        }
    }

    addStatusFilter(value: boolean) {
        if(this.statusFilter.isSelected && this.statusFilter.isOnline === value) {
            return this.statusFilter.isSelected = false;
        }

        this.statusFilter = {
            isSelected: true,
            isOnline: value
        }
    }

    getTags() {
        this.isLoadingTags = true;
        this
            .tagsSerivce
            .getTags()
            .toPromise()
            .then((res) => {
                this.tags = res as TagsDTO[];
                this.filteredTagsSelect = this.tags;
                this.isLoadingTags = false;
            }).catch(error => {
                this.erroHandler.handle(error);
                this.isLoadingTags = false;
            });
    }

    changeTag(event) {
        const tag = this.tags.filter(tag => tag.visibleId === event.id);
        event.checked 
            ? this.filteredTags.push(...tag) 
            : this.filteredTags = this.filteredTags.filter(tag => tag.visibleId !== event.id);
    }

    searchTag(event) {
        if(this.tagFilter) {
            this.filteredTagsSelect = []
            this.tags.forEach(tag => {
                if(tag.keyword.toLowerCase().match(this.tagFilter.toLowerCase())) {
                    this.filteredTagsSelect.push(tag);
                }
            });
        } else {
            this.filteredTagsSelect = this.tags;
        }
    }

    cleanFilter() {
        this.filteredTags = [];
        this.statusFilter.isSelected = false;
        this.typeFilter.isSelected = false;
        this.readingFilter.isSelected = false;
        this.filterHasBeenApplied = false;
        this.comissionedFilter.comissioned = true;
        this.cleanFilterSend.emit(this.getFilters());
        this.closeModal();
    }

    getColorReading(value: string) {
       if(value === '0') return CELLTABLECOLORS.ZERO;
       if(value === '1-3') return CELLTABLECOLORS.OneThree;
       if(value === '+3') return CELLTABLECOLORS.GreaterThanThree;
    }
}