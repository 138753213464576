import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IAllAlerts, ReadStatistic } from 'src/app/core/models/AlertDTO';
import { IPaginationResponseDTO } from 'src/app/core/models/PaginationResponseDTO';
import { IogHttpService } from 'src/app/security/iog-http.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AlertsService {
  static metersAlarmsUrl: string = environment.apiUrlBack + '/meter-alarms/'
  static smcAlarmsUrl: string = environment.apiUrlBack + '/smc-alarms/'
  static criticsUrl: string = environment.apiUrlBack + '/critical-alarms/'

  constructor(
    private _iogHttp: IogHttpService,
  ) { }


  getMetersAlerts({ page = 0, pageSize = 15, isCritical = false, tags = [], endDate = null, startDate = null, read = false, searchString = null }): Observable<IPaginationResponseDTO> {
    const params = `alarm-types-with-quantity?
      ${(isCritical == true || isCritical == false) ? `isCritical=${isCritical}&` : ''}
      ${endDate != null ? `endDate=${endDate}&` : ''}
      ${startDate != null ? `startDate=${startDate}&` : ''}
      ${read != null ? `wasRead=${read}&` : ''}
      ${tags.length ? tags.map(tag => `visibleId=${tag}&`).join().replace(/\,/g, '') : ''}
      ${searchString != null ? `searchString=${searchString}&` : ''}
      page=${page}&pageSize=${pageSize}
    `.replace(/\s/g, '');
    return this._iogHttp.get<IPaginationResponseDTO>(AlertsService.metersAlarmsUrl + params);
  }

  getSMCAlerts({ page = 0, pageSize = 15, isCritical = false, endDate = null, startDate = null, read = false, searchString = null }): Observable<IPaginationResponseDTO> {
    const params = `alarm-types-with-quantity?
      ${(isCritical == true || isCritical == false) ? `isCritical=${isCritical}&` : ''}
      ${endDate != null ? `endDate=${endDate}&` : ''}
      ${startDate != null ? `startDate=${startDate}&` : ''}
      ${read != null ? `wasRead=${read}&` : ''}
      ${searchString != null ? `searchString=${searchString}&` : ''}
      page=${page}&pageSize=${pageSize}
    `.replace(/\s/g, '');
    return this._iogHttp.get(AlertsService.smcAlarmsUrl + params);
  }


  getAllSMIAlarms(): Observable<IAllAlerts[]> {
    return this._iogHttp.get(AlertsService.metersAlarmsUrl + `all-alarm-types`)
  }

  getAllSMCAlarms(): Observable<IAllAlerts[]> {
    return this._iogHttp.get<IAllAlerts[]>(AlertsService.smcAlarmsUrl + 'all-alarm-types');
  }

  getSMIAlertReadingStatistics({ tags = [], endDate = null, startDate = null }): Observable<ReadStatistic> {
    const params = `alarm-statistics?
      ${endDate != null ? `endDate=${endDate}&` : ''}
      ${startDate != null ? `startDate=${startDate}&` : ''}
      ${tags.length ? tags.map(tag => `visibleIds=${tag}&`).join().replace(/\,/g, '') : ''}
    `.replace(/\s/g, '');
    return this._iogHttp.get<ReadStatistic>(AlertsService.metersAlarmsUrl + params)
  }

  getSMCAlertReadingStatistics({ endDate = null, startDate = null }): Observable<ReadStatistic> {
    const params = `alarm-statistics?
      ${endDate != null ? `endDate=${endDate}&` : ''}
      ${startDate != null ? `startDate=${startDate}&` : ''}
    `.replace(/\s/g, '');
    return this._iogHttp.get<ReadStatistic>(AlertsService.smcAlarmsUrl + params)
  }


  /**
   * Adiciona os alarmes como alarmes criticos
   * @param alarmsCritical lista dos alarmes a serem adicionados como criticos
   * @returns 200 
   */
  setCriticalAlarms(alarmsCritical: IAllAlerts[]) {
    return this._iogHttp.post(AlertsService.criticsUrl, alarmsCritical);
  }

  removeCriticalAlarms(criticalAlarmsID: string[]) {
    return this._iogHttp.post(AlertsService.criticsUrl + 'delete-by-id', criticalAlarmsID);
  }


  getMeterAlarmByDescription(alrmDescription: string, page = 1, pageSize = 15, searchString = '', endDate = '', startDate = '', sortBy=''): Observable<IPaginationResponseDTO> {
    return this._iogHttp.get<IPaginationResponseDTO>(AlertsService.metersAlarmsUrl + `by-type?type=${alrmDescription}&page=${page}&pageSize=${pageSize}&searchString=${searchString}&endDateTime=${endDate}&startDateTime=${startDate}&sortBy=${sortBy}`);
  }


  getSMCAlarmByDescription(alrmDescription: string, page = 1, pageSize = 15, searchString = '', endDate = '', startDate = '', sortBy = ''): Observable<IPaginationResponseDTO> {
    return this._iogHttp.get<IPaginationResponseDTO>(AlertsService.smcAlarmsUrl + `by-type?type=${alrmDescription}&page=${page}&pageSize=${pageSize}&searchString=${searchString}&endDateTime=${endDate}&startDateTime=${startDate}&sortBy=${sortBy}`);
  }

  markSmiAlarmAsRead({ description, serials }) {
    return this._iogHttp.post(AlertsService.metersAlarmsUrl + 'mark-as-read', { description, serials });
  }

  markSmcAlarmAsRead({ description, serials }) {
    return this._iogHttp.post(AlertsService.smcAlarmsUrl + 'mark-as-read', { description, serials });
  }

  markAllSmcAlarm(desc = "") {
    return this._iogHttp.post(AlertsService.smcAlarmsUrl + 'mark-all-as-read', { description: desc });
  }

  markAllSmiAlarm(desc = "") {
    return this._iogHttp.post(AlertsService.metersAlarmsUrl + 'mark-all-as-read', { description: desc });
  }
}
