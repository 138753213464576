export enum DATA_ACESS_RESULT {
    SUCESS = 0,
    HARDWARE_FAULT = 1
}

export enum DLMS_DATAS_TYPES {
    DLMS_ARRAY_STRUCT = 2,
    OCTECT_STRING = 9,
    UINT_8 = 17,
    COMPACT_ARRAY = 19,

}

export enum PROTOCOL_TYPE {
    DLMS_V2 = 0,
    IOG_V2 = 1,
}

export enum MESSAGE_TYPE {
    READ_RESPONSE = 0X0C,
    READ_REQUEST = 0X05,
    WRITE_RESPONSE = 0X0D,

}

export enum DLMS_INSTANCES {
    arrayStruct = 'arrayStruct',
    octectString = 'octectString',
    compactArray = 'compactArray',
    uint8 = 'uint8',
}


export function copyRange(target: number[], at: number, source: number[], start: number = 0, end: number = null): number[] {

    end = isValidRange(start, end, source.length);
    if (end === null) {
        throw new Error('Invalido');
    }
    let lenght = end - start;
    if (target.length < at + lenght) {
        throw new Error('Erro na função copyRange, fora do intervalo');
    }

    if (source !== target || start >= at) {
        for (let index: number = 0; index < lenght; index++) {
            target[at + index] = source[start + index];
        }
    } else {
        for (let index: number = 0; lenght; --index >= 0) {
            target[at + index] = source[start + index];
        }
    }

    return target;
}



export function isValidRange(start: number, end: number, lenght: number): number {
    if (0 > start || start > lenght)
        throw RangeError('Intervalo Invalido');

    if (end !== null) {
        if (start > end || end > lenght)
            throw RangeError('Intervalo invalido');
        return end;
    }
    return lenght;
}
