import { Injectable } from "@angular/core";
import "rxjs/add/operator/map";

import { environment } from "src/environments/environment";
import { IogHttpService } from "src/app/security/iog-http.service";

@Injectable({
  providedIn: "root",
})
export class RegistersService {
  static url = environment.apiUrlBack + "/registers";

  constructor(private http: IogHttpService) {}

  getRegisters() {
    return this.http
      .get(RegistersService.url)
      .map((response) => response.valueOf());
  }
}
