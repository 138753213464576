import { Component, Input } from "@angular/core";

@Component({
    selector: 'app-copy-button',
    templateUrl: './copy-button.component.html',
    styleUrls: ['./copy-button.component.scss']
})
export class CopyButtonComponent {
    @Input() text: string = '';

    clicked: boolean = false;
    itsSafe: boolean = window.isSecureContext;
    constructor() { }

    copy() {
        navigator
            .clipboard
            .writeText(this.text)
            .then(() => {
                this.clicked = true;
                setInterval(() => this.clicked = false, 500)
            });

    }
}