import { FileService } from 'src/app/shared/services/file.service';
import { Component, Input, Output, EventEmitter } from "@angular/core";
import { NgForm } from "@angular/forms";
import * as XLS from 'xlsx';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SmcModelDTO } from "src/app/core/models/SmcModelDTO";
import { SMCNotRegisteredDTO } from "src/app/core/models/SmcNotRegisteredDTO";
import { Utils } from "src/app/shared/utils";
import { HandleErrorService } from "../../services/handle-error.service";
import { MessageService } from "../../services/message.service";
import { SmcsService } from "../../services/smcs.service";

@Component({
  selector: "app-smc-not-registred-table",
  templateUrl: "./smc-not-registred-table.component.html",
  styleUrls: ["./smc-not-registred-table.component.scss"],
})
export class SmcNotRegistredTableComponent {
  @Input() smcnotregistred: SMCNotRegisteredDTO[] = [];
  @Input() isLoading = true;

  @Output() filter = new EventEmitter();
  @Output() reload = new EventEmitter();

  public smcToCreate: SMCNotRegisteredDTO = new SMCNotRegisteredDTO();

  SmcModels: SmcModelDTO[] = [];
  allModels: SmcModelDTO[] = [];

  Utils = Utils;
  isLoadingAllSmcNotRegistred = false;
  constructor(
    private modalService: NgbModal,
    private smcService: SmcsService,
    private messageService: MessageService,
    private handleService: HandleErrorService,
    private fileService: FileService
  ) {
    this.getSmcModels();
  }


  private getWorkSheet(rows): XLS.WorkSheet {
    const workSheet = XLS.utils.json_to_sheet([...rows]);
    workSheet["!cols"] = [{ width: 45 }];
    return workSheet;
  }

  private getWorkBook(workSheet: XLS.WorkSheet): XLS.WorkBook {
    return { 
      Sheets: { 
        "SMCs Não Registrados": workSheet, 
      }, 
      SheetNames: ["SMCs Não Registrados"]
    }
  }

  private writeXls(workBook) {
    return XLS.write(workBook, { bookType: 'xlsx', type: 'array' })
  }

  downloadAllSmcNotRegistred() {
    this.isLoadingAllSmcNotRegistred = true;
    this
      .smcService
      .getSMCUnregistered()
      .toPromise()
      .then((smcs: SMCNotRegisteredDTO[]) => this.processSmcs(smcs))
      .then(this.getWorkSheet)
      .then(this.getWorkBook)
      .then(this.writeXls)
      .then(buffer => this.fileService.saveFileAsExcel(buffer, "Todos os SMCs - Não Registrados"))
      .then(() => this.isLoadingAllSmcNotRegistred = false)
      .catch((error) => {
        this.handleService.handle(error);
        this.isLoadingAllSmcNotRegistred = false;
      });
  }

  private processSmcs(smcs: SMCNotRegisteredDTO[]) {
    let smcsList = [];
    smcs.forEach(_smc => {
      const meter = {
        Serial: _smc.serial,
        ['Eui']: _smc.deviceEui,
        ['Quantidade de Clientes']: _smc.qtyMeters,
        ['Última Modificação']: this.Utils.pipeDateTime(_smc.updatedAt)
      };
      smcsList.push(meter);
    });
    return smcsList;
  }

  filterBySerialAndDevEuid(value) {
    this.filter.emit(value);
  }

  openModal(content, row: SMCNotRegisteredDTO) {
    this.modalService.open(content, {
      centered: true
    })
    this.smcToCreate.serial = row.serial;
    this.smcToCreate.smcModel = this.allModels.find(_model => _model.name === 'PANTHEON');
  }

  changeModel(event) {
    const Models = this.allModels;
    const modelName = event.target.value;
    const selectedModel = Models.find(model => model.name === modelName);
    this.smcToCreate.smcModel = selectedModel;

  }

  reloadSmc() {
    this.reload.emit();
  }

  getSmcModels() {
    this.smcService.getSmcModels().toPromise()
      .then((models: SmcModelDTO[]) => {
        this.SmcModels = models;
        this.allModels = models;
      });
  }

  commitModalSmc(form: NgForm) {
    this.isLoading = true;
    this.smcService.saveSmc(this.smcToCreate).toPromise()
      .then(
        () => {
          this.finishOperation(form);
          this.messageService.showSuccess('Operação Concluída', `SMC ${this.smcToCreate.serial} com sucesso`);
          this.isLoading = false;
        }
      ).catch(err => {
        this.isLoading = false;
        this.handleService.handle(err);
      });
  }

  finishOperation(form?: NgForm) {
    this.smcToCreate = new SMCNotRegisteredDTO();
    this.modalService.dismissAll();
    if (form) {
      form.reset();
    }
  }
}
