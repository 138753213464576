import { Injectable } from "@angular/core";
import { IogHttpService } from "src/app/security/iog-http.service";
import { environment } from "src/environments/environment";
import { CommandsService } from "./commands.service";

@Injectable({ providedIn: 'root' })
export class MassMemoryServices {
  static url: string = environment.apiUrlBack;
  constructor(private http: IogHttpService) { }

  getMeterEnergy(
    serial: string,
    pageSize: number = 15,
    sortOrder: string = "dateTime-desc",
    currentPage: number = 1,
    startDateTime: string = "",
    endDateTime: string = ""
  ) {
    return this.http
      .get(
        `${MassMemoryServices.url}/data-readings/${serial}?page=${currentPage}&sortOrder=${sortOrder}&pageSize=${pageSize}&startDateTime=${startDateTime}&endDateTime=${endDateTime}`
      )
      .map((res) => res.valueOf());
  }

  recoverMassMemory(
    serial: string,
    startDateTime: string,
    endDateTime: string,
    intervalInMinutes: number
  ) {
    return this.http.post(
      CommandsService.url +
      `recover-mass-memory/${serial}?startDate=${startDateTime}&endDate=${endDateTime}&intervalInMinutes=${intervalInMinutes}`,
      {}
    );
  }

  recoveryMassMemorySMC(serial: string, quantity: number) {
    return this.http.post(
      CommandsService.url +
      `recover-mass-memory-smc/${serial}?quantity=${quantity}`,
      {}
    );
  }

  uploadMassMemory(serial: string, json: any) {
    return this.http.post(
      MassMemoryServices.url + "/data-readings/" + serial,
      json
    );
  }
}
