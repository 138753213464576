import { Component, Input, Output, EventEmitter } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { MeterDTO } from "src/app/core/models/MeterDTO";
import { UserType } from "src/app/core/models/UserDTO";
import { AuthService } from "src/app/security/auth.service";
import { HandleErrorService } from "../../services/handle-error.service";
import { MessageService } from "../../services/message.service";
import { QualityIndicatorsServices } from "../../services/quality-indicators.service";
import { CommandsService } from "../../services/commands.service";

@Component({
  selector: "app-quality-incators-button",
  templateUrl: "./quality-incators-button.component.html",
  styleUrls: ["./quality-incators-button.component.scss"],
})
export class QualityIncatorsButtonComponent {
  @Input() meter: MeterDTO = null;
  @Input() loadingQualityIndicators = false;

  @Output() requestQualityIndicators = new EventEmitter();

  public amountOfSample: number = null;
  public isLoading = false;
  public hasPermitionToRecoveryQEE = [UserType.ADMIN];

  constructor(
    private modalService: NgbModal,
    private qeeServices: QualityIndicatorsServices,
    private handleService: HandleErrorService,
    private messageService: MessageService,
    public authService: AuthService,
    public commandService: CommandsService
  ) {}

  openModal(content) {
    this.modalService.open(content, { backdrop: "static", centered: true });
  }

  setPramsQuality() {
    this.loadingQualityIndicators=true;
    this.commandService.setEventQeeIndicator(this.meter.serial)
    .toPromise()
    .then((response) => {
      this.loadingQualityIndicators = false;
      this.messageService.showSuccess(
        "Requisição Enviada",
        "Recuperação de indicadores de qualidade enviada"
      );
      this.close();
    })
    .catch((error) => {
      this.handleService.handle(error);
      this.loadingQualityIndicators = false;
    });
  }

  recoveryQEE() {
    this.isLoading = true;
    if (!this.isEasyTrafo()) {
      this.qeeServices
        .recoveryQEE(this.meter.serial, this.amountOfSample)
        .toPromise()
        .then((response) => {
          this.isLoading = false;
          this.messageService.showSuccess(
            "Requisição Enviada",
            "Recuperação de indicadores de qualidade enviada"
          );
          this.close();
        })
        .catch((error) => {
          this.handleService.handle(error);
          this.isLoading = false;
        });
    } else {
      this.qeeServices
        .recoveryQEEEasyTrafo(this.meter.serial)
        .toPromise()
        .then((response) => {
          this.isLoading = false;
          this.messageService.showSuccess(
            "Requisição Enviada",
            "Recuperação de indicadores de qualidade enviada"
          );
          this.close();
        })
        .catch((error) => {
          this.handleService.handle(error);
          this.isLoading = false;
        });
    }
  }

  isEasyTrafo(): boolean {
    return this.meter.meterModel.name === "EASY TRAFO";
  }

  close() {
    this.amountOfSample = null;
    this.modalService.dismissAll();
  }
}
