import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PaginationResponseDTO } from "src/app/core/models/PaginationResponseDTO";
import { ScheduleCommand, ScheduleCommandPost } from "src/app/core/models/ScheduleCommandDTO";
import { IogHttpService } from "src/app/security/iog-http.service";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: 'root' })
export class ScheduleCommandService {
    static url = environment.apiUrlBack + '/schedules';
    constructor(private httpService: IogHttpService) { }

    getWithPagination({
        searchString = null,
        sortBy = null,
        page = 1,
        pageSize = 15
    }): Observable<PaginationResponseDTO> {
        const params = `?
            ${searchString ? `searchString=${searchString}&` : ''}
            ${sortBy ? `sortBy=${sortBy}&` : ''}
            page=${page}&pageSize=${pageSize}
        `.replace(/\s/g, '');
        return this.httpService.get(ScheduleCommandService.url + params);
    }

    delete(id: string): Observable<void> {
        return this.httpService.delete(ScheduleCommandService.url + '/?id=' + id);
    }

    create(data: ScheduleCommandPost): Observable<any> {
        return this
            .httpService
            .post(ScheduleCommandService.url, data);
    }

    update(id: string, data: ScheduleCommandPost): Observable<void> {
        return this.httpService.put(ScheduleCommandService.url, data);
    }

    getById(id: string): Observable<ScheduleCommand> {
        return this.httpService.get(ScheduleCommandService.url + '/' + id);
    }

    setScheduleStatus(scheduleId: string, status: boolean): Observable<ScheduleCommand> {
        return this.httpService.put<ScheduleCommand>(ScheduleCommandService.url + `/${scheduleId}?status=${status}`, []);
    }
}